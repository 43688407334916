import { formatEther } from 'viem';

export type TokenAmountValue = string | number | bigint;

export type TokenAmountDisplayFormat = 'base' | 'compact' | 'fiat' | 'exact';

const displayFormatMap: { [key in TokenAmountDisplayFormat]: number } = {
  base: 9, // Rounds to a maximum of 9 decimals
  compact: 4, // Rounds to a maximum of 4 decimals
  fiat: 2, // Rounds to a maximum of 2 decimals
  exact: 0, // No decimals
};

/**
 * Used to compare with token amounts to determine if it should get rounded.
 * Contains a hex string that equals to 0.1 for the 'exact' amount,
 * unlike displayFormatMap where it equals 1.0.
 */
const amountThreshold: { [key in TokenAmountDisplayFormat]: bigint } = {
  base: 1000000000n, // 0.000000001
  compact: 100000000000000n, // 0.0001
  fiat: 10000000000000000n, // 0.01
  exact: 100000000000000000n, // 0.1
};

export const formatTokenAmount = (
  tokenAmount: TokenAmountValue = '',
  displayFormat: TokenAmountDisplayFormat = 'base',
  prefix = '',
): string => {
  try {
    const parsed = parse(tokenAmount);

    const threshold = amountThreshold[displayFormat];

    if (parsed < threshold && parsed !== 0n) {
      return `< ${prefix}${format(threshold, displayFormat)}`;
    }

    const formattedValue = format(parsed, displayFormat);
    return `${prefix}${formattedValue}`;
  } catch {
    return `${prefix}${tokenAmount.toString()}`;
  }
};

const parse = (value: TokenAmountValue): bigint => {
  switch (typeof value) {
    case 'bigint':
      return value;
    case 'string':
      if (value.startsWith('0x')) return BigInt(value);
      return BigInt(parseFloat(value) * 10 ** 18);
    case 'number':
      return BigInt(value * 10 ** 18);
    default:
      throw new Error('Invalid value');
  }
};

const format = (value: bigint, displayFormat: TokenAmountDisplayFormat): string => {
  const decimals = displayFormatMap[displayFormat];
  const scale = BigInt(10 ** decimals);
  const scaledValue = value * scale;
  const roundedValue = scaledValue / scale;

  if (roundedValue === 0n) return '0';

  let minimumFractionDigits = 1;
  // Don't alter fractions if an exact treshhold has been passed
  if (roundedValue !== amountThreshold[displayFormat]) {
    if (displayFormat === 'exact') minimumFractionDigits = 0;
    if (displayFormat === 'fiat') minimumFractionDigits = 2;
  }

  const formattedValue = Intl.NumberFormat('en-US', {
    maximumFractionDigits: Math.max(decimals, minimumFractionDigits),
    minimumFractionDigits,
  }).format(Number(formatEther(roundedValue)));

  return formattedValue;
};

export const absBigInt = (value: bigint): bigint => (value < 0n ? -value : value);

import { env } from '@/env.mjs';
import { ENSResponse } from '@/pages/api/ens/[address]';
import { truncate } from '@/utils/truncate';
import { formatWalletAddress } from '@sphere/ui';
import useSWR from 'swr';
import { getAddress } from 'viem';
import { mainnet } from 'wagmi/chains';

export const getENSDetails = async (address: string) => {
  try {
    const response: ENSResponse = await fetch(
      `${env.NEXT_PUBLIC_HOST_URL}/api/ens/${getAddress(address)}`,
    ).then(res => res.json());

    return {
      name: truncate(response.name || '', 24, '…'),
      image: response.avatar,
    };
  } catch {
    return {
      name: null,
      image: null,
    };
  }
};

export const useENSResolver = (address?: string, chainId = 1) => {
  const isENSAvailable = chainId === mainnet.id;

  const { data, ...response } = useSWR<ENSResponse>(
    isENSAvailable && address ? `/api/ens/${getAddress(address)}` : null,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    },
  );

  const { name, avatar } = data ?? {};

  return {
    ...response,
    address,
    name,
    displayName: name ? truncate(name || '', 24, '…') : formatWalletAddress(address || ''),
    avatar,
  };
};

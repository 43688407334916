// @ts-nocheck
/**
 * Generated by orval v6.22.1 🍺
 * Do not edit manually.
 * beam-reservoir API
 * You are viewing the reference docs for the beam-reservoir API.        For a more complete overview with guides and examples, check out the <a href='https://reservoirprotocol.github.io'>Reservoir Protocol Docs</a>.
 * OpenAPI spec version: 5.296.1
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import { client } from './reservoir-axios-client';
import type { ErrorType, BodyType } from './reservoir-axios-client';
export type PostExecuteSolveV1Params = {
/**
 * Signature for the solve request
 */
signature?: string;
};

export type PostExecutePresignatureV1Params = {
/**
 * Signature to attach to the pre-signature
 */
signature: string;
};

export type PostExecutePermitsignatureV1Params = {
/**
 * Signature to attach to the permit
 */
signature: string;
};

export type PostExecuteCancelsignatureV1Params = {
/**
 * Cancellation signature
 */
signature?: string;
/**
 * Optional auth token used instead of the signature
 */
auth?: string;
};

export type PostExecuteCancelimtblV1Params = {
/**
 * Cancellation signature
 */
signature?: string;
};

export type PostExecuteAuthsignatureV1Params = {
/**
 * Signature to attach to the auth challenge
 */
signature: string;
};

/**
 * 1 - All calls per hour/day/month<br>2 - All calls per key per hour/day/month<br>3 - All calls per key per route per hour/day/month<br>4 - All calls per key per route per status code per hour/day/month<br>
 */
export type PostAdminApikeysMetricsBodyGroupBy = typeof PostAdminApikeysMetricsBodyGroupBy[keyof typeof PostAdminApikeysMetricsBodyGroupBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminApikeysMetricsBodyGroupBy = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;

/**
 * Return results by either hourly/daily/monthly granularity.<br>Hourly will return time in format YYYY-MM-DDTHH:00:000Z<br>Daily will return time in format YYYY-MM-DDT00:00:000Z<br>Monthly will return time in format YYYY-MM-01T00:00:000Z<br>
 */
export type PostAdminApikeysMetricsBodyGranularity = typeof PostAdminApikeysMetricsBodyGranularity[keyof typeof PostAdminApikeysMetricsBodyGranularity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostAdminApikeysMetricsBodyGranularity = {
  hourly: 'hourly',
  daily: 'daily',
  monthly: 'monthly',
} as const;

export type PostAdminApikeysMetricsBody = {
  /** Get metrics before a particular time (allowed format YYYY-MM-DD HH:00) */
  endTime?: string;
  /** Return results by either hourly/daily/monthly granularity.<br>Hourly will return time in format YYYY-MM-DDTHH:00:000Z<br>Daily will return time in format YYYY-MM-DDT00:00:000Z<br>Monthly will return time in format YYYY-MM-01T00:00:000Z<br> */
  granularity?: PostAdminApikeysMetricsBodyGranularity;
  /** 1 - All calls per hour/day/month<br>2 - All calls per key per hour/day/month<br>3 - All calls per key per route per hour/day/month<br>4 - All calls per key per route per status code per hour/day/month<br> */
  groupBy?: PostAdminApikeysMetricsBodyGroupBy;
  /** Array API keys */
  keys: string[];
  /** Get metrics after a particular time (allowed format YYYY-MM-DD HH:00)<br>Hourly default to last 24 hours<br>Daily default to last 7 days<br>Monthly default to last 12 months */
  startTime?: string;
};

export type PostOrderV4Params = {
signature?: string;
};

export type PostOrderV3Params = {
signature?: string;
};

export type PostOrderV2Params = {
signature?: string;
};

export type PostApikeysBody = {
  /** The name of your app */
  appName: string;
  /** An e-mail address where you can be reached, in case of issues, to avoid service disruption */
  email: string;
  /** The website of your project */
  website: string;
};

export type GetRedirectTokensTokenImageV1ImageSize = typeof GetRedirectTokensTokenImageV1ImageSize[keyof typeof GetRedirectTokensTokenImageV1ImageSize];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetRedirectTokensTokenImageV1ImageSize = {
  small: 'small',
  medium: 'medium',
  large: 'large',
} as const;

export type GetRedirectTokensTokenImageV1Params = {
/**
 * Image size: 'small', 'medium', or 'large'. 
 */
imageSize?: GetRedirectTokensTokenImageV1ImageSize;
};

export type GetOrdersUsersUserTopbidsV3SortDirection = typeof GetOrdersUsersUserTopbidsV3SortDirection[keyof typeof GetOrdersUsersUserTopbidsV3SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV3SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetOrdersUsersUserTopbidsV3SortBy = typeof GetOrdersUsersUserTopbidsV3SortBy[keyof typeof GetOrdersUsersUserTopbidsV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV3SortBy = {
  topBidValue: 'topBidValue',
  dateCreated: 'dateCreated',
  orderExpiry: 'orderExpiry',
  floorDifferencePercentage: 'floorDifferencePercentage',
} as const;

export type GetOrdersUsersUserTopbidsV3Params = {
collection?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * If true, urls will only be returned for optimized sources that support royalties.
 */
optimizeCheckoutURL?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, will return the collection non flagged floor ask events.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Order of the items are returned in the response.
 */
sortBy?: GetOrdersUsersUserTopbidsV3SortBy;
sortDirection?: GetOrdersUsersUserTopbidsV3SortDirection;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Amount of tokens considered.
 */
sampleSize?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersUsersUserTopbidsV1SortDirection = typeof GetOrdersUsersUserTopbidsV1SortDirection[keyof typeof GetOrdersUsersUserTopbidsV1SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV1SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetOrdersUsersUserTopbidsV1SortBy = typeof GetOrdersUsersUserTopbidsV1SortBy[keyof typeof GetOrdersUsersUserTopbidsV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV1SortBy = {
  topBidValue: 'topBidValue',
  dateCreated: 'dateCreated',
  orderExpiry: 'orderExpiry',
  floorDifferencePercentage: 'floorDifferencePercentage',
} as const;

export type GetOrdersUsersUserTopbidsV1Params = {
collection?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * If true, urls will only be returned for optimized sources that support royalties.
 */
optimizeCheckoutURL?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Order of the items are returned in the response.
 */
sortBy?: GetOrdersUsersUserTopbidsV1SortBy;
sortDirection?: GetOrdersUsersUserTopbidsV1SortDirection;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetOrdersUsersUserTopbidsV2SortDirection = typeof GetOrdersUsersUserTopbidsV2SortDirection[keyof typeof GetOrdersUsersUserTopbidsV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetOrdersUsersUserTopbidsV2SortBy = typeof GetOrdersUsersUserTopbidsV2SortBy[keyof typeof GetOrdersUsersUserTopbidsV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV2SortBy = {
  topBidValue: 'topBidValue',
  dateCreated: 'dateCreated',
  orderExpiry: 'orderExpiry',
  floorDifferencePercentage: 'floorDifferencePercentage',
} as const;

export type GetOrdersUsersUserTopbidsV2Params = {
collection?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * If true, urls will only be returned for optimized sources that support royalties.
 */
optimizeCheckoutURL?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, will return the collection non flagged floor ask events.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Order of the items are returned in the response.
 */
sortBy?: GetOrdersUsersUserTopbidsV2SortBy;
sortDirection?: GetOrdersUsersUserTopbidsV2SortDirection;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetOrdersUsersUserTopbidsV4SortDirection = typeof GetOrdersUsersUserTopbidsV4SortDirection[keyof typeof GetOrdersUsersUserTopbidsV4SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV4SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetOrdersUsersUserTopbidsV4SortBy = typeof GetOrdersUsersUserTopbidsV4SortBy[keyof typeof GetOrdersUsersUserTopbidsV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersUsersUserTopbidsV4SortBy = {
  topBidValue: 'topBidValue',
  dateCreated: 'dateCreated',
  orderExpiry: 'orderExpiry',
  floorDifferencePercentage: 'floorDifferencePercentage',
} as const;

export type GetOrdersUsersUserTopbidsV4Params = {
collection?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * If true, urls will only be returned for optimized sources that support royalties.
 */
optimizeCheckoutURL?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts.
 */
excludeEOA?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, will return the collection non flagged floor ask events.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Order of the items are returned in the response. Options are `topBidValue`, `dateCreated`, `orderExpiry`, and `floorDifferencePercentage`.
 */
sortBy?: GetOrdersUsersUserTopbidsV4SortBy;
sortDirection?: GetOrdersUsersUserTopbidsV4SortDirection;
/**
 * Amount of items returned in response. Max limit is 100
 */
limit?: number;
/**
 * Amount of tokens considered. Min is 1000, max is default.
 */
sampleSize?: number;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetCollectionsCollectionAttributesExploreV5Params = {
/**
 * Filter to a particular token-id. Example: `1`
 */
tokenId?: string;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, range traits will be excluded from the response.
 */
excludeRangeTraits?: boolean;
/**
 * If true, number traits will be excluded from the response.
 */
excludeNumberTraits?: boolean;
/**
 * Filter to a particular attribute key. Example: `Composition`
 */
attributeKey?: string;
/**
 * Max number of items returned in the response.
 */
maxFloorAskPrices?: number;
/**
 * Max number of items returned in the response.
 */
maxLastSells?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Default limit is 20. Max limit is 5000.
 */
limit?: number;
};

export type GetCollectionsCollectionAttributesExploreV3SortBy = typeof GetCollectionsCollectionAttributesExploreV3SortBy[keyof typeof GetCollectionsCollectionAttributesExploreV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionAttributesExploreV3SortBy = {
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetCollectionsCollectionAttributesExploreV3Params = {
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * Filter to a particular attribute key. Example: `Composition`
 */
attributeKey?: string;
/**
 * Max number of items returned in the response.
 */
maxFloorAskPrices?: number;
/**
 * Max number of items returned in the response.
 */
maxLastSells?: number;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetCollectionsCollectionAttributesExploreV3SortBy;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetCollectionsCollectionAttributesExploreV1SortBy = typeof GetCollectionsCollectionAttributesExploreV1SortBy[keyof typeof GetCollectionsCollectionAttributesExploreV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionAttributesExploreV1SortBy = {
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetCollectionsCollectionAttributesExploreV1Params = {
/**
 * Filter to a particular attribute key, e.g. `Composition`
 */
attributeKey?: string;
sortBy?: GetCollectionsCollectionAttributesExploreV1SortBy;
offset?: number;
limit?: number;
};

export type GetCollectionsCollectionAttributesAllV4Params = {
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetCollectionsCollectionAttributesExploreV2SortBy = typeof GetCollectionsCollectionAttributesExploreV2SortBy[keyof typeof GetCollectionsCollectionAttributesExploreV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionAttributesExploreV2SortBy = {
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetCollectionsCollectionAttributesExploreV2Params = {
/**
 * Filter to a particular attribute key. Example: `Composition`
 */
attributeKey?: string;
/**
 * Max number of items returned in the response.
 */
maxFloorAskPrices?: number;
/**
 * Max number of items returned in the response.
 */
maxLastSells?: number;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetCollectionsCollectionAttributesExploreV2SortBy;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetCollectionsCollectionAttributesExploreV4Params = {
/**
 * Filter to a particular token-id. Example: `1`
 */
tokenId?: string;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, range traits will be excluded from the response.
 */
excludeRangeTraits?: boolean;
/**
 * If true, number traits will be excluded from the response.
 */
excludeNumberTraits?: boolean;
/**
 * Filter to a particular attribute key. Example: `Composition`
 */
attributeKey?: string;
/**
 * Max number of items returned in the response.
 */
maxFloorAskPrices?: number;
/**
 * Max number of items returned in the response.
 */
maxLastSells?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Default limit is 20. Max limit is 5000.
 */
limit?: number;
};

export type GetUsersUserTokensV7SortDirection = typeof GetUsersUserTokensV7SortDirection[keyof typeof GetUsersUserTokensV7SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV7SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetUsersUserTokensV7SortBy = typeof GetUsersUserTokensV7SortBy[keyof typeof GetUsersUserTokensV7SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV7SortBy = {
  acquiredAt: 'acquiredAt',
  lastAppraisalValue: 'lastAppraisalValue',
} as const;

export type GetUsersUserTokensV7Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response. Options are `acquiredAt` and `lastAppraisalValue`. `lastAppraisalValue` is the value of the last sale.
 */
sortBy?: GetUsersUserTokensV7SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetUsersUserTokensV7SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 200.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, attributes will be returned in the response.
 */
includeAttributes?: boolean;
/**
 * If true, last sale data including royalties paid will be returned in the response.
 */
includeLastSale?: boolean;
/**
 * If true, raw data is included in the response.
 */
includeRawData?: boolean;
/**
 * If true, will filter any tokens marked as spam.
 */
excludeSpam?: boolean;
/**
 * If true, will return the collection non flagged floor ask.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Input any ERC20 address to return result in given currency. Applies to `topBid` and `floorAsk`.
 */
displayCurrency?: string;
};

export type GetUsersUserTokensV5SortDirection = typeof GetUsersUserTokensV5SortDirection[keyof typeof GetUsersUserTokensV5SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV5SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetUsersUserTokensV5SortBy = typeof GetUsersUserTokensV5SortBy[keyof typeof GetUsersUserTokensV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV5SortBy = {
  acquiredAt: 'acquiredAt',
} as const;

export type GetUsersUserTokensV5Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Array of tokens. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetUsersUserTokensV5SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetUsersUserTokensV5SortDirection;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetUsersUserTokensV3SortDirection = typeof GetUsersUserTokensV3SortDirection[keyof typeof GetUsersUserTokensV3SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV3SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetUsersUserTokensV3SortBy = typeof GetUsersUserTokensV3SortBy[keyof typeof GetUsersUserTokensV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV3SortBy = {
  acquiredAt: 'acquiredAt',
} as const;

export type GetUsersUserTokensV3Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetUsersUserTokensV3SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetUsersUserTokensV3SortDirection;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
};

export type GetUsersUserTokensV1SortDirection = typeof GetUsersUserTokensV1SortDirection[keyof typeof GetUsersUserTokensV1SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV1SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetUsersUserTokensV1SortBy = typeof GetUsersUserTokensV1SortBy[keyof typeof GetUsersUserTokensV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV1SortBy = {
  topBuyValue: 'topBuyValue',
} as const;

export type GetUsersUserTokensV1Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
hasOffer?: boolean;
sortBy?: GetUsersUserTokensV1SortBy;
sortDirection?: GetUsersUserTokensV1SortDirection;
offset?: number;
limit?: number;
};

export type GetUsersUserCollectionsV3Params = {
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, number of tokens with bids will be returned in the response.
 */
includeLiquidCount?: boolean;
/**
 * If true, will filter any collections marked as spam.
 */
excludeSpam?: boolean;
/**
 * Use offset to request the next batch of items. Max is 10,000.
 */
offset?: number;
/**
 * Amount of items returned in response. max limit is 100.
 */
limit?: number;
/**
 * Input any ERC20 address to return result in given currency. Applies to `topBid` and `floorAsk`.
 */
displayCurrency?: string;
};

export type GetUsersUserCollectionsV1Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
offset?: number;
limit?: number;
};

export type GetUsersUserActivityV1TypesItem = typeof GetUsersUserActivityV1TypesItem[keyof typeof GetUsersUserActivityV1TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserActivityV1TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetUsersUserActivityV1Params = {
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: number;
types?: GetUsersUserActivityV1TypesItem[];
};

export type GetUsersUserCollectionsV2SortDirection = typeof GetUsersUserCollectionsV2SortDirection[keyof typeof GetUsersUserCollectionsV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserCollectionsV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetUsersUserCollectionsV2SortBy = typeof GetUsersUserCollectionsV2SortBy[keyof typeof GetUsersUserCollectionsV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserCollectionsV2SortBy = {
  allTimeVolume: 'allTimeVolume',
  '1DayVolume': '1DayVolume',
  '7DayVolume': '7DayVolume',
  '30DayVolume': '30DayVolume',
} as const;

export type GetUsersUserCollectionsV2Params = {
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, number of tokens with bids will be returned in the response.
 */
includeLiquidCount?: boolean;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Order the items are returned in the response. Defaults to allTimeVolume
 */
sortBy?: GetUsersUserCollectionsV2SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetUsersUserCollectionsV2SortDirection;
};

export type GetUsersUserPositionsV1Status = typeof GetUsersUserPositionsV1Status[keyof typeof GetUsersUserPositionsV1Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserPositionsV1Status = {
  valid: 'valid',
  invalid: 'invalid',
} as const;

export type GetUsersUserPositionsV1Side = typeof GetUsersUserPositionsV1Side[keyof typeof GetUsersUserPositionsV1Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserPositionsV1Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export type GetUsersUserPositionsV1Params = {
side: GetUsersUserPositionsV1Side;
status: GetUsersUserPositionsV1Status;
offset?: number;
limit?: number;
};

export type GetUsersUserTokensV2SortDirection = typeof GetUsersUserTokensV2SortDirection[keyof typeof GetUsersUserTokensV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetUsersUserTokensV2SortBy = typeof GetUsersUserTokensV2SortBy[keyof typeof GetUsersUserTokensV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV2SortBy = {
  acquiredAt: 'acquiredAt',
} as const;

export type GetUsersUserTokensV2Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetUsersUserTokensV2SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetUsersUserTokensV2SortDirection;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetUsersUserTokensV4SortDirection = typeof GetUsersUserTokensV4SortDirection[keyof typeof GetUsersUserTokensV4SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV4SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetUsersUserTokensV4SortBy = typeof GetUsersUserTokensV4SortBy[keyof typeof GetUsersUserTokensV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV4SortBy = {
  acquiredAt: 'acquiredAt',
} as const;

export type GetUsersUserTokensV4Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetUsersUserTokensV4SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetUsersUserTokensV4SortDirection;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetUsersUserTokensV6SortDirection = typeof GetUsersUserTokensV6SortDirection[keyof typeof GetUsersUserTokensV6SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV6SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetUsersUserTokensV6SortBy = typeof GetUsersUserTokensV6SortBy[keyof typeof GetUsersUserTokensV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersUserTokensV6SortBy = {
  acquiredAt: 'acquiredAt',
  lastAppraisalValue: 'lastAppraisalValue',
} as const;

export type GetUsersUserTokensV6Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Array of tokens. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetUsersUserTokensV6SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetUsersUserTokensV6SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, dynamic pricing data will be returned in the response.
 */
includeDynamicPricing?: boolean;
/**
 * If true, will return the collection non flagged floor ask.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetTokensTokenActivityV4TypesItem = typeof GetTokensTokenActivityV4TypesItem[keyof typeof GetTokensTokenActivityV4TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV4TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetTokensTokenActivityV4SortBy = typeof GetTokensTokenActivityV4SortBy[keyof typeof GetTokensTokenActivityV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV4SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetTokensTokenActivityV4Params = {
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetTokensTokenActivityV4SortBy;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetTokensTokenActivityV4TypesItem[];
};

export type GetTokensTokenActivityV2TypesItem = typeof GetTokensTokenActivityV2TypesItem[keyof typeof GetTokensTokenActivityV2TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV2TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetTokensTokenActivityV2SortBy = typeof GetTokensTokenActivityV2SortBy[keyof typeof GetTokensTokenActivityV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV2SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetTokensTokenActivityV2Params = {
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetTokensTokenActivityV2SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetTokensTokenActivityV2TypesItem[];
};

export type GetTokensTokenActivityV1TypesItem = typeof GetTokensTokenActivityV1TypesItem[keyof typeof GetTokensTokenActivityV1TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV1TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetTokensTokenActivityV1Params = {
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: number;
types?: GetTokensTokenActivityV1TypesItem[];
};

export type GetTokensTokenActivityV3TypesItem = typeof GetTokensTokenActivityV3TypesItem[keyof typeof GetTokensTokenActivityV3TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV3TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetTokensTokenActivityV3SortBy = typeof GetTokensTokenActivityV3SortBy[keyof typeof GetTokensTokenActivityV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV3SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetTokensTokenActivityV3Params = {
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetTokensTokenActivityV3SortBy;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetTokensTokenActivityV3TypesItem[];
};

export type GetTokensTokenActivityV5TypesItem = typeof GetTokensTokenActivityV5TypesItem[keyof typeof GetTokensTokenActivityV5TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV5TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetTokensTokenActivityV5SortBy = typeof GetTokensTokenActivityV5SortBy[keyof typeof GetTokensTokenActivityV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensTokenActivityV5SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetTokensTokenActivityV5Params = {
/**
 * Amount of items returned. Default and max is 20.
 */
limit?: number;
/**
 * Order the items are returned in the response. The blockchain event time is `eventTimestamp`. The event time recorded is `createdAt`.
 */
sortBy?: GetTokensTokenActivityV5SortBy;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * If true, will filter any activities marked as spam.
 */
excludeSpam?: boolean;
types?: GetTokensTokenActivityV5TypesItem[];
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetTokensFlagChangesV1FlagStatus = typeof GetTokensFlagChangesV1FlagStatus[keyof typeof GetTokensFlagChangesV1FlagStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensFlagChangesV1FlagStatus = {
  NUMBER_MINUS_1: -1,
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type GetTokensFlagChangesV1Params = {
/**
 * -1 = All tokens (default)
0 = Non flagged tokens
1 = Flagged tokens
 */
flagStatus?: GetTokensFlagChangesV1FlagStatus;
/**
 * Amount of items returned in response. Max is 200.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetOracleTokensStatusV3Signer = typeof GetOracleTokensStatusV3Signer[keyof typeof GetOracleTokensStatusV3Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleTokensStatusV3Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleTokensStatusV3Params = {
tokens: string[];
signer?: GetOracleTokensStatusV3Signer;
};

export type GetOracleTokensStatusV2Signer = typeof GetOracleTokensStatusV2Signer[keyof typeof GetOracleTokensStatusV2Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleTokensStatusV2Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleTokensStatusV2Params = {
tokens: string[];
signer?: GetOracleTokensStatusV2Signer;
};

export type GetOracleCollectionsTopbidV3Signer = typeof GetOracleCollectionsTopbidV3Signer[keyof typeof GetOracleCollectionsTopbidV3Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsTopbidV3Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleCollectionsTopbidV3Kind = typeof GetOracleCollectionsTopbidV3Kind[keyof typeof GetOracleCollectionsTopbidV3Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsTopbidV3Kind = {
  spot: 'spot',
  twap: 'twap',
  lower: 'lower',
  upper: 'upper',
} as const;

export type GetOracleCollectionsTopbidV3Params = {
kind?: GetOracleCollectionsTopbidV3Kind;
currency?: string;
twapSeconds?: number;
collection?: string;
token?: string;
signer?: GetOracleCollectionsTopbidV3Signer;
};

export type GetOracleCollectionsTopbidV2Signer = typeof GetOracleCollectionsTopbidV2Signer[keyof typeof GetOracleCollectionsTopbidV2Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsTopbidV2Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleCollectionsTopbidV2Kind = typeof GetOracleCollectionsTopbidV2Kind[keyof typeof GetOracleCollectionsTopbidV2Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsTopbidV2Kind = {
  spot: 'spot',
  twap: 'twap',
  lower: 'lower',
  upper: 'upper',
} as const;

export type GetOracleCollectionsTopbidV2Params = {
kind?: GetOracleCollectionsTopbidV2Kind;
currency?: string;
twapSeconds?: number;
collection?: string;
token?: string;
signer?: GetOracleCollectionsTopbidV2Signer;
};

export type GetOracleCollectionsFlooraskV6Signer = typeof GetOracleCollectionsFlooraskV6Signer[keyof typeof GetOracleCollectionsFlooraskV6Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsFlooraskV6Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleCollectionsFlooraskV6Kind = typeof GetOracleCollectionsFlooraskV6Kind[keyof typeof GetOracleCollectionsFlooraskV6Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsFlooraskV6Kind = {
  spot: 'spot',
  twap: 'twap',
  lower: 'lower',
  upper: 'upper',
} as const;

export type GetOracleCollectionsFlooraskV6Params = {
kind?: GetOracleCollectionsFlooraskV6Kind;
currency?: string;
twapSeconds?: number;
eip3668Calldata?: string;
collection?: string;
token?: string;
/**
 * If true, will use the collection non flagged floor ask events.
 */
useNonFlaggedFloorAsk?: boolean;
signer?: GetOracleCollectionsFlooraskV6Signer;
};

export type GetOracleCollectionsFlooraskV5Signer = typeof GetOracleCollectionsFlooraskV5Signer[keyof typeof GetOracleCollectionsFlooraskV5Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsFlooraskV5Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleCollectionsFlooraskV5Kind = typeof GetOracleCollectionsFlooraskV5Kind[keyof typeof GetOracleCollectionsFlooraskV5Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsFlooraskV5Kind = {
  spot: 'spot',
  twap: 'twap',
  lower: 'lower',
  upper: 'upper',
} as const;

export type GetOracleCollectionsFlooraskV5Params = {
kind?: GetOracleCollectionsFlooraskV5Kind;
currency?: string;
twapSeconds?: number;
eip3668Calldata?: string;
collection?: string;
token?: string;
/**
 * If true, will use the collection non flagged floor ask events.
 */
useNonFlaggedFloorAsk?: boolean;
signer?: GetOracleCollectionsFlooraskV5Signer;
};

export type GetOracleCollectionsFlooraskV4Kind = typeof GetOracleCollectionsFlooraskV4Kind[keyof typeof GetOracleCollectionsFlooraskV4Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsFlooraskV4Kind = {
  spot: 'spot',
  twap: 'twap',
  lower: 'lower',
  upper: 'upper',
} as const;

export type GetOracleCollectionsFlooraskV4Params = {
kind?: GetOracleCollectionsFlooraskV4Kind;
currency?: string;
twapSeconds?: number;
eip3668Calldata?: string;
collection?: string;
token?: string;
/**
 * If true, will use the collection non flagged floor ask events.
 */
useNonFlaggedFloorAsk?: boolean;
};

export type GetOracleCollectionsBidaskmidpointV1Signer = typeof GetOracleCollectionsBidaskmidpointV1Signer[keyof typeof GetOracleCollectionsBidaskmidpointV1Signer];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsBidaskmidpointV1Signer = {
  '0x32da57e736e05f75aa4fae2e9be60fd904492726': '0x32da57e736e05f75aa4fae2e9be60fd904492726',
  '0xaeb1d03929bf87f69888f381e73fbf75753d75af': '0xaeb1d03929bf87f69888f381e73fbf75753d75af',
} as const;

export type GetOracleCollectionsBidaskmidpointV1Kind = typeof GetOracleCollectionsBidaskmidpointV1Kind[keyof typeof GetOracleCollectionsBidaskmidpointV1Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOracleCollectionsBidaskmidpointV1Kind = {
  spot: 'spot',
  twap: 'twap',
  lower: 'lower',
  upper: 'upper',
} as const;

export type GetOracleCollectionsBidaskmidpointV1Params = {
kind?: GetOracleCollectionsBidaskmidpointV1Kind;
currency?: string;
twapSeconds?: number;
collection?: string;
token?: string;
signer?: GetOracleCollectionsBidaskmidpointV1Signer;
};

export type GetEventsTokensFlooraskV4SortDirection = typeof GetEventsTokensFlooraskV4SortDirection[keyof typeof GetEventsTokensFlooraskV4SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsTokensFlooraskV4SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsTokensFlooraskV4Params = {
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
sortDirection?: GetEventsTokensFlooraskV4SortDirection;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetEventsTokensFlooraskV3SortDirection = typeof GetEventsTokensFlooraskV3SortDirection[keyof typeof GetEventsTokensFlooraskV3SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsTokensFlooraskV3SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsTokensFlooraskV3Params = {
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
sortDirection?: GetEventsTokensFlooraskV3SortDirection;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
continuation?: string;
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetEventsTokensFlooraskV2SortDirection = typeof GetEventsTokensFlooraskV2SortDirection[keyof typeof GetEventsTokensFlooraskV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsTokensFlooraskV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsTokensFlooraskV2Params = {
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
sortDirection?: GetEventsTokensFlooraskV2SortDirection;
continuation?: string;
limit?: number;
};

export type GetEventsCollectionsTopbidV2SortDirection = typeof GetEventsCollectionsTopbidV2SortDirection[keyof typeof GetEventsCollectionsTopbidV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsCollectionsTopbidV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsCollectionsTopbidV2Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsCollectionsTopbidV2SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetEventsCollectionsTopbidV1SortDirection = typeof GetEventsCollectionsTopbidV1SortDirection[keyof typeof GetEventsCollectionsTopbidV1SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsCollectionsTopbidV1SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsCollectionsTopbidV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsCollectionsTopbidV1SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetEventsCollectionsFlooraskV2SortDirection = typeof GetEventsCollectionsFlooraskV2SortDirection[keyof typeof GetEventsCollectionsFlooraskV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsCollectionsFlooraskV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsCollectionsFlooraskV2Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, will exclude floor asks on flagged tokens. (only supported when `normalizeRoyalties` is false)
 */
excludeFlaggedTokens?: boolean;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsCollectionsFlooraskV2SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetEventsCollectionsFlooraskV1SortDirection = typeof GetEventsCollectionsFlooraskV1SortDirection[keyof typeof GetEventsCollectionsFlooraskV1SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsCollectionsFlooraskV1SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsCollectionsFlooraskV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, will exclude floor asks on flagged tokens. (only supported when `normalizeRoyalties` is false)
 */
excludeFlaggedTokens?: boolean;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsCollectionsFlooraskV1SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetCollectionsCollectionMarketplaceconfigurationsV1Params = {
/**
 * When set, token-level royalties will be returned in the response
 */
tokenId?: string;
};

export type GetCollectionsCollectionActivityV2TypesItem = typeof GetCollectionsCollectionActivityV2TypesItem[keyof typeof GetCollectionsCollectionActivityV2TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionActivityV2TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetCollectionsCollectionActivityV2SortBy = typeof GetCollectionsCollectionActivityV2SortBy[keyof typeof GetCollectionsCollectionActivityV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionActivityV2SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetCollectionsCollectionActivityV2Params = {
/**
 * Amount of items returned in response. If `includeMetadata=true` max limit is 20, otherwise max limit is 1,000.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetCollectionsCollectionActivityV2SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
types?: GetCollectionsCollectionActivityV2TypesItem[];
};

export type GetCollectionsCollectionActivityV1TypesItem = typeof GetCollectionsCollectionActivityV1TypesItem[keyof typeof GetCollectionsCollectionActivityV1TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionActivityV1TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetCollectionsCollectionActivityV1Params = {
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: number;
types?: GetCollectionsCollectionActivityV1TypesItem[];
};

export type GetCollectionsCollectionActivityV3TypesItem = typeof GetCollectionsCollectionActivityV3TypesItem[keyof typeof GetCollectionsCollectionActivityV3TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionActivityV3TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetCollectionsCollectionActivityV3SortBy = typeof GetCollectionsCollectionActivityV3SortBy[keyof typeof GetCollectionsCollectionActivityV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionActivityV3SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetCollectionsCollectionActivityV3Params = {
/**
 * Amount of items returned in response. If `includeMetadata=true` max limit is 20, otherwise max limit is 1,000.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetCollectionsCollectionActivityV3SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
types?: GetCollectionsCollectionActivityV3TypesItem[];
};

export type GetCollectionsCollectionAttributesV1SortBy = typeof GetCollectionsCollectionAttributesV1SortBy[keyof typeof GetCollectionsCollectionAttributesV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsCollectionAttributesV1SortBy = {
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetCollectionsCollectionAttributesV1Params = {
attributeKey?: string;
sortBy?: GetCollectionsCollectionAttributesV1SortBy;
offset?: number;
limit?: number;
};

export type GetCollectionsCollectionSupportedmarketplacesV1Params = {
/**
 * When set, token-level royalties will be returned in the response
 */
tokenId?: string;
};

export type GetUsersActivityV6TypesItem = typeof GetUsersActivityV6TypesItem[keyof typeof GetUsersActivityV6TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV6TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetUsersActivityV6SortBy = typeof GetUsersActivityV6SortBy[keyof typeof GetUsersActivityV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV6SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetUsersActivityV6Params = {
/**
 * Array of users addresses. Max is 50. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
users: string[];
collection?: string[];
/**
 * If true, will filter any activities marked as spam.
 */
excludeSpam?: boolean;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Amount of items returned in response. If `includeMetadata=true` max limit is 20, otherwise max limit is 1,000.
 */
limit?: number;
/**
 * Order the items are returned in the response. The blockchain event time is `eventTimestamp`. The event time recorded is `createdAt`.
 */
sortBy?: GetUsersActivityV6SortBy;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetUsersActivityV6TypesItem[];
/**
 * Input any ERC20 address to return result in given currency.
 */
displayCurrency?: string;
};

export type GetUsersActivityV5TypesItem = typeof GetUsersActivityV5TypesItem[keyof typeof GetUsersActivityV5TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV5TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetUsersActivityV5SortBy = typeof GetUsersActivityV5SortBy[keyof typeof GetUsersActivityV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV5SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetUsersActivityV5Params = {
/**
 * Array of users addresses. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
users: string[];
collection?: string[];
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Amount of items returned in response. If `includeMetadata=true` max limit is 20, otherwise max limit is 1,000.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetUsersActivityV5SortBy;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetUsersActivityV5TypesItem[];
};

export type GetUsersActivityV4TypesItem = typeof GetUsersActivityV4TypesItem[keyof typeof GetUsersActivityV4TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV4TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetUsersActivityV4SortBy = typeof GetUsersActivityV4SortBy[keyof typeof GetUsersActivityV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV4SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetUsersActivityV4Params = {
/**
 * Array of users addresses. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
users: string[];
collection?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Amount of items returned in response. If `includeMetadata=true` max limit is 20, otherwise max limit is 1,000.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetUsersActivityV4SortBy;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetUsersActivityV4TypesItem[];
};

export type GetUsersActivityV3TypesItem = typeof GetUsersActivityV3TypesItem[keyof typeof GetUsersActivityV3TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV3TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetUsersActivityV3SortBy = typeof GetUsersActivityV3SortBy[keyof typeof GetUsersActivityV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV3SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetUsersActivityV3Params = {
/**
 * Array of users addresses. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
users: string[];
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetUsersActivityV3SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetUsersActivityV3TypesItem[];
};

export type GetUsersActivityV2TypesItem = typeof GetUsersActivityV2TypesItem[keyof typeof GetUsersActivityV2TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetUsersActivityV2TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetUsersActivityV2Params = {
/**
 * Array of users addresses. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
users: string[];
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: number;
types?: GetUsersActivityV2TypesItem[];
};

export type GetTransfersBulkV2SortBy = typeof GetTransfersBulkV2SortBy[keyof typeof GetTransfersBulkV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTransfersBulkV2SortBy = {
  timestamp: 'timestamp',
  updatedAt: 'updatedAt',
} as const;

export type GetTransfersBulkV2Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
txHash?: string[];
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Order the items are returned in the response. Options are `timestamp`, and `updatedAt`. Default is `timestamp`.
 */
sortBy?: GetTransfersBulkV2SortBy;
sortDirection?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetTransfersBulkV1OrderBy = typeof GetTransfersBulkV1OrderBy[keyof typeof GetTransfersBulkV1OrderBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTransfersBulkV1OrderBy = {
  timestamp: 'timestamp',
  updated_at: 'updated_at',
} as const;

export type GetTransfersBulkV1Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Order the items are returned in the response. Options are `timestamp`, and `updated_at`. Default is `timestamp`.
 */
orderBy?: GetTransfersBulkV1OrderBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetTokensIdsV1FlagStatus = typeof GetTokensIdsV1FlagStatus[keyof typeof GetTokensIdsV1FlagStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensIdsV1FlagStatus = {
  NUMBER_MINUS_1: -1,
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type GetTokensIdsV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token set. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract.
 */
tokenSetId?: string;
/**
 * -1 = All tokens (default)
0 = Non flagged tokens
1 = Flagged tokens
 */
flagStatus?: GetTokensIdsV1FlagStatus;
/**
 * Amount of items returned in response. Max limit is 10,000.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetTokensFloorV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
};

export type GetTokensDetailsV4SortDirection = typeof GetTokensDetailsV4SortDirection[keyof typeof GetTokensDetailsV4SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensDetailsV4SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetTokensDetailsV4SortBy = typeof GetTokensDetailsV4SortBy[keyof typeof GetTokensDetailsV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensDetailsV4SortBy = {
  floorAskPrice: 'floorAskPrice',
  tokenId: 'tokenId',
} as const;

export type GetTokensDetailsV4Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Array of tokens. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * Filter to a particular token set. `Example: token:0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270:129000685`
 */
tokenSetId?: string;
/**
 * Filter to a particular attribute. Example: `attributes[Type]=Original`
 */
attributes?: string;
/**
 * Domain of the order source. Example `opensea.io`
 */
source?: string;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetTokensDetailsV4SortBy;
sortDirection?: GetTokensDetailsV4SortDirection;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetTokensDetailsV3SortBy = typeof GetTokensDetailsV3SortBy[keyof typeof GetTokensDetailsV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensDetailsV3SortBy = {
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetTokensDetailsV3Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to one or more tokens, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
tokens?: string[];
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular attribute, e.g. `attributes[Type]=Original`
 */
attributes?: string;
/**
 * Filter to a particular source, e.g. `0x5b3256965e7c3cf26e11fcaf296dfc8807c01073`
 */
source?: string;
sortBy?: GetTokensDetailsV3SortBy;
limit?: number;
continuation?: string;
};

export type GetTokensDetailsV2SortBy = typeof GetTokensDetailsV2SortBy[keyof typeof GetTokensDetailsV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensDetailsV2SortBy = {
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetTokensDetailsV2Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular attribute, e.g. `attributes[Type]=Original`
 */
attributes?: string;
/**
 * Filter to a particular source, e.g. `0x5b3256965e7c3cf26e11fcaf296dfc8807c01073`
 */
source?: string;
sortBy?: GetTokensDetailsV2SortBy;
limit?: number;
continuation?: string;
};

export type GetTokensBootstrapV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetSyncAsksV1Params = {
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetSearchCollectionsV2Params = {
/**
 * Lightweight search for collections that match a string. Example: `bored`
 */
name?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Return result in given currency
 */
displayCurrency?: string;
/**
 * Filter to a particular collection set
 */
collectionsSetId?: string;
/**
 * If true, will filter any collections marked as spam.
 */
excludeSpam?: boolean;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetSearchCollectionsV1Params = {
/**
 * Lightweight search for collections that match a string. Example: `bored`
 */
name?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Return result in given currency
 */
displayCurrency?: string;
/**
 * Filter to a particular collection set
 */
collectionsSetId?: string;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetSearchActivitiesV1TypesItem = typeof GetSearchActivitiesV1TypesItem[keyof typeof GetSearchActivitiesV1TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSearchActivitiesV1TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetSearchActivitiesV1SortBy = typeof GetSearchActivitiesV1SortBy[keyof typeof GetSearchActivitiesV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSearchActivitiesV1SortBy = {
  timestamp: 'timestamp',
  createdAt: 'createdAt',
} as const;

export type GetSearchActivitiesV1Params = {
/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * Array of collections. Max limit is 50. Example: `collections[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collections?: string[];
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/collections/activity/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original` or `https://api.reservoir.tools/collections/activity/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original&attribute[Type]=Sibling`
 */
attributes?: string;
/**
 * Array of source domains. Max limit is 50. Example: `sources[0]: opensea.io`
 */
sources?: string[];
/**
 * Array of users addresses. Max is 50. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
users?: string[];
/**
 * Amount of items returned. Max limit is 50 when `includedMetadata=true` otherwise max limit is 1000.
 */
limit?: number;
/**
 * Order the items are returned in the response. The blockchain event time is `timestamp`. The event time recorded is `createdAt`.
 */
sortBy?: GetSearchActivitiesV1SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
types?: GetSearchActivitiesV1TypesItem[];
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetSalesBulkV1Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetRedirectTokenV1Params = {
source: string;
/**
 * Redirect to the given token page, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token: string;
};

export type GetRedirectLogoV1Params = {
source: string;
};

export type GetOwnersCrosscollectionsV1Params = {
/**
 * Filter to one or more collections. Max limit is 5. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collections: string[];
/**
 * Amount of owners returned in response. Max limit is 50.
 */
limit?: number;
};

export type GetOwnersCountV1Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
};

export type GetOwnersCommoncollectionsV1Params = {
/**
 * Array of owner addresses. Max limit is 50. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
owners: string[];
/**
 * Amount of collections returned in response. Max limit is 100.
 */
limit?: number;
};

export type GetOrdersExecutedV1Params = {
ids: string[];
};

export type GetOrdersDepthV1Side = typeof GetOrdersDepthV1Side[keyof typeof GetOrdersDepthV1Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersDepthV1Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export type GetOrdersDepthV1Params = {
side: GetOrdersDepthV1Side;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`.
 */
token?: string;
/**
 * Filter to a particular collection. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`.
 */
collection?: string;
/**
 * Return all prices in this currency.
 */
displayCurrency?: string;
};

export type GetOrdersBidsV6SortBy = typeof GetOrdersBidsV6SortBy[keyof typeof GetOrdersBidsV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV6SortBy = {
  createdAt: 'createdAt',
  price: 'price',
  updatedAt: 'updatedAt',
} as const;

export type GetOrdersBidsV6Params = {
ids?: string[];
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract.
 */
tokenSetId?: string;
/**
 * Filter to a particular user. Must set `sources=blur.io` to reveal maker's blur bids. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular collection bids with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/orders/bids/v5?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original` or `https://api.reservoir.tools/orders/bids/v5?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original&attribute[Type]=Sibling`(Collection must be passed as well when filtering by attribute)
 */
attribute?: string;
/**
 * Filter to an array of contracts. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contracts?: string[];
/**
 * activeª^º = currently valid
inactiveª^ = temporarily invalid
expiredª^, cancelledª^, filledª^ = permanently invalid
anyªº = any status
ª when an `id` is passed
^ when a `maker` is passed
º when a `contract` is passed
 */
status?: string;
sources?: string[];
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * If true, private orders are included in the response.
 */
includePrivate?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * If true, raw data is included in the response. Set `sources=blur.io` and make this `true` to reveal individual blur bids.
 */
includeRawData?: boolean;
/**
 * If true, the depth of each order is included in the response.
 */
includeDepth?: boolean;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts.
 */
excludeEOA?: boolean;
excludeSources?: string[];
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response. Sorting by `price` defaults sorting direction to descending. 
 */
sortBy?: GetOrdersBidsV6SortBy;
sortDirection?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersBidsV5SortBy = typeof GetOrdersBidsV5SortBy[keyof typeof GetOrdersBidsV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV5SortBy = {
  createdAt: 'createdAt',
  price: 'price',
  updatedAt: 'updatedAt',
} as const;

export type GetOrdersBidsV5Params = {
ids?: string[];
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract.
 */
tokenSetId?: string;
/**
 * Filter to a particular user. Must set `source=blur.io` to reveal maker's blur bids. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
/**
 * Filter to a particular collection bids with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/orders/bids/v5?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original` or `https://api.reservoir.tools/orders/bids/v5?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original&attribute[Type]=Sibling`(Collection must be passed as well when filtering by attribute)
 */
attribute?: string;
/**
 * Filter to an array of contracts. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contracts?: string[];
/**
 * activeª^º = currently valid
inactiveª^ = temporarily invalid
expiredª^, canceledª^, filledª^ = permanently invalid
anyªº = any status
ª when an `id` is passed
^ when a `maker` is passed
º when a `contract` is passed
 */
status?: string;
/**
 * Filter to a source by domain. Only active listed will be returned. Must set `rawData=true` to reveal individual bids when `source=blur.io`. Example: `opensea.io`
 */
source?: string;
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * If true, raw data is included in the response. Set `source=blur.io` and make this `true` to reveal individual blur bids.
 */
includeRawData?: boolean;
/**
 * If true, the depth of each order is included in the response.
 */
includeDepth?: boolean;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts.
 */
excludeEOA?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetOrdersBidsV5SortBy;
sortDirection?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersBidsV4SortBy = typeof GetOrdersBidsV4SortBy[keyof typeof GetOrdersBidsV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV4SortBy = {
  createdAt: 'createdAt',
  price: 'price',
} as const;

export type GetOrdersBidsV4Params = {
ids?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set. Example: `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` or `token:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:1`
 */
tokenSetId?: string;
/**
 * Filter to a particular user. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection bids with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute within a collection. Example: `attribute[Mouth]=Bored` (Collection must be passed as well when filtering by attribute)
 */
attribute?: string;
/**
 * Filter to an array of contracts. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contracts?: string[];
/**
 * active = currently valid
inactive = temporarily invalid
expired, cancelled, filled = permanently invalid

Available when filtering by maker, otherwise only valid orders will be returned
 */
status?: string;
/**
 * Filter to a source by domain. Example: `opensea.io`
 */
source?: string;
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * If true, raw data is included in the response.
 */
includeRawData?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response, Sorting by price allowed only when filtering by token
 */
sortBy?: GetOrdersBidsV4SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersBidsV3SortBy = typeof GetOrdersBidsV3SortBy[keyof typeof GetOrdersBidsV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV3SortBy = {
  createdAt: 'createdAt',
  price: 'price',
} as const;

export type GetOrdersBidsV3Params = {
ids?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular user. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to an array of contracts. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contracts?: string[];
/**
 * active = currently valid, inactive = temporarily invalid, expired = permanently invalid

Available when filtering by maker, otherwise only valid orders will be returned
 */
status?: string;
/**
 * Filter to a source by domain. Example: `opensea.io`
 */
source?: string;
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * If true, raw data is included in the response.
 */
includeRawData?: boolean;
/**
 * Order the items are returned in the response, Sorting by price allowed only when filtering by token
 */
sortBy?: GetOrdersBidsV3SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersBidsV2Params = {
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular user. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to an array of contracts. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contracts?: string[];
/**
 * active = currently valid, inactive = temporarily invalid, expired = permanently invalid

Available when filtering by maker, otherwise only valid orders will be returned
 */
status?: string;
/**
 * Order the items are returned in the response.
 */
sortBy?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetOrdersBidsV1SortBy = typeof GetOrdersBidsV1SortBy[keyof typeof GetOrdersBidsV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV1SortBy = {
  price: 'price',
  createdAt: 'createdAt',
} as const;

export type GetOrdersBidsV1Status = typeof GetOrdersBidsV1Status[keyof typeof GetOrdersBidsV1Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersBidsV1Status = {
  active: 'active',
  inactive: 'inactive',
  expired: 'expired',
} as const;

export type GetOrdersBidsV1Params = {
/**
 * Filter to a token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular user, e.g. `0x4d04eb67a2d1e01c71fad0366e0c200207a75487`
 */
maker?: string;
/**
 * `active` = currently valid, `inactive` = temporarily invalid, `expired` = permanently invalid

Available when filtering by maker, otherwise only valid orders will be returned
 */
status?: GetOrdersBidsV1Status;
sortBy?: GetOrdersBidsV1SortBy;
continuation?: string;
limit?: number;
};

export type GetOrdersAsksV5SortBy = typeof GetOrdersAsksV5SortBy[keyof typeof GetOrdersAsksV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAsksV5SortBy = {
  createdAt: 'createdAt',
  price: 'price',
  updatedAt: 'updatedAt',
} as const;

export type GetOrdersAsksV5Params = {
ids?: string[];
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular user. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
contracts?: string[];
/**
 * activeª^º = currently valid
inactiveª^ = temporarily invalid
expiredª^, cancelledª^, filledª^ = permanently invalid
anyªº = any status
ª when an `id` is passed
^ when a `maker` is passed
º when a `contract` is passed
 */
status?: string;
sources?: string[];
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * If true, private orders are included in the response.
 */
includePrivate?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * If true, raw data is included in the response.
 */
includeRawData?: boolean;
/**
 * If true, dynamic pricing data will be returned in the response.
 */
includeDynamicPricing?: boolean;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts.
 */
excludeEOA?: boolean;
excludeSources?: string[];
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response. Sorting by `price` is ascending order only.
 */
sortBy?: GetOrdersAsksV5SortBy;
sortDirection?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersAsksV4SortBy = typeof GetOrdersAsksV4SortBy[keyof typeof GetOrdersAsksV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAsksV4SortBy = {
  createdAt: 'createdAt',
  price: 'price',
  updatedAt: 'updatedAt',
} as const;

export type GetOrdersAsksV4Params = {
ids?: string[];
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular user. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular contracts set.
 */
contractsSetId?: string;
contracts?: string[];
/**
 * activeª^º = currently valid
inactiveª^ = temporarily invalid
expiredª^, canceledª^, filledª^ = permanently invalid
anyªº = any status
ª when an `id` is passed
^ when a `maker` is passed
º when a `contract` is passed
 */
status?: string;
/**
 * Filter to a source by domain. Only active listed will be returned. Example: `opensea.io`
 */
source?: string;
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * If true, private orders are included in the response.
 */
includePrivate?: boolean;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * If true, raw data is included in the response.
 */
includeRawData?: boolean;
/**
 * If true, dynamic pricing data will be returned in the response.
 */
includeDynamicPricing?: boolean;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts.
 */
excludeEOA?: boolean;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response, Sorting by price allowed only when filtering by token
 */
sortBy?: GetOrdersAsksV4SortBy;
sortDirection?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersAsksV3SortBy = typeof GetOrdersAsksV3SortBy[keyof typeof GetOrdersAsksV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAsksV3SortBy = {
  createdAt: 'createdAt',
  price: 'price',
} as const;

export type GetOrdersAsksV3Params = {
ids?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular user. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
contracts?: string[];
/**
 * active = currently valid
inactive = temporarily invalid
expired, cancelled, filled = permanently invalid

Available when filtering by maker, otherwise only valid orders will be returned
 */
status?: string;
source?: string[];
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * If true, private orders are included in the response.
 */
includePrivate?: boolean;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
/**
 * If true, raw data is included in the response.
 */
includeRawData?: boolean;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Order the items are returned in the response, Sorting by price allowed only when filtering by token
 */
sortBy?: GetOrdersAsksV3SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersAsksV2Params = {
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular user. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
maker?: string;
contracts?: string[];
/**
 * active = currently valid, inactive = temporarily invalid

Available when filtering by maker, otherwise only valid orders will be returned
 */
status?: string;
/**
 * When true, private orders are included in the response.
 */
includePrivate?: boolean;
/**
 * Order the items are returned in the response.
 */
sortBy?: string;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetOrdersAsksV1SortBy = typeof GetOrdersAsksV1SortBy[keyof typeof GetOrdersAsksV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAsksV1SortBy = {
  price: 'price',
  createdAt: 'createdAt',
} as const;

export type GetOrdersAsksV1Status = typeof GetOrdersAsksV1Status[keyof typeof GetOrdersAsksV1Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAsksV1Status = {
  active: 'active',
  inactive: 'inactive',
} as const;

export type GetOrdersAsksV1Params = {
/**
 * Filter to a token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular user, e.g. `0x4d04eb67a2d1e01c71fad0366e0c200207a75487`
 */
maker?: string;
/**
 * Filter to a particular user, e.g. `0x4d04eb67a2d1e01c71fad0366e0c200207a75487`
 */
contract?: string;
/**
 * `active` = currently valid, `inactive` = temporarily invalid

Available when filtering by maker, otherwise only valid orders will be returned
 */
status?: GetOrdersAsksV1Status;
sortBy?: GetOrdersAsksV1SortBy;
continuation?: string;
limit?: number;
};

export type GetOrdersAllV2Side = typeof GetOrdersAllV2Side[keyof typeof GetOrdersAllV2Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAllV2Side = {
  sell: 'sell',
  buy: 'buy',
} as const;

export type GetOrdersAllV2Params = {
id?: string;
/**
 * Filter to a source by domain. Example: `opensea.io`
 */
source?: string;
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * Sell or buy side.
 */
side?: GetOrdersAllV2Side;
/**
 * If true, metadata will be included in the response.
 */
includeMetadata?: boolean;
/**
 * If true, raw data will be included in the response.
 */
includeRawData?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetOrdersAllV1Side = typeof GetOrdersAllV1Side[keyof typeof GetOrdersAllV1Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetOrdersAllV1Side = {
  sell: 'sell',
  buy: 'buy',
} as const;

export type GetOrdersAllV1Params = {
id?: string;
/**
 * Filter to a source by domain. Example: `opensea.io`
 */
source?: string;
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * Sell or buy side.
 */
side?: GetOrdersAllV1Side;
/**
 * If true, metadata will be included in the response.
 */
includeMetadata?: boolean;
/**
 * If true, raw data will be included in the response.
 */
includeRawData?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetLiquidityUsersV2Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection: string;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetLiquidityUsersV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular user. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00`
 */
user?: string;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetExecuteCancelV2Params = {
/**
 * Order Id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id: string;
/**
 * Optional. Set custom gas price
 */
maxFeePerGas?: string;
/**
 * Optional. Set custom gas price
 */
maxPriorityFeePerGas?: string;
};

export type GetEventsOrdersV1SortDirection = typeof GetEventsOrdersV1SortDirection[keyof typeof GetEventsOrdersV1SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsOrdersV1SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsOrdersV1Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsOrdersV1SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetEventsBidsV3SortDirection = typeof GetEventsBidsV3SortDirection[keyof typeof GetEventsBidsV3SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsBidsV3SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsBidsV3Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsBidsV3SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetEventsBidsV2SortDirection = typeof GetEventsBidsV2SortDirection[keyof typeof GetEventsBidsV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsBidsV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsBidsV2Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsBidsV2SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetEventsBidsV1SortDirection = typeof GetEventsBidsV1SortDirection[keyof typeof GetEventsBidsV1SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsBidsV1SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsBidsV1Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, bid criteria is included in the response.
 */
includeCriteria?: boolean;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsBidsV1SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetEventsAsksV3SortDirection = typeof GetEventsAsksV3SortDirection[keyof typeof GetEventsAsksV3SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsAsksV3SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsAsksV3Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * If true, criteria metadata is included in the response.
 */
includeCriteriaMetadata?: boolean;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsAsksV3SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response. Max is 1000
 */
limit?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetEventsAsksV2SortDirection = typeof GetEventsAsksV2SortDirection[keyof typeof GetEventsAsksV2SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetEventsAsksV2SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetEventsAsksV2Params = {
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetEventsAsksV2SortDirection;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetCurrenciesConversionV1Params = {
/**
 * Currency address or fiat symbol to convert from
 */
from?: string;
/**
 * Currency address or fiat symbol to convert to
 */
to?: string;
};

export type GetCollectionsTrendingmintsV1Type = typeof GetCollectionsTrendingmintsV1Type[keyof typeof GetCollectionsTrendingmintsV1Type];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTrendingmintsV1Type = {
  free: 'free',
  paid: 'paid',
  any: 'any',
} as const;

export type GetCollectionsTrendingmintsV1Period = typeof GetCollectionsTrendingmintsV1Period[keyof typeof GetCollectionsTrendingmintsV1Period];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTrendingmintsV1Period = {
  '5m': '5m',
  '10m': '10m',
  '30m': '30m',
  '1h': '1h',
  '2h': '2h',
  '6h': '6h',
  '24h': '24h',
} as const;

export type GetCollectionsTrendingmintsV1Params = {
/**
 * Time window to aggregate.
 */
period?: GetCollectionsTrendingmintsV1Period;
/**
 * The type of the mint (free/paid).
 */
type?: GetCollectionsTrendingmintsV1Type;
/**
 * Amount of items returned in response. Default is 50 and max is 50. Expected to be sorted and filtered on client side.
 */
limit?: number;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
};

export type GetCollectionsTrendingV1SortBy = typeof GetCollectionsTrendingV1SortBy[keyof typeof GetCollectionsTrendingV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTrendingV1SortBy = {
  volume: 'volume',
  sales: 'sales',
} as const;

export type GetCollectionsTrendingV1Period = typeof GetCollectionsTrendingV1Period[keyof typeof GetCollectionsTrendingV1Period];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTrendingV1Period = {
  '5m': '5m',
  '10m': '10m',
  '30m': '30m',
  '1h': '1h',
  '6h': '6h',
  '1d': '1d',
  '24h': '24h',
  '7d': '7d',
  '30d': '30d',
} as const;

export type GetCollectionsTrendingV1Params = {
/**
 * Time window to aggregate.
 */
period?: GetCollectionsTrendingV1Period;
/**
 * Amount of items returned in response. Default is 50 and max is 1000. Expected to be sorted and filtered on client side.
 */
limit?: number;
sortBy?: GetCollectionsTrendingV1SortBy;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
};

export type GetCollectionsTopsellingV2SortBy = typeof GetCollectionsTopsellingV2SortBy[keyof typeof GetCollectionsTopsellingV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTopsellingV2SortBy = {
  volume: 'volume',
  sales: 'sales',
} as const;

export type GetCollectionsTopsellingV2FillType = typeof GetCollectionsTopsellingV2FillType[keyof typeof GetCollectionsTopsellingV2FillType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTopsellingV2FillType = {
  sale: 'sale',
  mint: 'mint',
  any: 'any',
} as const;

export type GetCollectionsTopsellingV2Period = typeof GetCollectionsTopsellingV2Period[keyof typeof GetCollectionsTopsellingV2Period];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTopsellingV2Period = {
  '5m': '5m',
  '10m': '10m',
  '30m': '30m',
  '1h': '1h',
  '6h': '6h',
  '1d': '1d',
  '24h': '24h',
} as const;

export type GetCollectionsTopsellingV2Params = {
/**
 * Time window to aggregate.
 */
period?: GetCollectionsTopsellingV2Period;
/**
 * Fill types to aggregate from (sale, mint, any)
 */
fillType?: GetCollectionsTopsellingV2FillType;
/**
 * Amount of items returned in response. Default is 25 and max is 50
 */
limit?: number;
sortBy?: GetCollectionsTopsellingV2SortBy;
/**
 * If true, 8 recent sales will be included in the response
 */
includeRecentSales?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
};

export type GetCollectionsTopsellingV1FillType = typeof GetCollectionsTopsellingV1FillType[keyof typeof GetCollectionsTopsellingV1FillType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsTopsellingV1FillType = {
  sale: 'sale',
  mint: 'mint',
  any: 'any',
} as const;

export type GetCollectionsTopsellingV1Params = {
/**
 * Start time in unix timestamp. Must be less than 2 weeks ago. defaults to 24 hours
 */
startTime?: number;
/**
 * End time in unix timestamp. defaults to now
 */
endTime?: number;
/**
 * Fill types to aggregate from (sale, mint, any)
 */
fillType?: GetCollectionsTopsellingV1FillType;
/**
 * Amount of items returned in response. Default is 25 and max is 50
 */
limit?: number;
/**
 * If true, 8 recent sales will be included in the response
 */
includeRecentSales?: boolean;
};

export type GetCollectionsDailyvolumesV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * The start timestamp you want to filter on (UTC)
 */
startTimestamp?: number;
/**
 * The end timestamp you want to filter on (UTC)
 */
endTimestamp?: number;
};

export type GetCollectionsActivityV6TypesItem = typeof GetCollectionsActivityV6TypesItem[keyof typeof GetCollectionsActivityV6TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV6TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetCollectionsActivityV6SortBy = typeof GetCollectionsActivityV6SortBy[keyof typeof GetCollectionsActivityV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV6SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetCollectionsActivityV6Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/collections/activity/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original` or `https://api.reservoir.tools/collections/activity/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original&attribute[Type]=Sibling`
 */
attributes?: string;
/**
 * If true, will filter any activities marked as spam.
 */
excludeSpam?: boolean;
/**
 * Amount of items returned. Max limit is 50 when `includedMetadata=true` otherwise max limit is 1000.
 */
limit?: number;
/**
 * Order the items are returned in the response. The blockchain event time is `eventTimestamp`. The event time recorded is `createdAt`.
 */
sortBy?: GetCollectionsActivityV6SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * If true, metadata is included in the response. If true, max limit is 50.
 */
includeMetadata?: boolean;
types?: GetCollectionsActivityV6TypesItem[];
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetCollectionsActivityV5TypesItem = typeof GetCollectionsActivityV5TypesItem[keyof typeof GetCollectionsActivityV5TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV5TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetCollectionsActivityV5SortBy = typeof GetCollectionsActivityV5SortBy[keyof typeof GetCollectionsActivityV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV5SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetCollectionsActivityV5Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular attribute. Example: `attributes[Type]=Original`
 */
attributes?: string;
/**
 * Amount of items returned in response. If `includeMetadata=true` max limit is 50, otherwise max limit is 1,000.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetCollectionsActivityV5SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
types?: GetCollectionsActivityV5TypesItem[];
};

export type GetCollectionsActivityV4TypesItem = typeof GetCollectionsActivityV4TypesItem[keyof typeof GetCollectionsActivityV4TypesItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV4TypesItem = {
  sale: 'sale',
  ask: 'ask',
  transfer: 'transfer',
  mint: 'mint',
  bid: 'bid',
  bid_cancel: 'bid_cancel',
  ask_cancel: 'ask_cancel',
} as const;

export type GetCollectionsActivityV4SortBy = typeof GetCollectionsActivityV4SortBy[keyof typeof GetCollectionsActivityV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsActivityV4SortBy = {
  eventTimestamp: 'eventTimestamp',
  createdAt: 'createdAt',
} as const;

export type GetCollectionsActivityV4Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Amount of items returned in response. If `includeMetadata=true` max limit is 20, otherwise max limit is 1,000.
 */
limit?: number;
/**
 * Order the items are returned in the response, eventTimestamp = The blockchain event time, createdAt - The time in which event was recorded
 */
sortBy?: GetCollectionsActivityV4SortBy;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * If true, metadata is included in the response.
 */
includeMetadata?: boolean;
types?: GetCollectionsActivityV4TypesItem[];
};

export type GetAdminProvidermetadataTypeParams = {
/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens: string[];
/**
 * The indexing method to use
 */
method?: string;
};

export type GetTransfersV4SortBy = typeof GetTransfersV4SortBy[keyof typeof GetTransfersV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTransfersV4SortBy = {
  timestamp: 'timestamp',
  updatedAt: 'updatedAt',
} as const;

export type GetTransfersV4Params = {
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/transfers/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/transfers/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
/**
 * Order the items are returned in the response. Options are `timestamp`, and `updatedAt`. Default is `timestamp`.
 */
sortBy?: GetTransfersV4SortBy;
sortDirection?: string;
/**
 * Max limit is 100.
 */
limit?: number;
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetTransfersV3OrderBy = typeof GetTransfersV3OrderBy[keyof typeof GetTransfersV3OrderBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTransfersV3OrderBy = {
  timestamp: 'timestamp',
  updated_at: 'updated_at',
} as const;

export type GetTransfersV3Params = {
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/transfers/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/transfers/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
/**
 * Order the items are returned in the response. Options are `timestamp`, and `updated_at`. Default is `timestamp`.
 */
orderBy?: GetTransfersV3OrderBy;
/**
 * Max limit is 100.
 */
limit?: number;
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetTransfersV2Params = {
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/transfers/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/transfers/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
limit?: number;
continuation?: string;
};

export type GetTokensV7SortDirection = typeof GetTokensV7SortDirection[keyof typeof GetTokensV7SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV7SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetTokensV7SortBy = typeof GetTokensV7SortBy[keyof typeof GetTokensV7SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV7SortBy = {
  floorAskPrice: 'floorAskPrice',
  tokenId: 'tokenId',
  rarity: 'rarity',
  updatedAt: 'updatedAt',
} as const;

export type GetTokensV7FlagStatus = typeof GetTokensV7FlagStatus[keyof typeof GetTokensV7FlagStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV7FlagStatus = {
  NUMBER_MINUS_1: -1,
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type GetTokensV7Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular token by name. This is case sensitive. Example: `token #1`
 */
tokenName?: string;
/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Domain of the order source. Example `opensea.io` (Only listed tokens are returned when filtering by source)
 */
source?: string;
/**
 * Domain of the order source. Example `www.apecoinmarketplace.com`. For a native marketplace, return all tokens listed on this marketplace, even if better prices are available on other marketplaces.
 */
nativeSource?: string;
/**
 * Get tokens with a min rarity rank (inclusive), no rarity rank for collections over 100k
 */
minRarityRank?: number;
/**
 * Get tokens with a max rarity rank (inclusive), no rarity rank for collections over 100k
 */
maxRarityRank?: number;
/**
 * Get tokens with a min floor ask price (inclusive); use native currency
 */
minFloorAskPrice?: number;
/**
 * Get tokens with a max floor ask price (inclusive); use native currency
 */
maxFloorAskPrice?: number;
/**
 * Allowed only with collection and tokens filtering!
-1 = All tokens (default)
0 = Non flagged tokens
1 = Flagged tokens
 */
flagStatus?: GetTokensV7FlagStatus;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
contract?: string[];
/**
 * Filter to a particular token set. `Example: token:0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270:129000685`
 */
tokenSetId?: string;
/**
 * Order the items are returned in the response. Options are `floorAskPrice`, `tokenId`, `rarity`, and `updatedAt`. No rarity rank for collections over 100k.
 */
sortBy?: GetTokensV7SortBy;
sortDirection?: GetTokensV7SortDirection;
/**
 * Filter to tokens with a listing in a particular currency. Max limit is 50. `Example: currencies[0]: 0x0000000000000000000000000000000000000000`
 */
currencies?: string[];
/**
 * Amount of items returned in response. Max limit is 100, except when sorting by `updatedAt` which has a limit of 1000.
 */
limit?: number;
/**
 * When sorting by `updatedAt`, the start timestamp you want to filter on (UTC).
 */
startTimestamp?: number;
/**
 * When sorting by `updatedAt`, the end timestamp you want to filter on (UTC).
 */
endTimestamp?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, mint data for the tokens will be included in the response.
 */
includeMintStages?: boolean;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts. defaults to false
 */
excludeEOA?: boolean;
/**
 * If true, will filter any tokens marked as spam.
 */
excludeSpam?: boolean;
/**
 * If true, attributes will be returned in the response.
 */
includeAttributes?: boolean;
/**
 * If true, quantity filled and quantity remaining will be returned in the response.
 */
includeQuantity?: boolean;
/**
 * If true, dynamic pricing data will be returned in the response.
 */
includeDynamicPricing?: boolean;
/**
 * If true, last sale data including royalties paid will be returned in the response.
 */
includeLastSale?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency. Applies to `topBid` and `floorAsk`.
 */
displayCurrency?: string;
};

export type GetTokensV6SortDirection = typeof GetTokensV6SortDirection[keyof typeof GetTokensV6SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV6SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetTokensV6SortBy = typeof GetTokensV6SortBy[keyof typeof GetTokensV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV6SortBy = {
  floorAskPrice: 'floorAskPrice',
  tokenId: 'tokenId',
  rarity: 'rarity',
  updatedAt: 'updatedAt',
} as const;

export type GetTokensV6FlagStatus = typeof GetTokensV6FlagStatus[keyof typeof GetTokensV6FlagStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV6FlagStatus = {
  NUMBER_MINUS_1: -1,
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type GetTokensV6Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular token by name. This is case sensitive. Example: `token #1`
 */
tokenName?: string;
/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Filter to a particular range attribute. Range attributes are case sensitive and inclusive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&range_attributes[Type]=0,100` or `https://api.reservoir.tools/tokens/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&range_attributes[Type]=0,100&range_attributes[Type]=1,999` where first number is minRange and second number after comma is maxRange
 */
range_attributes?: string;
/**
 * Domain of the order source. Example `opensea.io` (Only listed tokens are returned when filtering by source)
 */
source?: string;
/**
 * Domain of the order source. Example `www.apecoinmarketplace.com`. For a native marketplace, return all tokens listed on this marketplace, even if better prices are available on other marketplaces.
 */
nativeSource?: string;
/**
 * Get tokens with a min rarity rank (inclusive), no rarity rank for collections over 100k
 */
minRarityRank?: number;
/**
 * Get tokens with a max rarity rank (inclusive), no rarity rank for collections over 100k
 */
maxRarityRank?: number;
/**
 * Get tokens with a min floor ask price (inclusive); use native currency
 */
minFloorAskPrice?: number;
/**
 * Get tokens with a max floor ask price (inclusive); use native currency
 */
maxFloorAskPrice?: number;
/**
 * Allowed only with collection and tokens filtering!
-1 = All tokens (default)
0 = Non flagged tokens
1 = Flagged tokens
 */
flagStatus?: GetTokensV6FlagStatus;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
contract?: string[];
/**
 * Filter to a particular token set. `Example: token:0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270:129000685`
 */
tokenSetId?: string;
/**
 * Order the items are returned in the response. Options are `floorAskPrice`, `tokenId`, `rarity`, and `updatedAt`. No rarity rank for collections over 100k.
 */
sortBy?: GetTokensV6SortBy;
sortDirection?: GetTokensV6SortDirection;
/**
 * Filter to tokens with a listing in a particular currency. Max limit is 50. `Example: currencies[0]: 0x0000000000000000000000000000000000000000`
 */
currencies?: string[];
/**
 * Amount of items returned in response. Max limit is 100, except when sorting by `updatedAt` which has a limit of 1000.
 */
limit?: number;
/**
 * When sorting by `updatedAt`, the start timestamp you want to filter on (UTC).
 */
startTimestamp?: number;
/**
 * When sorting by `updatedAt`, the end timestamp you want to filter on (UTC).
 */
endTimestamp?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, mint data for the tokens will be included in the response.
 */
includeMintStages?: boolean;
/**
 * Exclude orders that can only be filled by EOAs, to support filling with smart contracts. defaults to false
 */
excludeEOA?: boolean;
/**
 * If true, will filter any tokens marked as spam.
 */
excludeSpam?: boolean;
/**
 * Exclude orders that have been burned, defaults to false
 */
excludeBurns?: boolean;
/**
 * If true, attributes will be returned in the response.
 */
includeAttributes?: boolean;
/**
 * If true, quantity filled and quantity remaining will be returned in the response.
 */
includeQuantity?: boolean;
/**
 * If true, dynamic pricing data will be returned in the response.
 */
includeDynamicPricing?: boolean;
/**
 * If true, last sale data including royalties paid will be returned in the response.
 */
includeLastSale?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency. Applies to `topBid` and `floorAsk`.
 */
displayCurrency?: string;
};

export type GetTokensV5SortDirection = typeof GetTokensV5SortDirection[keyof typeof GetTokensV5SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV5SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetTokensV5SortBy = typeof GetTokensV5SortBy[keyof typeof GetTokensV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV5SortBy = {
  floorAskPrice: 'floorAskPrice',
  tokenId: 'tokenId',
  rarity: 'rarity',
} as const;

export type GetTokensV5FlagStatus = typeof GetTokensV5FlagStatus[keyof typeof GetTokensV5FlagStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV5FlagStatus = {
  NUMBER_MINUS_1: -1,
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export type GetTokensV5Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token by name. Example: `token #1`
 */
tokenName?: string;
/**
 * Array of tokens. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * Filter to a particular token set. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract.
 */
tokenSetId?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/tokens/v5?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/tokens/v5?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Domain of the order source. Example `opensea.io` (Only listed tokens are returned when filtering by source)
 */
source?: string;
/**
 * Domain of the order source. Example `www.apecoinmarketplace.com`. For a native marketplace, return all tokens listed on this marketplace, even if better prices are available on other marketplaces.
 */
nativeSource?: string;
/**
 * Get tokens with a min rarity rank (inclusive)
 */
minRarityRank?: number;
/**
 * Get tokens with a max rarity rank (inclusive)
 */
maxRarityRank?: number;
/**
 * Get tokens with a min floor ask price (inclusive)
 */
minFloorAskPrice?: number;
/**
 * Get tokens with a max floor ask price (inclusive)
 */
maxFloorAskPrice?: number;
/**
 * Allowed only with collection and tokens filtering!
-1 = All tokens (default)
0 = Non flagged tokens
1 = Flagged tokens
 */
flagStatus?: GetTokensV5FlagStatus;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetTokensV5SortBy;
sortDirection?: GetTokensV5SortDirection;
/**
 * Filter to tokens with a listing in a particular currency. `Example: currencies[0]: 0x0000000000000000000000000000000000000000`
 */
currencies?: string[];
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, attributes will be returned in the response.
 */
includeAttributes?: boolean;
/**
 * If true, quantity filled and quantity remaining will be returned in the response.
 */
includeQuantity?: boolean;
/**
 * If true, dynamic pricing data will be returned in the response.
 */
includeDynamicPricing?: boolean;
/**
 * If true, a boolean indicating whether royalties were paid on a token's last sale will be returned in the response.
 */
includeRoyaltiesPaid?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Return result in given currency
 */
displayCurrency?: string;
};

export type GetTokensV4SortDirection = typeof GetTokensV4SortDirection[keyof typeof GetTokensV4SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV4SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetTokensV4SortBy = typeof GetTokensV4SortBy[keyof typeof GetTokensV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV4SortBy = {
  floorAskPrice: 'floorAskPrice',
  tokenId: 'tokenId',
  rarity: 'rarity',
} as const;

export type GetTokensV4Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Array of tokens. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * Filter to a particular token set. Example: token:0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270:129000685
 */
tokenSetId?: string;
/**
 * Filter to a particular attribute. Example: `attributes[Type]=Original`
 */
attributes?: string;
/**
 * Domain of the order source. Example `opensea.io`
 */
source?: string;
/**
 * If true, results will filter only Reservoir orders.
 */
native?: boolean;
/**
 * Order the items are returned in the response, by default sorted by `floorAskPrice`. Not supported when filtering by `contract`. When filtering by `contract` the results are sorted by `tokenId` by default.
 */
sortBy?: GetTokensV4SortBy;
sortDirection?: GetTokensV4SortDirection;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetTokensV3SortBy = typeof GetTokensV3SortBy[keyof typeof GetTokensV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV3SortBy = {
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetTokensV3Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to one or more tokens, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
tokens?: string[];
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular attribute, e.g. `attributes[Type]=Original`
 */
attributes?: string;
sortBy?: GetTokensV3SortBy;
limit?: number;
continuation?: string;
};

export type GetTokensV2SortBy = typeof GetTokensV2SortBy[keyof typeof GetTokensV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV2SortBy = {
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetTokensV2Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Filter to a particular attribute, e.g. `attributes[Type]=Original`
 */
attributes?: string;
sortBy?: GetTokensV2SortBy;
limit?: number;
continuation?: string;
};

export type GetTokensV1SortDirection = typeof GetTokensV1SortDirection[keyof typeof GetTokensV1SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV1SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetTokensV1SortBy = typeof GetTokensV1SortBy[keyof typeof GetTokensV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTokensV1SortBy = {
  tokenId: 'tokenId',
  floorAskPrice: 'floorAskPrice',
  topBidValue: 'topBidValue',
} as const;

export type GetTokensV1Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
/**
 * Limit to tokens that are listed for sale
 */
onSale?: boolean;
sortBy?: GetTokensV1SortBy;
sortDirection?: GetTokensV1SortDirection;
offset?: number;
limit?: number;
};

export type GetStatsV2Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/stats/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/stats/v2?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetStatsV1Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular attribute. Example: `attributes[Type]=Original`
 */
attributes?: string;
};

export type GetSourcesV1SortDirection = typeof GetSourcesV1SortDirection[keyof typeof GetSourcesV1SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSourcesV1SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetSourcesV1SortBy = typeof GetSourcesV1SortBy[keyof typeof GetSourcesV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSourcesV1SortBy = {
  domain: 'domain',
  createdAt: 'createdAt',
} as const;

export type GetSourcesV1Params = {
/**
 * Order of the items are returned in the response.
 */
sortBy?: GetSourcesV1SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetSourcesV1SortDirection;
/**
 * Filter to a particular domain. Example: `x2y2.io`
 */
domain?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
continuation?: string;
};

export type GetSalesV6SortDirection = typeof GetSalesV6SortDirection[keyof typeof GetSalesV6SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesV6SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetSalesV6SortBy = typeof GetSalesV6SortBy[keyof typeof GetSalesV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesV6SortBy = {
  price: 'price',
  time: 'time',
  updatedAt: 'updatedAt',
} as const;

export type GetSalesV6Params = {
contract?: string[];
/**
 * Array of tokens. Max limit is 20. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * If enabled, also include token metadata in the response. Default is false.
 */
includeTokenMetadata?: boolean;
/**
 * If enabled, include sales that have been deleted. In some cases the backfilling process deletes sales that are no longer relevant or have been reverted.
 */
includeDeleted?: boolean;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/sales/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/sales/v6?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Order the items are returned in the response. Options are `price`, `time`, and `updatedAt`. Default is `time`.
 */
sortBy?: GetSalesV6SortBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetSalesV6SortDirection;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
/**
 * Get events after a particular unix timestamp (inclusive). Relative to the sortBy time filters.
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive). Relative to the sortBy time filters.
 */
endTimestamp?: number;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetSalesV5SortDirection = typeof GetSalesV5SortDirection[keyof typeof GetSalesV5SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesV5SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetSalesV5OrderBy = typeof GetSalesV5OrderBy[keyof typeof GetSalesV5OrderBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesV5OrderBy = {
  price: 'price',
  time: 'time',
  updated_at: 'updated_at',
} as const;

export type GetSalesV5Params = {
contract?: string[];
/**
 * Array of tokens. Max limit is 20. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
tokens?: string[];
/**
 * If enabled, also include token metadata in the response. Default is false.
 */
includeTokenMetadata?: boolean;
/**
 * If enabled, include sales that have been deleted. In some cases the backfilling process deletes sales that are no longer relevant or have been reverted.
 */
includeDeleted?: boolean;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/sales/v4?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/sales/v4?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Order the items are returned in the response. Options are `price`, `time`, and `updated_at`. Default is `time`.
 */
orderBy?: GetSalesV5OrderBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetSalesV5SortDirection;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
/**
 * Get events after a particular unix timestamp (inclusive). Relative to the orderBy time filters.
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive). Relative to the orderBy time filters.
 */
endTimestamp?: number;
/**
 * Amount of items returned in response. Max limit is 1000.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetSalesV4SortDirection = typeof GetSalesV4SortDirection[keyof typeof GetSalesV4SortDirection];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesV4SortDirection = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type GetSalesV4OrderBy = typeof GetSalesV4OrderBy[keyof typeof GetSalesV4OrderBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetSalesV4OrderBy = {
  price: 'price',
  time: 'time',
  updated_at: 'updated_at',
} as const;

export type GetSalesV4Params = {
contract?: string[];
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * If enabled, also include token metadata in the response.
 */
includeTokenMetadata?: boolean;
/**
 * If enabled, include sales that have been deleted. In some cases the backfilling process deletes sales that are no longer relevant or have been reverted.
 */
includeDeleted?: boolean;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/sales/v4?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/sales/v4?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Order the items are returned in the response.
 */
orderBy?: GetSalesV4OrderBy;
/**
 * Order the items are returned in the response.
 */
sortDirection?: GetSalesV4SortDirection;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
/**
 * Get events after a particular unix timestamp (inclusive). Relative to the orderBy time filters.
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive). Relative to the orderBy time filters.
 */
endTimestamp?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetSalesV3Params = {
contract?: string[];
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular attribute. Example: `attributes[Type]=Original`
 */
attributes?: string;
/**
 * Filter to a particular transaction. Example: `0x04654cc4c81882ed4d20b958e0eeb107915d75730110cce65333221439de6afc`
 */
txHash?: string;
/**
 * Get events after a particular unix timestamp (inclusive)
 */
startTimestamp?: number;
/**
 * Get events before a particular unix timestamp (inclusive)
 */
endTimestamp?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetSalesV2Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
offset?: number;
limit?: number;
};

export type GetSalesV1Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
offset?: number;
limit?: number;
};

export type GetOwnersV2Params = {
/**
 * Filter to a particular collection set id. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular attribute. Attributes are case sensitive. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/owners/v1?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original` or `https://api.reservoir.tools/owners/v1?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attribute[Type]=Original&attribute[Type]=Sibling`
 */
attributes?: string;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response. Max limit is 500.
 */
limit?: number;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetOwnersV1Params = {
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * Filter to a particular contract. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular attribute. Note: Our docs do not support this parameter correctly. To test, you can use the following URL in your browser. Example: `https://api.reservoir.tools/owners/v1?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original` or `https://api.reservoir.tools/owners/v1?collection=0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63&attributes[Type]=Original&attributes[Type]=Sibling`
 */
attributes?: string;
/**
 * Use offset to request the next batch of items.
 */
offset?: number;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetOrdersV2Params = {
id?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract.
 */
tokenSetId?: string;
offset?: number;
limit?: number;
};

export type GetOrdersV1Params = {
id?: string;
/**
 * Filter to a particular token, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123`
 */
token?: string;
/**
 * Filter to a particular set, e.g. `contract:0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
tokenSetId?: string;
offset?: number;
limit?: number;
};

export type GetCrosspostingordersV1Params = {
ids?: number[];
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Amount of items returned in response.
 */
limit?: number;
};

export type GetCollectionsV7SortBy = typeof GetCollectionsV7SortBy[keyof typeof GetCollectionsV7SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsV7SortBy = {
  '1DayVolume': '1DayVolume',
  '7DayVolume': '7DayVolume',
  '30DayVolume': '30DayVolume',
  allTimeVolume: 'allTimeVolume',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  floorAskPrice: 'floorAskPrice',
} as const;

export type GetCollectionsV7Params = {
/**
 * Filter to a particular collection with collection id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id?: string;
/**
 * Filter to a particular collection slug. Example: `boredapeyachtclub`
 */
slug?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
contract?: string[];
/**
 * Filter by creator
 */
creator?: string;
/**
 * Search for collections that match a string. Example: `bored`
 */
name?: string;
/**
 * Maximum floor price of the collection
 */
maxFloorAskPrice?: number;
/**
 * Minumum floor price of the collection
 */
minFloorAskPrice?: number;
/**
 * If true, attributes will be included in the response. Must filter by `id` or `slug` to a particular collection.
 */
includeAttributes?: boolean;
/**
 * If true, sales count (1 day, 7 day, 30 day, all time) will be included in the response. Must filter by `id` or `slug` to a particular collection.
 */
includeSalesCount?: boolean;
/**
 * If true, mint data for the collection will be included in the response.
 */
includeMintStages?: boolean;
/**
 * If true, security configuration data (e.g. ERC721C configuration) will be included in the response.
 */
includeSecurityConfigs?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Order the items are returned in the response. Options are `#DayVolume`, `createdAt`, `updatedAt`, or `floorAskPrice`
 */
sortBy?: GetCollectionsV7SortBy;
sortDirection?: string;
/**
 * Amount of items returned in response. Default and max limit is 20, unless sorting by `updatedAt` which has a max limit of 1000.
 */
limit?: number;
/**
 * If true, will filter any collections marked as spam.
 */
excludeSpam?: boolean;
/**
 * If true, will filter any collections that have no tokens.
 */
excludeEmptyCollections?: boolean;
/**
 * When sorting by `updatedAt`, the start timestamp you want to filter on (UTC).
 */
startTimestamp?: number;
/**
 * When sorting by `updatedAt`, the end timestamp you want to filter on (UTC).
 */
endTimestamp?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency. Applies to `topBid` and `floorAsk`.
 */
displayCurrency?: string;
};

export type GetCollectionsV6SortBy = typeof GetCollectionsV6SortBy[keyof typeof GetCollectionsV6SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsV6SortBy = {
  '1DayVolume': '1DayVolume',
  '7DayVolume': '7DayVolume',
  '30DayVolume': '30DayVolume',
  allTimeVolume: 'allTimeVolume',
  createdAt: 'createdAt',
  floorAskPrice: 'floorAskPrice',
} as const;

export type GetCollectionsV6Params = {
/**
 * Filter to a particular collection with collection id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id?: string;
/**
 * Filter to a particular collection slug. Example: `boredapeyachtclub`
 */
slug?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
contract?: string[];
/**
 * Filter by creator
 */
creator?: string;
/**
 * Search for collections that match a string. Example: `bored`
 */
name?: string;
/**
 * Maximum floor price of the collection
 */
maxFloorAskPrice?: number;
/**
 * Minumum floor price of the collection
 */
minFloorAskPrice?: number;
/**
 * If true, attributes will be included in the response. Must filter by `id` or `slug` to a particular collection.
 */
includeAttributes?: boolean;
/**
 * If true, sales count (1 day, 7 day, 30 day, all time) will be included in the response. Must filter by `id` or `slug` to a particular collection.
 */
includeSalesCount?: boolean;
/**
 * If true, mint data for the collection will be included in the response.
 */
includeMintStages?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Order the items are returned in the response. Options are `#DayVolume`, `createdAt`, or `floorAskPrice`
 */
sortBy?: GetCollectionsV6SortBy;
/**
 * Amount of items returned in response. Default and max limit is 20.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetCollectionsV5SortBy = typeof GetCollectionsV5SortBy[keyof typeof GetCollectionsV5SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsV5SortBy = {
  '1DayVolume': '1DayVolume',
  '7DayVolume': '7DayVolume',
  '30DayVolume': '30DayVolume',
  allTimeVolume: 'allTimeVolume',
  createdAt: 'createdAt',
  floorAskPrice: 'floorAskPrice',
} as const;

export type GetCollectionsV5Params = {
/**
 * Filter to a particular collection with collection id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id?: string;
/**
 * Filter to a particular collection slug. Example: `boredapeyachtclub`
 */
slug?: string;
/**
 * Filter to a particular collection set. Example: `8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65`
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
contract?: string[];
/**
 * Search for collections that match a string. Example: `bored`
 */
name?: string;
/**
 * Maximum floor price of the collection
 */
maxFloorAskPrice?: number;
/**
 * Minumum floor price of the collection
 */
minFloorAskPrice?: number;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * If true, attributes will be included in the response. Must filter by `id` or `slug` to a particular collection.
 */
includeAttributes?: boolean;
/**
 * If true, sales count (1 day, 7 day, 30 day, all time) will be included in the response. Must filter by `id` or `slug` to a particular collection.
 */
includeSalesCount?: boolean;
/**
 * If true, mint data for the collection will be included in the response.
 */
includeMintStages?: boolean;
/**
 * If true, prices will include missing royalties to be added on-top.
 */
normalizeRoyalties?: boolean;
/**
 * If true, return the non flagged floor ask. Supported only when `normalizeRoyalties` is false.
 */
useNonFlaggedFloorAsk?: boolean;
/**
 * Order the items are returned in the response. Options are `#DayVolume`, `createdAt`, or `floorAskPrice`
 */
sortBy?: GetCollectionsV5SortBy;
/**
 * Amount of items returned in response. Default and max limit is 20.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
/**
 * Input any ERC20 address to return result in given currency
 */
displayCurrency?: string;
};

export type GetCollectionsV4SortBy = typeof GetCollectionsV4SortBy[keyof typeof GetCollectionsV4SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsV4SortBy = {
  '1DayVolume': '1DayVolume',
  '7DayVolume': '7DayVolume',
  '30DayVolume': '30DayVolume',
  allTimeVolume: 'allTimeVolume',
} as const;

export type GetCollectionsV4Params = {
/**
 * Filter to a particular collection set.
 */
collectionsSetId?: string;
/**
 * Filter to a particular community. Example: `artblocks`
 */
community?: string;
contract?: string[];
/**
 * Search for collections that match a string. Example: `bored`
 */
name?: string;
/**
 * Filter to a particular collection slug. Example: `boredapeyachtclub`
 */
slug?: string;
/**
 * Order the items are returned in the response.
 */
sortBy?: GetCollectionsV4SortBy;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
/**
 * Amount of items returned in response.
 */
limit?: number;
/**
 * Use continuation token to request next offset of items.
 */
continuation?: string;
};

export type GetCollectionsV3SortBy = typeof GetCollectionsV3SortBy[keyof typeof GetCollectionsV3SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsV3SortBy = {
  '1DayVolume': '1DayVolume',
  '7DayVolume': '7DayVolume',
  '30DayVolume': '30DayVolume',
  allTimeVolume: 'allTimeVolume',
} as const;

export type GetCollectionsV3Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Search for collections that match a string, e.g. `bored`
 */
name?: string;
/**
 * Filter to a particular slug, e.g. `boredapeyachtclub`
 */
slug?: string;
sortBy?: GetCollectionsV3SortBy;
offset?: number;
limit?: number;
};

export type GetCollectionsV2SortBy = typeof GetCollectionsV2SortBy[keyof typeof GetCollectionsV2SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsV2SortBy = {
  '1DayVolume': '1DayVolume',
  '7DayVolume': '7DayVolume',
  '30DayVolume': '30DayVolume',
  allTimeVolume: 'allTimeVolume',
} as const;

export type GetCollectionsV2Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Search for collections that match a string, e.g. `bored`
 */
name?: string;
/**
 * Filter to a particular slug, e.g. `boredapeyachtclub`
 */
slug?: string;
sortBy?: GetCollectionsV2SortBy;
offset?: number;
limit?: number;
};

export type GetCollectionsV1SortBy = typeof GetCollectionsV1SortBy[keyof typeof GetCollectionsV1SortBy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCollectionsV1SortBy = {
  '1DayVolume': '1DayVolume',
  allTimeVolume: 'allTimeVolume',
} as const;

export type GetCollectionsV1Params = {
/**
 * Filter to a particular community, e.g. `artblocks`
 */
community?: string;
/**
 * Filter to a particular contract, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
contract?: string;
/**
 * Search for collections that match a string, e.g. `bored`
 */
name?: string;
/**
 * Filter to a particular slug, e.g. `boredapeyachtclub`
 */
slug?: string;
sortBy?: GetCollectionsV1SortBy;
offset?: number;
limit?: number;
};

export type GetCollectionV3Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id?: string;
/**
 * Filter to a particular collection slug. Example: `boredapeyachtclub`
 */
slug?: string;
/**
 * If true, top bid will be returned in the response.
 */
includeTopBid?: boolean;
};

export type GetCollectionV2Params = {
/**
 * Filter to a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id?: string;
/**
 * Filter to a particular collection slug. Example: `boredapeyachtclub`
 */
slug?: string;
};

export type GetCollectionV1Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
id?: string;
/**
 * Filter to a particular slug, e.g. `boredapeyachtclub`
 */
slug?: string;
};

export type GetAttributesV1Params = {
/**
 * Filter to a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
};

export type GetAssetsV1Params = {
asset: string;
};

export type GetAdminRatelimitrulesParams = {
/**
 * The route to get rules for
 */
route?: string;
};

export type GetAdminCollectionmetadatadetailsParams = {
/**
 * The collection to see metadata details for. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
collection?: string;
/**
 * If false, will also include collections and tokens marked as spam.
 */
excludeSpam?: boolean;
/**
 * The amount of results to return (max 25).
 */
limit?: number;
};

export type MetadataBody = Metadata;

export type Model391Body = Model391;

export interface Model564 {
  id: string;
  skipRevalidation?: boolean;
}

export interface PostExecuteSolveCapacityV1Response {
  maxItems: number;
  maxPricePerItem: string;
}

export type Model563Kind = typeof Model563Kind[keyof typeof Model563Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model563Kind = {
  'seaport-intent': 'seaport-intent',
} as const;

export interface Model563 {
  kind: Model563Kind;
}

export type Model562VerificationStatus = typeof Model562VerificationStatus[keyof typeof Model562VerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model562VerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

/**
 * Params that can be passed in order to override existing ones, to disable override pass null
 */
export interface Model562 {
  bannerImageUrl?: string;
  creator?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  imageUrl?: string;
  name?: string;
  royalties?: Model561;
  twitterUrl?: string;
  verificationStatus?: Model562VerificationStatus;
}

export interface Model560 {
  bps?: number;
  recipient?: string;
}

export type Model561 = Model560[];

/**
 * Array of tokens. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
export type Model558 = string[];

export interface Model559 {
  /** API to update the spam status of a token */
  spam?: boolean;
  tokens: Model558;
}

export interface Model557 {
  token?: string;
}

export type Model556Router = typeof Model556Router[keyof typeof Model556Router];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model556Router = {
  v5: 'v5',
  v6: 'v6',
} as const;

export interface Model556 {
  router?: Model556Router;
  token?: string;
}

export interface Model555 {
  /** If true, only liquidity data will be refreshed. */
  liquidityOnly?: boolean;
  /** If true, will force a refresh regardless of cool down. Requires an authorized api key to be passed. */
  overrideCoolDown?: boolean;
  /** Refresh the given token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
}

/**
 * 0 - Token is not flagged, 1 - Token is flagged
 */
export type Model554Flag = typeof Model554Flag[keyof typeof Model554Flag];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model554Flag = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;

export interface Model554 {
  /** 0 - Token is not flagged, 1 - Token is flagged */
  flag: Model554Flag;
  /** The token to update the flag status for. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
}

/**
 * Array of tokens to disable or reenable metadata for. Max limit is 50. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
export type Model552 = string[];

export interface Model553 {
  /** Whether to disable or reenable the metadata. Defaults to true (disable) */
  disable?: boolean;
  tokens: Model552;
}

export type Model549Source = typeof Model549Source[keyof typeof Model549Source];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model549Source = {
  'imtbl-orderbook': 'imtbl-orderbook',
} as const;

export interface Model549 {
  id: string;
  source: Model549Source;
}

export type Model550 = Model549[];

export interface Model551 {
  /** Chain where orders were cancelled */
  chainId: number;
  orders?: Model550;
}

export interface PostExecuteTransferV1Response {
  steps?: Model548;
}

/**
 * Returns `transaction`
 */
export type Model547Kind = typeof Model547Kind[keyof typeof Model547Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model547Kind = {
  transaction: 'transaction',
} as const;

export interface Model547 {
  action: string;
  description: string;
  /** Returns `nft-approval` or `transfer` */
  id: string;
  items: Model546;
  /** Returns `transaction` */
  kind: Model547Kind;
}

export type Model548 = Model547[];

/**
 * Returns `complete` or `incomplete`.
 */
export type Model545Status = typeof Model545Status[keyof typeof Model545Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model545Status = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface Model545 {
  data?: Metadata;
  /** Returns `complete` or `incomplete`. */
  status: Model545Status;
}

export type Model546 = Model545[];

export interface Model542 {
  quantity?: number;
  token: string;
}

export type Model543 = Model542[];

export interface Model544 {
  from: string;
  items?: Model543;
  to: string;
}

export type PostExecuteStatusV1ResponseStatus = typeof PostExecuteStatusV1ResponseStatus[keyof typeof PostExecuteStatusV1ResponseStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PostExecuteStatusV1ResponseStatus = {
  unknown: 'unknown',
  pending: 'pending',
  received: 'received',
  success: 'success',
  failure: 'failure',
} as const;

export type TxHashes = string[];

export interface PostExecuteStatusV1Response {
  details?: string;
  status: PostExecuteStatusV1ResponseStatus;
  time?: number;
  txHashes?: TxHashes;
}

/**
 * Execution kind
 */
export type Model541Kind = typeof Model541Kind[keyof typeof Model541Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model541Kind = {
  'cross-chain-intent': 'cross-chain-intent',
  'cross-chain-transaction': 'cross-chain-transaction',
  'seaport-intent': 'seaport-intent',
  transaction: 'transaction',
} as const;

export interface Model541 {
  /** Chain id where the action is happening (only relevant for 'cross-chain-transaction' actions) */
  chainId?: number;
  /** The id of the execution (eg. transaction / order / intent hash) */
  id: string;
  /** Execution kind */
  kind: Model541Kind;
}

export type StatusMethod = typeof StatusMethod[keyof typeof StatusMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusMethod = {
  POST: 'POST',
} as const;

export interface Status {
  body?: string;
  endpoint: string;
  method: StatusMethod;
}

export interface PostExecuteSolveV1Response {
  status?: Status;
}

export type Model540Kind = typeof Model540Kind[keyof typeof Model540Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model540Kind = {
  'seaport-intent': 'seaport-intent',
} as const;

export interface Model540 {
  kind: Model540Kind;
  order: string;
}

export interface Model538 {
  builtInFees?: BuiltInFees;
  contract?: string;
  currency?: string;
  currencyDecimals?: number;
  currencySymbol?: string;
  feesOnTop?: Model488;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
  totalPrice?: number;
  totalRawPrice?: string;
}

export type Model539 = Model538[];

export type Model537 = Model536[];

export interface GetExecuteSellV7Response {
  errors?: Model486;
  path?: Model539;
  requestId?: string;
  steps?: Model537;
}

/**
 * Returns `signature` or `transaction`.
 */
export type Model536Kind = typeof Model536Kind[keyof typeof Model536Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model536Kind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

/**
 * Returns `complete` or `incomplete`.
 */
export type Model534Status = typeof Model534Status[keyof typeof Model534Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model534Status = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface Model534 {
  data?: Metadata;
  /** Approximation of gas used (only applies to `transaction` items) */
  gasEstimate?: number;
  orderIds?: SampleImages;
  /** Returns `complete` or `incomplete`. */
  status: Model534Status;
  tip?: string;
}

export type Model535 = Model534[];

export interface Model536 {
  action: string;
  description: string;
  /** Returns `auth` or `nft-approval` */
  id: string;
  items: Model535;
  /** Returns `signature` or `transaction`. */
  kind: Model536Kind;
}

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when filling.
The currency used for any fees on top is always the wrapped native currency of the chain.
Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type Model532 = string[];

export interface Model533 {
  /** If true, inactive orders will not be skipped over (only relevant when filling via a specific order id). */
  allowInactiveOrderIds?: boolean;
  /** Optional Blur auth used for filling */
  blurAuth?: string;
  /** Exclude orders that can only be filled by EOAs, to support filling with smart contracts. */
  excludeEOA?: boolean;
  feesOnTop?: Model532;
  /** If true, filling will be forced to use the common 'approval + transfer' method instead of the approval-less 'on-received hook' method */
  forceRouter?: boolean;
  items: Model531;
  /** Optional custom gas settings. Includes base fee & priority fee in this limit. */
  maxFeePerGas?: string;
  /** Optional custom gas settings. */
  maxPriorityFeePerGas?: string;
  /** Charge any missing royalties. */
  normalizeRoyalties?: boolean;
  /** If true, only the filling path will be returned. */
  onlyPath?: boolean;
  /** Optional OpenSea API key used for filling. You don't need to pass your own key, but if you don't, you are more likely to be rate-limited. */
  openseaApiKey?: string;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** Filling source used for attribution. */
  source?: string;
  /** Address of wallet filling. */
  taker: string;
  /** Optional X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

export type Model529Kind = typeof Model529Kind[keyof typeof Model529Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model529Kind = {
  'blur-partial': 'blur-partial',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  rarible: 'rarible',
  sudoswap: 'sudoswap',
  nftx: 'nftx',
} as const;

/**
 * Optional raw order to sell into.
 */
export interface Model529 {
  data?: Metadata;
  kind?: Model529Kind;
}

export interface Model530 {
  /** Only consider orders from this source. */
  exactOrderSource?: string;
  exclusions?: Exclusions;
  /** Optional order id to sell into. */
  orderId?: string;
  /** Quantity of tokens to sell. */
  quantity?: number;
  rawOrder?: Model529;
  /** Token to sell. */
  token: string;
}

/**
 * List of items to sell.
 */
export type Model531 = Model530[];

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when filling.
The currency used for any fees on top matches the accepted bid's currency.
Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type Model527 = string[];

export type Model526Kind = typeof Model526Kind[keyof typeof Model526Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model526Kind = {
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
} as const;

export interface Model526 {
  data: Metadata;
  kind: Model526Kind;
}

export interface Model528 {
  /** If true, do not filter out inactive orders (only relevant for order id filtering). */
  allowInactiveOrderIds?: boolean;
  /** Exclude orders that can only be filled by EOAs, to support filling with smart contracts. */
  excludeEOA?: boolean;
  feesOnTop?: Model527;
  /** Optional. Set custom gas price. */
  maxFeePerGas?: string;
  /** Optional. Set custom gas price. */
  maxPriorityFeePerGas?: string;
  normalizeRoyalties?: boolean;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  orderId?: string;
  /** Quantity of tokens user is selling. Only compatible when selling a single ERC1155 token. Example: `5` */
  quantity?: number;
  rawOrder?: Model526;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  /** Address of wallet filling the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  taker: string;
  /** Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
  /** Override the X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

export interface PostExecuteResultsV1Response {
  message: string;
}

export interface Model525 {
  /** Associated error message */
  errorMessage?: string;
  /** Request id of the associate execute API request */
  requestId: string;
  /** Step id of the relevant execute item */
  stepId: string;
  /** Associated transaction hash */
  txHash?: string;
}

export interface Model524 {
  /** Id of the pre-signature */
  id: string;
}

export interface Model523 {
  /** Id of the permit */
  id: string;
  /** Whether to persist the permit or not */
  persist?: boolean;
}

export interface Model521 {
  buyIn?: BuyIn;
  contract?: string;
  currency?: string;
  currencyDecimals?: number;
  currencySymbol?: string;
  feesOnTop?: Model488;
  /** Chain id buying from */
  fromChainId?: number;
  gasCost?: string;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
  totalPrice?: number;
  totalRawPrice?: string;
}

export type Model522 = Model521[];

export interface PostExecuteMintV1Response {
  errors?: Model486;
  gasEstimate?: number;
  maxQuantities?: MaxQuantities;
  path?: Model522;
  requestId?: string;
  steps?: Model484;
}

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when minting.
Unless overridden via the `currency` param, the currency used for any fees on top matches the buy-in currency detected by the backend.
Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type Model519 = string[];

export interface Model520 {
  alternativeCurrencies?: AlternativeCurrencies;
  /** Mint comment (where suported). */
  comment?: string;
  /** The chain id of the purchase currency. */
  currencyChainId?: number;
  feesOnTop?: Model519;
  items: Model518;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** Referrer address (where supported). */
  referrer?: string;
  /** Address of wallet relaying the mint transaction(s) (paying for the NFT). */
  relayer?: string;
  /** If true, balance checks will be skipped. */
  skipBalanceCheck?: boolean;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  /** Address of wallet minting (receiver of the NFT). */
  taker: string;
}

export interface Tx {
  data?: Model516;
  to: string;
}

export interface Details {
  tx?: Tx;
}

/**
 * Optional custom details to use for minting.
 */
export interface Custom {
  contract: string;
  details: Details;
  price: string;
}

export interface Model517 {
  /** Collection to mint. */
  collection?: string;
  custom?: Custom;
  /** Quantity of tokens to mint. */
  quantity?: number;
  /** Token to mint. */
  token?: string;
}

/**
 * List of items to mint.
 */
export type Model518 = Model517[];

export interface Model514 {
  abiType: string;
  abiValue: string;
}

export type Model515 = Model514[];

export interface Model516 {
  params?: Model515;
  signature: string;
}

/**
 * Returns `request`, `signature`, or `transaction`.
 */
export type Model512Kind = typeof Model512Kind[keyof typeof Model512Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model512Kind = {
  request: 'request',
  signature: 'signature',
  transaction: 'transaction',
} as const;

export interface Model512 {
  action: string;
  description: string;
  /** Returns `nft-approval` or `order-signature` */
  id: string;
  items: Model511;
  /** Returns `request`, `signature`, or `transaction`. */
  kind: Model512Kind;
}

export type Model513 = Model512[];

export interface GetExecuteListV5Response {
  errors?: Errors;
  steps?: Model513;
}

/**
 * Returns `complete` or `incomplete`.
 */
export type Model510Status = typeof Model510Status[keyof typeof Model510Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model510Status = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface Model510 {
  data?: Metadata;
  orderIndexes?: FloorAskPrices;
  /** Returns `complete` or `incomplete`. */
  status: Model510Status;
  tip?: string;
}

export type Model511 = Model510[];

export type Model508 = Model507[];

export interface Model509 {
  /** Advanced use case to pass personal blurAuthToken; the API will generate one if left empty. */
  blurAuth?: string;
  /** Address of wallet making the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  maker: string;
  params?: Model508;
  /** Domain of your app that is creating the order, e.g. `myapp.xyz`. This is used for filtering, and to attribute the "order source" of sales in on-chain analytics, to help your app get discovered. Lean more <a href='https://docs.reservoir.tools/docs/calldata-attribution'>here</a> */
  source?: string;
}

/**
 * Exchange protocol used to create order. Example: `seaport-v1.5`
 */
export type Model507OrderKind = typeof Model507OrderKind[keyof typeof Model507OrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model507OrderKind = {
  blur: 'blur',
  'looks-rare': 'looks-rare',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  alienswap: 'alienswap',
  'payment-processor': 'payment-processor',
  'payment-processor-v2': 'payment-processor-v2',
} as const;

/**
 * Orderbook where order is placed. Example: `Reservoir`
 */
export type Model507Orderbook = typeof Model507Orderbook[keyof typeof Model507Orderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model507Orderbook = {
  blur: 'blur',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  reservoir: 'reservoir',
  x2y2: 'x2y2',
} as const;

export interface Alienswap {
  replaceOrderId?: string;
  useOffChainCancellation: boolean;
}

/**
 * Additional options.
 */
export interface Model506 {
  alienswap?: Alienswap;
  'payment-processor-v2'?: PaymentProcessorV2;
  'seaport-v1.4'?: SeaportV14;
  'seaport-v1.5'?: SeaportV14;
}

export interface Model507 {
  /** If true, royalty amounts and recipients will be set automatically. */
  automatedRoyalties?: boolean;
  currency?: string;
  customRoyalties?: CustomRoyalties;
  /** Amount seller is willing to sell for Dutch auction in the largest denomination for the specific currency. Example: `2000000000000000000` */
  endWeiPrice?: string;
  /** Unix timestamp (seconds) indicating when listing will expire. Example: `1656080318` */
  expirationTime?: string;
  fees?: Model452;
  /** Unix timestamp (seconds) indicating when listing will be listed. Example: `1656080318` */
  listingTime?: string;
  marketplaceFees?: MarketplaceFees;
  /** Optional. Set a custom nonce */
  nonce?: string;
  options?: Model506;
  /** Orderbook where order is placed. Example: `Reservoir` */
  orderbook?: Model507Orderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  /** Exchange protocol used to create order. Example: `seaport-v1.5` */
  orderKind?: Model507OrderKind;
  /** Quantity of tokens user is listing. Only compatible with ERC1155 tokens. Example: `5` */
  quantity?: number;
  /** Set a maximum amount of royalties to pay, rather than the full amount. Only relevant when using automated royalties. 1 BPS = 0.01% Note: OpenSea does not support values below 50 bps. */
  royaltyBps?: number;
  /** Optional. Random string to make the order unique */
  salt?: string;
  /** Address of wallet taking the private order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  taker?: string;
  /** Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
  /** Amount seller is willing to sell for in the smallest denomination for the specific currency. Example: `1000000000000000000` */
  weiPrice: string;
}

export interface GetExecuteListV4Response {
  steps?: Model451;
}

export interface Model505 {
  /** Address of wallet making the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  maker: string;
  params?: Model504;
  /** Domain of your app that is creating the order, e.g. `myapp.xyz`. This is used for filtering, and to attribute the "order source" of sales in on-chain analytics, to help your app get discovered. Lean more <a href='https://docs.reservoir.tools/docs/calldata-attribution'>here</a> */
  source?: string;
}

/**
 * Exchange protocol used to create order. Example: `seaport-v1.5`
 */
export type Model503OrderKind = typeof Model503OrderKind[keyof typeof Model503OrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model503OrderKind = {
  'looks-rare': 'looks-rare',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
} as const;

/**
 * Orderbook where order is placed. Example: `Reservoir`
 */
export type Model503Orderbook = typeof Model503Orderbook[keyof typeof Model503Orderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model503Orderbook = {
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  reservoir: 'reservoir',
  x2y2: 'x2y2',
} as const;

export interface Model503 {
  /** If true, royalties will be automatically included. */
  automatedRoyalties?: boolean;
  currency?: string;
  /** Unix timestamp (seconds) indicating when listing will expire. Example: `1656080318` */
  expirationTime?: string;
  fees?: Fees;
  /** Unix timestamp (seconds) indicating when listing will be listed. Example: `1656080318` */
  listingTime?: string;
  /** Optional. Set a custom nonce */
  nonce?: string;
  /** Orderbook where order is placed. Example: `Reservoir` */
  orderbook?: Model503Orderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  /** Exchange protocol used to create order. Example: `seaport-v1.5` */
  orderKind?: Model503OrderKind;
  /** Quantity of tokens user is listing. Only compatible with ERC1155 tokens. Example: `5` */
  quantity?: number;
  /** The royalty percentage to pay. Only relevant when using automated royalties. */
  royaltyBps?: number;
  /** Optional. Random string to make the order unique */
  salt?: string;
  /** Filter to a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
  /** Amount seller is willing to sell for in wei. Example: `1000000000000000000` */
  weiPrice: string;
}

export type Model504 = Model503[];

/**
 * Exchange protocol used to bulk cancel order. Example: `seaport-v1.5`
 */
export type Model502OrderKind = typeof Model502OrderKind[keyof typeof Model502OrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model502OrderKind = {
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  alienswap: 'alienswap',
  'blur-bid': 'blur-bid',
  'payment-processor-v2': 'payment-processor-v2',
} as const;

/**
 * Ids of the orders to cancel
 */
export type Model501 = string[];

export interface Model502 {
  orderIds: Model501;
  /** Exchange protocol used to bulk cancel order. Example: `seaport-v1.5` */
  orderKind: Model502OrderKind;
}

export interface PostCancelImtblV1Response {
  isPendingCancellation?: boolean;
  message?: string;
}

export interface Model498 {
  /** Id of the order to cancel */
  id: string;
  /** Corresponding Imtbl orderId to cancel */
  imtblOrderId: string;
}

/**
 * Orders to cancel
 */
export type Model499 = Model498[];

export interface Model500 {
  /** Chain where orders were created */
  chainId: number;
  /** Address of wallet cancelling the orders */
  makerAddress: string;
  orders: Model499;
}

export type Model497 = Model496[];

export interface GetExecuteCancelV3Response {
  steps?: Model497;
}

export type Model496Kind = typeof Model496Kind[keyof typeof Model496Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model496Kind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

export type Model494Status = typeof Model494Status[keyof typeof Model494Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model494Status = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface Model494 {
  data?: Metadata;
  status: Model494Status;
  tip?: string;
}

export type Model495 = Model494[];

export interface Model496 {
  action: string;
  description: string;
  id: string;
  items: Model495;
  kind: Model496Kind;
}

export type Model493OrderKind = typeof Model493OrderKind[keyof typeof Model493OrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model493OrderKind = {
  blur: 'blur',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4-erc721': 'zeroex-v4-erc721',
  'zeroex-v4-erc1155': 'zeroex-v4-erc1155',
  'payment-processor-v2': 'payment-processor-v2',
  rarible: 'rarible',
  alienswap: 'alienswap',
} as const;

export type Model492 = string[];

export interface Model493 {
  blurAuth?: string;
  maker?: string;
  /** Optional. Set custom gas price */
  maxFeePerGas?: string;
  /** Optional. Set custom gas price */
  maxPriorityFeePerGas?: string;
  orderIds?: Model492;
  orderKind?: Model493OrderKind;
  token?: string;
}

export interface Model491 {
  itemIndex: number;
  maxQuantity?: string;
}

export type MaxQuantities = Model491[];

export interface Model487 {
  amount?: number;
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
}

/**
 * Can be referral fees.
 */
export type Model488 = Model487[];

/**
 * Can be marketplace fees or royalties
 */
export type BuiltInFees = Model487[];

export type BuyIn = Price[];

export interface Model489 {
  builtInFees?: BuiltInFees;
  buyIn?: BuyIn;
  buyInCurrency?: string;
  buyInCurrencyDecimals?: number;
  buyInCurrencySymbol?: string;
  buyInQuote?: number;
  buyInRawQuote?: string;
  contract?: string;
  currency?: string;
  currencyDecimals?: number;
  currencySymbol?: string;
  feesOnTop?: Model488;
  /** Chain id buying from */
  fromChainId?: number;
  gasCost?: string;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
  totalPrice?: number;
  totalRawPrice?: string;
}

export type Model490 = Model489[];

export interface Model485 {
  message?: string;
  orderId?: string;
}

export type Model486 = Model485[];

export type Model484 = Model483[];

export interface GetExecuteBuyV7Response {
  errors?: Model486;
  gasEstimate?: number;
  maxQuantities?: MaxQuantities;
  path?: Model490;
  requestId?: string;
  steps?: Model484;
}

export type Model483Kind = typeof Model483Kind[keyof typeof Model483Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model483Kind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

export type Model482 = Model481[];

export interface Model483 {
  action: string;
  description: string;
  id: string;
  items: Model482;
  kind: Model483Kind;
}

/**
 * Response is `complete` or `incomplete`.
 */
export type Model481Status = typeof Model481Status[keyof typeof Model481Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model481Status = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export type CheckMethod = typeof CheckMethod[keyof typeof CheckMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CheckMethod = {
  POST: 'POST',
} as const;

/**
 * The details of the endpoint for checking the status of the step
 */
export interface Check {
  body?: string;
  endpoint: string;
  method: CheckMethod;
}

export interface Model481 {
  check?: Check;
  data?: Metadata;
  /** Approximation of gas used (only applies to `transaction` items) */
  gasEstimate?: number;
  orderIds?: SampleImages;
  /** Response is `complete` or `incomplete`. */
  status: Model481Status;
  tip?: string;
}

/**
 * Choose a specific swapping provider when buying in a different currency (defaults to `uniswap`)
 */
export type Model480SwapProvider = typeof Model480SwapProvider[keyof typeof Model480SwapProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model480SwapProvider = {
  uniswap: 'uniswap',
  '1inch': '1inch',
} as const;

export type Model480ExecutionMethod = typeof Model480ExecutionMethod[keyof typeof Model480ExecutionMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model480ExecutionMethod = {
  'seaport-intent': 'seaport-intent',
} as const;

/**
 * Alternative currencies to return the quote in.
 */
export type AlternativeCurrencies = string[];

export interface Model480 {
  /** If true, inactive orders will not be skipped over (only relevant when filling via a specific order id). */
  allowInactiveOrderIds?: boolean;
  alternativeCurrencies?: AlternativeCurrencies;
  /** Advanced use case to pass personal blurAuthToken; the API will generate one if left empty. */
  blurAuth?: string;
  /** Mint comment (where suported) */
  comment?: string;
  /** Currency to be used for purchases. */
  currency?: string;
  /** The chain id of the purchase currency */
  currencyChainId?: number;
  /** Exclude orders that can only be filled by EOAs, to support filling with smart contracts. If marked `true`, blur will be excluded. */
  excludeEOA?: boolean;
  executionMethod?: Model480ExecutionMethod;
  feesOnTop?: Model471;
  /** If true, all fills will be executed through the router (where possible) */
  forceRouter?: boolean;
  items: Model479;
  /** Optional custom gas settings. Includes base fee & priority fee in this limit. */
  maxFeePerGas?: string;
  /** Optional custom gas settings. */
  maxPriorityFeePerGas?: string;
  /** Charge any missing royalties. */
  normalizeRoyalties?: boolean;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  /** Optional OpenSea API key used for filling. You don't need to pass your own key, but if you don't, you are more likely to be rate-limited. */
  openseaApiKey?: string;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** Referrer address (where supported) */
  referrer?: string;
  /** Address of wallet relaying the fill transaction (paying for the NFT). */
  relayer?: string;
  /** If true, balance check will be skipped. */
  skipBalanceCheck?: boolean;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  /** Choose a specific swapping provider when buying in a different currency (defaults to `uniswap`) */
  swapProvider?: Model480SwapProvider;
  /** Address of wallet filling (receiver of the NFT). */
  taker: string;
  /** When true, will use permit to avoid approvals. */
  usePermit?: boolean;
  /** Optional X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

/**
 * Optionally specify a particular fill method. Only relevant when filling via `collection`.
 */
export type Model478FillType = typeof Model478FillType[keyof typeof Model478FillType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model478FillType = {
  trade: 'trade',
  mint: 'mint',
  preferMint: 'preferMint',
} as const;

export interface Model478 {
  /** Collection to buy. */
  collection?: string;
  /** Only consider orders from this source. */
  exactOrderSource?: string;
  exclusions?: Exclusions;
  /** Optionally specify a particular fill method. Only relevant when filling via `collection`. */
  fillType?: Model478FillType;
  /** Optional order id to fill. */
  orderId?: string;
  /** If there are multiple listings with equal best price, prefer this source over others.
NOTE: if you want to fill a listing that is not the best priced, you need to pass a specific order id or use `exactOrderSource`. */
  preferredOrderSource?: string;
  /** Quantity of tokens to buy. */
  quantity?: number;
  rawOrder?: RawOrder;
  /** Token to buy. */
  token?: string;
}

/**
 * List of items to buy.
 */
export type Model479 = Model478[];

export interface Model477 {
  orderId: string;
  price?: string;
}

/**
 * Items to exclude
 */
export type Exclusions = Model477[];

export type RawOrderKind = typeof RawOrderKind[keyof typeof RawOrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RawOrderKind = {
  opensea: 'opensea',
  'blur-partial': 'blur-partial',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  rarible: 'rarible',
  sudoswap: 'sudoswap',
  nftx: 'nftx',
  alienswap: 'alienswap',
  mint: 'mint',
} as const;

/**
 * Optional raw order to fill.
 */
export interface RawOrder {
  data?: Metadata;
  kind?: RawOrderKind;
}

export interface Model475 {
  message?: string;
  orderId?: number;
}

export type Model476 = Model475[];

export interface GetExecuteBuyV6Response {
  errors?: Model476;
  path?: Path;
  steps?: Model474;
}

export type Model473Kind = typeof Model473Kind[keyof typeof Model473Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model473Kind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

export interface Model473 {
  action: string;
  description: string;
  id: string;
  items: Model465;
  kind: Model473Kind;
}

export type Model474 = Model473[];

/**
 * Currency to buy all listings in.
 */
export type Model472Currency = typeof Model472Currency[keyof typeof Model472Currency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model472Currency = {
  '0x0000000000000000000000000000000000000000': '0x0000000000000000000000000000000000000000',
} as const;

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when filling.
Unless overridden via the `currency` param, the currency used for any fees on top matches the buy-in currency detected by the backend.
Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type Model471 = string[];

export interface Model472 {
  /** If true, do not filter out inactive orders (only relevant for order id filtering). */
  allowInactiveOrderIds?: boolean;
  /** Currency to buy all listings in. */
  currency?: Model472Currency;
  /** Exclude orders that can only be filled by EOAs, to support filling with smart contracts. */
  excludeEOA?: boolean;
  feesOnTop?: Model471;
  /** If true, all fills will be executed through the router. */
  forceRouter?: boolean;
  /** Optional. Set custom gas price. */
  maxFeePerGas?: string;
  /** Optional. Set custom gas price. */
  maxPriorityFeePerGas?: string;
  normalizeRoyalties?: boolean;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  orderIds?: OrderIds;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** If there are multiple listings with equal best price, prefer this source over others.
NOTE: if you want to fill a listing that is not the best priced, you need to pass a specific order ID. */
  preferredOrderSource?: string;
  /** Quantity of tokens user is buying. Only compatible when buying a single ERC1155 token. Example: `5` */
  quantity?: number;
  rawOrders?: Model470;
  /** Address of wallet relaying the filling transaction */
  relayer?: string;
  /** If true, balance check will be skipped. */
  skipBalanceCheck?: boolean;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  /** Address of wallet filling the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  taker: string;
  tokens?: Model462;
  /** Override the X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

export type Model469Kind = typeof Model469Kind[keyof typeof Model469Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model469Kind = {
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  rarible: 'rarible',
  sudoswap: 'sudoswap',
  nftx: 'nftx',
} as const;

export interface Model469 {
  data: Metadata;
  kind: Model469Kind;
}

export type Model470 = Model469[];

export interface Model468 {
  contract?: string;
  currency?: string;
  orderId?: string;
  quantity?: number;
  quote?: number;
  rawQuote?: string;
  source?: string;
  tokenId?: string;
}

export type Path = Model468[];

export type Model467 = Model466[];

export interface GetExecuteBuyV5Response {
  path?: Path;
  steps?: Model467;
}

export type Model466Kind = typeof Model466Kind[keyof typeof Model466Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model466Kind = {
  transaction: 'transaction',
} as const;

export type Model464Status = typeof Model464Status[keyof typeof Model464Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model464Status = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface Model464 {
  data?: Metadata;
  status: Model464Status;
}

export type Model465 = Model464[];

export interface Model466 {
  action: string;
  description: string;
  items: Model465;
  kind: Model466Kind;
}

/**
 * List of fees (formatted as `feeRecipient:feeAmount`) to be taken when filling. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:1000000000000000`
 */
export type FeesOnTop = string[];

/**
 * Array of tokens user is buying. Example: `tokens[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:704 tokens[1]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:979`
 */
export type Model462 = string[];

export interface Model463 {
  currency?: string;
  feesOnTop?: FeesOnTop;
  /** If true, all fills will be executed through the router. */
  forceRouter?: boolean;
  /** Optional. Set custom gas price. */
  maxFeePerGas?: string;
  /** Optional. Set custom gas price. */
  maxPriorityFeePerGas?: string;
  normalizeRoyalties?: boolean;
  /** If true, only the path will be returned. */
  onlyPath?: boolean;
  orderIds?: OrderIds;
  /** If true, any off-chain or on-chain errors will be skipped. */
  partial?: boolean;
  /** If there are multiple listings with equal best price, prefer this source over others.
NOTE: if you want to fill a listing that is not the best priced, you need to pass a specific order ID. */
  preferredOrderSource?: string;
  /** Quantity of tokens user is buying. Only compatible when buying a single ERC1155 token. Example: `5` */
  quantity?: number;
  rawOrders?: RawOrders;
  /** Address of wallet relaying the filling transaction */
  relayer?: string;
  /** If true, balance check will be skipped. */
  skipBalanceCheck?: boolean;
  /** Filling source used for attribution. Example: `reservoir.market` */
  source?: string;
  /** Address of wallet filling the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  taker: string;
  tokens?: Model462;
  /** Override the X2Y2 API key used for filling. */
  x2y2ApiKey?: string;
}

export type Model461Kind = typeof Model461Kind[keyof typeof Model461Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model461Kind = {
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  x2y2: 'x2y2',
} as const;

export interface Model461 {
  data: Metadata;
  kind: Model461Kind;
}

export type RawOrders = Model461[];

export type OrderIds = string[];

export interface Model460 {
  message?: string;
  orderIndex?: number;
}

export type Errors = Model460[];

export type Model459 = Model458[];

export interface GetExecuteBidV5Response {
  errors?: Errors;
  steps?: Model459;
}

/**
 * Returns `request`, `signature`, or `transaction`.
 */
export type Model458Kind = typeof Model458Kind[keyof typeof Model458Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model458Kind = {
  request: 'request',
  signature: 'signature',
  transaction: 'transaction',
} as const;

/**
 * Returns `complete` or `incomplete`
 */
export type Model456Status = typeof Model456Status[keyof typeof Model456Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model456Status = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface Model456 {
  data?: Metadata;
  orderIndexes?: FloorAskPrices;
  /** Returns `complete` or `incomplete` */
  status: Model456Status;
  tip?: string;
}

export type Model457 = Model456[];

export interface Model458 {
  action: string;
  description: string;
  /** Returns `currency-wrapping`, `currency-approval`, or `order-signature`. */
  id: string;
  items: Model457;
  /** Returns `request`, `signature`, or `transaction`. */
  kind: Model458Kind;
}

export type Model454 = Model453[];

export interface Model455 {
  /** Advanced use case to pass personal blurAuthToken; the API will generate one if left empty. */
  blurAuth?: string;
  /** Address of wallet making the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  maker: string;
  params?: Model454;
  /** Domain of your app that is creating the order, e.g. `myapp.xyz`. This is used for filtering, and to attribute the "order source" of sales in on-chain analytics, to help your app get discovered. Lean more <a href='https://docs.reservoir.tools/docs/calldata-attribution'>here</a> */
  source?: string;
}

/**
 * Exchange protocol used to create order. Example: `seaport-v1.5`
 */
export type Model453OrderKind = typeof Model453OrderKind[keyof typeof Model453OrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model453OrderKind = {
  blur: 'blur',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  'looks-rare': 'looks-rare',
  'looks-rare-v2': 'looks-rare-v2',
  x2y2: 'x2y2',
  alienswap: 'alienswap',
  'payment-processor': 'payment-processor',
  'payment-processor-v2': 'payment-processor-v2',
} as const;

/**
 * Orderbook where order is placed. Example: `Reservoir`
 */
export type Model453Orderbook = typeof Model453Orderbook[keyof typeof Model453Orderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model453Orderbook = {
  blur: 'blur',
  reservoir: 'reservoir',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  x2y2: 'x2y2',
} as const;

/**
 * List of custom royalties (formatted as `feeRecipient:feeBps`) to be bundled within the order. 1 BPS = 0.01% Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:100`
 */
export type CustomRoyalties = string[];

/**
 * List of marketplace fees (formatted as `feeRecipient:feeBps`) to be bundled within the order. 1 BPS = 0.01% Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:100`
 */
export type MarketplaceFees = string[];

/**
 * Deprecated, use `marketplaceFees` and/or `customRoyalties`
 */
export type Model452 = string[];

export interface Model453 {
  /** Bid on a particular attribute key. This is case sensitive. Example: `Composition` */
  attributeKey?: string;
  /** Bid on a particular attribute value. This is case sensitive. Example: `Teddy (#33)` */
  attributeValue?: string;
  /** If true, royalty amounts and recipients will be set automatically. */
  automatedRoyalties?: boolean;
  /** Bid on a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection?: string;
  currency?: string;
  customRoyalties?: CustomRoyalties;
  /** If true flagged tokens will be excluded */
  excludeFlaggedTokens?: boolean;
  /** Unix timestamp (seconds) indicating when listing will expire. Example: `1656080318` */
  expirationTime?: string;
  fees?: Model452;
  /** Unix timestamp (seconds) indicating when listing will be listed. Example: `1656080318` */
  listingTime?: string;
  marketplaceFees?: MarketplaceFees;
  /** Optional. Set a custom nonce */
  nonce?: string;
  options?: Options;
  /** Orderbook where order is placed. Example: `Reservoir` */
  orderbook?: Model453Orderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  /** Exchange protocol used to create order. Example: `seaport-v1.5` */
  orderKind?: Model453OrderKind;
  /** Quantity of tokens to bid on. */
  quantity?: number;
  /** Set a maximum amount of royalties to pay, rather than the full amount. Only relevant when using automated royalties. 1 BPS = 0.01% Note: OpenSea does not support values below 50 bps. */
  royaltyBps?: number;
  /** Optional. Random string to make the order unique */
  salt?: string;
  /** Bid on a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token?: string;
  /** Bid on a particular token set. Cannot be used with cross-posting to OpenSea. Example: `token:CONTRACT:TOKEN_ID` representing a single token within contract, `contract:CONTRACT` representing a whole contract, `range:CONTRACT:START_TOKEN_ID:END_TOKEN_ID` representing a continuous token id range within a contract and `list:CONTRACT:TOKEN_IDS_HASH` representing a list of token ids within a contract. */
  tokenSetId?: string;
  /** When true, will use permit to avoid approvals. */
  usePermit?: boolean;
  /** Amount bidder is willing to offer in the smallest denomination for the specific currency. Example: `1000000000000000000` */
  weiPrice: string;
}

export interface PaymentProcessorV2 {
  useOffChainCancellation: boolean;
}

export interface SeaportV14 {
  conduitKey?: string;
  replaceOrderId?: string;
  useOffChainCancellation: boolean;
}

/**
 * Additional options.
 */
export interface Options {
  'payment-processor-v2'?: PaymentProcessorV2;
  'seaport-v1.4'?: SeaportV14;
  'seaport-v1.5'?: SeaportV14;
}

export type Model450Kind = typeof Model450Kind[keyof typeof Model450Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model450Kind = {
  request: 'request',
  signature: 'signature',
  transaction: 'transaction',
} as const;

export interface Model450 {
  action: string;
  description: string;
  id: string;
  items: Items;
  kind: Model450Kind;
}

export type Model451 = Model450[];

export interface GetExecuteBidV4Response {
  query?: Metadata;
  steps?: Model451;
}

export type Params = Model448[];

export interface Model449 {
  /** Address of wallet making the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00` */
  maker: string;
  params?: Params;
  /** Domain of your app that is creating the order, e.g. `myapp.xyz`. This is used for filtering, and to attribute the "order source" of sales in on-chain analytics, to help your app get discovered. Lean more <a href='https://docs.reservoir.tools/docs/calldata-attribution'>here</a> */
  source?: string;
}

/**
 * Exchange protocol used to create order. Example: `seaport-v1.5`
 */
export type Model448OrderKind = typeof Model448OrderKind[keyof typeof Model448OrderKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model448OrderKind = {
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  'looks-rare': 'looks-rare',
  'looks-rare-v2': 'looks-rare-v2',
  x2y2: 'x2y2',
} as const;

/**
 * Orderbook where order is placed. Example: `Reservoir`
 */
export type Model448Orderbook = typeof Model448Orderbook[keyof typeof Model448Orderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model448Orderbook = {
  reservoir: 'reservoir',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  x2y2: 'x2y2',
} as const;

/**
 * List of fees (formatted as `feeRecipient:feeBps`) to be bundled within the order. Example: `0xF296178d553C8Ec21A2fBD2c5dDa8CA9ac905A00:100`
 */
export type Fees = string[];

export interface Model448 {
  /** Bid on a particular attribute key. Example: `Composition` */
  attributeKey?: string;
  /** Bid on a particular attribute value. Example: `Teddy (#33)` */
  attributeValue?: string;
  /** If true, royalties will be automatically included. */
  automatedRoyalties?: boolean;
  /** Bid on a particular collection with collection-id. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection?: string;
  currency?: string;
  /** If true flagged tokens will be excluded */
  excludeFlaggedTokens?: boolean;
  /** Unix timestamp (seconds) indicating when listing will expire. Example: `1656080318` */
  expirationTime?: string;
  fees?: Fees;
  /** Unix timestamp (seconds) indicating when listing will be listed. Example: `1656080318` */
  listingTime?: string;
  /** Optional. Set a custom nonce */
  nonce?: string;
  /** Orderbook where order is placed. Example: `Reservoir` */
  orderbook?: Model448Orderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  /** Exchange protocol used to create order. Example: `seaport-v1.5` */
  orderKind?: Model448OrderKind;
  /** Quantity of tokens user is buying. Only compatible with ERC1155 tokens. Example: `5` */
  quantity?: number;
  /** The royalty percentage to pay. Only relevant when using automated royalties. */
  royaltyBps?: number;
  /** Optional. Random string to make the order unique */
  salt?: string;
  /** Bid on a particular token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token?: string;
  /** Bid on a particular token set. */
  tokenSetId?: string;
  /** Amount bidder is willing to offer in wei. Example: `1000000000000000000` */
  weiPrice: string;
}

export interface PostAuthSignatureV1Response {
  auth?: string;
}

/**
 * Type of auth
 */
export type Model447Kind = typeof Model447Kind[keyof typeof Model447Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model447Kind = {
  blur: 'blur',
  erc721c: 'erc721c',
  opensea: 'opensea',
} as const;

export interface Model447 {
  /** Id of the auth challenge */
  id: string;
  /** Type of auth */
  kind: Model447Kind;
}

/**
 * Update to one or more collections. Max limit is 50. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63`
 */
export type Model445 = string[];

export interface Model446 {
  collections: Model445;
  /** API to update the spam status of a collection */
  spam?: boolean;
}

export interface Model444 {
  /** Refresh the given collection. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection: string;
  /** If true, will force a refresh regardless of cool down. Requires an authorized api key to be passed. */
  overrideCoolDown?: boolean;
  /** If true, will refresh the metadata for the tokens in the collection. */
  refreshTokens?: boolean;
}

export interface Model443 {
  /** Refresh the given collection. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection: string;
  /** If true, will only refresh the collection metadata. */
  metadataOnly?: boolean;
  /** If true, will force a refresh regardless of cool down. Requires an authorized api key to be passed. */
  overrideCoolDown?: boolean;
}

/**
 * Array of collection ids to disable metadata for. Max limit is 50. Example: `collections[0]: 0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63 collections[1]: 0x521f9c7505005cfa19a8e5786a9c3c9c9f5e6f42`
 */
export type Model441 = string[];

export interface Model442 {
  collections: Model441;
  /** Whether to disable or reenable the metadata. Defaults to true (disable) */
  disable?: boolean;
}

export interface Model440 {
  apiCallsCount?: number;
  key?: string;
  pointsConsumed?: number;
  route?: string;
  statusCode?: number;
  time?: string;
}

export type Metrics = Model440[];

export interface PostApiKeyMetricsResponse {
  metrics?: Metrics;
}

export type Model438 = string[];

export interface Model439 {
  tokens: Model438;
}

export type TokenIds = string[];

export interface Model437 {
  /** Contract address. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  contract: string;
  tokenIds: TokenIds;
}

export interface ProtocolData {
  parameters?: string;
  signature?: string;
}

export interface Model435 {
  protocol_data?: ProtocolData;
}

export type SeaportOffers = Model435[];

export interface Model436 {
  seaport_offers?: SeaportOffers;
}

export interface Model434 {
  orders?: Model433;
}

export type Model432Kind = typeof Model432Kind[keyof typeof Model432Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model432Kind = {
  blur: 'blur',
  'zeroex-v4': 'zeroex-v4',
  x2y2: 'x2y2',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  element: 'element',
  rarible: 'rarible',
  manifold: 'manifold',
  'looks-rare-v2': 'looks-rare-v2',
} as const;

export interface Model432 {
  data: Metadata;
  kind: Model432Kind;
  originatedAt?: string;
  source?: string;
}

export type Model433 = Model432[];

export interface Model431 {
  /** Only available when posting to external orderbook. Can be used to retrieve the status of a cross-post order. */
  crossPostingOrderId?: string;
  /** Current cross-post order status. Responses are `pending`, `posted`, or `failed`. */
  crossPostingOrderStatus?: string;
  message?: string;
  orderId?: string;
  orderIndex?: number;
}

export type Results = Model431[];

export interface PostOrderV4Response {
  results?: Results;
}

export interface Model430 {
  items?: Model429;
  /** The source domain */
  source?: string;
}

export type Model428Orderbook = typeof Model428Orderbook[keyof typeof Model428Orderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model428Orderbook = {
  blur: 'blur',
  reservoir: 'reservoir',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  x2y2: 'x2y2',
} as const;

export interface Model428 {
  attribute?: Attribute;
  bulkData?: BulkData;
  collection?: string;
  isNonFlagged?: boolean;
  order?: Model426;
  orderbook?: Model428Orderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  permitId?: string;
  permitIndex?: number;
  tokenSetId?: string;
}

export type Model429 = Model428[];

export type BulkDataKind = typeof BulkDataKind[keyof typeof BulkDataKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BulkDataKind = {
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  alienswap: 'alienswap',
} as const;

export interface Model427 {
  merkleProof: SampleImages;
  orderIndex: number;
}

export interface BulkData {
  data?: Model427;
  kind?: BulkDataKind;
}

export type Model426Kind = typeof Model426Kind[keyof typeof Model426Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model426Kind = {
  blur: 'blur',
  opensea: 'opensea',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  alienswap: 'alienswap',
  'payment-processor': 'payment-processor',
  'payment-processor-v2': 'payment-processor-v2',
} as const;

export interface Model426 {
  data: Metadata;
  kind: Model426Kind;
}

export type Model425Orderbook = typeof Model425Orderbook[keyof typeof Model425Orderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model425Orderbook = {
  reservoir: 'reservoir',
  opensea: 'opensea',
  'looks-rare': 'looks-rare',
  x2y2: 'x2y2',
} as const;

export interface Model425 {
  attribute?: Attribute;
  collection?: string;
  isNonFlagged?: boolean;
  order?: Model424;
  orderbook?: Model425Orderbook;
  /** Optional API key for the target orderbook */
  orderbookApiKey?: string;
  permitId?: string;
  permitIndex?: number;
  /** The source domain */
  source?: string;
  tokenSetId?: string;
}

export type Model424Kind = typeof Model424Kind[keyof typeof Model424Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model424Kind = {
  opensea: 'opensea',
  blur: 'blur',
  'looks-rare-v2': 'looks-rare-v2',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  'seaport-v14': 'seaport-v1.4',
  'seaport-v15': 'seaport-v1.5',
  x2y2: 'x2y2',
  alienswap: 'alienswap',
} as const;

export interface Model424 {
  data: Metadata;
  kind: Model424Kind;
}

export interface PostOrderV2Response {
  /** Only available when posting to external orderbook. Can be used to retrieve the status of a cross-post order. */
  crossPostingOrderId?: string;
  crossPostingOrderStatus?: string;
  message?: string;
  orderId?: string;
}

export type Model423Orderbook = typeof Model423Orderbook[keyof typeof Model423Orderbook];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model423Orderbook = {
  reservoir: 'reservoir',
  opensea: 'opensea',
} as const;

export interface Attribute {
  collection: string;
  key: string;
  value: string;
}

export type Model422Kind = typeof Model422Kind[keyof typeof Model422Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model422Kind = {
  opensea: 'opensea',
  'zeroex-v4': 'zeroex-v4',
  seaport: 'seaport',
  x2y2: 'x2y2',
} as const;

export interface Model422 {
  data: Metadata;
  kind: Model422Kind;
}

export interface Model423 {
  attribute?: Attribute;
  collection?: string;
  isNonFlagged?: boolean;
  order?: Model422;
  orderbook?: Model423Orderbook;
  orderbookApiKey?: string;
  /** The name of the source */
  source?: string;
  tokenSetId?: string;
}

export interface PostCreateContractsSetV1Response {
  contractsSetId?: string;
}

export type Contracts = string[];

export interface Model421 {
  contracts: Contracts;
}

export interface PostCreateCollectionsSetV1Response {
  collectionsSetId?: string;
}

export type Model419 = string[];

export interface Model420 {
  collections: Model419;
}

export interface Model418 {
  icon?: string;
  optimized?: boolean;
  /** The source domain to sync. Example: `reservoir.market` */
  source?: string;
  title?: string;
}

export type Model417Method = typeof Model417Method[keyof typeof Model417Method];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model417Method = {
  get: 'get',
  post: 'post',
  delete: 'delete',
  put: 'put',
} as const;

export interface Model417 {
  apiKey?: string;
  duration?: number;
  method?: Model417Method;
  payload?: Model388;
  points?: number;
  pointsToConsume?: number;
  /** The rule ID to update */
  ruleId: number;
  tier?: number;
}

export interface Model416 {
  /** Refresh the given token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
}

export interface Permissions {
  assign_collection_to_community?: boolean;
  entity_data_override?: boolean;
  override_collection_refresh_cool_down?: boolean;
  update_metadata_disabled?: boolean;
  update_spam_status?: boolean;
}

export type Origins = string[];

export type Ips = string[];

export interface Model415 {
  active?: boolean;
  /** The api key to update */
  apiKey?: string;
  ips?: Ips;
  origins?: Origins;
  permissions?: Permissions;
  revShareBps?: number;
  tier?: number;
}

/**
 * Should be passed in array [{"contract": "...", "tokenId": "..."}]
 */
export interface Model414 {
  params?: string;
  path?: string;
}

export interface Model413 {
  params?: string;
  path?: string;
}

export type Model411Method = typeof Model411Method[keyof typeof Model411Method];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model411Method = {
  events: 'events',
} as const;

export interface Model411 {
  events?: SampleImages;
  method?: Model411Method;
}

export interface Model412 {
  backfill?: boolean;
  blocksPerBatch?: number;
  fromBlock: number;
  skipNonFillWrites?: boolean;
  syncDetails?: Model411;
  toBlock: number;
}

export interface Model410 {
  /** If no days are passed, will automatically resync from beginning of time. */
  days?: number;
}

export interface Model409 {
  /** Update community for a particular collection, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection: string;
  community: string;
  doRetries?: boolean;
}

export interface Model407 {
  address: string;
  deploymentBlock: number;
  domain: string;
}

export type Routers = Model407[];

export interface Model408 {
  routers: Routers;
}

export type Model406Status = typeof Model406Status[keyof typeof Model406Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model406Status = {
  active: 'active',
  inactive: 'inactive',
} as const;

export interface Model406 {
  id: string;
  status: Model406Status;
}

export interface Model405 {
  /** The queue name to retry */
  queueName: string;
}

export interface Model404 {
  collection: string;
  user: string;
}

export interface Model403 {
  /** The source domain to sync. Example: `reservoir.market` */
  source?: string;
}

export type Model402Kind = typeof Model402Kind[keyof typeof Model402Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model402Kind = {
  all: 'all',
} as const;

export interface Model401 {
  blockRange?: number;
  fromBlock: number;
  toBlock: number;
}

export interface Model402 {
  data?: Model401;
  kind?: Model402Kind;
}

export interface Model400 {
  collection?: string;
  token?: string;
}

export interface Model399 {
  /** The queue name to resume */
  queueName: string;
}

export type Model398Method = typeof Model398Method[keyof typeof Model398Method];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model398Method = {
  opensea: 'opensea',
  simplehash: 'simplehash',
  centerdev: 'centerdev',
  soundxyz: 'soundxyz',
  beam: 'beam',
} as const;

export interface Model398 {
  method?: Model398Method;
  /** Refresh the given token. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63:123` */
  token: string;
}

export type Model397RefreshKind = typeof Model397RefreshKind[keyof typeof Model397RefreshKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model397RefreshKind = {
  'full-collection': 'full-collection',
  'full-collection-by-slug': 'full-collection-by-slug',
  'missing-metadata': 'missing-metadata',
} as const;

export interface Model397 {
  /** If true, will only refresh the collection cache. */
  cacheOnly?: boolean;
  /** Refresh the given collection. Example: `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection: string;
  refreshKind?: Model397RefreshKind;
}

export interface Model396 {
  /** The queue name to pause */
  queueName: string;
}

export interface Model395 {
  token: string;
}

export type Model394Kind = typeof Model394Kind[keyof typeof Model394Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model394Kind = {
  'tokens-floor-sell': 'tokens-floor-sell',
  'tokens-top-buy': 'tokens-top-buy',
} as const;

export interface Model394 {
  kind: Model394Kind;
  token: string;
}

export type Model393By = typeof Model393By[keyof typeof Model393By];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model393By = {
  id: 'id',
  maker: 'maker',
  token: 'token',
  contract: 'contract',
} as const;

export interface Model393 {
  by: Model393By;
  contract: string;
  id: string;
  maker: string;
  token: string;
}

export type Model392Kind = typeof Model392Kind[keyof typeof Model392Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model392Kind = {
  'tokens-floor-sell': 'tokens-floor-sell',
  'tokens-top-buy': 'tokens-top-buy',
} as const;

export interface Model392 {
  contracts?: OperatorWhitelist;
  kind: Model392Kind;
}

export interface Model391 {
  fromBlock: number;
  toBlock: number;
}

export interface Model390 {
  /** The rule ID to delete */
  ruleId: number;
}

export type Model389Method = typeof Model389Method[keyof typeof Model389Method];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model389Method = {
  get: 'get',
  post: 'post',
  delete: 'delete',
  put: 'put',
} as const;

export interface Model387 {
  key?: string;
  value?: string;
}

export type Model388 = Model387[];

export interface Model389 {
  apiKey?: string;
  duration?: number;
  method?: Model389Method;
  payload?: Model388;
  points?: number;
  pointsToConsume?: number;
  /** The route for which the rule is created */
  route: string;
  tier?: number;
}

export interface Model386 {
  /** The collection for which to calculate the rarity, e.g. `0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63` */
  collection: string;
}

export interface GetNewApiKeyResponse {
  key: string;
}

export interface PutSetCollectionCommunityV1Response {
  message?: string;
}

export interface Model385 {
  community: string;
}

export interface Model383 {
  createdAt?: string;
  criteria?: Model110;
  feeBreakdown?: Model163;
  floorDifferencePercentage?: number;
  id?: string;
  maker?: string;
  price?: Price;
  source?: Source;
  token?: Model378;
  validFrom?: number;
  validUntil?: number;
}

export type Model384 = Model383[];

export interface GetUserTopBidsV3Response {
  continuation?: string;
  topBids?: Model384;
  totalAmount?: number;
  totalTokensWithBids?: number;
}

export interface GetUserTopBidsV1Response {
  continuation?: string;
  topBids?: Model382;
  totalTokensWithBids?: number;
}

export interface Model378 {
  collection?: Model291;
  contract?: string;
  floorAskPrice?: number;
  image?: string;
  lastSalePrice?: number;
  name?: string;
  tokenId?: string;
}

export interface Model381 {
  context?: Model161;
  createdAt?: string;
  feeBreakdown?: Model163;
  floorDifferencePercentage?: number;
  id?: string;
  maker?: string;
  price?: number;
  source?: Source;
  token?: Model378;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export type Model382 = Model381[];

export interface Model379 {
  createdAt?: string;
  criteria?: Model110;
  feeBreakdown?: Model163;
  floorDifferencePercentage?: number;
  id?: string;
  maker?: string;
  price?: number;
  source?: Source;
  token?: Model378;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export type Model380 = Model379[];

export interface GetUserTopBidsV2Response {
  continuation?: string;
  topBids?: Model380;
  totalTokensWithBids?: number;
}

export interface Model376 {
  /** Time when added to indexer */
  createdAt?: string;
  criteria?: Model110;
  feeBreakdown?: Model172;
  /** Percentage difference between this bid and the current floor price. */
  floorDifferencePercentage?: number;
  id?: string;
  maker?: string;
  price?: Model114;
  source?: Source;
  token?: Model375;
  validFrom?: number;
  validUntil?: number;
}

export type Model377 = Model376[];

export interface GetUserTopBidsV4Response {
  continuation?: string;
  topBids?: Model377;
  /** Amount of currency from all token bids; native currency unless `displayCurrency` passed */
  totalAmount?: number;
  /** Amount of token with bids. */
  totalTokensWithBids?: number;
}

/**
 * Native currency to chain unless displayCurrency is passed.
 */
export interface Model373 {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

export interface Model374 {
  floorAskPrice?: Model373;
  id?: string;
  imageUrl?: string;
  name?: string;
}

export interface Model375 {
  collection?: Model374;
  contract?: string;
  floorAskPrice?: Price;
  image?: string;
  lastSalePrice?: Price;
  name?: string;
  tokenId?: string;
}

export interface Model371 {
  floorAskPrices?: FloorAskPrices;
  key: string;
  lastBuys?: Model349;
  lastSells?: Model349;
  onSaleCount: number;
  sampleImages?: SampleImages;
  tokenCount: number;
  topBid?: TopBid;
  value: string;
}

export type Model372 = Model371[];

export interface GetAttributesExploreV5Response {
  attributes?: Model372;
  continuation?: string;
}

export interface GetAttributesExploreV3Response {
  attributes?: Model372;
}

export type Model370 = Model369[];

export interface GetAttributesStaticV1Response {
  attributes?: Model370;
}

export type Model369Kind = typeof Model369Kind[keyof typeof Model369Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model369Kind = {
  string: 'string',
  number: 'number',
  date: 'date',
  range: 'range',
} as const;

export interface Model367 {
  count?: number;
  tokens?: SampleImages;
  value: string;
}

export type Model368 = Model367[];

export interface Model369 {
  key: string;
  kind: Model369Kind;
  values?: Model368;
}

export type Model365Kind = typeof Model365Kind[keyof typeof Model365Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model365Kind = {
  string: 'string',
  number: 'number',
  date: 'date',
  range: 'range',
} as const;

export interface Model365 {
  attributeCount?: number;
  key: string;
  kind: Model365Kind;
  maxRange?: number;
  minRange?: number;
  values?: Model364;
}

export type Model366 = Model365[];

export interface GetAttributesAllV3Response {
  attributes?: Model366;
}

export interface Model363 {
  count?: number;
  /** Returned only for attributes with less than 10k tokens */
  floorAskPrice?: number;
  value: string;
}

export type Model364 = Model363[];

export type Model362 = Model361[];

export interface GetAttributesAllV2Response {
  attributes?: Model362;
}

export type Model361Kind = typeof Model361Kind[keyof typeof Model361Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model361Kind = {
  string: 'string',
  number: 'number',
  date: 'date',
  range: 'range',
} as const;

export interface Model359 {
  count?: number;
  floorAskPrice?: number;
  value: string;
}

export type Model360 = Model359[];

export interface Model361 {
  attributeCount?: number;
  key: string;
  kind: Model361Kind;
  maxRange?: number;
  minRange?: number;
  values?: Model360;
}

export type Model358 = Model357[];

export interface GetAttributesAllV4Response {
  attributes?: Model358;
}

export type Model357Kind = typeof Model357Kind[keyof typeof Model357Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model357Kind = {
  string: 'string',
  number: 'number',
  date: 'date',
  range: 'range',
} as const;

export type Model356 = Model355[];

export interface Model357 {
  /** Number of possible attribute kinds */
  attributeCount?: number;
  /** Case sensitive */
  key: string;
  kind: Model357Kind;
  maxRange?: number;
  minRange?: number;
  values?: Model356;
}

/**
 * Returned only for attributes with less than 10k tokens
 */
export interface Model354 {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

export interface Model355 {
  count?: number;
  floorAskPrice?: Model354;
  /** Case sensitive */
  value: string;
}

export interface Model352 {
  floorAskPrices?: FloorAskPrices;
  key: string;
  lastSells?: Model349;
  onSaleCount: number;
  sampleImages?: SampleImages;
  tokenCount: number;
  topBid?: TopBid;
  value: string;
}

export type Model353 = Model352[];

export interface GetAttributesExploreV2Response {
  attributes?: Model353;
}

export type Model351 = Model350[];

export interface GetAttributesExploreV4Response {
  attributes?: Model351;
  continuation?: string;
}

export interface Model348 {
  timestamp: number;
  tokenId: string;
  value: number;
}

export type Model349 = Model348[];

/**
 * Current floor price ask.
 */
export type Model347 = number[];

export interface Model350 {
  floorAskPrices?: Model347;
  /** Case sensitive */
  key: string;
  lastBuys?: Model349;
  lastSells?: Model349;
  /** Token count with this attribute on sale. */
  onSaleCount: number;
  sampleImages?: SampleImages;
  /** Total token count with this attribute. */
  tokenCount: number;
  topBid?: TopBid;
  /** Case sensitive */
  value: string;
}

export type Model346 = Model345[];

export interface GetUserTokensV7Response {
  continuation?: string;
  tokens?: Model346;
}

/**
 * Can be null if no asks.
 */
export interface Model343 {
  id?: string;
  isNativeOffChainCancellable?: boolean;
  kind?: string;
  maker?: string;
  price?: Price;
  rawData?: Metadata;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export interface Model344 {
  acquiredAt?: string;
  floorAsk?: Model343;
  onSaleCount?: string;
  tokenCount?: string;
}

export interface Model345 {
  ownership?: Model344;
  token?: Model342;
}

export interface Model340 {
  createdAt?: string;
  /** Can be null. */
  floorAskPrice?: number;
  /** Case sensitive */
  key?: string;
  /** Can be `string`, `number, `date, or `range`. */
  kind?: string;
  onSaleCount?: number;
  tokenCount?: number;
  /** Can be null. */
  topBidValue?: number;
  /** Case sensitive. */
  value: string;
}

export type Model341 = Model340[];

/**
 * Can be null if not active bids.
 */
export interface Model339 {
  id?: string;
  price?: Price;
  source?: Source;
}

export interface Model342 {
  attributes?: Model341;
  chainId: number;
  collection?: Model338;
  contract?: string;
  description?: string;
  image?: string;
  imageLarge?: string;
  imageSmall?: string;
  isFlagged?: boolean;
  isSpam?: boolean;
  /** Can be erc721, erc115, etc. */
  kind?: string;
  /** The value of the last sale.Can be null. */
  lastAppraisalValue?: number;
  lastFlagChange?: string;
  lastFlagUpdate?: string;
  lastSale?: Model55;
  media?: string;
  metadata?: Metadata;
  metadataDisabled?: boolean;
  name?: string;
  /** No rarity rank for collections over 100k */
  rarityRank?: number;
  /** No rarity for collections over 100k */
  rarityScore?: number;
  remainingSupply?: number;
  /** Can be higher than one if erc1155. */
  supply?: number;
  tokenId?: string;
  topBid?: Model339;
}

export type Model338VerificationStatus = typeof Model338VerificationStatus[keyof typeof Model338VerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model338VerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

export interface Model336 {
  bps?: number;
  recipient?: string;
}

export type Model337 = Model336[];

/**
 * Can be null if no active asks.
 */
export interface Model335 {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

export interface Model338 {
  floorAskPrice?: Model335;
  id?: string;
  imageUrl?: string;
  isSpam?: boolean;
  metadataDisabled?: boolean;
  name?: string;
  openseaVerificationStatus?: string;
  royalties?: Model337;
  royaltiesBps?: number;
  /** Open Sea slug */
  slug?: string;
  symbol?: string;
  verificationStatus?: Model338VerificationStatus;
}

export type Model334 = Model333[];

export interface GetUserTokensV5Response {
  tokens?: Model334;
}

export interface Model331 {
  id?: string;
  maker?: string;
  price?: Price;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export interface Model332 {
  acquiredAt?: string;
  floorAsk?: Model331;
  onSaleCount?: string;
  tokenCount?: string;
}

export interface Model333 {
  ownership?: Model332;
  token?: Model298;
}

export type Model330 = Model329[];

export interface GetUserTokensV3Response {
  tokens?: Model330;
}

export interface Model327 {
  id?: string;
  value?: number;
}

export interface Model328 {
  collection?: Model291;
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
  topBid?: Model327;
}

export interface Model329 {
  ownership?: Model303;
  token?: Model328;
}

export interface Model324 {
  acquiredAt?: number;
  floorSellValue?: number;
  onSaleCount?: string;
  tokenCount?: string;
}

export interface Model325 {
  ownership?: Model324;
  token?: Model323;
}

export type Model326 = Model325[];

export interface GetUserTokensV1Response {
  tokens?: Model326;
}

export interface Model322 {
  id?: string;
  schema?: Metadata;
  value?: number;
}

export interface Model323 {
  collection?: Model46;
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
  topBid?: Model322;
}

export type Model321 = Model320[];

export interface GetUserCollectionsV3Response {
  collections?: Model321;
}

/**
 * Top bid offer currently if offer is valid
 */
export interface Model318 {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

/**
 * Current floor ask price
 */
export interface Model317 {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

export interface Model319 {
  banner?: string;
  /** Returns `erc721`, `erc1155`, etc. */
  contractKind?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  floorAskPrice?: Model317;
  floorSale?: FloorSale;
  /** Collection Id */
  id?: string;
  image?: string;
  isSpam?: boolean;
  metadataDisabled?: boolean;
  name?: string;
  openseaVerificationStatus?: string;
  primaryContract?: string;
  rank?: Model23;
  sampleImages?: SampleImages;
  slug?: string;
  /** Total token count */
  tokenCount?: string;
  tokenSetId?: string;
  topBidMaker?: string;
  topBidSourceDomain?: string;
  topBidValue?: Model318;
  twitterUrl?: string;
  twitterUsername?: string;
  volume?: Volume;
  volumeChange?: Model30;
}

export interface Model320 {
  collection?: Model319;
  ownership?: Model308;
}

export type Model316 = Model315[];

export interface GetUserCollectionsV1Response {
  collections?: Model316;
}

export interface Model313 {
  bannerImageUrl?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  imageUrl?: string;
  twitterUsername?: string;
}

export interface Model314 {
  floorAskPrice?: number;
  id?: string;
  metadata?: Model313;
  name?: string;
  topBidValue?: number;
}

export interface Model315 {
  collection?: Model314;
  ownership?: Model308;
}

export interface Model311 {
  amount?: number;
  batchIndex?: number;
  collection?: Model104;
  fromAddress?: string;
  logIndex?: number;
  price?: number;
  timestamp?: number;
  toAddress?: string;
  token?: Model103;
  txHash?: string;
  type?: string;
}

export type Model312 = Model311[];

export interface GetUserActivityV1Response {
  activities?: Model312;
  continuation?: number;
}

export interface GetUserCollectionsV2Response {
  collections?: Model310;
}

export interface Model308 {
  liquidCount?: string;
  onSaleCount?: string;
  tokenCount?: string;
}

export interface Model307 {
  banner?: string;
  createdAt?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  floorAskPrice?: number;
  floorSale?: VolumeChange;
  id?: string;
  image?: string;
  name?: string;
  openseaVerificationStatus?: string;
  primaryContract?: string;
  rank?: Rank;
  sampleImages?: SampleImages;
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBidMaker?: string;
  topBidSourceDomain?: string;
  topBidValue?: number;
  twitterUsername?: string;
  volume?: Rank;
  volumeChange?: VolumeChange;
}

export interface Model309 {
  collection?: Model307;
  ownership?: Model308;
}

export type Model310 = Model309[];

export interface GetUserPositionsV1Response {
  positions?: Positions;
}

export interface PrimaryOrder {
  expiration?: number;
  id?: string;
  value?: number;
}

export interface Set {
  floorAskPrice?: number;
  id?: string;
  image?: string;
  metadata?: Model161;
  sampleImages?: SampleImages;
  topBidValue?: number;
}

export interface Model306 {
  primaryOrder?: PrimaryOrder;
  set?: Set;
  totalValid?: number;
}

export type Positions = Model306[];

export interface Model303 {
  acquiredAt?: string;
  floorAskPrice?: number;
  onSaleCount?: string;
  tokenCount?: string;
}

export interface Model302 {
  collection?: Model291;
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface Model304 {
  ownership?: Model303;
  token?: Model302;
}

export type Model305 = Model304[];

export interface GetUserTokensV2Response {
  tokens?: Model305;
}

export type Model301 = Model300[];

export interface GetUserTokensV4Response {
  tokens?: Model301;
}

export interface Model299 {
  acquiredAt?: string;
  floorAskPrice?: Price;
  onSaleCount?: string;
  tokenCount?: string;
}

export interface Model298 {
  collection?: Model291;
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
  topBid?: Model292;
}

export interface Model300 {
  ownership?: Model299;
  token?: Model298;
}

export interface Model296 {
  ownership?: Model295;
  token?: Model293;
}

export type Model297 = Model296[];

export interface GetUserTokensV6Response {
  continuation?: string;
  tokens?: Model297;
}

export interface Model294 {
  dynamicPricing?: Model170;
  id?: string;
  maker?: string;
  price?: Price;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export interface Model295 {
  acquiredAt?: string;
  floorAsk?: Model294;
  onSaleCount?: string;
  tokenCount?: string;
}

export interface Model292 {
  id?: string;
  price?: Price;
}

export interface Model291 {
  floorAskPrice?: number;
  id?: string;
  imageUrl?: string;
  name?: string;
}

export interface Model293 {
  collection?: Model291;
  contract?: string;
  image?: string;
  kind?: string;
  lastAppraisalValue?: number;
  lastBuy?: LastBuy;
  lastSell?: LastBuy;
  media?: string;
  name?: string;
  rarityRank?: number;
  rarityScore?: number;
  tokenId?: string;
  topBid?: Model292;
}

export interface GetTransactionSyncedV1Response {
  synced: boolean;
}

export interface GetTokenActivityV4Response {
  activities?: Model113;
  continuation?: string;
}

export interface GetTokenActivityV5Response {
  activities?: Model290;
  continuation?: string;
}

export interface Model288 {
  isSpam?: boolean;
  tokenId?: string;
  tokenImage?: string;
  tokenName?: string;
}

export interface Model289 {
  amount?: number;
  batchIndex?: number;
  collection?: Model234;
  contract?: string;
  /** Time when added in the indexer. */
  createdAt?: string;
  fillSource?: Source;
  fromAddress?: string;
  logIndex?: number;
  order?: Model117;
  price?: Model114;
  /** Time when added on the blockchain. */
  timestamp?: number;
  toAddress?: string;
  token?: Model288;
  /** Txn hash from the blockchain. */
  txHash?: string;
  /** Possible types returned: `ask`, `ask_cancel`, `bid`, `bid_cancel`, `sale`, `mint, and `transfer`. */
  type?: string;
}

export type Model290 = Model289[];

export interface Model286 {
  contract: string;
  isFlagged?: boolean;
  lastFlagChange?: string;
  tokenId: string;
}

export type Model287 = Model286[];

export interface GetFlaggedTokensV1Response {
  continuation?: string;
  tokens?: Model287;
}

export interface Model284 {
  isFlagged: boolean;
  lastTransferTime: number;
  message?: Message;
  token: string;
}

export type Model285 = Model284[];

export interface GetTokenStatusOracleV3Response {
  messages?: Model285;
}

export interface Model282 {
  id: string;
  payload: string;
  signature: string;
  timestamp: number;
}

export interface Model283 {
  isFlagged: boolean;
  lastTransferTime: number;
  message?: Model282;
  token: string;
}

export type Messages = Model283[];

export interface GetTokenStatusOracleV2Response {
  messages?: Messages;
}

export interface GetCollectionTopBidOracleV2Response {
  message?: Model282;
  price: number;
}

export interface GetCollectionFloorAskOracleV4Response {
  data?: string;
  message?: Model282;
  price: number;
}

export interface Message {
  chainId: string;
  id: string;
  payload: string;
  signature: string;
  timestamp: number;
}

export interface GetCollectionFloorAskOracleV6Response {
  data?: string;
  message?: Message;
  price: number;
}

export interface GetCollectionBidAskMidpointOracleV1Response {
  message?: Message;
  price: number;
}

export interface GetTokensFloorAskV4Response {
  continuation?: string;
  events?: Model281;
}

export interface Model279 {
  dynamicPricing?: DynamicPricing;
  isDynamic?: boolean;
  maker?: string;
  nonce?: string;
  orderId?: string;
  price?: Price;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export interface Model280 {
  event?: Model264;
  floorAsk?: Model279;
  token?: Model187;
}

export type Model281 = Model280[];

export interface Model276 {
  isDynamic?: boolean;
  maker?: string;
  nonce?: string;
  orderId?: string;
  price?: Price;
  source?: string;
  validFrom?: number;
  validUntil?: number;
}

export interface Model277 {
  event?: Model260;
  floorAsk?: Model276;
  token?: Model187;
}

export type Model278 = Model277[];

export interface GetTokensFloorAskV3Response {
  continuation?: string;
  events?: Model278;
}

export interface Model273 {
  maker?: string;
  nonce?: string;
  orderId?: string;
  price?: number;
  source?: string;
  validFrom?: number;
  validUntil?: number;
}

export interface Model274 {
  event?: Model260;
  floorAsk?: Model273;
  token?: Model187;
}

export type Model275 = Model274[];

export interface GetTokensFloorAskV2Response {
  continuation?: string;
  events?: Model275;
}

export interface GetCollectionsTopbidV2Response {
  continuation?: string;
  events?: Model272;
}

export interface Model270 {
  contract?: string;
  maker?: string;
  orderId?: string;
  price?: Price;
  source?: string;
  tokenSetId?: string;
  validUntil?: number;
}

export interface Model271 {
  collection?: Model258;
  event?: Model264;
  topBid?: Model270;
}

export type Model272 = Model271[];

export interface Model267 {
  contract?: string;
  maker?: string;
  orderId?: string;
  price?: number;
  source?: string;
  tokenSetId?: string;
  validUntil?: number;
}

export interface Model268 {
  collection?: Model258;
  event?: Model260;
  topBid?: Model267;
}

export type Model269 = Model268[];

export interface GetCollectionsTopbidV1Response {
  continuation?: string;
  events?: Model269;
}

export interface GetCollectionsFloorAskV2Response {
  continuation?: string;
  events?: Model266;
}

export type Model264Kind = typeof Model264Kind[keyof typeof Model264Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model264Kind = {
  'new-order': 'new-order',
  expiry: 'expiry',
  sale: 'sale',
  cancel: 'cancel',
  'balance-change': 'balance-change',
  'approval-change': 'approval-change',
  bootstrap: 'bootstrap',
  revalidation: 'revalidation',
  reprice: 'reprice',
} as const;

export interface Model264 {
  /** Time when added to indexer */
  createdAt?: string;
  id?: number;
  kind?: Model264Kind;
  previousPrice?: number;
  txHash?: string;
  /** Time when added on the blockchain. */
  txTimestamp?: number;
}

export interface Model263 {
  contract?: string;
  maker?: string;
  orderId?: string;
  price?: Price;
  source?: string;
  tokenId?: string;
  validUntil?: number;
}

export interface Model265 {
  collection?: Model258;
  event?: Model264;
  floorAsk?: Model263;
}

export type Model266 = Model265[];

export type Model262 = Model261[];

export interface GetCollectionsFloorAskV1Response {
  continuation?: string;
  events?: Model262;
}

export type Model260Kind = typeof Model260Kind[keyof typeof Model260Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model260Kind = {
  'new-order': 'new-order',
  expiry: 'expiry',
  sale: 'sale',
  cancel: 'cancel',
  'balance-change': 'balance-change',
  'approval-change': 'approval-change',
  bootstrap: 'bootstrap',
  revalidation: 'revalidation',
  reprice: 'reprice',
} as const;

export interface Model260 {
  createdAt?: string;
  id?: number;
  kind?: Model260Kind;
  previousPrice?: number;
  txHash?: string;
  txTimestamp?: number;
}

export interface Model259 {
  contract?: string;
  maker?: string;
  orderId?: string;
  price?: number;
  source?: string;
  tokenId?: string;
  validUntil?: number;
}

export interface Model258 {
  id?: string;
}

export interface Model261 {
  collection?: Model258;
  event?: Model260;
  floorAsk?: Model259;
}

export interface Model256 {
  /** The amount of owners with the same `tokenCount`. */
  ownerCount?: number;
  tokenCount?: number;
}

export type Model257 = Model256[];

export interface GetCollectionsSetOwnersDistributionV1Response {
  ownersDistribution?: Model257;
}

export type Model254 = Model253[];

export interface Model255 {
  marketplaces?: Model254;
}

export interface String {
  collectionBidSupported?: boolean;
  customFeesSupported?: boolean;
  enabled?: boolean;
  maxPriceRaw?: string;
  minimumBidExpiry?: number;
  minimumPrecision?: string;
  minPriceRaw?: string;
  orderKind?: string;
  /** This indicates whether or not multi quantity bidding is supported */
  partialOrderSupported?: boolean;
  paymentTokens?: PaymentTokens;
  supportedBidCurrencies?: SupportedBidCurrencies;
  traitBidSupported?: boolean;
}

export type Model253Exchanges = {
  string?: String;
};

export interface Model253 {
  domain?: string;
  exchanges?: Model253Exchanges;
  fee?: Model237;
  imageUrl?: string;
  name?: string;
  orderbook?: string;
  royalties?: Model238;
}

export interface Model252 {
  /** The amount of owners that have the same `tokenCount`. */
  ownerCount?: number;
  tokenCount?: number;
}

export type OwnersDistribution = Model252[];

export interface GetCollectionOwnersDistributionV1Response {
  ownersDistribution?: OwnersDistribution;
}

export interface Model250 {
  amount?: number;
  batchIndex?: number;
  collection?: Model104;
  createdAt?: string;
  fromAddress?: string;
  logIndex?: number;
  price?: number;
  source?: Source;
  timestamp?: number;
  toAddress?: string;
  token?: Model103;
  txHash?: string;
  type?: string;
}

export type Model251 = Model250[];

export interface GetCollectionActivityV2Response {
  activities?: Model251;
  continuation?: string;
}

export type Model247Side = typeof Model247Side[keyof typeof Model247Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model247Side = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface Model247 {
  id?: string;
  side?: Model247Side;
  source?: Source;
}

export interface Model248 {
  amount?: number;
  batchIndex?: number;
  collection?: Model104;
  contract?: string;
  createdAt?: string;
  fromAddress?: string;
  logIndex?: number;
  order?: Model247;
  price?: number;
  timestamp?: number;
  toAddress?: string;
  token?: Model103;
  txHash?: string;
  type?: string;
}

export type Model249 = Model248[];

export interface GetCollectionActivityV3Response {
  activities?: Model249;
  continuation?: string;
}

export type Model246 = Model245[];

export interface GetCollectionAttributesV1Response {
  attributes?: Model246;
}

export type FloorAskPrices = number[];

export interface Model244 {
  timestamp: number;
  value: number;
}

export type LastBuys = Model244[];

export interface Model245 {
  floorAskPrices?: FloorAskPrices;
  key: string;
  lastBuys?: LastBuys;
  lastSells?: LastBuys;
  sampleImages?: SampleImages;
  tokenCount: number;
  topBid?: TopBid;
  value: string;
}

export interface Model243 {
  quantity?: number;
  value?: number;
}

export type TopBids = Model243[];

export interface GetCollectionTopBidsV1Response {
  topBids?: TopBids;
}

export type Model241 = Model240[];

export interface Model242 {
  marketplaces?: Model241;
}

export interface Model239 {
  address?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export type PaymentTokens = Model239[];

/**
 * erc20 contract addresses
 */
export type SupportedBidCurrencies = string[];

export interface Model238 {
  maxBps?: number;
  minBps?: number;
}

/**
 * Marketplace Fee
 */
export interface Model237 {
  bps?: number;
}

export interface Model240 {
  collectionBidSupported?: boolean;
  customFeesSupported?: boolean;
  domain?: string;
  fee?: Model237;
  imageUrl?: string;
  listingEnabled?: boolean;
  minimumBidExpiry?: number;
  minimumPrecision?: string;
  name?: string;
  orderbook?: string;
  orderKind?: string;
  /** This indicates whether or not multi quantity bidding is supported */
  partialBidSupported?: boolean;
  paymentTokens?: PaymentTokens;
  royalties?: Model238;
  supportedBidCurrencies?: SupportedBidCurrencies;
  traitBidSupported?: boolean;
}

export interface GetUserActivityV6Response {
  activities?: Model236;
  continuation?: string;
}

export interface Model234 {
  collectionId?: string;
  collectionImage?: string;
  collectionName?: string;
  isSpam?: boolean;
}

export interface Model233 {
  isSpam?: boolean;
  lastBuy?: LastBuy;
  lastSell?: LastBuy;
  tokenId?: string;
  tokenImage?: string;
  tokenMedia?: string;
  tokenName?: string;
  /** No rarity rank for collections over 100k */
  tokenRarityRank?: number;
  /** No rarity for collections over 100k */
  tokenRarityScore?: number;
}

export interface Model235 {
  amount?: number;
  batchIndex?: number;
  collection?: Model234;
  contract?: string;
  createdAt?: string;
  fillSource?: Source;
  fromAddress?: string;
  logIndex?: number;
  order?: Model117;
  price?: Model114;
  /** Time when added on the blockchain. */
  timestamp?: number;
  toAddress?: string;
  token?: Model233;
  /** Txn hash from the blockchain. */
  txHash?: string;
  /** Possible types returned: `ask`, `ask_cancel`, `bid`, `bid_cancel`, `sale`, `mint, and `transfer`. */
  type?: string;
}

export type Model236 = Model235[];

export interface Model230 {
  lastBuy?: LastBuy;
  lastSell?: LastBuy;
  tokenId?: string;
  tokenImage?: string;
  tokenMedia?: string;
  tokenName?: string;
  tokenRarityRank?: number;
  tokenRarityScore?: number;
}

export interface Model231 {
  amount?: number;
  batchIndex?: number;
  collection?: Model104;
  contract?: string;
  createdAt?: string;
  fromAddress?: string;
  logIndex?: number;
  order?: Model111;
  price?: number;
  timestamp?: number;
  toAddress?: string;
  token?: Model230;
  txHash?: string;
  type?: string;
}

export type Model232 = Model231[];

export interface GetUserActivityV5Response {
  activities?: Model232;
  continuation?: string;
}

export interface Model228 {
  amount?: number;
  batchIndex?: number;
  collection?: Model104;
  contract?: string;
  createdAt?: string;
  fromAddress?: string;
  logIndex?: number;
  order?: Order;
  price?: number;
  timestamp?: number;
  toAddress?: string;
  token?: Model103;
  txHash?: string;
  type?: string;
}

export type Model229 = Model228[];

export interface GetUserActivityV4Response {
  activities?: Model229;
  continuation?: string;
}

export interface Model226 {
  amount?: number;
  batchIndex?: number;
  collection?: Model104;
  createdAt?: string;
  fromAddress?: string;
  logIndex?: number;
  price?: number;
  source?: Source;
  timestamp?: number;
  toAddress?: string;
  token?: Model103;
  txHash?: string;
  type?: string;
}

export type Model227 = Model226[];

export interface GetUserActivityV3Response {
  activities?: Model227;
  continuation?: string;
}

export interface Model224 {
  amount?: number;
  batchIndex?: number;
  collection?: Model104;
  fromAddress?: string;
  logIndex?: number;
  price?: number;
  source?: Source;
  timestamp?: number;
  toAddress?: string;
  token?: Model103;
  txHash?: string;
  type?: string;
}

export type Model225 = Model224[];

export interface GetUserActivityV2Response {
  activities?: Model225;
  continuation?: number;
}

export interface Model222 {
  /** Can be more than 1 if erc1155. */
  amount?: string;
  batchIndex?: number;
  block?: number;
  from?: string;
  id?: string;
  isDeleted?: boolean;
  logIndex?: number;
  timestamp?: number;
  to?: string;
  token?: Model187;
  txHash?: string;
  /** Time when updated in indexer */
  updatedAt?: string;
}

export type Model223 = Model222[];

export interface GetTransfersBulkV1Response {
  continuation?: string;
  transfers?: Model223;
}

export type Model221 = string[];

export interface GetTokensIdsV1Response {
  continuation?: string;
  tokens?: Model221;
}

export type GetTokensFloorV1ResponseTokens = {
  string?: number;
};

export interface GetTokensFloorV1Response {
  tokens?: GetTokensFloorV1ResponseTokens;
}

export type Model220 = Model219[];

export interface GetTokensDetailsV4Response {
  continuation?: string;
  tokens?: Model220;
}

export interface Model217 {
  id?: string;
  maker?: string;
  price?: number;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export interface Model218 {
  floorAsk?: Model217;
  topBid?: TopBid;
}

export interface Model214 {
  floorAskPrice?: number;
  key?: string;
  onSaleCount?: number;
  tokenCount?: number;
  topBidValue?: number;
  value: string;
}

export type Model215 = Model214[];

export interface Model216 {
  attributes?: Model215;
  collection?: Model66;
  contract: string;
  description?: string;
  image?: string;
  isFlagged?: boolean;
  kind?: string;
  lastBuy?: LastBuy;
  lastFlagUpdate?: string;
  lastSell?: LastBuy;
  media?: string;
  name?: string;
  owner?: string;
  tokenId: string;
}

export interface Model219 {
  market?: Model218;
  token?: Model216;
}

export interface Model212 {
  market?: Model208;
  token?: Model211;
}

export type Model213 = Model212[];

export interface GetTokensDetailsV3Response {
  continuation?: string;
  tokens?: Model213;
}

export interface GetTokensDetailsV2Response {
  continuation?: string;
  tokens?: Model210;
}

export interface Model207 {
  id?: string;
  maker?: string;
  price?: number;
  source?: Metadata;
  validFrom?: number;
  validUntil?: number;
}

export interface Model208 {
  floorAsk?: Model207;
  topBid?: TopBid;
}

export interface Model209 {
  market?: Model208;
  token?: Model206;
}

export type Model210 = Model209[];

export interface Model204 {
  key?: string;
  value: string;
}

export type Model205 = Model204[];

export interface Model211 {
  attributes?: Model205;
  collection?: Model66;
  contract: string;
  description?: string;
  image?: string;
  kind?: string;
  lastBuy?: LastBuy;
  lastSell?: LastBuy;
  name?: string;
  owner?: string;
  tokenId: string;
}

export interface Model206 {
  attributes?: Model205;
  collection?: Model46;
  contract: string;
  description?: string;
  image?: string;
  kind?: string;
  lastBuy?: LastBuy;
  lastSell?: LastBuy;
  name?: string;
  owner?: string;
  tokenId: string;
}

export interface Model202 {
  contract?: string;
  image?: string;
  maker?: string;
  orderId?: string;
  /** Native currency of chain */
  price?: number;
  source?: string;
  tokenId?: string;
  validFrom?: number;
  validUntil?: number;
}

export type Model203 = Model202[];

export interface GetTokensBootstrapV1Response {
  continuation?: string;
  tokens?: Model203;
}

export type Model201 = Model200[];

export interface SyncOrdersAsksV1Response {
  continuation?: string;
  orders?: Model201;
}

export type Model200Side = typeof Model200Side[keyof typeof Model200Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model200Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model199 {
  amount?: string;
  bps?: number;
  recipient?: string;
}

export type MissingRoyalties = Model199[];

export interface Model200 {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: Model163;
  id: string;
  isDynamic?: boolean;
  isReservoir?: boolean;
  kind: string;
  maker: string;
  missingRoyalties?: MissingRoyalties;
  normalizedPrice?: Price;
  price?: Price;
  quantityFilled?: number;
  quantityRemaining?: number;
  rawData?: Metadata;
  side: Model200Side;
  source?: Source;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

export type Model197VerificationStatus = typeof Model197VerificationStatus[keyof typeof Model197VerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model197VerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

/**
 * Current floor ask price.
 */
export interface Model196 {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

export interface Model197 {
  allTimeVolume?: number;
  banner?: string;
  collectionId?: string;
  contract?: string;
  description?: string;
  floorAskPrice?: Model196;
  image?: string;
  isSpam?: boolean;
  metadataDisabled?: boolean;
  name?: string;
  openseaVerificationStatus?: string;
  slug?: string;
  verificationStatus?: Model197VerificationStatus;
}

export type Model198 = Model197[];

export interface GetSearchCollectionsV2Response {
  collections?: Model198;
}

export type Model195 = Model194[];

export interface GetSearchCollectionsV1Response {
  collections?: Model195;
}

export interface Model194 {
  allTimeVolume?: number;
  banner?: string;
  collectionId?: string;
  contract?: string;
  description?: string;
  floorAskPrice?: number;
  image?: string;
  name?: string;
  openseaVerificationStatus?: string;
  tokenCount?: string;
}

export interface GetSearchActivitiesV1Response {
  activities?: Model193;
  continuation?: string;
}

export interface Model191 {
  id?: string;
  image?: string;
  name?: string;
}

export interface Model190 {
  id?: string;
  image?: string;
  media?: string;
  name?: string;
}

export interface Model192 {
  amount?: number;
  batchIndex?: number;
  collection?: Model191;
  contract?: string;
  createdAt?: string;
  fromAddress?: string;
  logIndex?: number;
  order?: Model117;
  price?: Price;
  timestamp?: number;
  toAddress?: string;
  token?: Model190;
  txHash?: string;
  type?: string;
}

export type Model193 = Model192[];

export type Model189 = Model188[];

export interface GetSalesBulkV1Response {
  continuation?: string;
  sales?: Model189;
}

export type Model188OrderSide = typeof Model188OrderSide[keyof typeof Model188OrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model188OrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface Model187 {
  contract?: string;
  tokenId?: string;
}

export interface Model188 {
  amount?: string;
  batchIndex?: number;
  fillSource?: string;
  from?: string;
  id?: string;
  logIndex?: number;
  orderKind?: string;
  orderSide?: Model188OrderSide;
  orderSource?: string;
  orderSourceDomain?: string;
  price?: number;
  saleId?: string;
  timestamp?: number;
  to?: string;
  token?: Model187;
  txHash?: string;
}

export interface Model185 {
  /** Wallet Address */
  address?: string;
  collections?: SampleImages;
  /** Token Count */
  count?: number;
}

export type Model186 = Model185[];

export interface GetCrossCollectionsOwnersV1Response {
  owners?: Model186;
}

export interface GetOwnerCountV1Response {
  owners?: number;
}

export interface Model183 {
  /** Contract address */
  address?: string;
  /** Token count */
  count?: number;
  owners?: SampleImages;
}

export type Model184 = Model183[];

export interface GetCommonCollectionsOwnersV1Response {
  collections?: Model184;
}

export interface GetOrdersDepthV1Response {
  depth?: Depth;
}

export type Model182 = Model174[];

export interface GetOrdersBidsV5Response {
  continuation?: string;
  orders?: Model182;
}

export type Model180Side = typeof Model180Side[keyof typeof Model180Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model180Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model180 {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: Model163;
  id: string;
  isReservoir?: boolean;
  kind: string;
  maker: string;
  metadata?: Model105;
  price?: Price;
  quantityFilled?: number;
  quantityRemaining?: number;
  rawData?: Metadata;
  side: Model180Side;
  source?: Source;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

export type Model181 = Model180[];

export interface GetOrdersBidsV4Response {
  continuation?: string;
  orders?: Model181;
}

export interface GetOrdersBidsV3Response {
  continuation?: string;
  orders?: Model179;
}

export type Model178Side = typeof Model178Side[keyof typeof Model178Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model178Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model178 {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: Model163;
  id: string;
  isReservoir?: boolean;
  kind: string;
  maker: string;
  metadata?: Model161;
  price?: Price;
  quantityFilled?: number;
  quantityRemaining?: number;
  rawData?: Metadata;
  side: Model178Side;
  source?: Metadata;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

export type Model179 = Model178[];

export type Model176Side = typeof Model176Side[keyof typeof Model176Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model176Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model176 {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: Model163;
  id: string;
  kind: string;
  maker: string;
  metadata?: Model161;
  price: number;
  rawData?: Metadata;
  side: Model176Side;
  source?: Metadata;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
  value: number;
}

export type Model177 = Model176[];

export interface GetOrdersBidsV1Response {
  continuation?: string;
  orders?: Model177;
}

/**
 * Either `buy` or `sell`
 */
export type Model174Side = typeof Model174Side[keyof typeof Model174Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model174Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model174 {
  contract?: string;
  contractKind?: string;
  /** Time when added to indexer */
  createdAt: string;
  criteria?: Model110;
  depth?: Depth;
  dynamicPricing?: Model170;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: Model172;
  id: string;
  isDynamic?: boolean;
  isNativeOffChainCancellable?: boolean;
  isReservoir?: boolean;
  /** This is the `orderKind`. */
  kind: string;
  maker: string;
  /** Time when created by maker */
  originatedAt?: string;
  price?: Model114;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityFilled?: number;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityRemaining?: number;
  rawData?: Metadata;
  /** Either `buy` or `sell` */
  side: Model174Side;
  source?: Metadata;
  /** Can be `active`, `inactive`, `expired`, `canceled`, or `filled` */
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  /** Time when updated in indexer */
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

/**
 * `taker` will have wallet address if private listing.
 */
export type Model175 = Model174[];

export interface GetOrdersAsksV4Response {
  continuation?: string;
  orders?: Model175;
}

export interface Model173 {
  price?: number;
  quantity?: number;
}

export type Depth = Model173[];

export interface Model171 {
  bps?: number;
  /** Can be marketplace or royalty */
  kind?: string;
  recipient?: string;
}

export type Model172 = Model171[];

export type Model170Kind = typeof Model170Kind[keyof typeof Model170Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model170Kind = {
  dutch: 'dutch',
} as const;

export interface Model170 {
  data?: Model169;
  kind?: Model170Kind;
}

export interface Time {
  end?: number;
  start?: number;
}

export interface Model168 {
  end?: Price;
  start?: Price;
}

export interface Model169 {
  price?: Model168;
  time?: Time;
}

export interface GetOrdersAsksV3Response {
  continuation?: string;
  orders?: Model167;
}

export type Model166Side = typeof Model166Side[keyof typeof Model166Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model166Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model166 {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: Model163;
  id: string;
  isDynamic?: boolean;
  isReservoir?: boolean;
  kind: string;
  maker: string;
  metadata?: Model161;
  price?: Price;
  quantityFilled?: number;
  quantityRemaining?: number;
  rawData?: Metadata;
  side: Model166Side;
  source?: Source;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

export type Model167 = Model166[];

export interface GetOrdersAsksV1Response {
  continuation?: string;
  orders?: Model165;
}

export type Model164Side = typeof Model164Side[keyof typeof Model164Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model164Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model164 {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: Model163;
  id: string;
  kind: string;
  maker: string;
  metadata?: Model161;
  price: number;
  rawData?: Metadata;
  side: Model164Side;
  source?: Source;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
  value: number;
}

export type Model165 = Model164[];

export interface Model162 {
  bps?: number;
  kind?: string;
  recipient?: string;
}

export type Model163 = Model162[];

export type Model161Kind = typeof Model161Kind[keyof typeof Model161Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model161Kind = {
  token: 'token',
} as const;

export interface Model160 {
  collectionName?: string;
  image?: string;
  tokenName?: string;
}

export interface Model161 {
  data?: Model160;
  kind?: Model161Kind;
}

export type Model158Side = typeof Model158Side[keyof typeof Model158Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model158Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model158 {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: Model155;
  id: string;
  kind: string;
  maker: string;
  metadata?: Metadata;
  price?: Price;
  rawData?: Metadata;
  side: Model158Side;
  source?: string;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
}

export type Model159 = Model158[];

export interface GetOrdersAllV2Response {
  continuation?: string;
  orders?: Model159;
}

export interface GetOrdersAllV1Response {
  continuation?: string;
  orders?: Model157;
}

export type Model156Side = typeof Model156Side[keyof typeof Model156Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model156Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model156 {
  contract?: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: Model155;
  id: string;
  kind: string;
  maker: string;
  metadata?: Metadata;
  price: number;
  rawData?: Metadata;
  side: Model156Side;
  source?: string;
  status?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
  value: number;
}

export type Model157 = Model156[];

export interface Model154 {
  bps?: string;
  kind?: string;
  recipient?: string;
}

export type Model155 = Model154[];

export interface Model153 {
  liquidity: number;
  maxTopBuyValue: number;
  rank: number;
  tokenCount: string;
  user?: string;
  wethBalance: number;
}

export type Liquidity = Model153[];

export interface GetUsersLiquidityV1Response {
  liquidity?: Liquidity;
}

export type Model152Kind = typeof Model152Kind[keyof typeof Model152Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model152Kind = {
  signature: 'signature',
  transaction: 'transaction',
} as const;

export interface Model152 {
  action: string;
  description: string;
  id: string;
  items: Items;
  kind: Model152Kind;
}

export type Steps = Model152[];

export interface GetExecuteCancelV2Response {
  steps?: Steps;
}

export type Model151Status = typeof Model151Status[keyof typeof Model151Status];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model151Status = {
  complete: 'complete',
  incomplete: 'incomplete',
} as const;

export interface Model151 {
  data?: Metadata;
  orderIndex?: number;
  status: Model151Status;
}

export type Items = Model151[];

export interface Model148 {
  contract?: string;
  id?: string;
  maker?: string;
  nonce?: string;
  price?: number;
  quantityRemaining?: number;
  source?: string;
  status?: string;
  tokenId?: string;
  validFrom?: number;
  validUntil?: number;
}

export interface Model149 {
  event?: Event;
  order?: Model148;
}

export type Model150 = Model149[];

export interface GetOrderEventsV1Response {
  continuation?: string;
  events?: Model150;
}

export interface Model145 {
  contract?: string;
  criteria?: Model110;
  /** Order Id */
  id?: string;
  kind?: string;
  maker?: string;
  nonce?: string;
  price?: Price;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityRemaining?: number;
  rawData?: Metadata;
  source?: string;
  /** Can return `active`,  inactive`, `expired`, `canceled`, or `filled`. */
  status?: string;
  validFrom?: number;
  validUntil?: number;
}

export interface Model146 {
  bid?: Model145;
  event?: Model137;
}

export type Model147 = Model146[];

export interface GetBidEventsV3Response {
  continuation?: string;
  events?: Model147;
}

export interface GetBidEventsV2Response {
  continuation?: string;
  events?: Model144;
}

export interface Model142 {
  contract?: string;
  criteria?: Model110;
  id?: string;
  kind?: string;
  maker?: string;
  nonce?: string;
  price?: number;
  quantityRemaining?: number;
  source?: string;
  status?: string;
  tokenSetId?: string;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export interface GetBidEventsV1Response {
  continuation?: string;
  events?: Model141;
}

export interface Bid {
  contract?: string;
  criteria?: Model110;
  id?: string;
  maker?: string;
  nonce?: string;
  price?: number;
  quantityRemaining?: number;
  source?: string;
  status?: string;
  tokenSetId?: string;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export interface Model140 {
  bid?: Bid;
  event?: Event;
}

export type Model141 = Model140[];

export type Model139 = Model138[];

export interface GetAsksEventsV3Response {
  continuation?: string;
  events?: Model139;
}

export type Model137Kind = typeof Model137Kind[keyof typeof Model137Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model137Kind = {
  'new-order': 'new-order',
  expiry: 'expiry',
  sale: 'sale',
  cancel: 'cancel',
  'balance-change': 'balance-change',
  'approval-change': 'approval-change',
  bootstrap: 'bootstrap',
  revalidation: 'revalidation',
  reprice: 'reprice',
} as const;

export interface Model137 {
  /** Time when added to indexer */
  createdAt?: string;
  id?: number;
  kind?: Model137Kind;
  txHash?: string;
  /** Time when added on the blockchain. */
  txTimestamp?: number;
}

export interface Model136 {
  contract?: string;
  criteria?: Model110;
  /** Order Id */
  id?: string;
  isDynamic?: boolean;
  kind?: string;
  maker?: string;
  nonce?: string;
  price?: Price;
  /** With ERC1155s, quantity can be higher than 1 */
  quantityRemaining?: number;
  rawData?: Metadata;
  source?: string;
  status?: string;
  validFrom?: number;
  validUntil?: number;
}

export interface Model138 {
  event?: Model137;
  order?: Model136;
}

export interface GetAsksEventsV2Response {
  continuation?: string;
  events?: Events;
}

export type EventKind = typeof EventKind[keyof typeof EventKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EventKind = {
  'new-order': 'new-order',
  expiry: 'expiry',
  sale: 'sale',
  cancel: 'cancel',
  'balance-change': 'balance-change',
  'approval-change': 'approval-change',
  bootstrap: 'bootstrap',
  revalidation: 'revalidation',
  reprice: 'reprice',
} as const;

export interface Event {
  createdAt?: string;
  id?: number;
  kind?: EventKind;
  txHash?: string;
  txTimestamp?: number;
}

export interface Model143 {
  bid?: Model142;
  event?: Event;
}

export type Model144 = Model143[];

export interface Model134 {
  contract?: string;
  id?: string;
  isDynamic?: boolean;
  maker?: string;
  nonce?: string;
  price?: Price;
  quantityRemaining?: number;
  source?: string;
  status?: string;
  tokenId?: string;
  validFrom?: number;
  validUntil?: number;
}

export interface Model135 {
  event?: Event;
  order?: Model134;
}

export type Events = Model135[];

export interface GetCurrencyConversionV1Response {
  conversion?: string;
  usd?: string;
}

export interface Model133 {
  floorAsk?: FloorAsk;
  id?: string;
  lastBuy?: LastBuy;
  lastSell?: LastBuy;
  metadata?: Metadata;
  name?: string;
  onSaleCount?: string;
  rank?: Rank;
  royalties?: Royalties;
  sampleImages?: SampleImages;
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: TopBid;
  volume?: Rank;
}

export interface GetCollectionDeprecatedV1Response {
  collection?: Model133;
}

export type Mints = Model132[];

export interface GetTrendingMintsV1Response {
  mints?: Mints;
}

export type Model132MintType = typeof Model132MintType[keyof typeof Model132MintType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model132MintType = {
  free: 'free',
  paid: 'paid',
} as const;

export interface Model130 {
  endTime?: number;
  kind: string;
  maxMintsPerWallet?: number;
  price?: Price;
  stage?: string;
  startTime?: number;
  tokenId?: string;
}

export type Model131 = Model130[];

export interface Model132 {
  banner?: string;
  collectionVolume?: Volume;
  contract?: string;
  countPercentChange?: number;
  createdAt?: string;
  creator?: string;
  description?: string;
  endDate?: string;
  floorAsk?: Model21;
  /** Collection id */
  id?: string;
  image?: string;
  isSpam?: boolean;
  maxSupply?: number;
  mintCount?: number;
  mintPrice?: string;
  mintStages?: Model131;
  mintStatus?: string;
  mintType?: Model132MintType;
  mintVolume?: number;
  name?: string;
  oneHourCount?: number;
  onSaleCount?: number;
  openseaVerificationStatus?: string;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  sampleImages?: SampleImages;
  sixHourCount?: number;
  startDate?: string;
  /** Total tokens within the collection. */
  tokenCount?: number;
  volumeChange?: Model30;
  volumePercentChange?: number;
}

export interface GetTrendingCollectionsV1Response {
  collections?: Model129;
}

export interface Model128 {
  banner?: string;
  collectionVolume?: Volume;
  contract?: string;
  count?: number;
  countPercentChange?: number;
  creator?: string;
  description?: string;
  floorAsk?: Model21;
  /** Collection id */
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
  onSaleCount?: number;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  /** Total tokens within the collection. */
  tokenCount?: number;
  volume?: number;
  volumeChange?: Model30;
  volumePercentChange?: number;
}

export type Model129 = Model128[];

export interface Model126 {
  banner?: string;
  count?: number;
  countPercentChange?: number;
  description?: string;
  floorAsk?: Model21;
  /** Collection id */
  id?: string;
  image?: string;
  name?: string;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  recentSales?: RecentSales;
  /** Total tokens within the collection. */
  tokenCount?: number;
  volume?: number;
  volumeChange?: Model30;
  volumePercentChange?: number;
}

export type Model127 = Model126[];

export interface GetTopSellingCollectionsV2Response {
  collections?: Model127;
}

export interface Model124 {
  count?: number;
  countPercentChange?: number;
  /** Collection id */
  id?: string;
  image?: string;
  name?: string;
  primaryContract?: string;
  recentSales?: RecentSales;
  volume?: number;
  volumePercentChange?: number;
}

export type Model125 = Model124[];

export interface GetTopSellingCollectionsV1Response {
  collections?: Model125;
}

export interface Model122 {
  id?: string;
  image?: string;
  name?: string;
}

export interface Model123 {
  collection?: Model122;
  contract?: string;
  price?: Price;
  timestamp?: number;
  toAddress?: string;
  token?: Model122;
  type?: string;
}

export type RecentSales = Model123[];

export interface Model120 {
  /** Native currency to chain. */
  floor_sell_value?: number;
  id?: string;
  rank?: number;
  sales_count?: number;
  timestamp?: number;
  volume?: number;
}

export type Model121 = Model120[];

export interface GetDailyVolumesV1Response {
  collections?: Model121;
}

export type Model119 = Model118[];

export interface GetCollectionActivityV6Response {
  activities?: Model119;
  continuation?: string;
}

export type Model117Side = typeof Model117Side[keyof typeof Model117Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model117Side = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface Model116 {
  collectionId?: string;
  collectionImage?: string;
  collectionName?: string;
  isSpam?: boolean;
}

export interface Model115 {
  isSpam?: boolean;
  tokenId?: string;
  tokenImage?: string;
  tokenName?: string;
}

/**
 * Return native currency unless displayCurrency contract was passed.
 */
export interface Model114 {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

export type Model113 = Model112[];

export interface GetCollectionActivityV5Response {
  activities?: Model113;
  continuation?: string;
  es?: boolean;
}

export type Model111Side = typeof Model111Side[keyof typeof Model111Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model111Side = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface Model111 {
  criteria?: Model110;
  id?: string;
  side?: Model111Side;
  source?: Source;
}

export interface Model112 {
  amount?: number;
  batchIndex?: number;
  collection?: Model104;
  contract?: string;
  createdAt?: string;
  fromAddress?: string;
  logIndex?: number;
  order?: Model111;
  price?: number;
  timestamp?: number;
  toAddress?: string;
  token?: Model103;
  txHash?: string;
  type?: string;
}

export type Model110Kind = typeof Model110Kind[keyof typeof Model110Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model110Kind = {
  token: 'token',
} as const;

export interface Model110 {
  data?: Model109;
  kind?: Model110Kind;
}

export interface Model117 {
  criteria?: Model110;
  id?: string;
  side?: Model117Side;
  source?: Metadata;
}

export interface Model118 {
  amount?: number;
  batchIndex?: number;
  collection?: Model116;
  contract?: string;
  /** Time when added in the indexer. */
  createdAt?: string;
  fillSource?: Source;
  fromAddress?: string;
  logIndex?: number;
  order?: Model117;
  price?: Model114;
  /** Time when added on the blockchain. */
  timestamp?: number;
  toAddress?: string;
  token?: Model115;
  /** Txn hash from the blockchain. */
  txHash?: string;
  /** Possible types returned: `ask`, `ask_cancel`, `bid`, `bid_cancel`, `sale`, `mint, and `transfer`. */
  type?: string;
}

export interface Model108 {
  id?: string;
  image?: string;
  isSpam?: boolean;
  name?: string;
}

export interface Model107 {
  image?: string;
  isSpam?: boolean;
  name?: string;
  tokenId?: string;
}

export interface Model109 {
  collection?: Model108;
  token?: Model107;
}

export type OrderSide = typeof OrderSide[keyof typeof OrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface Order {
  id?: string;
  metadata?: Model105;
  side?: OrderSide;
  source?: Source;
}

export interface Model106 {
  amount?: number;
  batchIndex?: number;
  collection?: Model104;
  contract?: string;
  createdAt?: string;
  fromAddress?: string;
  logIndex?: number;
  order?: Order;
  price?: number;
  timestamp?: number;
  toAddress?: string;
  token?: Model103;
  txHash?: string;
  type?: string;
}

export type Activities = Model106[];

export interface GetCollectionActivityV4Response {
  activities?: Activities;
  continuation?: string;
}

export type Model105Kind = typeof Model105Kind[keyof typeof Model105Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model105Kind = {
  token: 'token',
} as const;

export interface Data {
  collectionId?: string;
  collectionName?: string;
  image?: string;
  tokenName?: string;
}

export interface Model105 {
  data?: Data;
  kind?: Model105Kind;
}

export interface Model104 {
  collectionId?: string;
  collectionImage?: string;
  collectionName?: string;
}

export interface Model103 {
  tokenId?: string;
  tokenImage?: string;
  tokenName?: string;
}

export interface Model102 {
  '1day'?: '1day';
  '7day'?: '1day';
}

export interface GetChainStatsV5Response {
  stats?: Model102;
}

export interface N1day {
  mintCount?: number;
  mintVolume?: number;
  saleCount?: number;
  saleVolume?: number;
  totalCount?: number;
  totalVolume?: number;
}

export type Payload = Metadata[];

export interface Model100 {
  allowedRequests?: number;
  method?: string;
  payload?: Payload;
  perSeconds?: number;
  route?: string;
}

export type RateLimits = Model100[];

export interface Model101 {
  rateLimits?: RateLimits;
}

export interface GetApiKeyRateLimitsResponse {
  active?: boolean;
  appName?: string;
  createdAt?: string;
  email?: string;
  ips?: SampleImages;
  key?: string;
  origins?: SampleImages;
  permissions?: Metadata;
  revShareBps?: number;
  tier?: number;
  website?: string;
}

export interface Model98 {
  /** Can be higher than 1 if erc1155. */
  amount?: string;
  batchIndex?: number;
  block?: number;
  from?: string;
  isDeleted?: boolean;
  logIndex?: number;
  price?: Price;
  timestamp?: number;
  to?: string;
  token?: Model47;
  txHash?: string;
  /** Time when last updated in indexer */
  updatedAt?: string;
}

export type Model99 = Model98[];

export interface GetTransfersV3Response {
  continuation?: string;
  transfers?: Model99;
}

export interface Model97 {
  amount?: string;
  batchIndex?: number;
  block?: number;
  from?: string;
  logIndex?: number;
  price?: number;
  timestamp?: number;
  to?: string;
  token?: Model47;
  txHash?: string;
}

export type Transfers = Model97[];

export interface GetTransfersV2Response {
  continuation?: string;
  transfers?: Transfers;
}

export interface GetTokensV7Response {
  continuation?: string;
  tokens?: Model96;
}

export interface Model91 {
  creator?: string;
  id?: string;
  image?: string;
  metadataDisabled?: boolean;
  name?: string;
  slug?: string;
  symbol?: string;
  tokenCount?: number;
}

export interface Model92 {
  attributes?: Model81;
  chainId: number;
  collection?: Model91;
  contract: string;
  description?: string;
  image?: string;
  imageLarge?: string;
  imageSmall?: string;
  isFlagged?: boolean;
  isSpam?: boolean;
  /** Can be erc721, erc115, etc. */
  kind?: string;
  lastFlagChange?: string;
  lastFlagUpdate?: string;
  lastSale?: Model55;
  media?: string;
  metadata?: Metadata;
  metadataDisabled?: boolean;
  mintStages?: MintStages;
  name?: string;
  owner?: string;
  /** No rarity for collections over 100k */
  rarity?: number;
  /** No rarity rank for collections over 100k */
  rarityRank?: number;
  remainingSupply?: number;
  /** Can be higher than 1 if erc1155 */
  supply?: number;
  tokenId: string;
}

export interface Model95 {
  market?: Model94;
  token?: Model92;
  updatedAt?: string;
}

export type Model96 = Model95[];

export type Model90 = Model89[];

export interface GetTokensV6Response {
  continuation?: string;
  tokens?: Model90;
}

export interface Model85 {
  bps?: number;
  /** Can be `marketplace` or `royalty`. */
  kind?: string;
  recipient?: string;
}

/**
 * Can be null if no active bids
 */
export type Model86 = Model85[];

export interface Model87 {
  feeBreakdown?: Model86;
  id?: string;
  maker?: string;
  price?: Price;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export type Model83Kind = typeof Model83Kind[keyof typeof Model83Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model83Kind = {
  dutch: 'dutch',
  pool: 'pool',
} as const;

/**
 * Can be null if no active ask.
 */
export interface Model83 {
  data?: Metadata;
  kind?: Model83Kind;
}

export interface Model93 {
  dynamicPricing?: Model83;
  id?: string;
  maker?: string;
  price?: Price;
  quantityFilled?: number;
  quantityRemaining?: number;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export interface Model94 {
  floorAsk?: Model93;
  topBid?: Model87;
}

export interface Model84 {
  dynamicPricing?: Model83;
  id?: string;
  maker?: string;
  price?: Price;
  quantityFilled?: number;
  quantityRemaining?: number;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export interface Model88 {
  floorAsk?: Model84;
  topBid?: Model87;
}

export interface Model80 {
  createdAt?: string;
  floorAskPrice?: number;
  /** Case sensitive. */
  key?: string;
  /** Can be `string`, `number`, `date`, or `range`. */
  kind?: string;
  onSaleCount?: number;
  tokenCount?: number;
  topBidValue?: number;
  /** Case sensitive. */
  value: string;
}

export type Model81 = Model80[];

export interface Model82 {
  attributes?: Model81;
  chainId: number;
  collection?: Model79;
  contract: string;
  description?: string;
  image?: string;
  imageLarge?: string;
  imageSmall?: string;
  isFlagged?: boolean;
  isSpam?: boolean;
  /** Can be erc721, erc115, etc. */
  kind?: string;
  lastFlagChange?: string;
  lastFlagUpdate?: string;
  lastSale?: Model55;
  media?: string;
  metadata?: Metadata;
  metadataDisabled?: boolean;
  mintStages?: MintStages;
  name?: string;
  owner?: string;
  /** No rarity for collections over 100k */
  rarity?: number;
  /** No rarity rank for collections over 100k */
  rarityRank?: number;
  remainingSupply?: number;
  /** Can be higher than 1 if erc1155 */
  supply?: number;
  tokenId: string;
}

export interface Model89 {
  market?: Model88;
  token?: Model82;
  updatedAt?: string;
}

export type Model79VerificationStatus = typeof Model79VerificationStatus[keyof typeof Model79VerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model79VerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

export interface Model79 {
  creator?: string;
  id?: string;
  image?: string;
  metadataDisabled?: boolean;
  name?: string;
  slug?: string;
  symbol?: string;
  tokenCount?: number;
  verificationStatus?: Model79VerificationStatus;
}

export interface Model77 {
  market?: Model76;
  token?: Model73;
}

export type Model78 = Model77[];

export interface GetTokensV5Response {
  continuation?: string;
  tokens?: Model78;
}

export interface Source {
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  url?: string;
}

export interface Model75 {
  feeBreakdown?: FeeBreakdown;
  id?: string;
  maker?: string;
  price?: Price;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export type DynamicPricingKind = typeof DynamicPricingKind[keyof typeof DynamicPricingKind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DynamicPricingKind = {
  dutch: 'dutch',
  pool: 'pool',
} as const;

export interface DynamicPricing {
  data?: Metadata;
  kind?: DynamicPricingKind;
}

export interface Model74 {
  dynamicPricing?: DynamicPricing;
  id?: string;
  maker?: string;
  price?: Price;
  quantityFilled?: number;
  quantityRemaining?: number;
  source?: Source;
  validFrom?: number;
  validUntil?: number;
}

export interface Model76 {
  floorAsk?: Model74;
  royaltiesPaid?: boolean;
  topBid?: Model75;
}

export interface Model71 {
  createdAt?: string;
  floorAskPrice?: number;
  key?: string;
  kind?: string;
  onSaleCount?: number;
  tokenCount?: number;
  topBidValue?: number;
  value: string;
}

export type Model72 = Model71[];

export interface GetTokensV4Response {
  continuation?: string;
  tokens?: Model70;
}

export interface Model66 {
  id?: string;
  image?: string;
  name?: string;
  slug?: string;
}

export interface Model73 {
  attributes?: Model72;
  collection?: Model66;
  contract: string;
  description?: string;
  image?: string;
  isFlagged?: boolean;
  kind?: string;
  lastBuy?: LastBuy;
  lastFlagChange?: string;
  lastFlagUpdate?: string;
  lastSell?: LastBuy;
  media?: string;
  name?: string;
  owner?: string;
  rarity?: number;
  rarityRank?: number;
  tokenId: string;
}

export interface Model69 {
  collection?: Model66;
  contract: string;
  floorAskPrice?: number;
  image?: string;
  isFlagged?: boolean;
  lastFlagUpdate?: string;
  media?: string;
  name?: string;
  owner?: string;
  rarity?: number;
  rarityRank?: number;
  source?: string;
  sourceDomain?: string;
  tokenId: string;
  topBidValue?: number;
}

export type Model70 = Model69[];

export interface Model67 {
  collection?: Model66;
  contract: string;
  floorAskPrice?: number;
  image?: string;
  name?: string;
  tokenId: string;
  topBidValue?: number;
}

export type Model68 = Model67[];

export interface GetTokensV3Response {
  continuation?: string;
  tokens?: Model68;
}

export interface Model65 {
  collection?: Model46;
  contract: string;
  floorAskPrice?: number;
  image?: string;
  name?: string;
  tokenId: string;
  topBidValue?: number;
}

export type Tokens = Model65[];

export interface GetTokensV2Response {
  continuation?: string;
  tokens?: Tokens;
}

export interface GetTokensV1Response {
  tokens?: Tokens;
}

export interface GetStatsV2Response {
  stats?: Model64;
}

/**
 * Can be null is not active bids
 */
export interface Model62 {
  id?: string;
  maker?: string;
  price?: Price;
  validFrom?: number;
  validUntil?: number;
}

export interface Model63 {
  floorAsk?: Model61;
  topBid?: Model62;
}

export interface Model64 {
  flaggedTokenCount: number;
  market?: Model63;
  onSaleCount: number;
  sampleImages?: SampleImages;
  tokenCount: number;
}

/**
 * Can be null if no active asks.
 */
export interface Model60 {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface Model61 {
  id?: string;
  maker?: string;
  price?: Price;
  token?: Model60;
  validFrom?: number;
  validUntil?: number;
}

export interface GetStatsV1Response {
  stats?: Stats;
}

export interface Model58 {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface Model59 {
  id?: string;
  maker?: string;
  price?: number;
  token?: Model58;
  validFrom?: number;
  validUntil?: number;
}

export interface Market {
  floorAsk?: Model59;
  topBid?: TopBid;
}

export interface Stats {
  market?: Market;
  onSaleCount: number;
  sampleImages?: SampleImages;
  tokenCount: number;
}

export interface Model57 {
  description?: string;
  domain?: string;
  icon?: string;
  id?: string;
  name?: string;
  socialImage?: string;
  tokenUrl?: string;
  twitterUsername?: string;
}

export type Sources = Model57[];

export interface GetSourcesV1Response {
  continuation?: string;
  sources?: Sources;
}

export type Model56 = Model55[];

export interface GetSalesV4Response {
  continuation?: string;
  sales?: Model56;
}

/**
 * Can be `ask` or `bid`.
 */
export type Model55OrderSide = typeof Model55OrderSide[keyof typeof Model55OrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model55OrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface Model53 {
  bps?: number;
  kind?: string;
  rawAmount?: string;
  recipient?: string;
  source?: string;
}

/**
 * `kind` can be `marketplace` or `royalty`
 */
export type Model54 = Model53[];

export interface Model55 {
  amount?: string;
  batchIndex?: number;
  block?: number;
  /** Time when added to indexer */
  createdAt?: string;
  feeBreakdown?: Model54;
  fillSource?: string;
  from?: string;
  /** Deprecated. Use `saleId` instead. */
  id?: string;
  isDeleted?: boolean;
  logIndex?: number;
  marketplaceFeeBps?: number;
  orderId?: string;
  orderKind?: string;
  /** Can be `ask` or `bid`. */
  orderSide?: Model55OrderSide;
  orderSource?: string;
  paidFullRoyalty?: boolean;
  price?: Price;
  royaltyFeeBps?: number;
  /** Unique identifier made from txn hash, price, etc. */
  saleId?: string;
  /** Time added on the blockchain */
  timestamp?: number;
  to?: string;
  token?: Model47;
  txHash?: string;
  /** Time when updated in indexer */
  updatedAt?: string;
  washTradingScore?: number;
}

export type Model52 = Model51[];

export interface GetSalesV3Response {
  continuation?: string;
  sales?: Model52;
}

export type Model51OrderSide = typeof Model51OrderSide[keyof typeof Model51OrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model51OrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface GetSalesV2Response {
  sales?: Model50;
}

export type Model49OrderSide = typeof Model49OrderSide[keyof typeof Model49OrderSide];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model49OrderSide = {
  ask: 'ask',
  bid: 'bid',
} as const;

export interface Model49 {
  amount?: string;
  from?: string;
  orderSide?: Model49OrderSide;
  price?: number;
  timestamp?: number;
  to?: string;
  token?: Model47;
  txHash?: string;
}

export type Model50 = Model49[];

export type Sales = Model48[];

export interface GetSalesV1Response {
  sales?: Sales;
}

export interface Model46 {
  id?: string;
  name?: string;
}

export interface Model47 {
  collection?: Model46;
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface Model51 {
  amount?: string;
  batchIndex?: number;
  currency?: string;
  currencyPrice?: number;
  fillSource?: string;
  from?: string;
  id?: string;
  logIndex?: number;
  orderKind?: string;
  orderSide?: Model51OrderSide;
  orderSource?: string;
  orderSourceDomain?: string;
  price?: number;
  saleId?: string;
  timestamp?: number;
  to?: string;
  token?: Model47;
  txHash?: string;
  usdPrice?: number;
  washTradingScore?: number;
}

export interface Model48 {
  amount?: string;
  maker?: string;
  price?: number;
  taker?: string;
  timestamp?: number;
  token?: Model47;
  txHash?: string;
}

export type Model45 = Model44[];

export interface GetOwnersV2Response {
  owners?: Model45;
}

/**
 * Can return `null` if there are no bids
 */
export interface TopBidValue {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

/**
 * Can return `null` if there is no Floor Ask
 */
export interface FloorAskPrice {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

export interface Model43 {
  floorAskPrice?: FloorAskPrice;
  onSaleCount?: string;
  tokenCount?: string;
  topBidValue?: TopBidValue;
  totalBidValue?: TopBidValue;
}

export interface Model44 {
  address?: string;
  ownership?: Model43;
}

export interface GetOwnersV1Response {
  owners?: Owners;
}

export interface Ownership {
  floorAskPrice?: number;
  onSaleCount?: string;
  tokenCount?: string;
  topBidValue?: number;
  totalBidValue?: number;
}

export interface Model42 {
  address?: string;
  ownership?: Ownership;
}

export type Owners = Model42[];

export interface GetOrdersV2Response {
  orders?: Model41;
}

export type Model40Side = typeof Model40Side[keyof typeof Model40Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model40Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface GetOrdersV1Response {
  orders?: Model39;
}

export type Model38Side = typeof Model38Side[keyof typeof Model38Side];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model38Side = {
  buy: 'buy',
  sell: 'sell',
} as const;

export interface Model37 {
  bps?: number;
  kind?: string;
  recipient?: string;
}

export type FeeBreakdown = Model37[];

export interface Model40 {
  approvalStatus: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: FeeBreakdown;
  fillabilityStatus: string;
  id: string;
  kind: string;
  maker: string;
  price: number;
  rawData?: Metadata;
  side: Model40Side;
  source?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
  value: number;
}

export type Model41 = Model40[];

export interface Model38 {
  approvalStatus: string;
  createdAt: string;
  expiration: number;
  feeBps?: number;
  feeBreakdown?: FeeBreakdown;
  fillabilityStatus: string;
  id: string;
  kind: string;
  maker: string;
  price: number;
  rawData?: Metadata;
  side: Model38Side;
  sourceId?: string;
  taker: string;
  tokenSetId: string;
  tokenSetSchemaHash: string;
  updatedAt: string;
  validFrom: number;
  validUntil: number;
  value: number;
}

export type Model39 = Model38[];

export type Orders = Model36[];

export interface GetCrossPostingOrdersV1Response {
  continuation?: string;
  orders?: Orders;
}

export interface Model36 {
  /** Time when added to indexer */
  createdAt: string;
  id: number;
  orderbook: string;
  orderId: string;
  /** Possible values: pending - Waiting to be submitted. posted - Successfully submitted to the marketplace. posted - Failed to be submitted to the marketplace (see statusReason for detail). */
  status: string;
  statusReason: string;
  /** Time when updated in indexer */
  updatedAt: string;
}

export type Model35 = Model34[];

export interface GetCollectionsV7Response {
  collections?: Model35;
  continuation?: string;
}

export type Model34VerificationStatus = typeof Model34VerificationStatus[keyof typeof Model34VerificationStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model34VerificationStatus = {
  verified: 'verified',
  unverified: 'unverified',
} as const;

export type OperatorWhitelist = string[];

export interface SecurityConfig {
  operatorWhitelist?: OperatorWhitelist;
  receiverAllowList?: OperatorWhitelist;
  transferSecurityLevel?: number;
  transferValidator?: string;
}

export type Model33 = Model32[];

export interface GetCollectionsV6Response {
  collections?: Model33;
  continuation?: string;
}

/**
 * Floor sale change from X-days vs X-days ago. (e.g. 7day floor sale vs floor sale 14 days ago). A value over 1 is a positive gain, under 1 is a negative loss. e.g. 1 means no change; 1.1 means 10% increase; 0.9 means 10% decrease.
 */
export interface Model31 {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Total volume change X-days vs previous X-days. (e.g. 7day [days 1-7] vs 7day prior [days 8-14]). A value over 1 is a positive gain, under 1 is a negative loss. e.g. 1 means no change; 1.1 means 10% increase; 0.9 means 10% decrease.
 */
export interface Model30 {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

export type Model29 = Model28[];

export interface GetCollectionsV5Response {
  collections?: Model29;
  continuation?: string;
}

export interface Model27 {
  endTime?: number;
  kind: string;
  maxMintsPerWallet?: number;
  price?: Price;
  stage: string;
  startTime?: number;
  tokenId?: string;
}

export type MintStages = Model27[];

export interface Model25 {
  count?: number;
  /** Case sensitive */
  key?: string;
  /** `string`, `number`, `date`, or `range` */
  kind?: string;
}

export type Model26 = Model25[];

/**
 * Number of sales of X-days period
 */
export interface SalesCount {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

/**
 * Floor sale change from X-days vs X-days ago. (e.g. 7day floor sale vs floor sale 14 days ago)
 */
export interface FloorSaleChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * The floor sale from X-days ago.
 */
export interface FloorSale {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Total volume change X-days vs previous X-days. (e.g. 7day [days 1-7] vs 7day prior [days 8-14])
 */
export interface Model24 {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

/**
 * Total volume in given time period.
 */
export interface Volume {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

/**
 * Current rank based from overall volume
 */
export interface Model23 {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

/**
 * Highest current offer
 */
export interface Model22 {
  id?: string;
  maker?: string;
  price?: Price;
  sourceDomain?: string;
  validFrom?: number;
  validUntil?: number;
}

export interface Model34 {
  allRoyalties?: Metadata;
  attributes?: Model26;
  banner?: string;
  chainId: number;
  /** true or false */
  collectionBidSupported?: boolean;
  /** Time when contract was deployed */
  contractDeployedAt?: string;
  /** Returns `erc721`, `erc1155`, etc. */
  contractKind?: string;
  /** Time when added to indexer */
  createdAt?: string;
  creator?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  floorAsk?: Model21;
  floorSale?: FloorSale;
  floorSaleChange?: Model31;
  /** Collection id */
  id?: string;
  image?: string;
  isSpam?: boolean;
  metadataDisabled?: boolean;
  mintedTimestamp?: number;
  mintStages?: MintStages;
  name?: string;
  /** Total tokens currently on sale. */
  onSaleCount?: string;
  openseaVerificationStatus?: string;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  rank?: Model23;
  royalties?: Model19;
  salesCount?: SalesCount;
  sampleImages?: SampleImages;
  securityConfig?: SecurityConfig;
  /** Open Sea slug */
  slug?: string;
  symbol?: string;
  /** Total tokens within the collection. */
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: Model22;
  twitterUrl?: string;
  twitterUsername?: string;
  /** Time when updated in indexer */
  updatedAt?: string;
  verificationStatus?: Model34VerificationStatus;
  volume?: Volume;
  volumeChange?: Model30;
}

/**
 * Lowest Ask Price.
 */
export interface Model20 {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface Model21 {
  id?: string;
  maker?: string;
  price?: Price;
  sourceDomain?: string;
  token?: Model20;
  validFrom?: number;
  validUntil?: number;
}

export interface Model32 {
  allRoyalties?: Metadata;
  attributes?: Model26;
  banner?: string;
  /** true or false */
  collectionBidSupported?: boolean;
  /** Returns `erc721`, `erc1155`, etc. */
  contractKind?: string;
  /** Time when added to indexer */
  createdAt?: string;
  creator?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  floorAsk?: Model21;
  floorSale?: FloorSale;
  floorSaleChange?: Model31;
  /** Collection id */
  id?: string;
  image?: string;
  mintedTimestamp?: number;
  mintStages?: MintStages;
  name?: string;
  /** Total tokens currently on sale. */
  onSaleCount?: string;
  openseaVerificationStatus?: string;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  rank?: Model23;
  royalties?: Model19;
  salesCount?: SalesCount;
  sampleImages?: SampleImages;
  /** Open Sea slug */
  slug?: string;
  /** Total tokens within the collection. */
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: Model22;
  twitterUsername?: string;
  volume?: Volume;
  volumeChange?: Model30;
}

export interface Model28 {
  allRoyalties?: Metadata;
  attributes?: Model26;
  banner?: string;
  /** true or false */
  collectionBidSupported?: boolean;
  /** Returns `erc721`, `erc1155`, etc. */
  contractKind?: string;
  /** Time when added to indexer */
  createdAt?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  floorAsk?: Model21;
  floorSale?: FloorSale;
  floorSaleChange?: FloorSaleChange;
  /** Collection id */
  id?: string;
  image?: string;
  lastBuy?: LastBuy;
  mintedTimestamp?: number;
  mintStages?: MintStages;
  name?: string;
  /** Total tokens currently on sale. */
  onSaleCount?: string;
  openseaVerificationStatus?: string;
  /** Unique number of owners. */
  ownerCount?: number;
  primaryContract?: string;
  rank?: Model23;
  royalties?: Model19;
  salesCount?: SalesCount;
  sampleImages?: SampleImages;
  /** Open Sea slug */
  slug?: string;
  /** Total tokens within the collection. */
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: Model22;
  twitterUsername?: string;
  volume?: Volume;
  volumeChange?: Model24;
}

/**
 * Amount with fees & royalties removed.
 */
export interface NetAmount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

/**
 * Amount with fees & royalties included.
 */
export interface Amount {
  decimal?: number;
  native?: number;
  raw?: string;
  usd?: number;
}

export interface Currency {
  chainId?: number;
  contract?: string;
  decimals?: number;
  name?: string;
  symbol?: string;
}

export interface Price {
  amount?: Amount;
  currency?: Currency;
  netAmount?: NetAmount;
}

export interface Model18 {
  bps?: number;
  recipient?: string;
}

export type Breakdown = Model18[];

export interface Model19 {
  bps?: number;
  breakdown?: Breakdown;
  recipient?: string;
}

export interface Model16 {
  banner?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  floorAskPrice?: number;
  floorSale?: VolumeChange;
  floorSaleChange?: VolumeChange;
  id?: string;
  image?: string;
  name?: string;
  primaryContract?: string;
  rank?: Rank;
  sampleImages?: SampleImages;
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBidMaker?: string;
  topBidValue?: number;
  twitterUsername?: string;
  volume?: Rank;
  volumeChange?: VolumeChange;
}

export type Model17 = Model16[];

export interface GetCollectionsV4Response {
  collections?: Model17;
  continuation?: string;
}

export interface GetCollectionsV3Response {
  collections?: Model15;
}

export interface Model14 {
  banner?: string;
  description?: string;
  discordUrl?: string;
  externalUrl?: string;
  floorAskPrice?: number;
  floorSale?: VolumeChange;
  id?: string;
  image?: string;
  name?: string;
  primaryContract?: string;
  rank?: Rank;
  sampleImages?: SampleImages;
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBidMaker?: string;
  topBidValue?: number;
  twitterUsername?: string;
  volume?: Rank;
  volumeChange?: VolumeChange;
}

export type Model15 = Model14[];

export interface Model12 {
  '1dayVolume'?: number;
  '30dayVolume'?: number;
  '7dayVolume'?: number;
  allTimeRank?: number;
  allTimeVolume?: number;
  banner?: string;
  floorAskPrice?: number;
  id?: string;
  image?: string;
  name?: string;
  primaryContract?: string;
  sampleImages?: SampleImages;
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBidMaker?: string;
  topBidValue?: number;
}

export type Model13 = Model12[];

export interface GetCollectionsV2Response {
  collections?: Model13;
}

export interface Model10 {
  floorAskPrice?: number;
  id?: string;
  metadata?: Metadata;
  name?: string;
  rank?: Rank;
  royalties?: Royalties;
  sampleImages?: SampleImages;
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBidMaker?: string;
  topBidValue?: number;
  volume?: Rank;
}

export type Model11 = Model10[];

export interface GetCollectionsV1Response {
  collections?: Model11;
}

export interface Model8 {
  id?: string;
  maker?: string;
  price?: number;
  sourceDomain?: string;
  token?: Token;
  validFrom?: number;
  validUntil?: number;
}

export interface Model5 {
  count?: number;
  key?: string;
  kind?: string;
}

export type Model6 = Model5[];

export interface GetCollectionV1Response {
  collection?: Collection;
}

export interface VolumeChange {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
}

export interface Rank {
  '1day'?: number;
  '30day'?: number;
  '7day'?: number;
  allTime?: number;
}

export interface TopBid {
  id?: string;
  maker?: string;
  validFrom?: number;
  validUntil?: number;
  value?: number;
}

export interface Model7 {
  attributes?: Model6;
  collectionBidSupported?: boolean;
  floorAsk?: FloorAsk;
  floorSale?: VolumeChange;
  floorSaleChange?: VolumeChange;
  id?: string;
  lastBuy?: LastBuy;
  metadata?: Metadata;
  name?: string;
  onSaleCount?: string;
  ownerCount?: number;
  primaryContract?: string;
  rank?: Rank;
  royalties?: Royalties;
  sampleImages?: SampleImages;
  /** Open Sea slug */
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: TopBid;
  volume?: Rank;
  volumeChange?: VolumeChange;
}

export interface GetCollectionV2Response {
  collection?: Model7;
}

export interface Token {
  contract?: string;
  image?: string;
  name?: string;
  tokenId?: string;
}

export interface FloorAsk {
  id?: string;
  maker?: string;
  price?: number;
  token?: Token;
  validFrom?: number;
  validUntil?: number;
}

export interface LastBuy {
  timestamp?: number;
  value?: number;
}

export interface Royalties {
  bps?: number;
  recipient?: string;
}

export type SampleImages = string[];

export interface Metadata { [key: string]: any }

export interface Model9 {
  attributes?: Model6;
  collectionBidSupported?: boolean;
  floorAsk?: Model8;
  floorSale?: VolumeChange;
  floorSaleChange?: VolumeChange;
  id?: string;
  lastBuy?: LastBuy;
  metadata?: Metadata;
  name?: string;
  onSaleCount?: string;
  ownerCount?: number;
  primaryContract?: string;
  rank?: Rank;
  royalties?: Royalties;
  sampleImages?: SampleImages;
  /** Open Sea slug */
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: TopBid;
  volume?: Rank;
  volumeChange?: VolumeChange;
}

export interface GetCollectionV3Response {
  collection?: Model9;
}

export interface Collection {
  floorAsk?: FloorAsk;
  floorSale?: VolumeChange;
  floorSaleChange?: VolumeChange;
  id?: string;
  lastBuy?: LastBuy;
  lastSell?: LastBuy;
  metadata?: Metadata;
  name?: string;
  onSaleCount?: string;
  primaryContract?: string;
  rank?: Rank;
  royalties?: Royalties;
  sampleImages?: SampleImages;
  slug?: string;
  tokenCount?: string;
  tokenSetId?: string;
  topBid?: TopBid;
  volume?: Rank;
  volumeChange?: VolumeChange;
}

export type Attributes = Model4[];

export interface GetAttributesV1Response {
  attributes?: Attributes;
}

export type Model4Kind = typeof Model4Kind[keyof typeof Model4Kind];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Model4Kind = {
  string: 'string',
  number: 'number',
  date: 'date',
  range: 'range',
} as const;

export interface Model3 {
  count?: number;
  value: string;
}

export type Values = Model3[];

export interface Model4 {
  key: string;
  kind: Model4Kind;
  values?: Values;
}

export interface GetMarketplacesv1Resp {
  marketplaces?: Marketplaces;
}

export interface Fee {
  bps?: number;
  percent?: number;
}

export interface Model2 {
  fee?: Fee;
  feeBps?: number;
  imageUrl?: string;
  listingEnabled?: boolean;
  name?: string;
  orderbook?: string;
  orderKind?: string;
}

export type Marketplaces = Model2[];

export interface Model1 {
  /** Collection Id */
  id?: string;
  isSpam?: boolean;
  name?: string;
  percentageTotalTokens?: string;
  tokensMissingMetadata?: number;
  totalTokens?: number;
}

export type Collections = Model1[];

export interface GetCollectionMetadataDetailsResponse {
  collections?: Collections;
}




type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * @summary Get metadata details for the given collections
 */
export const getAdminCollectionmetadatadetails = (
    params?: GetAdminCollectionmetadatadetailsParams,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionMetadataDetailsResponse>(
      {url: `/admin/collection-metadata-details`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAdminCollectionmetadatadetailsQueryKey = (params?: GetAdminCollectionmetadatadetailsParams,) => {
    return [`/admin/collection-metadata-details`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAdminCollectionmetadatadetailsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, GetAdminCollectionmetadatadetailsParams['continuation']>, TError = ErrorType<unknown>>(params?: GetAdminCollectionmetadatadetailsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData, Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, QueryKey, GetAdminCollectionmetadatadetailsParams['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminCollectionmetadatadetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, QueryKey, GetAdminCollectionmetadatadetailsParams['continuation']> = ({ signal, pageParam }) => getAdminCollectionmetadatadetails({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData, Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, QueryKey, GetAdminCollectionmetadatadetailsParams['continuation']> & { queryKey: QueryKey }
}

export type GetAdminCollectionmetadatadetailsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>>
export type GetAdminCollectionmetadatadetailsInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get metadata details for the given collections
 */
export const useGetAdminCollectionmetadatadetailsInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, GetAdminCollectionmetadatadetailsParams['continuation']>, TError = ErrorType<unknown>>(
 params?: GetAdminCollectionmetadatadetailsParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData, Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, QueryKey, GetAdminCollectionmetadatadetailsParams['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminCollectionmetadatadetailsInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminCollectionmetadatadetailsQueryOptions = <TData = Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError = ErrorType<unknown>>(params?: GetAdminCollectionmetadatadetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminCollectionmetadatadetailsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>> = ({ signal }) => getAdminCollectionmetadatadetails(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminCollectionmetadatadetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>>
export type GetAdminCollectionmetadatadetailsQueryError = ErrorType<unknown>

/**
 * @summary Get metadata details for the given collections
 */
export const useGetAdminCollectionmetadatadetails = <TData = Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError = ErrorType<unknown>>(
 params?: GetAdminCollectionmetadatadetailsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminCollectionmetadatadetails>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminCollectionmetadatadetailsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get supported marketplaces
 */
export const getAdminGetmarketplaces = (
    
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetMarketplacesv1Resp>(
      {url: `/admin/get-marketplaces`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAdminGetmarketplacesQueryKey = () => {
    return [`/admin/get-marketplaces`] as const;
    }

    
export const getGetAdminGetmarketplacesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminGetmarketplaces>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminGetmarketplacesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGetmarketplaces>>> = ({ signal }) => getAdminGetmarketplaces(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminGetmarketplacesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGetmarketplaces>>>
export type GetAdminGetmarketplacesInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get supported marketplaces
 */
export const useGetAdminGetmarketplacesInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminGetmarketplaces>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminGetmarketplacesInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminGetmarketplacesQueryOptions = <TData = Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminGetmarketplacesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGetmarketplaces>>> = ({ signal }) => getAdminGetmarketplaces(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminGetmarketplacesQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGetmarketplaces>>>
export type GetAdminGetmarketplacesQueryError = ErrorType<unknown>

/**
 * @summary Get supported marketplaces
 */
export const useGetAdminGetmarketplaces = <TData = Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminGetmarketplaces>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminGetmarketplacesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get swagger json in OpenApi V3
 */
export const getAdminOpenapi = (
    
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/admin/open-api`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAdminOpenapiQueryKey = () => {
    return [`/admin/open-api`] as const;
    }

    
export const getGetAdminOpenapiInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminOpenapi>>>, TError = ErrorType<string>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminOpenapiQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminOpenapi>>> = ({ signal }) => getAdminOpenapi(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminOpenapiInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminOpenapi>>>
export type GetAdminOpenapiInfiniteQueryError = ErrorType<string>

/**
 * @summary Get swagger json in OpenApi V3
 */
export const useGetAdminOpenapiInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminOpenapi>>>, TError = ErrorType<string>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminOpenapiInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminOpenapiQueryOptions = <TData = Awaited<ReturnType<typeof getAdminOpenapi>>, TError = ErrorType<string>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminOpenapiQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminOpenapi>>> = ({ signal }) => getAdminOpenapi(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminOpenapiQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminOpenapi>>>
export type GetAdminOpenapiQueryError = ErrorType<string>

/**
 * @summary Get swagger json in OpenApi V3
 */
export const useGetAdminOpenapi = <TData = Awaited<ReturnType<typeof getAdminOpenapi>>, TError = ErrorType<string>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminOpenapi>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminOpenapiQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get rate limit rules
 */
export const getAdminRatelimitrules = (
    params?: GetAdminRatelimitrulesParams,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/admin/rate-limit-rules`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAdminRatelimitrulesQueryKey = (params?: GetAdminRatelimitrulesParams,) => {
    return [`/admin/rate-limit-rules`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAdminRatelimitrulesInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminRatelimitrules>>, GetAdminRatelimitrulesParams['continuation']>, TError = ErrorType<string>>(params?: GetAdminRatelimitrulesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminRatelimitrules>>, TError, TData, Awaited<ReturnType<typeof getAdminRatelimitrules>>, QueryKey, GetAdminRatelimitrulesParams['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminRatelimitrulesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminRatelimitrules>>, QueryKey, GetAdminRatelimitrulesParams['continuation']> = ({ signal, pageParam }) => getAdminRatelimitrules({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminRatelimitrules>>, TError, TData, Awaited<ReturnType<typeof getAdminRatelimitrules>>, QueryKey, GetAdminRatelimitrulesParams['continuation']> & { queryKey: QueryKey }
}

export type GetAdminRatelimitrulesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminRatelimitrules>>>
export type GetAdminRatelimitrulesInfiniteQueryError = ErrorType<string>

/**
 * @summary Get rate limit rules
 */
export const useGetAdminRatelimitrulesInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminRatelimitrules>>, GetAdminRatelimitrulesParams['continuation']>, TError = ErrorType<string>>(
 params?: GetAdminRatelimitrulesParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminRatelimitrules>>, TError, TData, Awaited<ReturnType<typeof getAdminRatelimitrules>>, QueryKey, GetAdminRatelimitrulesParams['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminRatelimitrulesInfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminRatelimitrulesQueryOptions = <TData = Awaited<ReturnType<typeof getAdminRatelimitrules>>, TError = ErrorType<string>>(params?: GetAdminRatelimitrulesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminRatelimitrules>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminRatelimitrulesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminRatelimitrules>>> = ({ signal }) => getAdminRatelimitrules(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminRatelimitrules>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminRatelimitrulesQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminRatelimitrules>>>
export type GetAdminRatelimitrulesQueryError = ErrorType<string>

/**
 * @summary Get rate limit rules
 */
export const useGetAdminRatelimitrules = <TData = Awaited<ReturnType<typeof getAdminRatelimitrules>>, TError = ErrorType<string>>(
 params?: GetAdminRatelimitrulesParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminRatelimitrules>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminRatelimitrulesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Return the asset based on the given param
 */
export const getAssetsV1 = (
    params: GetAssetsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/assets/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAssetsV1QueryKey = (params: GetAssetsV1Params,) => {
    return [`/assets/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAssetsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAssetsV1>>, GetAssetsV1Params['continuation']>, TError = ErrorType<string>>(params: GetAssetsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData, Awaited<ReturnType<typeof getAssetsV1>>, QueryKey, GetAssetsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetsV1>>, QueryKey, GetAssetsV1Params['continuation']> = ({ signal, pageParam }) => getAssetsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData, Awaited<ReturnType<typeof getAssetsV1>>, QueryKey, GetAssetsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetAssetsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAssetsV1>>>
export type GetAssetsV1InfiniteQueryError = ErrorType<string>

/**
 * @summary Return the asset based on the given param
 */
export const useGetAssetsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAssetsV1>>, GetAssetsV1Params['continuation']>, TError = ErrorType<string>>(
 params: GetAssetsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData, Awaited<ReturnType<typeof getAssetsV1>>, QueryKey, GetAssetsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAssetsV1QueryOptions = <TData = Awaited<ReturnType<typeof getAssetsV1>>, TError = ErrorType<string>>(params: GetAssetsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssetsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssetsV1>>> = ({ signal }) => getAssetsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssetsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getAssetsV1>>>
export type GetAssetsV1QueryError = ErrorType<string>

/**
 * @summary Return the asset based on the given param
 */
export const useGetAssetsV1 = <TData = Awaited<ReturnType<typeof getAssetsV1>>, TError = ErrorType<string>>(
 params: GetAssetsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssetsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssetsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary List of attributes
 */
export const getAttributesV1 = (
    params?: GetAttributesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesV1Response>(
      {url: `/attributes/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAttributesV1QueryKey = (params?: GetAttributesV1Params,) => {
    return [`/attributes/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAttributesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAttributesV1>>, GetAttributesV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetAttributesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getAttributesV1>>, QueryKey, GetAttributesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAttributesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAttributesV1>>, QueryKey, GetAttributesV1Params['continuation']> = ({ signal, pageParam }) => getAttributesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getAttributesV1>>, QueryKey, GetAttributesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetAttributesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAttributesV1>>>
export type GetAttributesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of attributes
 */
export const useGetAttributesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAttributesV1>>, GetAttributesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetAttributesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getAttributesV1>>, QueryKey, GetAttributesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAttributesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAttributesV1QueryOptions = <TData = Awaited<ReturnType<typeof getAttributesV1>>, TError = ErrorType<unknown>>(params?: GetAttributesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAttributesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAttributesV1>>> = ({ signal }) => getAttributesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAttributesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getAttributesV1>>>
export type GetAttributesV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of attributes
 */
export const useGetAttributesV1 = <TData = Awaited<ReturnType<typeof getAttributesV1>>, TError = ErrorType<unknown>>(
 params?: GetAttributesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAttributesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAttributesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get detailed information about a single collection, including real-time stats.
 * @deprecated
 * @summary Get detailed information about a single collection
 */
export const getCollectionV1 = (
    params?: GetCollectionV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionV1Response>(
      {url: `/collection/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionV1QueryKey = (params?: GetCollectionV1Params,) => {
    return [`/collection/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionV1>>, GetCollectionV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionV1>>, QueryKey, GetCollectionV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionV1>>, QueryKey, GetCollectionV1Params['continuation']> = ({ signal, pageParam }) => getCollectionV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionV1>>, QueryKey, GetCollectionV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionV1>>>
export type GetCollectionV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get detailed information about a single collection
 */
export const useGetCollectionV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionV1>>, GetCollectionV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionV1>>, QueryKey, GetCollectionV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionV1>>, TError = ErrorType<unknown>>(params?: GetCollectionV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionV1>>> = ({ signal }) => getCollectionV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionV1>>>
export type GetCollectionV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get detailed information about a single collection
 */
export const useGetCollectionV1 = <TData = Awaited<ReturnType<typeof getCollectionV1>>, TError = ErrorType<unknown>>(
 params?: GetCollectionV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get detailed information about a single collection, including real-time stats.
 * @deprecated
 * @summary Single Collection
 */
export const getCollectionV2 = (
    params?: GetCollectionV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionV2Response>(
      {url: `/collection/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionV2QueryKey = (params?: GetCollectionV2Params,) => {
    return [`/collection/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionV2>>, GetCollectionV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionV2>>, QueryKey, GetCollectionV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionV2>>, QueryKey, GetCollectionV2Params['continuation']> = ({ signal, pageParam }) => getCollectionV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionV2>>, QueryKey, GetCollectionV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionV2>>>
export type GetCollectionV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single Collection
 */
export const useGetCollectionV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionV2>>, GetCollectionV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionV2>>, QueryKey, GetCollectionV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionV2QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionV2>>, TError = ErrorType<unknown>>(params?: GetCollectionV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionV2>>> = ({ signal }) => getCollectionV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionV2QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionV2>>>
export type GetCollectionV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single Collection
 */
export const useGetCollectionV2 = <TData = Awaited<ReturnType<typeof getCollectionV2>>, TError = ErrorType<unknown>>(
 params?: GetCollectionV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get detailed information about a single collection, including real-time stats.
 * @deprecated
 * @summary Single Collection
 */
export const getCollectionV3 = (
    params?: GetCollectionV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionV3Response>(
      {url: `/collection/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionV3QueryKey = (params?: GetCollectionV3Params,) => {
    return [`/collection/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionV3>>, GetCollectionV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionV3>>, QueryKey, GetCollectionV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionV3>>, QueryKey, GetCollectionV3Params['continuation']> = ({ signal, pageParam }) => getCollectionV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionV3>>, QueryKey, GetCollectionV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionV3>>>
export type GetCollectionV3InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single Collection
 */
export const useGetCollectionV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionV3>>, GetCollectionV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionV3>>, QueryKey, GetCollectionV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionV3QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionV3>>, TError = ErrorType<unknown>>(params?: GetCollectionV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionV3>>> = ({ signal }) => getCollectionV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionV3QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionV3>>>
export type GetCollectionV3QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single Collection
 */
export const useGetCollectionV3 = <TData = Awaited<ReturnType<typeof getCollectionV3>>, TError = ErrorType<unknown>>(
 params?: GetCollectionV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Useful for getting multiple collections to show in a marketplace, or search for particular collections.
 * @deprecated
 * @summary List of collections
 */
export const getCollectionsV1 = (
    params?: GetCollectionsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsV1Response>(
      {url: `/collections/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsV1QueryKey = (params?: GetCollectionsV1Params,) => {
    return [`/collections/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV1>>, GetCollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV1>>, QueryKey, GetCollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV1>>, QueryKey, GetCollectionsV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV1>>, QueryKey, GetCollectionsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV1>>>
export type GetCollectionsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of collections
 */
export const useGetCollectionsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV1>>, GetCollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV1>>, QueryKey, GetCollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsV1>>, TError = ErrorType<unknown>>(params?: GetCollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV1>>> = ({ signal }) => getCollectionsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV1>>>
export type GetCollectionsV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of collections
 */
export const useGetCollectionsV1 = <TData = Awaited<ReturnType<typeof getCollectionsV1>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Useful for getting multiple collections to show in a marketplace, or search for particular collections.
 * @deprecated
 * @summary Get a filtered list of collections
 */
export const getCollectionsV2 = (
    params?: GetCollectionsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsV2Response>(
      {url: `/collections/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsV2QueryKey = (params?: GetCollectionsV2Params,) => {
    return [`/collections/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV2>>, GetCollectionsV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV2>>, QueryKey, GetCollectionsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV2>>, QueryKey, GetCollectionsV2Params['continuation']> = ({ signal, pageParam }) => getCollectionsV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV2>>, QueryKey, GetCollectionsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV2>>>
export type GetCollectionsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a filtered list of collections
 */
export const useGetCollectionsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV2>>, GetCollectionsV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV2>>, QueryKey, GetCollectionsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsV2QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsV2>>, TError = ErrorType<unknown>>(params?: GetCollectionsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV2>>> = ({ signal }) => getCollectionsV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV2>>>
export type GetCollectionsV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a filtered list of collections
 */
export const useGetCollectionsV2 = <TData = Awaited<ReturnType<typeof getCollectionsV2>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Useful for getting multiple collections to show in a marketplace, or search for particular collections.
 * @deprecated
 * @summary Get a filtered list of collections
 */
export const getCollectionsV3 = (
    params?: GetCollectionsV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsV3Response>(
      {url: `/collections/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsV3QueryKey = (params?: GetCollectionsV3Params,) => {
    return [`/collections/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV3>>, GetCollectionsV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV3>>, QueryKey, GetCollectionsV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV3>>, QueryKey, GetCollectionsV3Params['continuation']> = ({ signal, pageParam }) => getCollectionsV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV3>>, QueryKey, GetCollectionsV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV3>>>
export type GetCollectionsV3InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a filtered list of collections
 */
export const useGetCollectionsV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV3>>, GetCollectionsV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV3>>, QueryKey, GetCollectionsV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsV3QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsV3>>, TError = ErrorType<unknown>>(params?: GetCollectionsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV3>>> = ({ signal }) => getCollectionsV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsV3QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV3>>>
export type GetCollectionsV3QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a filtered list of collections
 */
export const useGetCollectionsV3 = <TData = Awaited<ReturnType<typeof getCollectionsV3>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Useful for getting multiple collections to show in a marketplace, or search for particular collections.
 * @deprecated
 * @summary Collections
 */
export const getCollectionsV4 = (
    params?: GetCollectionsV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsV4Response>(
      {url: `/collections/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsV4QueryKey = (params?: GetCollectionsV4Params,) => {
    return [`/collections/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV4>>, GetCollectionsV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV4>>, QueryKey, GetCollectionsV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV4>>, QueryKey, GetCollectionsV4Params['continuation']> = ({ signal, pageParam }) => getCollectionsV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV4>>, QueryKey, GetCollectionsV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV4>>>
export type GetCollectionsV4InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collections
 */
export const useGetCollectionsV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV4>>, GetCollectionsV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV4>>, QueryKey, GetCollectionsV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsV4QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsV4>>, TError = ErrorType<unknown>>(params?: GetCollectionsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV4>>> = ({ signal }) => getCollectionsV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsV4QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV4>>>
export type GetCollectionsV4QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collections
 */
export const useGetCollectionsV4 = <TData = Awaited<ReturnType<typeof getCollectionsV4>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Use this API to explore a collection’s metadata and statistics (sales, volume, etc).
 * @deprecated
 * @summary Collections
 */
export const getCollectionsV5 = (
    params?: GetCollectionsV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsV5Response>(
      {url: `/collections/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsV5QueryKey = (params?: GetCollectionsV5Params,) => {
    return [`/collections/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV5>>, GetCollectionsV5Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV5>>, QueryKey, GetCollectionsV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV5>>, QueryKey, GetCollectionsV5Params['continuation']> = ({ signal, pageParam }) => getCollectionsV5({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV5>>, QueryKey, GetCollectionsV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV5>>>
export type GetCollectionsV5InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collections
 */
export const useGetCollectionsV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV5>>, GetCollectionsV5Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV5>>, QueryKey, GetCollectionsV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV5InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsV5QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsV5>>, TError = ErrorType<unknown>>(params?: GetCollectionsV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV5>>> = ({ signal }) => getCollectionsV5(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsV5QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV5>>>
export type GetCollectionsV5QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collections
 */
export const useGetCollectionsV5 = <TData = Awaited<ReturnType<typeof getCollectionsV5>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV5QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Use this API to explore a collection's metadata and statistics (sales, volume, etc).
 * @deprecated
 * @summary Collections
 */
export const getCollectionsV6 = (
    params?: GetCollectionsV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsV6Response>(
      {url: `/collections/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsV6QueryKey = (params?: GetCollectionsV6Params,) => {
    return [`/collections/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV6>>, GetCollectionsV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV6>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV6>>, QueryKey, GetCollectionsV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV6>>, QueryKey, GetCollectionsV6Params['continuation']> = ({ signal, pageParam }) => getCollectionsV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV6>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV6>>, QueryKey, GetCollectionsV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV6>>>
export type GetCollectionsV6InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collections
 */
export const useGetCollectionsV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV6>>, GetCollectionsV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV6>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV6>>, QueryKey, GetCollectionsV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsV6QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsV6>>, TError = ErrorType<unknown>>(params?: GetCollectionsV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV6>>> = ({ signal }) => getCollectionsV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsV6QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV6>>>
export type GetCollectionsV6QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collections
 */
export const useGetCollectionsV6 = <TData = Awaited<ReturnType<typeof getCollectionsV6>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Use this API to explore a collection's metadata and statistics (sales, volume, etc).
 * @summary Collections
 */
export const getCollectionsV7 = (
    params?: GetCollectionsV7Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsV7Response>(
      {url: `/collections/v7`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsV7QueryKey = (params?: GetCollectionsV7Params,) => {
    return [`/collections/v7`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsV7InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV7>>, GetCollectionsV7Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV7>>, QueryKey, GetCollectionsV7Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV7QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV7>>, QueryKey, GetCollectionsV7Params['continuation']> = ({ signal, pageParam }) => getCollectionsV7({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV7>>, QueryKey, GetCollectionsV7Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsV7InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV7>>>
export type GetCollectionsV7InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collections
 */
export const useGetCollectionsV7Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsV7>>, GetCollectionsV7Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData, Awaited<ReturnType<typeof getCollectionsV7>>, QueryKey, GetCollectionsV7Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV7InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsV7QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsV7>>, TError = ErrorType<unknown>>(params?: GetCollectionsV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsV7QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsV7>>> = ({ signal }) => getCollectionsV7(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsV7QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsV7>>>
export type GetCollectionsV7QueryError = ErrorType<unknown>

/**
 * @summary Collections
 */
export const useGetCollectionsV7 = <TData = Awaited<ReturnType<typeof getCollectionsV7>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsV7>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsV7QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to check the status of cross posted listings and bids.

 Input your `crossPostingOrderId` into the `ids` param and submit for the status. 

 The `crossPostingOrderId` is returned in the `execute/bids` and `execute/asks` response as well as the `onProgess` callback for the SDK. 

 Note: ReservoirKit does not return a `crossPostingOrderId`.
 * @summary Check cross posting status
 */
export const getCrosspostingordersV1 = (
    params?: GetCrosspostingordersV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCrossPostingOrdersV1Response>(
      {url: `/cross-posting-orders/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCrosspostingordersV1QueryKey = (params?: GetCrosspostingordersV1Params,) => {
    return [`/cross-posting-orders/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCrosspostingordersV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCrosspostingordersV1>>, GetCrosspostingordersV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCrosspostingordersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData, Awaited<ReturnType<typeof getCrosspostingordersV1>>, QueryKey, GetCrosspostingordersV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCrosspostingordersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCrosspostingordersV1>>, QueryKey, GetCrosspostingordersV1Params['continuation']> = ({ signal, pageParam }) => getCrosspostingordersV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData, Awaited<ReturnType<typeof getCrosspostingordersV1>>, QueryKey, GetCrosspostingordersV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCrosspostingordersV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCrosspostingordersV1>>>
export type GetCrosspostingordersV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Check cross posting status
 */
export const useGetCrosspostingordersV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCrosspostingordersV1>>, GetCrosspostingordersV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCrosspostingordersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData, Awaited<ReturnType<typeof getCrosspostingordersV1>>, QueryKey, GetCrosspostingordersV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCrosspostingordersV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCrosspostingordersV1QueryOptions = <TData = Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError = ErrorType<unknown>>(params?: GetCrosspostingordersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCrosspostingordersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCrosspostingordersV1>>> = ({ signal }) => getCrosspostingordersV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCrosspostingordersV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCrosspostingordersV1>>>
export type GetCrosspostingordersV1QueryError = ErrorType<unknown>

/**
 * @summary Check cross posting status
 */
export const useGetCrosspostingordersV1 = <TData = Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError = ErrorType<unknown>>(
 params?: GetCrosspostingordersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCrosspostingordersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCrosspostingordersV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Access orders with various filters applied. If you need orders created by a single user, use the positions API instead.
 * @deprecated
 * @summary List of valid orders.
 */
export const getOrdersV1 = (
    params?: GetOrdersV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersV1Response>(
      {url: `/orders/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersV1QueryKey = (params?: GetOrdersV1Params,) => {
    return [`/orders/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersV1>>, GetOrdersV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersV1>>, QueryKey, GetOrdersV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersV1>>, QueryKey, GetOrdersV1Params['continuation']> = ({ signal, pageParam }) => getOrdersV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersV1>>, QueryKey, GetOrdersV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersV1>>>
export type GetOrdersV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of valid orders.
 */
export const useGetOrdersV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersV1>>, GetOrdersV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersV1>>, QueryKey, GetOrdersV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersV1QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersV1>>, TError = ErrorType<unknown>>(params?: GetOrdersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersV1>>> = ({ signal }) => getOrdersV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersV1>>>
export type GetOrdersV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of valid orders.
 */
export const useGetOrdersV1 = <TData = Awaited<ReturnType<typeof getOrdersV1>>, TError = ErrorType<unknown>>(
 params?: GetOrdersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary Submit order batch
 */
export const postOrdersV1 = (
    model434: BodyType<Model434>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/orders/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model434
    },
      options);
    }
  


export const getPostOrdersV1MutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersV1>>, TError,{data: BodyType<Model434>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrdersV1>>, TError,{data: BodyType<Model434>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrdersV1>>, {data: BodyType<Model434>}> = (props) => {
          const {data} = props ?? {};

          return  postOrdersV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrdersV1MutationResult = NonNullable<Awaited<ReturnType<typeof postOrdersV1>>>
    export type PostOrdersV1MutationBody = BodyType<Model434>
    export type PostOrdersV1MutationError = ErrorType<string>

    /**
 * @deprecated
 * @summary Submit order batch
 */
export const usePostOrdersV1 = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersV1>>, TError,{data: BodyType<Model434>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrdersV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Access orders with various filters applied. If you need orders created by a single user, use the positions API instead.
 * @deprecated
 * @summary Submit order batch
 */
export const getOrdersV2 = (
    params?: GetOrdersV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersV2Response>(
      {url: `/orders/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersV2QueryKey = (params?: GetOrdersV2Params,) => {
    return [`/orders/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersV2>>, GetOrdersV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersV2>>, QueryKey, GetOrdersV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersV2>>, QueryKey, GetOrdersV2Params['continuation']> = ({ signal, pageParam }) => getOrdersV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersV2>>, QueryKey, GetOrdersV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersV2>>>
export type GetOrdersV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Submit order batch
 */
export const useGetOrdersV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersV2>>, GetOrdersV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersV2>>, QueryKey, GetOrdersV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersV2QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersV2>>, TError = ErrorType<unknown>>(params?: GetOrdersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersV2>>> = ({ signal }) => getOrdersV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersV2>>>
export type GetOrdersV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Submit order batch
 */
export const useGetOrdersV2 = <TData = Awaited<ReturnType<typeof getOrdersV2>>, TError = ErrorType<unknown>>(
 params?: GetOrdersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get owners with various filters applied, and a summary of their ownership. Useful for exploring top owners in a collection or attribute.
 * @summary Owners
 */
export const getOwnersV1 = (
    params?: GetOwnersV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOwnersV1Response>(
      {url: `/owners/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOwnersV1QueryKey = (params?: GetOwnersV1Params,) => {
    return [`/owners/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOwnersV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersV1>>, GetOwnersV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOwnersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersV1>>, QueryKey, GetOwnersV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersV1>>, QueryKey, GetOwnersV1Params['continuation']> = ({ signal, pageParam }) => getOwnersV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersV1>>, QueryKey, GetOwnersV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOwnersV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersV1>>>
export type GetOwnersV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners
 */
export const useGetOwnersV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersV1>>, GetOwnersV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOwnersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersV1>>, QueryKey, GetOwnersV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOwnersV1QueryOptions = <TData = Awaited<ReturnType<typeof getOwnersV1>>, TError = ErrorType<unknown>>(params?: GetOwnersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersV1>>> = ({ signal }) => getOwnersV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnersV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnersV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersV1>>>
export type GetOwnersV1QueryError = ErrorType<unknown>

/**
 * @summary Owners
 */
export const useGetOwnersV1 = <TData = Awaited<ReturnType<typeof getOwnersV1>>, TError = ErrorType<unknown>>(
 params?: GetOwnersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get owners with various filters applied, and a summary of their ownership. Useful for exploring top owners in a collection or attribute.
 * @summary Owners
 */
export const getOwnersV2 = (
    params?: GetOwnersV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOwnersV2Response>(
      {url: `/owners/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOwnersV2QueryKey = (params?: GetOwnersV2Params,) => {
    return [`/owners/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOwnersV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersV2>>, GetOwnersV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOwnersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData, Awaited<ReturnType<typeof getOwnersV2>>, QueryKey, GetOwnersV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersV2>>, QueryKey, GetOwnersV2Params['continuation']> = ({ signal, pageParam }) => getOwnersV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData, Awaited<ReturnType<typeof getOwnersV2>>, QueryKey, GetOwnersV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOwnersV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersV2>>>
export type GetOwnersV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners
 */
export const useGetOwnersV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersV2>>, GetOwnersV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOwnersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData, Awaited<ReturnType<typeof getOwnersV2>>, QueryKey, GetOwnersV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOwnersV2QueryOptions = <TData = Awaited<ReturnType<typeof getOwnersV2>>, TError = ErrorType<unknown>>(params?: GetOwnersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersV2>>> = ({ signal }) => getOwnersV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnersV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersV2>>>
export type GetOwnersV2QueryError = ErrorType<unknown>

/**
 * @summary Owners
 */
export const useGetOwnersV2 = <TData = Awaited<ReturnType<typeof getOwnersV2>>, TError = ErrorType<unknown>>(
 params?: GetOwnersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent sales for a contract or token.
 * @deprecated
 * @summary Historical sales
 */
export const getSalesV1 = (
    params?: GetSalesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSalesV1Response>(
      {url: `/sales/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSalesV1QueryKey = (params?: GetSalesV1Params,) => {
    return [`/sales/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSalesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV1>>, GetSalesV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSalesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV1>>, TError, TData, Awaited<ReturnType<typeof getSalesV1>>, QueryKey, GetSalesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV1>>, QueryKey, GetSalesV1Params['continuation']> = ({ signal, pageParam }) => getSalesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV1>>, TError, TData, Awaited<ReturnType<typeof getSalesV1>>, QueryKey, GetSalesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSalesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV1>>>
export type GetSalesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Historical sales
 */
export const useGetSalesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV1>>, GetSalesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSalesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV1>>, TError, TData, Awaited<ReturnType<typeof getSalesV1>>, QueryKey, GetSalesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSalesV1QueryOptions = <TData = Awaited<ReturnType<typeof getSalesV1>>, TError = ErrorType<unknown>>(params?: GetSalesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV1>>> = ({ signal }) => getSalesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSalesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSalesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV1>>>
export type GetSalesV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Historical sales
 */
export const useGetSalesV1 = <TData = Awaited<ReturnType<typeof getSalesV1>>, TError = ErrorType<unknown>>(
 params?: GetSalesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent sales for a contract or token.
 * @deprecated
 * @summary Historical sales
 */
export const getSalesV2 = (
    params?: GetSalesV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSalesV2Response>(
      {url: `/sales/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSalesV2QueryKey = (params?: GetSalesV2Params,) => {
    return [`/sales/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSalesV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV2>>, GetSalesV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSalesV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV2>>, TError, TData, Awaited<ReturnType<typeof getSalesV2>>, QueryKey, GetSalesV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV2>>, QueryKey, GetSalesV2Params['continuation']> = ({ signal, pageParam }) => getSalesV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV2>>, TError, TData, Awaited<ReturnType<typeof getSalesV2>>, QueryKey, GetSalesV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetSalesV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV2>>>
export type GetSalesV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Historical sales
 */
export const useGetSalesV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV2>>, GetSalesV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSalesV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV2>>, TError, TData, Awaited<ReturnType<typeof getSalesV2>>, QueryKey, GetSalesV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSalesV2QueryOptions = <TData = Awaited<ReturnType<typeof getSalesV2>>, TError = ErrorType<unknown>>(params?: GetSalesV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV2>>> = ({ signal }) => getSalesV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSalesV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSalesV2QueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV2>>>
export type GetSalesV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Historical sales
 */
export const useGetSalesV2 = <TData = Awaited<ReturnType<typeof getSalesV2>>, TError = ErrorType<unknown>>(
 params?: GetSalesV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent sales for a contract or token. Note: this API is returns rich metadata, and has advanced filters, so is only designed for small amounts of recent sales. If you want access to sales in bulk, use the `Aggregator > Bulk Sales` API.
 * @deprecated
 * @summary Historical sales
 */
export const getSalesV3 = (
    params?: GetSalesV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSalesV3Response>(
      {url: `/sales/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSalesV3QueryKey = (params?: GetSalesV3Params,) => {
    return [`/sales/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSalesV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV3>>, GetSalesV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSalesV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV3>>, TError, TData, Awaited<ReturnType<typeof getSalesV3>>, QueryKey, GetSalesV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV3>>, QueryKey, GetSalesV3Params['continuation']> = ({ signal, pageParam }) => getSalesV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV3>>, TError, TData, Awaited<ReturnType<typeof getSalesV3>>, QueryKey, GetSalesV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetSalesV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV3>>>
export type GetSalesV3InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Historical sales
 */
export const useGetSalesV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV3>>, GetSalesV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSalesV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV3>>, TError, TData, Awaited<ReturnType<typeof getSalesV3>>, QueryKey, GetSalesV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSalesV3QueryOptions = <TData = Awaited<ReturnType<typeof getSalesV3>>, TError = ErrorType<unknown>>(params?: GetSalesV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV3>>> = ({ signal }) => getSalesV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSalesV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSalesV3QueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV3>>>
export type GetSalesV3QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Historical sales
 */
export const useGetSalesV3 = <TData = Awaited<ReturnType<typeof getSalesV3>>, TError = ErrorType<unknown>>(
 params?: GetSalesV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent sales for a contract or token.
 * @deprecated
 * @summary Sales
 */
export const getSalesV4 = (
    params?: GetSalesV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSalesV4Response>(
      {url: `/sales/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSalesV4QueryKey = (params?: GetSalesV4Params,) => {
    return [`/sales/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSalesV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV4>>, GetSalesV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSalesV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV4>>, TError, TData, Awaited<ReturnType<typeof getSalesV4>>, QueryKey, GetSalesV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV4>>, QueryKey, GetSalesV4Params['continuation']> = ({ signal, pageParam }) => getSalesV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV4>>, TError, TData, Awaited<ReturnType<typeof getSalesV4>>, QueryKey, GetSalesV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetSalesV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV4>>>
export type GetSalesV4InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Sales
 */
export const useGetSalesV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV4>>, GetSalesV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSalesV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV4>>, TError, TData, Awaited<ReturnType<typeof getSalesV4>>, QueryKey, GetSalesV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSalesV4QueryOptions = <TData = Awaited<ReturnType<typeof getSalesV4>>, TError = ErrorType<unknown>>(params?: GetSalesV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV4>>> = ({ signal }) => getSalesV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSalesV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSalesV4QueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV4>>>
export type GetSalesV4QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Sales
 */
export const useGetSalesV4 = <TData = Awaited<ReturnType<typeof getSalesV4>>, TError = ErrorType<unknown>>(
 params?: GetSalesV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent sales for a contract or token. Paid mints are returned in this `sales` endpoint, free mints can be found in the `/activities/` endpoints. Array of contracts max limit is 20.
 * @deprecated
 * @summary Sales
 */
export const getSalesV5 = (
    params?: GetSalesV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSalesV4Response>(
      {url: `/sales/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSalesV5QueryKey = (params?: GetSalesV5Params,) => {
    return [`/sales/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSalesV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV5>>, GetSalesV5Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSalesV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV5>>, TError, TData, Awaited<ReturnType<typeof getSalesV5>>, QueryKey, GetSalesV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV5>>, QueryKey, GetSalesV5Params['continuation']> = ({ signal, pageParam }) => getSalesV5({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV5>>, TError, TData, Awaited<ReturnType<typeof getSalesV5>>, QueryKey, GetSalesV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetSalesV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV5>>>
export type GetSalesV5InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Sales
 */
export const useGetSalesV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV5>>, GetSalesV5Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSalesV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV5>>, TError, TData, Awaited<ReturnType<typeof getSalesV5>>, QueryKey, GetSalesV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV5InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSalesV5QueryOptions = <TData = Awaited<ReturnType<typeof getSalesV5>>, TError = ErrorType<unknown>>(params?: GetSalesV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV5>>> = ({ signal }) => getSalesV5(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSalesV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSalesV5QueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV5>>>
export type GetSalesV5QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Sales
 */
export const useGetSalesV5 = <TData = Awaited<ReturnType<typeof getSalesV5>>, TError = ErrorType<unknown>>(
 params?: GetSalesV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV5QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent sales for a contract or token. Paid mints are returned in this `sales` endpoint, free mints can be found in the `/activities/` endpoints. Array of contracts max limit is 20.
 * @summary Sales
 */
export const getSalesV6 = (
    params?: GetSalesV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSalesV4Response>(
      {url: `/sales/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSalesV6QueryKey = (params?: GetSalesV6Params,) => {
    return [`/sales/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSalesV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV6>>, GetSalesV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSalesV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData, Awaited<ReturnType<typeof getSalesV6>>, QueryKey, GetSalesV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV6>>, QueryKey, GetSalesV6Params['continuation']> = ({ signal, pageParam }) => getSalesV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData, Awaited<ReturnType<typeof getSalesV6>>, QueryKey, GetSalesV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetSalesV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV6>>>
export type GetSalesV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Sales
 */
export const useGetSalesV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesV6>>, GetSalesV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSalesV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData, Awaited<ReturnType<typeof getSalesV6>>, QueryKey, GetSalesV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSalesV6QueryOptions = <TData = Awaited<ReturnType<typeof getSalesV6>>, TError = ErrorType<unknown>>(params?: GetSalesV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesV6>>> = ({ signal }) => getSalesV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSalesV6QueryResult = NonNullable<Awaited<ReturnType<typeof getSalesV6>>>
export type GetSalesV6QueryError = ErrorType<unknown>

/**
 * @summary Sales
 */
export const useGetSalesV6 = <TData = Awaited<ReturnType<typeof getSalesV6>>, TError = ErrorType<unknown>>(
 params?: GetSalesV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API returns a list of sources
 * @summary Sources List
 */
export const getSourcesV1 = (
    params?: GetSourcesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSourcesV1Response>(
      {url: `/sources/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSourcesV1QueryKey = (params?: GetSourcesV1Params,) => {
    return [`/sources/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSourcesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSourcesV1>>, GetSourcesV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSourcesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData, Awaited<ReturnType<typeof getSourcesV1>>, QueryKey, GetSourcesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSourcesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSourcesV1>>, QueryKey, GetSourcesV1Params['continuation']> = ({ signal, pageParam }) => getSourcesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData, Awaited<ReturnType<typeof getSourcesV1>>, QueryKey, GetSourcesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSourcesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSourcesV1>>>
export type GetSourcesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Sources List
 */
export const useGetSourcesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSourcesV1>>, GetSourcesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSourcesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData, Awaited<ReturnType<typeof getSourcesV1>>, QueryKey, GetSourcesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSourcesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSourcesV1QueryOptions = <TData = Awaited<ReturnType<typeof getSourcesV1>>, TError = ErrorType<unknown>>(params?: GetSourcesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSourcesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSourcesV1>>> = ({ signal }) => getSourcesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSourcesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSourcesV1>>>
export type GetSourcesV1QueryError = ErrorType<unknown>

/**
 * @summary Sources List
 */
export const useGetSourcesV1 = <TData = Awaited<ReturnType<typeof getSourcesV1>>, TError = ErrorType<unknown>>(
 params?: GetSourcesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSourcesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSourcesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get aggregate stats for a particular set (collection, attribute or single token)
 * @deprecated
 * @summary Stats
 */
export const getStatsV1 = (
    params?: GetStatsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetStatsV1Response>(
      {url: `/stats/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetStatsV1QueryKey = (params?: GetStatsV1Params,) => {
    return [`/stats/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetStatsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getStatsV1>>, GetStatsV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetStatsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStatsV1>>, TError, TData, Awaited<ReturnType<typeof getStatsV1>>, QueryKey, GetStatsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStatsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatsV1>>, QueryKey, GetStatsV1Params['continuation']> = ({ signal, pageParam }) => getStatsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStatsV1>>, TError, TData, Awaited<ReturnType<typeof getStatsV1>>, QueryKey, GetStatsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetStatsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getStatsV1>>>
export type GetStatsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Stats
 */
export const useGetStatsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getStatsV1>>, GetStatsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetStatsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStatsV1>>, TError, TData, Awaited<ReturnType<typeof getStatsV1>>, QueryKey, GetStatsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStatsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetStatsV1QueryOptions = <TData = Awaited<ReturnType<typeof getStatsV1>>, TError = ErrorType<unknown>>(params?: GetStatsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStatsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatsV1>>> = ({ signal }) => getStatsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStatsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStatsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getStatsV1>>>
export type GetStatsV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Stats
 */
export const useGetStatsV1 = <TData = Awaited<ReturnType<typeof getStatsV1>>, TError = ErrorType<unknown>>(
 params?: GetStatsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStatsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get aggregate stats for a particular set (collection, attribute or single token)
 * @summary Stats
 */
export const getStatsV2 = (
    params?: GetStatsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetStatsV2Response>(
      {url: `/stats/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetStatsV2QueryKey = (params?: GetStatsV2Params,) => {
    return [`/stats/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetStatsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getStatsV2>>, GetStatsV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetStatsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData, Awaited<ReturnType<typeof getStatsV2>>, QueryKey, GetStatsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStatsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatsV2>>, QueryKey, GetStatsV2Params['continuation']> = ({ signal, pageParam }) => getStatsV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData, Awaited<ReturnType<typeof getStatsV2>>, QueryKey, GetStatsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetStatsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getStatsV2>>>
export type GetStatsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Stats
 */
export const useGetStatsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getStatsV2>>, GetStatsV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetStatsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData, Awaited<ReturnType<typeof getStatsV2>>, QueryKey, GetStatsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStatsV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetStatsV2QueryOptions = <TData = Awaited<ReturnType<typeof getStatsV2>>, TError = ErrorType<unknown>>(params?: GetStatsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStatsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStatsV2>>> = ({ signal }) => getStatsV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStatsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getStatsV2>>>
export type GetStatsV2QueryError = ErrorType<unknown>

/**
 * @summary Stats
 */
export const useGetStatsV2 = <TData = Awaited<ReturnType<typeof getStatsV2>>, TError = ErrorType<unknown>>(
 params?: GetStatsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStatsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStatsV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is optimized for quickly fetching a list of tokens in a collection, sorted by price, with only the most important information returned. If you need more metadata, use the `tokens/details` API
 * @deprecated
 * @summary List of tokens
 */
export const getTokensV1 = (
    params?: GetTokensV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensV1Response>(
      {url: `/tokens/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensV1QueryKey = (params?: GetTokensV1Params,) => {
    return [`/tokens/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV1>>, GetTokensV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV1>>, TError, TData, Awaited<ReturnType<typeof getTokensV1>>, QueryKey, GetTokensV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV1>>, QueryKey, GetTokensV1Params['continuation']> = ({ signal, pageParam }) => getTokensV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV1>>, TError, TData, Awaited<ReturnType<typeof getTokensV1>>, QueryKey, GetTokensV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV1>>>
export type GetTokensV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of tokens
 */
export const useGetTokensV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV1>>, GetTokensV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV1>>, TError, TData, Awaited<ReturnType<typeof getTokensV1>>, QueryKey, GetTokensV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensV1>>, TError = ErrorType<unknown>>(params?: GetTokensV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV1>>> = ({ signal }) => getTokensV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV1>>>
export type GetTokensV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of tokens
 */
export const useGetTokensV1 = <TData = Awaited<ReturnType<typeof getTokensV1>>, TError = ErrorType<unknown>>(
 params?: GetTokensV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is optimized for quickly fetching a list of tokens in a collection, sorted by price, with only the most important information returned. If you need more metadata, use the `tokens/details` API
 * @deprecated
 * @summary List of tokens, with basic details, optimized for speed
 */
export const getTokensV2 = (
    params?: GetTokensV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensV2Response>(
      {url: `/tokens/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensV2QueryKey = (params?: GetTokensV2Params,) => {
    return [`/tokens/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV2>>, GetTokensV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV2>>, TError, TData, Awaited<ReturnType<typeof getTokensV2>>, QueryKey, GetTokensV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV2>>, QueryKey, GetTokensV2Params['continuation']> = ({ signal, pageParam }) => getTokensV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV2>>, TError, TData, Awaited<ReturnType<typeof getTokensV2>>, QueryKey, GetTokensV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV2>>>
export type GetTokensV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of tokens, with basic details, optimized for speed
 */
export const useGetTokensV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV2>>, GetTokensV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV2>>, TError, TData, Awaited<ReturnType<typeof getTokensV2>>, QueryKey, GetTokensV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensV2QueryOptions = <TData = Awaited<ReturnType<typeof getTokensV2>>, TError = ErrorType<unknown>>(params?: GetTokensV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV2>>> = ({ signal }) => getTokensV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV2>>>
export type GetTokensV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of tokens, with basic details, optimized for speed
 */
export const useGetTokensV2 = <TData = Awaited<ReturnType<typeof getTokensV2>>, TError = ErrorType<unknown>>(
 params?: GetTokensV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is optimized for quickly fetching a list of tokens in a collection, sorted by price, with only the most important information returned. If you need more metadata, use the `tokens/details` API
 * @deprecated
 * @summary List of tokens, with basic details, optimized for speed
 */
export const getTokensV3 = (
    params?: GetTokensV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensV3Response>(
      {url: `/tokens/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensV3QueryKey = (params?: GetTokensV3Params,) => {
    return [`/tokens/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV3>>, GetTokensV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV3>>, TError, TData, Awaited<ReturnType<typeof getTokensV3>>, QueryKey, GetTokensV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV3>>, QueryKey, GetTokensV3Params['continuation']> = ({ signal, pageParam }) => getTokensV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV3>>, TError, TData, Awaited<ReturnType<typeof getTokensV3>>, QueryKey, GetTokensV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV3>>>
export type GetTokensV3InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of tokens, with basic details, optimized for speed
 */
export const useGetTokensV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV3>>, GetTokensV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV3>>, TError, TData, Awaited<ReturnType<typeof getTokensV3>>, QueryKey, GetTokensV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensV3QueryOptions = <TData = Awaited<ReturnType<typeof getTokensV3>>, TError = ErrorType<unknown>>(params?: GetTokensV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV3>>> = ({ signal }) => getTokensV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensV3QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV3>>>
export type GetTokensV3QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary List of tokens, with basic details, optimized for speed
 */
export const useGetTokensV3 = <TData = Awaited<ReturnType<typeof getTokensV3>>, TError = ErrorType<unknown>>(
 params?: GetTokensV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is optimized for quickly fetching a list of tokens in a collection, sorted by price, with only the most important information returned. If you need more metadata, use the tokens/details API
 * @deprecated
 * @summary Tokens
 */
export const getTokensV4 = (
    params?: GetTokensV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensV4Response>(
      {url: `/tokens/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensV4QueryKey = (params?: GetTokensV4Params,) => {
    return [`/tokens/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV4>>, GetTokensV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV4>>, TError, TData, Awaited<ReturnType<typeof getTokensV4>>, QueryKey, GetTokensV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV4>>, QueryKey, GetTokensV4Params['continuation']> = ({ signal, pageParam }) => getTokensV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV4>>, TError, TData, Awaited<ReturnType<typeof getTokensV4>>, QueryKey, GetTokensV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV4>>>
export type GetTokensV4InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Tokens
 */
export const useGetTokensV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV4>>, GetTokensV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV4>>, TError, TData, Awaited<ReturnType<typeof getTokensV4>>, QueryKey, GetTokensV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensV4QueryOptions = <TData = Awaited<ReturnType<typeof getTokensV4>>, TError = ErrorType<unknown>>(params?: GetTokensV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV4>>> = ({ signal }) => getTokensV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensV4QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV4>>>
export type GetTokensV4QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Tokens
 */
export const useGetTokensV4 = <TData = Awaited<ReturnType<typeof getTokensV4>>, TError = ErrorType<unknown>>(
 params?: GetTokensV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of tokens with full metadata. This is useful for showing a single token page, or scenarios that require more metadata.
 * @summary Tokens
 */
export const getTokensV5 = (
    params?: GetTokensV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensV5Response>(
      {url: `/tokens/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensV5QueryKey = (params?: GetTokensV5Params,) => {
    return [`/tokens/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV5>>, GetTokensV5Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV5>>, TError, TData, Awaited<ReturnType<typeof getTokensV5>>, QueryKey, GetTokensV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV5>>, QueryKey, GetTokensV5Params['continuation']> = ({ signal, pageParam }) => getTokensV5({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV5>>, TError, TData, Awaited<ReturnType<typeof getTokensV5>>, QueryKey, GetTokensV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV5>>>
export type GetTokensV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Tokens
 */
export const useGetTokensV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV5>>, GetTokensV5Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV5>>, TError, TData, Awaited<ReturnType<typeof getTokensV5>>, QueryKey, GetTokensV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV5InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensV5QueryOptions = <TData = Awaited<ReturnType<typeof getTokensV5>>, TError = ErrorType<unknown>>(params?: GetTokensV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV5>>> = ({ signal }) => getTokensV5(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensV5QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV5>>>
export type GetTokensV5QueryError = ErrorType<unknown>

/**
 * @summary Tokens
 */
export const useGetTokensV5 = <TData = Awaited<ReturnType<typeof getTokensV5>>, TError = ErrorType<unknown>>(
 params?: GetTokensV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV5QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of tokens with full metadata. This is useful for showing a single token page, or scenarios that require more metadata.
 * @summary Tokens
 */
export const getTokensV6 = (
    params?: GetTokensV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensV6Response>(
      {url: `/tokens/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensV6QueryKey = (params?: GetTokensV6Params,) => {
    return [`/tokens/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV6>>, GetTokensV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData, Awaited<ReturnType<typeof getTokensV6>>, QueryKey, GetTokensV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV6>>, QueryKey, GetTokensV6Params['continuation']> = ({ signal, pageParam }) => getTokensV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData, Awaited<ReturnType<typeof getTokensV6>>, QueryKey, GetTokensV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV6>>>
export type GetTokensV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Tokens
 */
export const useGetTokensV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV6>>, GetTokensV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData, Awaited<ReturnType<typeof getTokensV6>>, QueryKey, GetTokensV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensV6QueryOptions = <TData = Awaited<ReturnType<typeof getTokensV6>>, TError = ErrorType<unknown>>(params?: GetTokensV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV6>>> = ({ signal }) => getTokensV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensV6QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV6>>>
export type GetTokensV6QueryError = ErrorType<unknown>

/**
 * @summary Tokens
 */
export const useGetTokensV6 = <TData = Awaited<ReturnType<typeof getTokensV6>>, TError = ErrorType<unknown>>(
 params?: GetTokensV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of tokens with full metadata. This is useful for showing a single token page, or scenarios that require more metadata.
 * @deprecated
 * @summary Tokens
 */
export const getTokensV7 = (
    params?: GetTokensV7Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensV7Response>(
      {url: `/tokens/v7`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensV7QueryKey = (params?: GetTokensV7Params,) => {
    return [`/tokens/v7`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensV7InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV7>>, GetTokensV7Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV7>>, TError, TData, Awaited<ReturnType<typeof getTokensV7>>, QueryKey, GetTokensV7Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV7QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV7>>, QueryKey, GetTokensV7Params['continuation']> = ({ signal, pageParam }) => getTokensV7({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV7>>, TError, TData, Awaited<ReturnType<typeof getTokensV7>>, QueryKey, GetTokensV7Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensV7InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV7>>>
export type GetTokensV7InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Tokens
 */
export const useGetTokensV7Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensV7>>, GetTokensV7Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensV7>>, TError, TData, Awaited<ReturnType<typeof getTokensV7>>, QueryKey, GetTokensV7Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV7InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensV7QueryOptions = <TData = Awaited<ReturnType<typeof getTokensV7>>, TError = ErrorType<unknown>>(params?: GetTokensV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV7>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensV7QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensV7>>> = ({ signal }) => getTokensV7(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensV7>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensV7QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensV7>>>
export type GetTokensV7QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Tokens
 */
export const useGetTokensV7 = <TData = Awaited<ReturnType<typeof getTokensV7>>, TError = ErrorType<unknown>>(
 params?: GetTokensV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensV7>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensV7QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent transfers for a contract or token.
 * @summary Historical token transfers
 */
export const getTransfersV2 = (
    params?: GetTransfersV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTransfersV2Response>(
      {url: `/transfers/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTransfersV2QueryKey = (params?: GetTransfersV2Params,) => {
    return [`/transfers/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTransfersV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersV2>>, GetTransfersV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTransfersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV2>>, TError, TData, Awaited<ReturnType<typeof getTransfersV2>>, QueryKey, GetTransfersV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersV2>>, QueryKey, GetTransfersV2Params['continuation']> = ({ signal, pageParam }) => getTransfersV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV2>>, TError, TData, Awaited<ReturnType<typeof getTransfersV2>>, QueryKey, GetTransfersV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetTransfersV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersV2>>>
export type GetTransfersV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Historical token transfers
 */
export const useGetTransfersV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersV2>>, GetTransfersV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTransfersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV2>>, TError, TData, Awaited<ReturnType<typeof getTransfersV2>>, QueryKey, GetTransfersV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTransfersV2QueryOptions = <TData = Awaited<ReturnType<typeof getTransfersV2>>, TError = ErrorType<unknown>>(params?: GetTransfersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersV2>>> = ({ signal }) => getTransfersV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransfersV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransfersV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersV2>>>
export type GetTransfersV2QueryError = ErrorType<unknown>

/**
 * @summary Historical token transfers
 */
export const useGetTransfersV2 = <TData = Awaited<ReturnType<typeof getTransfersV2>>, TError = ErrorType<unknown>>(
 params?: GetTransfersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent transfers for a contract or token.
 * @deprecated
 * @summary Historical token transfers
 */
export const getTransfersV3 = (
    params?: GetTransfersV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTransfersV3Response>(
      {url: `/transfers/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTransfersV3QueryKey = (params?: GetTransfersV3Params,) => {
    return [`/transfers/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTransfersV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersV3>>, GetTransfersV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTransfersV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV3>>, TError, TData, Awaited<ReturnType<typeof getTransfersV3>>, QueryKey, GetTransfersV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersV3>>, QueryKey, GetTransfersV3Params['continuation']> = ({ signal, pageParam }) => getTransfersV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV3>>, TError, TData, Awaited<ReturnType<typeof getTransfersV3>>, QueryKey, GetTransfersV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetTransfersV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersV3>>>
export type GetTransfersV3InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Historical token transfers
 */
export const useGetTransfersV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersV3>>, GetTransfersV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTransfersV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV3>>, TError, TData, Awaited<ReturnType<typeof getTransfersV3>>, QueryKey, GetTransfersV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTransfersV3QueryOptions = <TData = Awaited<ReturnType<typeof getTransfersV3>>, TError = ErrorType<unknown>>(params?: GetTransfersV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersV3>>> = ({ signal }) => getTransfersV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransfersV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransfersV3QueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersV3>>>
export type GetTransfersV3QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Historical token transfers
 */
export const useGetTransfersV3 = <TData = Awaited<ReturnType<typeof getTransfersV3>>, TError = ErrorType<unknown>>(
 params?: GetTransfersV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get recent transfers for a contract or token.
 * @summary Historical token transfers
 */
export const getTransfersV4 = (
    params?: GetTransfersV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTransfersV3Response>(
      {url: `/transfers/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTransfersV4QueryKey = (params?: GetTransfersV4Params,) => {
    return [`/transfers/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTransfersV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersV4>>, GetTransfersV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTransfersV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData, Awaited<ReturnType<typeof getTransfersV4>>, QueryKey, GetTransfersV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersV4>>, QueryKey, GetTransfersV4Params['continuation']> = ({ signal, pageParam }) => getTransfersV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData, Awaited<ReturnType<typeof getTransfersV4>>, QueryKey, GetTransfersV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetTransfersV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersV4>>>
export type GetTransfersV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Historical token transfers
 */
export const useGetTransfersV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersV4>>, GetTransfersV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTransfersV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData, Awaited<ReturnType<typeof getTransfersV4>>, QueryKey, GetTransfersV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTransfersV4QueryOptions = <TData = Awaited<ReturnType<typeof getTransfersV4>>, TError = ErrorType<unknown>>(params?: GetTransfersV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersV4>>> = ({ signal }) => getTransfersV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransfersV4QueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersV4>>>
export type GetTransfersV4QueryError = ErrorType<unknown>

/**
 * @summary Historical token transfers
 */
export const useGetTransfersV4 = <TData = Awaited<ReturnType<typeof getTransfersV4>>, TError = ErrorType<unknown>>(
 params?: GetTransfersV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get the associated info for the given API key
 */
export const getAdminGetapikeyKey = (
    key: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetApiKeyRateLimitsResponse>(
      {url: `/admin/get-api-key/${key}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAdminGetapikeyKeyQueryKey = (key: string,) => {
    return [`/admin/get-api-key/${key}`] as const;
    }

    
export const getGetAdminGetapikeyKeyInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminGetapikeyKey>>>, TError = ErrorType<unknown>>(key: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminGetapikeyKeyQueryKey(key);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGetapikeyKey>>> = ({ signal }) => getAdminGetapikeyKey(key, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(key),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminGetapikeyKeyInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGetapikeyKey>>>
export type GetAdminGetapikeyKeyInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get the associated info for the given API key
 */
export const useGetAdminGetapikeyKeyInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminGetapikeyKey>>>, TError = ErrorType<unknown>>(
 key: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminGetapikeyKeyInfiniteQueryOptions(key,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminGetapikeyKeyQueryOptions = <TData = Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError = ErrorType<unknown>>(key: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminGetapikeyKeyQueryKey(key);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGetapikeyKey>>> = ({ signal }) => getAdminGetapikeyKey(key, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(key),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminGetapikeyKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGetapikeyKey>>>
export type GetAdminGetapikeyKeyQueryError = ErrorType<unknown>

/**
 * @summary Get the associated info for the given API key
 */
export const useGetAdminGetapikeyKey = <TData = Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError = ErrorType<unknown>>(
 key: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminGetapikeyKey>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminGetapikeyKeyQueryOptions(key,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Get metadata for a token or collection
 */
export const getAdminProvidermetadataType = (
    type: 'token' | 'collection',
    params: GetAdminProvidermetadataTypeParams,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/admin/provider-metadata/${type}`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAdminProvidermetadataTypeQueryKey = (type: 'token' | 'collection',
    params: GetAdminProvidermetadataTypeParams,) => {
    return [`/admin/provider-metadata/${type}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAdminProvidermetadataTypeInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminProvidermetadataType>>, GetAdminProvidermetadataTypeParams['continuation']>, TError = ErrorType<string>>(type: 'token' | 'collection',
    params: GetAdminProvidermetadataTypeParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminProvidermetadataType>>, TError, TData, Awaited<ReturnType<typeof getAdminProvidermetadataType>>, QueryKey, GetAdminProvidermetadataTypeParams['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminProvidermetadataTypeQueryKey(type,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminProvidermetadataType>>, QueryKey, GetAdminProvidermetadataTypeParams['continuation']> = ({ signal, pageParam }) => getAdminProvidermetadataType(type,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(type),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminProvidermetadataType>>, TError, TData, Awaited<ReturnType<typeof getAdminProvidermetadataType>>, QueryKey, GetAdminProvidermetadataTypeParams['continuation']> & { queryKey: QueryKey }
}

export type GetAdminProvidermetadataTypeInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminProvidermetadataType>>>
export type GetAdminProvidermetadataTypeInfiniteQueryError = ErrorType<string>

/**
 * @summary Get metadata for a token or collection
 */
export const useGetAdminProvidermetadataTypeInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getAdminProvidermetadataType>>, GetAdminProvidermetadataTypeParams['continuation']>, TError = ErrorType<string>>(
 type: 'token' | 'collection',
    params: GetAdminProvidermetadataTypeParams, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getAdminProvidermetadataType>>, TError, TData, Awaited<ReturnType<typeof getAdminProvidermetadataType>>, QueryKey, GetAdminProvidermetadataTypeParams['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminProvidermetadataTypeInfiniteQueryOptions(type,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetAdminProvidermetadataTypeQueryOptions = <TData = Awaited<ReturnType<typeof getAdminProvidermetadataType>>, TError = ErrorType<string>>(type: 'token' | 'collection',
    params: GetAdminProvidermetadataTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminProvidermetadataType>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAdminProvidermetadataTypeQueryKey(type,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminProvidermetadataType>>> = ({ signal }) => getAdminProvidermetadataType(type,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(type),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAdminProvidermetadataType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAdminProvidermetadataTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminProvidermetadataType>>>
export type GetAdminProvidermetadataTypeQueryError = ErrorType<string>

/**
 * @summary Get metadata for a token or collection
 */
export const useGetAdminProvidermetadataType = <TData = Awaited<ReturnType<typeof getAdminProvidermetadataType>>, TError = ErrorType<string>>(
 type: 'token' | 'collection',
    params: GetAdminProvidermetadataTypeParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAdminProvidermetadataType>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAdminProvidermetadataTypeQueryOptions(type,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get the rate limits for the given API key. Note: API keys are not universal across all available chains; please make a different key for every chain.
 * @summary Get rate limits for the given API key
 */
export const getApikeysKeyRatelimits = (
    key: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<Model101>(
      {url: `/api-keys/${key}/rate-limits`, method: 'GET', signal
    },
      options);
    }
  

export const getGetApikeysKeyRatelimitsQueryKey = (key: string,) => {
    return [`/api-keys/${key}/rate-limits`] as const;
    }

    
export const getGetApikeysKeyRatelimitsInfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>>, TError = ErrorType<unknown>>(key: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApikeysKeyRatelimitsQueryKey(key);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>> = ({ signal }) => getApikeysKeyRatelimits(key, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(key),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApikeysKeyRatelimitsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>>
export type GetApikeysKeyRatelimitsInfiniteQueryError = ErrorType<unknown>

/**
 * @summary Get rate limits for the given API key
 */
export const useGetApikeysKeyRatelimitsInfinite = <TData = InfiniteData<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>>, TError = ErrorType<unknown>>(
 key: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApikeysKeyRatelimitsInfiniteQueryOptions(key,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetApikeysKeyRatelimitsQueryOptions = <TData = Awaited<ReturnType<typeof getApikeysKeyRatelimits>>, TError = ErrorType<unknown>>(key: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApikeysKeyRatelimitsQueryKey(key);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>> = ({ signal }) => getApikeysKeyRatelimits(key, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(key),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApikeysKeyRatelimitsQueryResult = NonNullable<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>>
export type GetApikeysKeyRatelimitsQueryError = ErrorType<unknown>

/**
 * @summary Get rate limits for the given API key
 */
export const useGetApikeysKeyRatelimits = <TData = Awaited<ReturnType<typeof getApikeysKeyRatelimits>>, TError = ErrorType<unknown>>(
 key: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApikeysKeyRatelimits>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApikeysKeyRatelimitsQueryOptions(key,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get chain mint and sales stats for 1 and 7 days
 * @summary Chain Stats
 */
export const getChainStatsV1 = (
    
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetChainStatsV5Response>(
      {url: `/chain/stats/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChainStatsV1QueryKey = () => {
    return [`/chain/stats/v1`] as const;
    }

    
export const getGetChainStatsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getChainStatsV1>>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChainStatsV1QueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChainStatsV1>>> = ({ signal }) => getChainStatsV1(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChainStatsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getChainStatsV1>>>
export type GetChainStatsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Chain Stats
 */
export const useGetChainStatsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getChainStatsV1>>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChainStatsV1InfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetChainStatsV1QueryOptions = <TData = Awaited<ReturnType<typeof getChainStatsV1>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChainStatsV1QueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChainStatsV1>>> = ({ signal }) => getChainStatsV1(requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChainStatsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getChainStatsV1>>>
export type GetChainStatsV1QueryError = ErrorType<unknown>

/**
 * @summary Chain Stats
 */
export const useGetChainStatsV1 = <TData = Awaited<ReturnType<typeof getChainStatsV1>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChainStatsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChainStatsV1QueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a collection
 * @summary Collection activity
 */
export const getCollectionsActivityV4 = (
    params?: GetCollectionsActivityV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionActivityV4Response>(
      {url: `/collections/activity/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsActivityV4QueryKey = (params?: GetCollectionsActivityV4Params,) => {
    return [`/collections/activity/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsActivityV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsActivityV4>>, GetCollectionsActivityV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsActivityV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV4>>, QueryKey, GetCollectionsActivityV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsActivityV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsActivityV4>>, QueryKey, GetCollectionsActivityV4Params['continuation']> = ({ signal, pageParam }) => getCollectionsActivityV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV4>>, QueryKey, GetCollectionsActivityV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsActivityV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsActivityV4>>>
export type GetCollectionsActivityV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsActivityV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsActivityV4>>, GetCollectionsActivityV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsActivityV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV4>>, QueryKey, GetCollectionsActivityV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsActivityV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsActivityV4QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsActivityV4>>, TError = ErrorType<unknown>>(params?: GetCollectionsActivityV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsActivityV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsActivityV4>>> = ({ signal }) => getCollectionsActivityV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsActivityV4QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsActivityV4>>>
export type GetCollectionsActivityV4QueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsActivityV4 = <TData = Awaited<ReturnType<typeof getCollectionsActivityV4>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsActivityV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsActivityV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a collection
 * @summary Collection activity
 */
export const getCollectionsActivityV5 = (
    params?: GetCollectionsActivityV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionActivityV5Response>(
      {url: `/collections/activity/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsActivityV5QueryKey = (params?: GetCollectionsActivityV5Params,) => {
    return [`/collections/activity/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsActivityV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsActivityV5>>, GetCollectionsActivityV5Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsActivityV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV5>>, QueryKey, GetCollectionsActivityV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsActivityV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsActivityV5>>, QueryKey, GetCollectionsActivityV5Params['continuation']> = ({ signal, pageParam }) => getCollectionsActivityV5({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV5>>, QueryKey, GetCollectionsActivityV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsActivityV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsActivityV5>>>
export type GetCollectionsActivityV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsActivityV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsActivityV5>>, GetCollectionsActivityV5Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsActivityV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV5>>, QueryKey, GetCollectionsActivityV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsActivityV5InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsActivityV5QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsActivityV5>>, TError = ErrorType<unknown>>(params?: GetCollectionsActivityV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsActivityV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsActivityV5>>> = ({ signal }) => getCollectionsActivityV5(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsActivityV5QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsActivityV5>>>
export type GetCollectionsActivityV5QueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsActivityV5 = <TData = Awaited<ReturnType<typeof getCollectionsActivityV5>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsActivityV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsActivityV5QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a collection including sales, asks, transfers, mints, bids, cancelled bids, and cancelled asks types.
 * @summary Collection activity
 */
export const getCollectionsActivityV6 = (
    params?: GetCollectionsActivityV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionActivityV6Response>(
      {url: `/collections/activity/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsActivityV6QueryKey = (params?: GetCollectionsActivityV6Params,) => {
    return [`/collections/activity/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsActivityV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsActivityV6>>, GetCollectionsActivityV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsActivityV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV6>>, QueryKey, GetCollectionsActivityV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsActivityV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsActivityV6>>, QueryKey, GetCollectionsActivityV6Params['continuation']> = ({ signal, pageParam }) => getCollectionsActivityV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV6>>, QueryKey, GetCollectionsActivityV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsActivityV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsActivityV6>>>
export type GetCollectionsActivityV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsActivityV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsActivityV6>>, GetCollectionsActivityV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsActivityV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData, Awaited<ReturnType<typeof getCollectionsActivityV6>>, QueryKey, GetCollectionsActivityV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsActivityV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsActivityV6QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError = ErrorType<unknown>>(params?: GetCollectionsActivityV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsActivityV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsActivityV6>>> = ({ signal }) => getCollectionsActivityV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsActivityV6QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsActivityV6>>>
export type GetCollectionsActivityV6QueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsActivityV6 = <TData = Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsActivityV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsActivityV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsActivityV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get date, volume, rank and sales count for each collection
 * @summary Daily collection volume
 */
export const getCollectionsDailyvolumesV1 = (
    params: GetCollectionsDailyvolumesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetDailyVolumesV1Response>(
      {url: `/collections/daily-volumes/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsDailyvolumesV1QueryKey = (params: GetCollectionsDailyvolumesV1Params,) => {
    return [`/collections/daily-volumes/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsDailyvolumesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, GetCollectionsDailyvolumesV1Params['continuation']>, TError = ErrorType<unknown>>(params: GetCollectionsDailyvolumesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, QueryKey, GetCollectionsDailyvolumesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsDailyvolumesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, QueryKey, GetCollectionsDailyvolumesV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsDailyvolumesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, QueryKey, GetCollectionsDailyvolumesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsDailyvolumesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>>
export type GetCollectionsDailyvolumesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Daily collection volume
 */
export const useGetCollectionsDailyvolumesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, GetCollectionsDailyvolumesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetCollectionsDailyvolumesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, QueryKey, GetCollectionsDailyvolumesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsDailyvolumesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsDailyvolumesV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError = ErrorType<unknown>>(params: GetCollectionsDailyvolumesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsDailyvolumesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>> = ({ signal }) => getCollectionsDailyvolumesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsDailyvolumesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>>
export type GetCollectionsDailyvolumesV1QueryError = ErrorType<unknown>

/**
 * @summary Daily collection volume
 */
export const useGetCollectionsDailyvolumesV1 = <TData = Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError = ErrorType<unknown>>(
 params: GetCollectionsDailyvolumesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsDailyvolumesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsDailyvolumesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get top selling and minting collections
 * @deprecated
 * @summary Top Selling Collections
 */
export const getCollectionsTopsellingV1 = (
    params?: GetCollectionsTopsellingV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTopSellingCollectionsV1Response>(
      {url: `/collections/top-selling/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsTopsellingV1QueryKey = (params?: GetCollectionsTopsellingV1Params,) => {
    return [`/collections/top-selling/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsTopsellingV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, GetCollectionsTopsellingV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsTopsellingV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, QueryKey, GetCollectionsTopsellingV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTopsellingV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, QueryKey, GetCollectionsTopsellingV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsTopsellingV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, QueryKey, GetCollectionsTopsellingV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsTopsellingV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>>
export type GetCollectionsTopsellingV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Top Selling Collections
 */
export const useGetCollectionsTopsellingV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, GetCollectionsTopsellingV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTopsellingV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, QueryKey, GetCollectionsTopsellingV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTopsellingV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsTopsellingV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, TError = ErrorType<unknown>>(params?: GetCollectionsTopsellingV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTopsellingV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>> = ({ signal }) => getCollectionsTopsellingV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsTopsellingV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>>
export type GetCollectionsTopsellingV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Top Selling Collections
 */
export const useGetCollectionsTopsellingV1 = <TData = Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTopsellingV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTopsellingV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get top selling and minting collections
 * @deprecated
 * @summary Top selling collections
 */
export const getCollectionsTopsellingV2 = (
    params?: GetCollectionsTopsellingV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTopSellingCollectionsV2Response>(
      {url: `/collections/top-selling/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsTopsellingV2QueryKey = (params?: GetCollectionsTopsellingV2Params,) => {
    return [`/collections/top-selling/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsTopsellingV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, GetCollectionsTopsellingV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsTopsellingV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, QueryKey, GetCollectionsTopsellingV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTopsellingV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, QueryKey, GetCollectionsTopsellingV2Params['continuation']> = ({ signal, pageParam }) => getCollectionsTopsellingV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, QueryKey, GetCollectionsTopsellingV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsTopsellingV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>>
export type GetCollectionsTopsellingV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Top selling collections
 */
export const useGetCollectionsTopsellingV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, GetCollectionsTopsellingV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTopsellingV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, QueryKey, GetCollectionsTopsellingV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTopsellingV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsTopsellingV2QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError = ErrorType<unknown>>(params?: GetCollectionsTopsellingV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTopsellingV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>> = ({ signal }) => getCollectionsTopsellingV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsTopsellingV2QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>>
export type GetCollectionsTopsellingV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Top selling collections
 */
export const useGetCollectionsTopsellingV2 = <TData = Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTopsellingV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTopsellingV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTopsellingV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get top selling and minting collections
 * @summary Top Selling Collections
 */
export const getCollectionsTrendingV1 = (
    params?: GetCollectionsTrendingV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTrendingCollectionsV1Response>(
      {url: `/collections/trending/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsTrendingV1QueryKey = (params?: GetCollectionsTrendingV1Params,) => {
    return [`/collections/trending/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsTrendingV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, GetCollectionsTrendingV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsTrendingV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingV1>>, QueryKey, GetCollectionsTrendingV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTrendingV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, QueryKey, GetCollectionsTrendingV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsTrendingV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingV1>>, QueryKey, GetCollectionsTrendingV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsTrendingV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTrendingV1>>>
export type GetCollectionsTrendingV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Top Selling Collections
 */
export const useGetCollectionsTrendingV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, GetCollectionsTrendingV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTrendingV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingV1>>, QueryKey, GetCollectionsTrendingV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTrendingV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsTrendingV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError = ErrorType<unknown>>(params?: GetCollectionsTrendingV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTrendingV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTrendingV1>>> = ({ signal }) => getCollectionsTrendingV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsTrendingV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTrendingV1>>>
export type GetCollectionsTrendingV1QueryError = ErrorType<unknown>

/**
 * @summary Top Selling Collections
 */
export const useGetCollectionsTrendingV1 = <TData = Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTrendingV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTrendingV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get top trending mints
 * @summary Top Trending Mints
 */
export const getCollectionsTrendingmintsV1 = (
    params?: GetCollectionsTrendingmintsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTrendingMintsV1Response>(
      {url: `/collections/trending-mints/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsTrendingmintsV1QueryKey = (params?: GetCollectionsTrendingmintsV1Params,) => {
    return [`/collections/trending-mints/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsTrendingmintsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, GetCollectionsTrendingmintsV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCollectionsTrendingmintsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, QueryKey, GetCollectionsTrendingmintsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTrendingmintsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, QueryKey, GetCollectionsTrendingmintsV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsTrendingmintsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, QueryKey, GetCollectionsTrendingmintsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsTrendingmintsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>>
export type GetCollectionsTrendingmintsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Top Trending Mints
 */
export const useGetCollectionsTrendingmintsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, GetCollectionsTrendingmintsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTrendingmintsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, QueryKey, GetCollectionsTrendingmintsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTrendingmintsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsTrendingmintsV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError = ErrorType<unknown>>(params?: GetCollectionsTrendingmintsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsTrendingmintsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>> = ({ signal }) => getCollectionsTrendingmintsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsTrendingmintsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>>
export type GetCollectionsTrendingmintsV1QueryError = ErrorType<unknown>

/**
 * @summary Top Trending Mints
 */
export const useGetCollectionsTrendingmintsV1 = <TData = Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError = ErrorType<unknown>>(
 params?: GetCollectionsTrendingmintsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsTrendingmintsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsTrendingmintsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get detailed information about a single collection, including real-time stats.
 * @deprecated
 * @summary Single collection
 */
export const getCollectionsCollectionorslugV1 = (
    collectionOrSlug: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionDeprecatedV1Response>(
      {url: `/collections/${collectionOrSlug}/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionorslugV1QueryKey = (collectionOrSlug: string,) => {
    return [`/collections/${collectionOrSlug}/v1`] as const;
    }

    
export const getGetCollectionsCollectionorslugV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>>, TError = ErrorType<unknown>>(collectionOrSlug: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionorslugV1QueryKey(collectionOrSlug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>> = ({ signal }) => getCollectionsCollectionorslugV1(collectionOrSlug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionOrSlug),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionorslugV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>>
export type GetCollectionsCollectionorslugV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single collection
 */
export const useGetCollectionsCollectionorslugV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>>, TError = ErrorType<unknown>>(
 collectionOrSlug: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionorslugV1InfiniteQueryOptions(collectionOrSlug,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionorslugV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError = ErrorType<unknown>>(collectionOrSlug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionorslugV1QueryKey(collectionOrSlug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>> = ({ signal }) => getCollectionsCollectionorslugV1(collectionOrSlug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionOrSlug),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionorslugV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>>
export type GetCollectionsCollectionorslugV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Single collection
 */
export const useGetCollectionsCollectionorslugV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError = ErrorType<unknown>>(
 collectionOrSlug: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionorslugV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionorslugV1QueryOptions(collectionOrSlug,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Convert an amount in one currency to another
 * @deprecated
 * @summary Currency Conversions
 */
export const getCurrenciesConversionV1 = (
    params?: GetCurrenciesConversionV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCurrencyConversionV1Response>(
      {url: `/currencies/conversion/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCurrenciesConversionV1QueryKey = (params?: GetCurrenciesConversionV1Params,) => {
    return [`/currencies/conversion/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCurrenciesConversionV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, GetCurrenciesConversionV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetCurrenciesConversionV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData, Awaited<ReturnType<typeof getCurrenciesConversionV1>>, QueryKey, GetCurrenciesConversionV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrenciesConversionV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, QueryKey, GetCurrenciesConversionV1Params['continuation']> = ({ signal, pageParam }) => getCurrenciesConversionV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData, Awaited<ReturnType<typeof getCurrenciesConversionV1>>, QueryKey, GetCurrenciesConversionV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCurrenciesConversionV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrenciesConversionV1>>>
export type GetCurrenciesConversionV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Currency Conversions
 */
export const useGetCurrenciesConversionV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, GetCurrenciesConversionV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetCurrenciesConversionV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData, Awaited<ReturnType<typeof getCurrenciesConversionV1>>, QueryKey, GetCurrenciesConversionV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrenciesConversionV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCurrenciesConversionV1QueryOptions = <TData = Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError = ErrorType<unknown>>(params?: GetCurrenciesConversionV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCurrenciesConversionV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrenciesConversionV1>>> = ({ signal }) => getCurrenciesConversionV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCurrenciesConversionV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCurrenciesConversionV1>>>
export type GetCurrenciesConversionV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Currency Conversions
 */
export const useGetCurrenciesConversionV1 = <TData = Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError = ErrorType<unknown>>(
 params?: GetCurrenciesConversionV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCurrenciesConversionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCurrenciesConversionV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get updates any time an asks status changes
 * @deprecated
 * @summary Asks status changes
 */
export const getEventsAsksV2 = (
    params?: GetEventsAsksV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAsksEventsV2Response>(
      {url: `/events/asks/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsAsksV2QueryKey = (params?: GetEventsAsksV2Params,) => {
    return [`/events/asks/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsAsksV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsAsksV2>>, GetEventsAsksV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsAsksV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsAsksV2>>, TError, TData, Awaited<ReturnType<typeof getEventsAsksV2>>, QueryKey, GetEventsAsksV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsAsksV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsAsksV2>>, QueryKey, GetEventsAsksV2Params['continuation']> = ({ signal, pageParam }) => getEventsAsksV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsAsksV2>>, TError, TData, Awaited<ReturnType<typeof getEventsAsksV2>>, QueryKey, GetEventsAsksV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsAsksV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsAsksV2>>>
export type GetEventsAsksV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Asks status changes
 */
export const useGetEventsAsksV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsAsksV2>>, GetEventsAsksV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsAsksV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsAsksV2>>, TError, TData, Awaited<ReturnType<typeof getEventsAsksV2>>, QueryKey, GetEventsAsksV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsAsksV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsAsksV2QueryOptions = <TData = Awaited<ReturnType<typeof getEventsAsksV2>>, TError = ErrorType<unknown>>(params?: GetEventsAsksV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsAsksV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsAsksV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsAsksV2>>> = ({ signal }) => getEventsAsksV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsAsksV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsAsksV2QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsAsksV2>>>
export type GetEventsAsksV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Asks status changes
 */
export const useGetEventsAsksV2 = <TData = Awaited<ReturnType<typeof getEventsAsksV2>>, TError = ErrorType<unknown>>(
 params?: GetEventsAsksV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsAsksV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsAsksV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time an ask status changes, an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new listing at a lower price

- `expiry` > the previous best listing expired

- `sale` > the previous best listing was filled

- `cancel` > the previous best listing was canceled

- `balance-change` > the best listing was invalidated due to no longer owning the NFT

- `approval-change` > the best listing was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Note: Private listings (asks) will not appear in the results.
 * @summary Asks status changes
 */
export const getEventsAsksV3 = (
    params?: GetEventsAsksV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAsksEventsV3Response>(
      {url: `/events/asks/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsAsksV3QueryKey = (params?: GetEventsAsksV3Params,) => {
    return [`/events/asks/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsAsksV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsAsksV3>>, GetEventsAsksV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsAsksV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData, Awaited<ReturnType<typeof getEventsAsksV3>>, QueryKey, GetEventsAsksV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsAsksV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsAsksV3>>, QueryKey, GetEventsAsksV3Params['continuation']> = ({ signal, pageParam }) => getEventsAsksV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData, Awaited<ReturnType<typeof getEventsAsksV3>>, QueryKey, GetEventsAsksV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsAsksV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsAsksV3>>>
export type GetEventsAsksV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Asks status changes
 */
export const useGetEventsAsksV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsAsksV3>>, GetEventsAsksV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsAsksV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData, Awaited<ReturnType<typeof getEventsAsksV3>>, QueryKey, GetEventsAsksV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsAsksV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsAsksV3QueryOptions = <TData = Awaited<ReturnType<typeof getEventsAsksV3>>, TError = ErrorType<unknown>>(params?: GetEventsAsksV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsAsksV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsAsksV3>>> = ({ signal }) => getEventsAsksV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsAsksV3QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsAsksV3>>>
export type GetEventsAsksV3QueryError = ErrorType<unknown>

/**
 * @summary Asks status changes
 */
export const useGetEventsAsksV3 = <TData = Awaited<ReturnType<typeof getEventsAsksV3>>, TError = ErrorType<unknown>>(
 params?: GetEventsAsksV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsAsksV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsAsksV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get updates any time a bid status changes
 * @deprecated
 * @summary Bid status changes
 */
export const getEventsBidsV1 = (
    params?: GetEventsBidsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetBidEventsV1Response>(
      {url: `/events/bids/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsBidsV1QueryKey = (params?: GetEventsBidsV1Params,) => {
    return [`/events/bids/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsBidsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsBidsV1>>, GetEventsBidsV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsBidsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV1>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV1>>, QueryKey, GetEventsBidsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsBidsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsBidsV1>>, QueryKey, GetEventsBidsV1Params['continuation']> = ({ signal, pageParam }) => getEventsBidsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV1>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV1>>, QueryKey, GetEventsBidsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsBidsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsBidsV1>>>
export type GetEventsBidsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bid status changes
 */
export const useGetEventsBidsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsBidsV1>>, GetEventsBidsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsBidsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV1>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV1>>, QueryKey, GetEventsBidsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsBidsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsBidsV1QueryOptions = <TData = Awaited<ReturnType<typeof getEventsBidsV1>>, TError = ErrorType<unknown>>(params?: GetEventsBidsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsBidsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsBidsV1>>> = ({ signal }) => getEventsBidsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsBidsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsBidsV1>>>
export type GetEventsBidsV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bid status changes
 */
export const useGetEventsBidsV1 = <TData = Awaited<ReturnType<typeof getEventsBidsV1>>, TError = ErrorType<unknown>>(
 params?: GetEventsBidsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsBidsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get updates any time a bid status changes
 * @deprecated
 * @summary Bid status changes
 */
export const getEventsBidsV2 = (
    params?: GetEventsBidsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetBidEventsV2Response>(
      {url: `/events/bids/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsBidsV2QueryKey = (params?: GetEventsBidsV2Params,) => {
    return [`/events/bids/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsBidsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsBidsV2>>, GetEventsBidsV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsBidsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV2>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV2>>, QueryKey, GetEventsBidsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsBidsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsBidsV2>>, QueryKey, GetEventsBidsV2Params['continuation']> = ({ signal, pageParam }) => getEventsBidsV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV2>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV2>>, QueryKey, GetEventsBidsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsBidsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsBidsV2>>>
export type GetEventsBidsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bid status changes
 */
export const useGetEventsBidsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsBidsV2>>, GetEventsBidsV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsBidsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV2>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV2>>, QueryKey, GetEventsBidsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsBidsV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsBidsV2QueryOptions = <TData = Awaited<ReturnType<typeof getEventsBidsV2>>, TError = ErrorType<unknown>>(params?: GetEventsBidsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsBidsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsBidsV2>>> = ({ signal }) => getEventsBidsV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsBidsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsBidsV2>>>
export type GetEventsBidsV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bid status changes
 */
export const useGetEventsBidsV2 = <TData = Awaited<ReturnType<typeof getEventsBidsV2>>, TError = ErrorType<unknown>>(
 params?: GetEventsBidsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsBidsV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time a bid status changes, an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new offer at a lower price

- `expiry` > the previous best offer expired

- `sale` > the previous best offer was filled

- `cancel` > the previous best offer was canceled

- `balance-change` > the best offer was invalidated due to no longer owning the NFT

- `approval-change` > the best offer was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Selling a partial quantity of available 1155 tokens in a listing will generate a `sale` and will have a new quantity.

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.
 * @summary Bid status changes
 */
export const getEventsBidsV3 = (
    params?: GetEventsBidsV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetBidEventsV3Response>(
      {url: `/events/bids/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsBidsV3QueryKey = (params?: GetEventsBidsV3Params,) => {
    return [`/events/bids/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsBidsV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsBidsV3>>, GetEventsBidsV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsBidsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV3>>, QueryKey, GetEventsBidsV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsBidsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsBidsV3>>, QueryKey, GetEventsBidsV3Params['continuation']> = ({ signal, pageParam }) => getEventsBidsV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV3>>, QueryKey, GetEventsBidsV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsBidsV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsBidsV3>>>
export type GetEventsBidsV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Bid status changes
 */
export const useGetEventsBidsV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsBidsV3>>, GetEventsBidsV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsBidsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData, Awaited<ReturnType<typeof getEventsBidsV3>>, QueryKey, GetEventsBidsV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsBidsV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsBidsV3QueryOptions = <TData = Awaited<ReturnType<typeof getEventsBidsV3>>, TError = ErrorType<unknown>>(params?: GetEventsBidsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsBidsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsBidsV3>>> = ({ signal }) => getEventsBidsV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsBidsV3QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsBidsV3>>>
export type GetEventsBidsV3QueryError = ErrorType<unknown>

/**
 * @summary Bid status changes
 */
export const useGetEventsBidsV3 = <TData = Awaited<ReturnType<typeof getEventsBidsV3>>, TError = ErrorType<unknown>>(
 params?: GetEventsBidsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsBidsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsBidsV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get updates any time an order status changes
 * @deprecated
 * @summary Order status changes
 */
export const getEventsOrdersV1 = (
    params?: GetEventsOrdersV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrderEventsV1Response>(
      {url: `/events/orders/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsOrdersV1QueryKey = (params?: GetEventsOrdersV1Params,) => {
    return [`/events/orders/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsOrdersV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsOrdersV1>>, GetEventsOrdersV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsOrdersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsOrdersV1>>, TError, TData, Awaited<ReturnType<typeof getEventsOrdersV1>>, QueryKey, GetEventsOrdersV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsOrdersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsOrdersV1>>, QueryKey, GetEventsOrdersV1Params['continuation']> = ({ signal, pageParam }) => getEventsOrdersV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsOrdersV1>>, TError, TData, Awaited<ReturnType<typeof getEventsOrdersV1>>, QueryKey, GetEventsOrdersV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsOrdersV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsOrdersV1>>>
export type GetEventsOrdersV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Order status changes
 */
export const useGetEventsOrdersV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsOrdersV1>>, GetEventsOrdersV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsOrdersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsOrdersV1>>, TError, TData, Awaited<ReturnType<typeof getEventsOrdersV1>>, QueryKey, GetEventsOrdersV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsOrdersV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsOrdersV1QueryOptions = <TData = Awaited<ReturnType<typeof getEventsOrdersV1>>, TError = ErrorType<unknown>>(params?: GetEventsOrdersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsOrdersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsOrdersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsOrdersV1>>> = ({ signal }) => getEventsOrdersV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsOrdersV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsOrdersV1QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsOrdersV1>>>
export type GetEventsOrdersV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Order status changes
 */
export const useGetEventsOrdersV1 = <TData = Awaited<ReturnType<typeof getEventsOrdersV1>>, TError = ErrorType<unknown>>(
 params?: GetEventsOrdersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsOrdersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsOrdersV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Cancel an existing order on any marketplace
 * @deprecated
 * @summary Cancel order
 */
export const getExecuteCancelV2 = (
    params: GetExecuteCancelV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetExecuteCancelV2Response>(
      {url: `/execute/cancel/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetExecuteCancelV2QueryKey = (params: GetExecuteCancelV2Params,) => {
    return [`/execute/cancel/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetExecuteCancelV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getExecuteCancelV2>>, GetExecuteCancelV2Params['continuation']>, TError = ErrorType<unknown>>(params: GetExecuteCancelV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData, Awaited<ReturnType<typeof getExecuteCancelV2>>, QueryKey, GetExecuteCancelV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExecuteCancelV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecuteCancelV2>>, QueryKey, GetExecuteCancelV2Params['continuation']> = ({ signal, pageParam }) => getExecuteCancelV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData, Awaited<ReturnType<typeof getExecuteCancelV2>>, QueryKey, GetExecuteCancelV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetExecuteCancelV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getExecuteCancelV2>>>
export type GetExecuteCancelV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Cancel order
 */
export const useGetExecuteCancelV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getExecuteCancelV2>>, GetExecuteCancelV2Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetExecuteCancelV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData, Awaited<ReturnType<typeof getExecuteCancelV2>>, QueryKey, GetExecuteCancelV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExecuteCancelV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetExecuteCancelV2QueryOptions = <TData = Awaited<ReturnType<typeof getExecuteCancelV2>>, TError = ErrorType<unknown>>(params: GetExecuteCancelV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExecuteCancelV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecuteCancelV2>>> = ({ signal }) => getExecuteCancelV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExecuteCancelV2QueryResult = NonNullable<Awaited<ReturnType<typeof getExecuteCancelV2>>>
export type GetExecuteCancelV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Cancel order
 */
export const useGetExecuteCancelV2 = <TData = Awaited<ReturnType<typeof getExecuteCancelV2>>, TError = ErrorType<unknown>>(
 params: GetExecuteCancelV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecuteCancelV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExecuteCancelV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API calculates the total liquidity created by users, based on the number of tokens they are top bidder for.
 * @summary User bid liquidity rankings
 */
export const getLiquidityUsersV1 = (
    params?: GetLiquidityUsersV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUsersLiquidityV1Response>(
      {url: `/liquidity/users/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetLiquidityUsersV1QueryKey = (params?: GetLiquidityUsersV1Params,) => {
    return [`/liquidity/users/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetLiquidityUsersV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getLiquidityUsersV1>>, GetLiquidityUsersV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetLiquidityUsersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV1>>, TError, TData, Awaited<ReturnType<typeof getLiquidityUsersV1>>, QueryKey, GetLiquidityUsersV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLiquidityUsersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLiquidityUsersV1>>, QueryKey, GetLiquidityUsersV1Params['continuation']> = ({ signal, pageParam }) => getLiquidityUsersV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV1>>, TError, TData, Awaited<ReturnType<typeof getLiquidityUsersV1>>, QueryKey, GetLiquidityUsersV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetLiquidityUsersV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getLiquidityUsersV1>>>
export type GetLiquidityUsersV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User bid liquidity rankings
 */
export const useGetLiquidityUsersV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getLiquidityUsersV1>>, GetLiquidityUsersV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetLiquidityUsersV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV1>>, TError, TData, Awaited<ReturnType<typeof getLiquidityUsersV1>>, QueryKey, GetLiquidityUsersV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLiquidityUsersV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetLiquidityUsersV1QueryOptions = <TData = Awaited<ReturnType<typeof getLiquidityUsersV1>>, TError = ErrorType<unknown>>(params?: GetLiquidityUsersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLiquidityUsersV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLiquidityUsersV1>>> = ({ signal }) => getLiquidityUsersV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLiquidityUsersV1QueryResult = NonNullable<Awaited<ReturnType<typeof getLiquidityUsersV1>>>
export type GetLiquidityUsersV1QueryError = ErrorType<unknown>

/**
 * @summary User bid liquidity rankings
 */
export const useGetLiquidityUsersV1 = <TData = Awaited<ReturnType<typeof getLiquidityUsersV1>>, TError = ErrorType<unknown>>(
 params?: GetLiquidityUsersV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLiquidityUsersV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API calculates the total liquidity created by users, based on the number of tokens they are top bidder for.
 * @summary User bid liquidity rankings
 */
export const getLiquidityUsersV2 = (
    params: GetLiquidityUsersV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUsersLiquidityV1Response>(
      {url: `/liquidity/users/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetLiquidityUsersV2QueryKey = (params: GetLiquidityUsersV2Params,) => {
    return [`/liquidity/users/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetLiquidityUsersV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getLiquidityUsersV2>>, GetLiquidityUsersV2Params['continuation']>, TError = ErrorType<unknown>>(params: GetLiquidityUsersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData, Awaited<ReturnType<typeof getLiquidityUsersV2>>, QueryKey, GetLiquidityUsersV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLiquidityUsersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLiquidityUsersV2>>, QueryKey, GetLiquidityUsersV2Params['continuation']> = ({ signal, pageParam }) => getLiquidityUsersV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData, Awaited<ReturnType<typeof getLiquidityUsersV2>>, QueryKey, GetLiquidityUsersV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetLiquidityUsersV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getLiquidityUsersV2>>>
export type GetLiquidityUsersV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User bid liquidity rankings
 */
export const useGetLiquidityUsersV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getLiquidityUsersV2>>, GetLiquidityUsersV2Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetLiquidityUsersV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData, Awaited<ReturnType<typeof getLiquidityUsersV2>>, QueryKey, GetLiquidityUsersV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLiquidityUsersV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetLiquidityUsersV2QueryOptions = <TData = Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError = ErrorType<unknown>>(params: GetLiquidityUsersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLiquidityUsersV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLiquidityUsersV2>>> = ({ signal }) => getLiquidityUsersV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLiquidityUsersV2QueryResult = NonNullable<Awaited<ReturnType<typeof getLiquidityUsersV2>>>
export type GetLiquidityUsersV2QueryError = ErrorType<unknown>

/**
 * @summary User bid liquidity rankings
 */
export const useGetLiquidityUsersV2 = <TData = Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError = ErrorType<unknown>>(
 params: GetLiquidityUsersV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLiquidityUsersV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLiquidityUsersV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @deprecated
 * @summary Bulk historical orders
 */
export const getOrdersAllV1 = (
    params?: GetOrdersAllV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersAllV1Response>(
      {url: `/orders/all/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersAllV1QueryKey = (params?: GetOrdersAllV1Params,) => {
    return [`/orders/all/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersAllV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAllV1>>, GetOrdersAllV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersAllV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAllV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersAllV1>>, QueryKey, GetOrdersAllV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAllV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAllV1>>, QueryKey, GetOrdersAllV1Params['continuation']> = ({ signal, pageParam }) => getOrdersAllV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAllV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersAllV1>>, QueryKey, GetOrdersAllV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersAllV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAllV1>>>
export type GetOrdersAllV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical orders
 */
export const useGetOrdersAllV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAllV1>>, GetOrdersAllV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersAllV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAllV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersAllV1>>, QueryKey, GetOrdersAllV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAllV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersAllV1QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersAllV1>>, TError = ErrorType<unknown>>(params?: GetOrdersAllV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAllV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAllV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAllV1>>> = ({ signal }) => getOrdersAllV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersAllV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersAllV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAllV1>>>
export type GetOrdersAllV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical orders
 */
export const useGetOrdersAllV1 = <TData = Awaited<ReturnType<typeof getOrdersAllV1>>, TError = ErrorType<unknown>>(
 params?: GetOrdersAllV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAllV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAllV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @deprecated
 * @summary Bulk historical orders
 */
export const getOrdersAllV2 = (
    params?: GetOrdersAllV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersAllV2Response>(
      {url: `/orders/all/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersAllV2QueryKey = (params?: GetOrdersAllV2Params,) => {
    return [`/orders/all/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersAllV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAllV2>>, GetOrdersAllV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersAllV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersAllV2>>, QueryKey, GetOrdersAllV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAllV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAllV2>>, QueryKey, GetOrdersAllV2Params['continuation']> = ({ signal, pageParam }) => getOrdersAllV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersAllV2>>, QueryKey, GetOrdersAllV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersAllV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAllV2>>>
export type GetOrdersAllV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical orders
 */
export const useGetOrdersAllV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAllV2>>, GetOrdersAllV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersAllV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersAllV2>>, QueryKey, GetOrdersAllV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAllV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersAllV2QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersAllV2>>, TError = ErrorType<unknown>>(params?: GetOrdersAllV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAllV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAllV2>>> = ({ signal }) => getOrdersAllV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersAllV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAllV2>>>
export type GetOrdersAllV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical orders
 */
export const useGetOrdersAllV2 = <TData = Awaited<ReturnType<typeof getOrdersAllV2>>, TError = ErrorType<unknown>>(
 params?: GetOrdersAllV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAllV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAllV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @deprecated
 * @summary Get a list of asks (listings), filtered by token, collection or maker
 */
export const getOrdersAsksV1 = (
    params?: GetOrdersAsksV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersAsksV1Response>(
      {url: `/orders/asks/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersAsksV1QueryKey = (params?: GetOrdersAsksV1Params,) => {
    return [`/orders/asks/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersAsksV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV1>>, GetOrdersAsksV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV1>>, QueryKey, GetOrdersAsksV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV1>>, QueryKey, GetOrdersAsksV1Params['continuation']> = ({ signal, pageParam }) => getOrdersAsksV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV1>>, QueryKey, GetOrdersAsksV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersAsksV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV1>>>
export type GetOrdersAsksV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a list of asks (listings), filtered by token, collection or maker
 */
export const useGetOrdersAsksV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV1>>, GetOrdersAsksV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV1>>, QueryKey, GetOrdersAsksV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersAsksV1QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersAsksV1>>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV1>>> = ({ signal }) => getOrdersAsksV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersAsksV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV1>>>
export type GetOrdersAsksV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a list of asks (listings), filtered by token, collection or maker
 */
export const useGetOrdersAsksV1 = <TData = Awaited<ReturnType<typeof getOrdersAsksV1>>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of asks (listings), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @deprecated
 * @summary Asks (listings)
 */
export const getOrdersAsksV2 = (
    params?: GetOrdersAsksV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersAsksV1Response>(
      {url: `/orders/asks/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersAsksV2QueryKey = (params?: GetOrdersAsksV2Params,) => {
    return [`/orders/asks/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersAsksV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV2>>, GetOrdersAsksV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV2>>, QueryKey, GetOrdersAsksV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV2>>, QueryKey, GetOrdersAsksV2Params['continuation']> = ({ signal, pageParam }) => getOrdersAsksV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV2>>, QueryKey, GetOrdersAsksV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersAsksV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV2>>>
export type GetOrdersAsksV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV2>>, GetOrdersAsksV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV2>>, QueryKey, GetOrdersAsksV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersAsksV2QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersAsksV2>>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV2>>> = ({ signal }) => getOrdersAsksV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersAsksV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV2>>>
export type GetOrdersAsksV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV2 = <TData = Awaited<ReturnType<typeof getOrdersAsksV2>>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of asks (listings), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @summary Asks (listings)
 */
export const getOrdersAsksV3 = (
    params?: GetOrdersAsksV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersAsksV3Response>(
      {url: `/orders/asks/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersAsksV3QueryKey = (params?: GetOrdersAsksV3Params,) => {
    return [`/orders/asks/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersAsksV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV3>>, GetOrdersAsksV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV3>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV3>>, QueryKey, GetOrdersAsksV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV3>>, QueryKey, GetOrdersAsksV3Params['continuation']> = ({ signal, pageParam }) => getOrdersAsksV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV3>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV3>>, QueryKey, GetOrdersAsksV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersAsksV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV3>>>
export type GetOrdersAsksV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV3>>, GetOrdersAsksV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV3>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV3>>, QueryKey, GetOrdersAsksV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersAsksV3QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersAsksV3>>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV3>>> = ({ signal }) => getOrdersAsksV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersAsksV3QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV3>>>
export type GetOrdersAsksV3QueryError = ErrorType<unknown>

/**
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV3 = <TData = Awaited<ReturnType<typeof getOrdersAsksV3>>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of asks (listings), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing.

 Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Asks (listings)
 */
export const getOrdersAsksV4 = (
    params?: GetOrdersAsksV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersAsksV4Response>(
      {url: `/orders/asks/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersAsksV4QueryKey = (params?: GetOrdersAsksV4Params,) => {
    return [`/orders/asks/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersAsksV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV4>>, GetOrdersAsksV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV4>>, QueryKey, GetOrdersAsksV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV4>>, QueryKey, GetOrdersAsksV4Params['continuation']> = ({ signal, pageParam }) => getOrdersAsksV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV4>>, QueryKey, GetOrdersAsksV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersAsksV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV4>>>
export type GetOrdersAsksV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV4>>, GetOrdersAsksV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV4>>, QueryKey, GetOrdersAsksV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersAsksV4QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersAsksV4>>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV4>>> = ({ signal }) => getOrdersAsksV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersAsksV4QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV4>>>
export type GetOrdersAsksV4QueryError = ErrorType<unknown>

/**
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV4 = <TData = Awaited<ReturnType<typeof getOrdersAsksV4>>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of asks (listings), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing.

 To get all orders unflitered, select `sortBy` to `updatedAt`. No need to pass any other param. This will return any orders for any collections, token, attribute, etc.

 Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Asks (listings)
 */
export const getOrdersAsksV5 = (
    params?: GetOrdersAsksV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersAsksV4Response>(
      {url: `/orders/asks/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersAsksV5QueryKey = (params?: GetOrdersAsksV5Params,) => {
    return [`/orders/asks/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersAsksV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV5>>, GetOrdersAsksV5Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV5>>, QueryKey, GetOrdersAsksV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV5>>, QueryKey, GetOrdersAsksV5Params['continuation']> = ({ signal, pageParam }) => getOrdersAsksV5({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV5>>, QueryKey, GetOrdersAsksV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersAsksV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV5>>>
export type GetOrdersAsksV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersAsksV5>>, GetOrdersAsksV5Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData, Awaited<ReturnType<typeof getOrdersAsksV5>>, QueryKey, GetOrdersAsksV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV5InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersAsksV5QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersAsksV5>>, TError = ErrorType<unknown>>(params?: GetOrdersAsksV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersAsksV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersAsksV5>>> = ({ signal }) => getOrdersAsksV5(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersAsksV5QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersAsksV5>>>
export type GetOrdersAsksV5QueryError = ErrorType<unknown>

/**
 * @summary Asks (listings)
 */
export const useGetOrdersAsksV5 = <TData = Awaited<ReturnType<typeof getOrdersAsksV5>>, TError = ErrorType<unknown>>(
 params?: GetOrdersAsksV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersAsksV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersAsksV5QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @deprecated
 * @summary Get a list of bids (offers), filtered by token, collection or maker
 */
export const getOrdersBidsV1 = (
    params?: GetOrdersBidsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersBidsV1Response>(
      {url: `/orders/bids/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersBidsV1QueryKey = (params?: GetOrdersBidsV1Params,) => {
    return [`/orders/bids/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersBidsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV1>>, GetOrdersBidsV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV1>>, QueryKey, GetOrdersBidsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV1>>, QueryKey, GetOrdersBidsV1Params['continuation']> = ({ signal, pageParam }) => getOrdersBidsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV1>>, QueryKey, GetOrdersBidsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersBidsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV1>>>
export type GetOrdersBidsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a list of bids (offers), filtered by token, collection or maker
 */
export const useGetOrdersBidsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV1>>, GetOrdersBidsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV1>>, QueryKey, GetOrdersBidsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersBidsV1QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersBidsV1>>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV1>>> = ({ signal }) => getOrdersBidsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersBidsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV1>>>
export type GetOrdersBidsV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a list of bids (offers), filtered by token, collection or maker
 */
export const useGetOrdersBidsV1 = <TData = Awaited<ReturnType<typeof getOrdersBidsV1>>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of bids (offers), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @deprecated
 * @summary Bids (offers)
 */
export const getOrdersBidsV2 = (
    params?: GetOrdersBidsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersBidsV1Response>(
      {url: `/orders/bids/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersBidsV2QueryKey = (params?: GetOrdersBidsV2Params,) => {
    return [`/orders/bids/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersBidsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV2>>, GetOrdersBidsV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV2>>, QueryKey, GetOrdersBidsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV2>>, QueryKey, GetOrdersBidsV2Params['continuation']> = ({ signal, pageParam }) => getOrdersBidsV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV2>>, QueryKey, GetOrdersBidsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersBidsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV2>>>
export type GetOrdersBidsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV2>>, GetOrdersBidsV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV2>>, QueryKey, GetOrdersBidsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersBidsV2QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersBidsV2>>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV2>>> = ({ signal }) => getOrdersBidsV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersBidsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV2>>>
export type GetOrdersBidsV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV2 = <TData = Awaited<ReturnType<typeof getOrdersBidsV2>>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of bids (offers), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @summary Bids (offers)
 */
export const getOrdersBidsV3 = (
    params?: GetOrdersBidsV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersBidsV3Response>(
      {url: `/orders/bids/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersBidsV3QueryKey = (params?: GetOrdersBidsV3Params,) => {
    return [`/orders/bids/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersBidsV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV3>>, GetOrdersBidsV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV3>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV3>>, QueryKey, GetOrdersBidsV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV3>>, QueryKey, GetOrdersBidsV3Params['continuation']> = ({ signal, pageParam }) => getOrdersBidsV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV3>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV3>>, QueryKey, GetOrdersBidsV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersBidsV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV3>>>
export type GetOrdersBidsV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV3>>, GetOrdersBidsV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV3>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV3>>, QueryKey, GetOrdersBidsV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersBidsV3QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersBidsV3>>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV3>>> = ({ signal }) => getOrdersBidsV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersBidsV3QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV3>>>
export type GetOrdersBidsV3QueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV3 = <TData = Awaited<ReturnType<typeof getOrdersBidsV3>>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of bids (offers), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing
 * @summary Bids (offers)
 */
export const getOrdersBidsV4 = (
    params?: GetOrdersBidsV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersBidsV4Response>(
      {url: `/orders/bids/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersBidsV4QueryKey = (params?: GetOrdersBidsV4Params,) => {
    return [`/orders/bids/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersBidsV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV4>>, GetOrdersBidsV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV4>>, QueryKey, GetOrdersBidsV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV4>>, QueryKey, GetOrdersBidsV4Params['continuation']> = ({ signal, pageParam }) => getOrdersBidsV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV4>>, QueryKey, GetOrdersBidsV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersBidsV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV4>>>
export type GetOrdersBidsV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV4>>, GetOrdersBidsV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV4>>, QueryKey, GetOrdersBidsV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersBidsV4QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersBidsV4>>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV4>>> = ({ signal }) => getOrdersBidsV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersBidsV4QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV4>>>
export type GetOrdersBidsV4QueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV4 = <TData = Awaited<ReturnType<typeof getOrdersBidsV4>>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of bids (offers), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing.

 There are a different kind of bids than can be returned:

- Inputting a 'contract' will return token and attribute bids.

- Inputting a 'collection-id' will return collection wide bids./n/n Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Bids (offers)
 */
export const getOrdersBidsV5 = (
    params?: GetOrdersBidsV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersBidsV5Response>(
      {url: `/orders/bids/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersBidsV5QueryKey = (params?: GetOrdersBidsV5Params,) => {
    return [`/orders/bids/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersBidsV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV5>>, GetOrdersBidsV5Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV5>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV5>>, QueryKey, GetOrdersBidsV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV5>>, QueryKey, GetOrdersBidsV5Params['continuation']> = ({ signal, pageParam }) => getOrdersBidsV5({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV5>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV5>>, QueryKey, GetOrdersBidsV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersBidsV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV5>>>
export type GetOrdersBidsV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV5>>, GetOrdersBidsV5Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV5>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV5>>, QueryKey, GetOrdersBidsV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV5InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersBidsV5QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersBidsV5>>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV5>>> = ({ signal }) => getOrdersBidsV5(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersBidsV5QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV5>>>
export type GetOrdersBidsV5QueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV5 = <TData = Awaited<ReturnType<typeof getOrdersBidsV5>>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV5QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of bids (offers), filtered by token, collection or maker. This API is designed for efficiently ingesting large volumes of orders, for external processing.

 There are a different kind of bids than can be returned:

- To get all orders unfiltered, select `sortBy` to `updatedAt`. No need to pass any other param. This will return any orders for any collections, token, attribute, etc. 

- Inputting a 'contract' will return token and attribute bids.

- Inputting a 'collection-id' will return collection wide bids.

- Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Bids (offers)
 */
export const getOrdersBidsV6 = (
    params?: GetOrdersBidsV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersBidsV5Response>(
      {url: `/orders/bids/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersBidsV6QueryKey = (params?: GetOrdersBidsV6Params,) => {
    return [`/orders/bids/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersBidsV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV6>>, GetOrdersBidsV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV6>>, QueryKey, GetOrdersBidsV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV6>>, QueryKey, GetOrdersBidsV6Params['continuation']> = ({ signal, pageParam }) => getOrdersBidsV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV6>>, QueryKey, GetOrdersBidsV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersBidsV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV6>>>
export type GetOrdersBidsV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersBidsV6>>, GetOrdersBidsV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData, Awaited<ReturnType<typeof getOrdersBidsV6>>, QueryKey, GetOrdersBidsV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersBidsV6QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersBidsV6>>, TError = ErrorType<unknown>>(params?: GetOrdersBidsV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersBidsV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersBidsV6>>> = ({ signal }) => getOrdersBidsV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersBidsV6QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersBidsV6>>>
export type GetOrdersBidsV6QueryError = ErrorType<unknown>

/**
 * @summary Bids (offers)
 */
export const useGetOrdersBidsV6 = <TData = Awaited<ReturnType<typeof getOrdersBidsV6>>, TError = ErrorType<unknown>>(
 params?: GetOrdersBidsV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersBidsV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersBidsV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get the depth of a token or collection.
 * @summary Orders depth
 */
export const getOrdersDepthV1 = (
    params: GetOrdersDepthV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOrdersDepthV1Response>(
      {url: `/orders/depth/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersDepthV1QueryKey = (params: GetOrdersDepthV1Params,) => {
    return [`/orders/depth/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersDepthV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersDepthV1>>, GetOrdersDepthV1Params['continuation']>, TError = ErrorType<unknown>>(params: GetOrdersDepthV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersDepthV1>>, QueryKey, GetOrdersDepthV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersDepthV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersDepthV1>>, QueryKey, GetOrdersDepthV1Params['continuation']> = ({ signal, pageParam }) => getOrdersDepthV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersDepthV1>>, QueryKey, GetOrdersDepthV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersDepthV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersDepthV1>>>
export type GetOrdersDepthV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Orders depth
 */
export const useGetOrdersDepthV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersDepthV1>>, GetOrdersDepthV1Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetOrdersDepthV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersDepthV1>>, QueryKey, GetOrdersDepthV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersDepthV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersDepthV1QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersDepthV1>>, TError = ErrorType<unknown>>(params: GetOrdersDepthV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersDepthV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersDepthV1>>> = ({ signal }) => getOrdersDepthV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersDepthV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersDepthV1>>>
export type GetOrdersDepthV1QueryError = ErrorType<unknown>

/**
 * @summary Orders depth
 */
export const useGetOrdersDepthV1 = <TData = Awaited<ReturnType<typeof getOrdersDepthV1>>, TError = ErrorType<unknown>>(
 params: GetOrdersDepthV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersDepthV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersDepthV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary Order status
 */
export const getOrdersExecutedV1 = (
    params: GetOrdersExecutedV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/orders/executed/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersExecutedV1QueryKey = (params: GetOrdersExecutedV1Params,) => {
    return [`/orders/executed/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersExecutedV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersExecutedV1>>, GetOrdersExecutedV1Params['continuation']>, TError = ErrorType<string>>(params: GetOrdersExecutedV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersExecutedV1>>, QueryKey, GetOrdersExecutedV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersExecutedV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersExecutedV1>>, QueryKey, GetOrdersExecutedV1Params['continuation']> = ({ signal, pageParam }) => getOrdersExecutedV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersExecutedV1>>, QueryKey, GetOrdersExecutedV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersExecutedV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersExecutedV1>>>
export type GetOrdersExecutedV1InfiniteQueryError = ErrorType<string>

/**
 * @deprecated
 * @summary Order status
 */
export const useGetOrdersExecutedV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersExecutedV1>>, GetOrdersExecutedV1Params['continuation']>, TError = ErrorType<string>>(
 params: GetOrdersExecutedV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersExecutedV1>>, QueryKey, GetOrdersExecutedV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersExecutedV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersExecutedV1QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError = ErrorType<string>>(params: GetOrdersExecutedV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersExecutedV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersExecutedV1>>> = ({ signal }) => getOrdersExecutedV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersExecutedV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersExecutedV1>>>
export type GetOrdersExecutedV1QueryError = ErrorType<string>

/**
 * @deprecated
 * @summary Order status
 */
export const useGetOrdersExecutedV1 = <TData = Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError = ErrorType<string>>(
 params: GetOrdersExecutedV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersExecutedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersExecutedV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to find top common collections from an array of owners.
 * @summary Common Collections
 */
export const getOwnersCommoncollectionsV1 = (
    params: GetOwnersCommoncollectionsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCommonCollectionsOwnersV1Response>(
      {url: `/owners/common-collections/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOwnersCommoncollectionsV1QueryKey = (params: GetOwnersCommoncollectionsV1Params,) => {
    return [`/owners/common-collections/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOwnersCommoncollectionsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, GetOwnersCommoncollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(params: GetOwnersCommoncollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, QueryKey, GetOwnersCommoncollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCommoncollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, QueryKey, GetOwnersCommoncollectionsV1Params['continuation']> = ({ signal, pageParam }) => getOwnersCommoncollectionsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, QueryKey, GetOwnersCommoncollectionsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOwnersCommoncollectionsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>>
export type GetOwnersCommoncollectionsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Common Collections
 */
export const useGetOwnersCommoncollectionsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, GetOwnersCommoncollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetOwnersCommoncollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, QueryKey, GetOwnersCommoncollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCommoncollectionsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOwnersCommoncollectionsV1QueryOptions = <TData = Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError = ErrorType<unknown>>(params: GetOwnersCommoncollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCommoncollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>> = ({ signal }) => getOwnersCommoncollectionsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnersCommoncollectionsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>>
export type GetOwnersCommoncollectionsV1QueryError = ErrorType<unknown>

/**
 * @summary Common Collections
 */
export const useGetOwnersCommoncollectionsV1 = <TData = Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError = ErrorType<unknown>>(
 params: GetOwnersCommoncollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCommoncollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCommoncollectionsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get total owners count for a specific token. Useful for 1155 tokens, where you want to know the exact amount of owners.
 * @summary Owners Count
 */
export const getOwnersCountV1 = (
    params?: GetOwnersCountV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetOwnerCountV1Response>(
      {url: `/owners/count/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOwnersCountV1QueryKey = (params?: GetOwnersCountV1Params,) => {
    return [`/owners/count/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOwnersCountV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCountV1>>, GetOwnersCountV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOwnersCountV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCountV1>>, QueryKey, GetOwnersCountV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCountV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCountV1>>, QueryKey, GetOwnersCountV1Params['continuation']> = ({ signal, pageParam }) => getOwnersCountV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCountV1>>, QueryKey, GetOwnersCountV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOwnersCountV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCountV1>>>
export type GetOwnersCountV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners Count
 */
export const useGetOwnersCountV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCountV1>>, GetOwnersCountV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOwnersCountV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCountV1>>, QueryKey, GetOwnersCountV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCountV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOwnersCountV1QueryOptions = <TData = Awaited<ReturnType<typeof getOwnersCountV1>>, TError = ErrorType<unknown>>(params?: GetOwnersCountV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCountV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCountV1>>> = ({ signal }) => getOwnersCountV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnersCountV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCountV1>>>
export type GetOwnersCountV1QueryError = ErrorType<unknown>

/**
 * @summary Owners Count
 */
export const useGetOwnersCountV1 = <TData = Awaited<ReturnType<typeof getOwnersCountV1>>, TError = ErrorType<unknown>>(
 params?: GetOwnersCountV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCountV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCountV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Find which addresses own the most of a group of collections.
 * @summary Owners intersection
 */
export const getOwnersCrosscollectionsV1 = (
    params: GetOwnersCrosscollectionsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCrossCollectionsOwnersV1Response>(
      {url: `/owners/cross-collections/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOwnersCrosscollectionsV1QueryKey = (params: GetOwnersCrosscollectionsV1Params,) => {
    return [`/owners/cross-collections/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOwnersCrosscollectionsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, GetOwnersCrosscollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(params: GetOwnersCrosscollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, QueryKey, GetOwnersCrosscollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCrosscollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, QueryKey, GetOwnersCrosscollectionsV1Params['continuation']> = ({ signal, pageParam }) => getOwnersCrosscollectionsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, QueryKey, GetOwnersCrosscollectionsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOwnersCrosscollectionsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>>
export type GetOwnersCrosscollectionsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners intersection
 */
export const useGetOwnersCrosscollectionsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, GetOwnersCrosscollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetOwnersCrosscollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, QueryKey, GetOwnersCrosscollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCrosscollectionsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOwnersCrosscollectionsV1QueryOptions = <TData = Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError = ErrorType<unknown>>(params: GetOwnersCrosscollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOwnersCrosscollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>> = ({ signal }) => getOwnersCrosscollectionsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOwnersCrosscollectionsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>>
export type GetOwnersCrosscollectionsV1QueryError = ErrorType<unknown>

/**
 * @summary Owners intersection
 */
export const useGetOwnersCrosscollectionsV1 = <TData = Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError = ErrorType<unknown>>(
 params: GetOwnersCrosscollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOwnersCrosscollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOwnersCrosscollectionsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary Redirect response to the given source logo
 */
export const getRedirectLogoV1 = (
    params: GetRedirectLogoV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/logo/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRedirectLogoV1QueryKey = (params: GetRedirectLogoV1Params,) => {
    return [`/redirect/logo/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRedirectLogoV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectLogoV1>>, GetRedirectLogoV1Params['continuation']>, TError = ErrorType<string>>(params: GetRedirectLogoV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectLogoV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectLogoV1>>, QueryKey, GetRedirectLogoV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectLogoV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectLogoV1>>, QueryKey, GetRedirectLogoV1Params['continuation']> = ({ signal, pageParam }) => getRedirectLogoV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectLogoV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectLogoV1>>, QueryKey, GetRedirectLogoV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetRedirectLogoV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectLogoV1>>>
export type GetRedirectLogoV1InfiniteQueryError = ErrorType<string>

/**
 * @deprecated
 * @summary Redirect response to the given source logo
 */
export const useGetRedirectLogoV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectLogoV1>>, GetRedirectLogoV1Params['continuation']>, TError = ErrorType<string>>(
 params: GetRedirectLogoV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectLogoV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectLogoV1>>, QueryKey, GetRedirectLogoV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectLogoV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectLogoV1QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectLogoV1>>, TError = ErrorType<string>>(params: GetRedirectLogoV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectLogoV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectLogoV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectLogoV1>>> = ({ signal }) => getRedirectLogoV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectLogoV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectLogoV1QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectLogoV1>>>
export type GetRedirectLogoV1QueryError = ErrorType<string>

/**
 * @deprecated
 * @summary Redirect response to the given source logo
 */
export const useGetRedirectLogoV1 = <TData = Awaited<ReturnType<typeof getRedirectLogoV1>>, TError = ErrorType<string>>(
 params: GetRedirectLogoV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectLogoV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectLogoV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary Redirect response to the given source token page
 */
export const getRedirectTokenV1 = (
    params: GetRedirectTokenV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/token/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRedirectTokenV1QueryKey = (params: GetRedirectTokenV1Params,) => {
    return [`/redirect/token/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRedirectTokenV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectTokenV1>>, GetRedirectTokenV1Params['continuation']>, TError = ErrorType<string>>(params: GetRedirectTokenV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectTokenV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectTokenV1>>, QueryKey, GetRedirectTokenV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectTokenV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectTokenV1>>, QueryKey, GetRedirectTokenV1Params['continuation']> = ({ signal, pageParam }) => getRedirectTokenV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectTokenV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectTokenV1>>, QueryKey, GetRedirectTokenV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetRedirectTokenV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectTokenV1>>>
export type GetRedirectTokenV1InfiniteQueryError = ErrorType<string>

/**
 * @deprecated
 * @summary Redirect response to the given source token page
 */
export const useGetRedirectTokenV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectTokenV1>>, GetRedirectTokenV1Params['continuation']>, TError = ErrorType<string>>(
 params: GetRedirectTokenV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectTokenV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectTokenV1>>, QueryKey, GetRedirectTokenV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectTokenV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectTokenV1QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectTokenV1>>, TError = ErrorType<string>>(params: GetRedirectTokenV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectTokenV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectTokenV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectTokenV1>>> = ({ signal }) => getRedirectTokenV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectTokenV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectTokenV1QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectTokenV1>>>
export type GetRedirectTokenV1QueryError = ErrorType<string>

/**
 * @deprecated
 * @summary Redirect response to the given source token page
 */
export const useGetRedirectTokenV1 = <TData = Awaited<ReturnType<typeof getRedirectTokenV1>>, TError = ErrorType<string>>(
 params: GetRedirectTokenV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectTokenV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectTokenV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Note: this API is optimized for bulk access, and offers minimal filters/metadata. If you need more flexibility, try the `NFT API > Sales` endpoint
 * @deprecated
 * @summary Bulk historical sales
 */
export const getSalesBulkV1 = (
    params?: GetSalesBulkV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSalesBulkV1Response>(
      {url: `/sales/bulk/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSalesBulkV1QueryKey = (params?: GetSalesBulkV1Params,) => {
    return [`/sales/bulk/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSalesBulkV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesBulkV1>>, GetSalesBulkV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSalesBulkV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData, Awaited<ReturnType<typeof getSalesBulkV1>>, QueryKey, GetSalesBulkV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesBulkV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesBulkV1>>, QueryKey, GetSalesBulkV1Params['continuation']> = ({ signal, pageParam }) => getSalesBulkV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData, Awaited<ReturnType<typeof getSalesBulkV1>>, QueryKey, GetSalesBulkV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSalesBulkV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSalesBulkV1>>>
export type GetSalesBulkV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical sales
 */
export const useGetSalesBulkV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSalesBulkV1>>, GetSalesBulkV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSalesBulkV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData, Awaited<ReturnType<typeof getSalesBulkV1>>, QueryKey, GetSalesBulkV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesBulkV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSalesBulkV1QueryOptions = <TData = Awaited<ReturnType<typeof getSalesBulkV1>>, TError = ErrorType<unknown>>(params?: GetSalesBulkV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSalesBulkV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesBulkV1>>> = ({ signal }) => getSalesBulkV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSalesBulkV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSalesBulkV1>>>
export type GetSalesBulkV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical sales
 */
export const useGetSalesBulkV1 = <TData = Awaited<ReturnType<typeof getSalesBulkV1>>, TError = ErrorType<unknown>>(
 params?: GetSalesBulkV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSalesBulkV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSalesBulkV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a collection including sales, asks, transfers, mints, bids, cancelled bids, and cancelled asks types.
 * @summary Search activity
 */
export const getSearchActivitiesV1 = (
    params?: GetSearchActivitiesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSearchActivitiesV1Response>(
      {url: `/search/activities/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSearchActivitiesV1QueryKey = (params?: GetSearchActivitiesV1Params,) => {
    return [`/search/activities/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSearchActivitiesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchActivitiesV1>>, GetSearchActivitiesV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSearchActivitiesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData, Awaited<ReturnType<typeof getSearchActivitiesV1>>, QueryKey, GetSearchActivitiesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchActivitiesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchActivitiesV1>>, QueryKey, GetSearchActivitiesV1Params['continuation']> = ({ signal, pageParam }) => getSearchActivitiesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData, Awaited<ReturnType<typeof getSearchActivitiesV1>>, QueryKey, GetSearchActivitiesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSearchActivitiesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchActivitiesV1>>>
export type GetSearchActivitiesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Search activity
 */
export const useGetSearchActivitiesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchActivitiesV1>>, GetSearchActivitiesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSearchActivitiesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData, Awaited<ReturnType<typeof getSearchActivitiesV1>>, QueryKey, GetSearchActivitiesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchActivitiesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSearchActivitiesV1QueryOptions = <TData = Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError = ErrorType<unknown>>(params?: GetSearchActivitiesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchActivitiesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchActivitiesV1>>> = ({ signal }) => getSearchActivitiesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSearchActivitiesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSearchActivitiesV1>>>
export type GetSearchActivitiesV1QueryError = ErrorType<unknown>

/**
 * @summary Search activity
 */
export const useGetSearchActivitiesV1 = <TData = Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError = ErrorType<unknown>>(
 params?: GetSearchActivitiesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchActivitiesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchActivitiesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Search collections
 */
export const getSearchCollectionsV1 = (
    params?: GetSearchCollectionsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSearchCollectionsV1Response>(
      {url: `/search/collections/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSearchCollectionsV1QueryKey = (params?: GetSearchCollectionsV1Params,) => {
    return [`/search/collections/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSearchCollectionsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchCollectionsV1>>, GetSearchCollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSearchCollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getSearchCollectionsV1>>, QueryKey, GetSearchCollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchCollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchCollectionsV1>>, QueryKey, GetSearchCollectionsV1Params['continuation']> = ({ signal, pageParam }) => getSearchCollectionsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getSearchCollectionsV1>>, QueryKey, GetSearchCollectionsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSearchCollectionsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchCollectionsV1>>>
export type GetSearchCollectionsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Search collections
 */
export const useGetSearchCollectionsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchCollectionsV1>>, GetSearchCollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSearchCollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getSearchCollectionsV1>>, QueryKey, GetSearchCollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchCollectionsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSearchCollectionsV1QueryOptions = <TData = Awaited<ReturnType<typeof getSearchCollectionsV1>>, TError = ErrorType<unknown>>(params?: GetSearchCollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchCollectionsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchCollectionsV1>>> = ({ signal }) => getSearchCollectionsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSearchCollectionsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSearchCollectionsV1>>>
export type GetSearchCollectionsV1QueryError = ErrorType<unknown>

/**
 * @summary Search collections
 */
export const useGetSearchCollectionsV1 = <TData = Awaited<ReturnType<typeof getSearchCollectionsV1>>, TError = ErrorType<unknown>>(
 params?: GetSearchCollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchCollectionsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Search collections
 */
export const getSearchCollectionsV2 = (
    params?: GetSearchCollectionsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetSearchCollectionsV2Response>(
      {url: `/search/collections/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSearchCollectionsV2QueryKey = (params?: GetSearchCollectionsV2Params,) => {
    return [`/search/collections/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSearchCollectionsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchCollectionsV2>>, GetSearchCollectionsV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSearchCollectionsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getSearchCollectionsV2>>, QueryKey, GetSearchCollectionsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchCollectionsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchCollectionsV2>>, QueryKey, GetSearchCollectionsV2Params['continuation']> = ({ signal, pageParam }) => getSearchCollectionsV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getSearchCollectionsV2>>, QueryKey, GetSearchCollectionsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetSearchCollectionsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSearchCollectionsV2>>>
export type GetSearchCollectionsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Search collections
 */
export const useGetSearchCollectionsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSearchCollectionsV2>>, GetSearchCollectionsV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSearchCollectionsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getSearchCollectionsV2>>, QueryKey, GetSearchCollectionsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchCollectionsV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSearchCollectionsV2QueryOptions = <TData = Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError = ErrorType<unknown>>(params?: GetSearchCollectionsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSearchCollectionsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSearchCollectionsV2>>> = ({ signal }) => getSearchCollectionsV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSearchCollectionsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getSearchCollectionsV2>>>
export type GetSearchCollectionsV2QueryError = ErrorType<unknown>

/**
 * @summary Search collections
 */
export const useGetSearchCollectionsV2 = <TData = Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError = ErrorType<unknown>>(
 params?: GetSearchCollectionsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSearchCollectionsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSearchCollectionsV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is optimized for bulk access to asks (listings) for syncing a remote database. Thus it offers minimal filters/metadata.
 * @summary Sync Asks (listings)
 */
export const getSyncAsksV1 = (
    params?: GetSyncAsksV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<SyncOrdersAsksV1Response>(
      {url: `/sync/asks/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetSyncAsksV1QueryKey = (params?: GetSyncAsksV1Params,) => {
    return [`/sync/asks/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSyncAsksV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getSyncAsksV1>>, GetSyncAsksV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetSyncAsksV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData, Awaited<ReturnType<typeof getSyncAsksV1>>, QueryKey, GetSyncAsksV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSyncAsksV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSyncAsksV1>>, QueryKey, GetSyncAsksV1Params['continuation']> = ({ signal, pageParam }) => getSyncAsksV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData, Awaited<ReturnType<typeof getSyncAsksV1>>, QueryKey, GetSyncAsksV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetSyncAsksV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getSyncAsksV1>>>
export type GetSyncAsksV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Sync Asks (listings)
 */
export const useGetSyncAsksV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getSyncAsksV1>>, GetSyncAsksV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetSyncAsksV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData, Awaited<ReturnType<typeof getSyncAsksV1>>, QueryKey, GetSyncAsksV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSyncAsksV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetSyncAsksV1QueryOptions = <TData = Awaited<ReturnType<typeof getSyncAsksV1>>, TError = ErrorType<unknown>>(params?: GetSyncAsksV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSyncAsksV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSyncAsksV1>>> = ({ signal }) => getSyncAsksV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSyncAsksV1QueryResult = NonNullable<Awaited<ReturnType<typeof getSyncAsksV1>>>
export type GetSyncAsksV1QueryError = ErrorType<unknown>

/**
 * @summary Sync Asks (listings)
 */
export const useGetSyncAsksV1 = <TData = Awaited<ReturnType<typeof getSyncAsksV1>>, TError = ErrorType<unknown>>(
 params?: GetSyncAsksV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getSyncAsksV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSyncAsksV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get the latest price event per token in a collection, so that you can listen to future events and keep track of prices
 * @summary Token Events Bootstrap
 */
export const getTokensBootstrapV1 = (
    params?: GetTokensBootstrapV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensBootstrapV1Response>(
      {url: `/tokens/bootstrap/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensBootstrapV1QueryKey = (params?: GetTokensBootstrapV1Params,) => {
    return [`/tokens/bootstrap/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensBootstrapV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensBootstrapV1>>, GetTokensBootstrapV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensBootstrapV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData, Awaited<ReturnType<typeof getTokensBootstrapV1>>, QueryKey, GetTokensBootstrapV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensBootstrapV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensBootstrapV1>>, QueryKey, GetTokensBootstrapV1Params['continuation']> = ({ signal, pageParam }) => getTokensBootstrapV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData, Awaited<ReturnType<typeof getTokensBootstrapV1>>, QueryKey, GetTokensBootstrapV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensBootstrapV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensBootstrapV1>>>
export type GetTokensBootstrapV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token Events Bootstrap
 */
export const useGetTokensBootstrapV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensBootstrapV1>>, GetTokensBootstrapV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensBootstrapV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData, Awaited<ReturnType<typeof getTokensBootstrapV1>>, QueryKey, GetTokensBootstrapV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensBootstrapV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensBootstrapV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError = ErrorType<unknown>>(params?: GetTokensBootstrapV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensBootstrapV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensBootstrapV1>>> = ({ signal }) => getTokensBootstrapV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensBootstrapV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensBootstrapV1>>>
export type GetTokensBootstrapV1QueryError = ErrorType<unknown>

/**
 * @summary Token Events Bootstrap
 */
export const useGetTokensBootstrapV1 = <TData = Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError = ErrorType<unknown>>(
 params?: GetTokensBootstrapV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensBootstrapV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensBootstrapV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of tokens with full metadata. This is useful for showing a single token page, or scenarios that require more metadata. If you don't need this metadata, you should use the <a href='#/tokens/getTokensV1'>tokens</a> API, which is much faster.
 * @deprecated
 * @summary Get one or more tokens with full details
 */
export const getTokensDetailsV2 = (
    params?: GetTokensDetailsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensDetailsV2Response>(
      {url: `/tokens/details/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensDetailsV2QueryKey = (params?: GetTokensDetailsV2Params,) => {
    return [`/tokens/details/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensDetailsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensDetailsV2>>, GetTokensDetailsV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensDetailsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV2>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV2>>, QueryKey, GetTokensDetailsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensDetailsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensDetailsV2>>, QueryKey, GetTokensDetailsV2Params['continuation']> = ({ signal, pageParam }) => getTokensDetailsV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV2>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV2>>, QueryKey, GetTokensDetailsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensDetailsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensDetailsV2>>>
export type GetTokensDetailsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get one or more tokens with full details
 */
export const useGetTokensDetailsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensDetailsV2>>, GetTokensDetailsV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensDetailsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV2>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV2>>, QueryKey, GetTokensDetailsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensDetailsV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensDetailsV2QueryOptions = <TData = Awaited<ReturnType<typeof getTokensDetailsV2>>, TError = ErrorType<unknown>>(params?: GetTokensDetailsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensDetailsV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensDetailsV2>>> = ({ signal }) => getTokensDetailsV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensDetailsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensDetailsV2>>>
export type GetTokensDetailsV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get one or more tokens with full details
 */
export const useGetTokensDetailsV2 = <TData = Awaited<ReturnType<typeof getTokensDetailsV2>>, TError = ErrorType<unknown>>(
 params?: GetTokensDetailsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensDetailsV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of tokens with full metadata. This is useful for showing a single token page, or scenarios that require more metadata. If you don't need this metadata, you should use the <a href='#/tokens/getTokensV1'>tokens</a> API, which is much faster.
 * @deprecated
 * @summary Get one or more tokens with full details
 */
export const getTokensDetailsV3 = (
    params?: GetTokensDetailsV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensDetailsV3Response>(
      {url: `/tokens/details/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensDetailsV3QueryKey = (params?: GetTokensDetailsV3Params,) => {
    return [`/tokens/details/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensDetailsV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensDetailsV3>>, GetTokensDetailsV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensDetailsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV3>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV3>>, QueryKey, GetTokensDetailsV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensDetailsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensDetailsV3>>, QueryKey, GetTokensDetailsV3Params['continuation']> = ({ signal, pageParam }) => getTokensDetailsV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV3>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV3>>, QueryKey, GetTokensDetailsV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensDetailsV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensDetailsV3>>>
export type GetTokensDetailsV3InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get one or more tokens with full details
 */
export const useGetTokensDetailsV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensDetailsV3>>, GetTokensDetailsV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensDetailsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV3>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV3>>, QueryKey, GetTokensDetailsV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensDetailsV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensDetailsV3QueryOptions = <TData = Awaited<ReturnType<typeof getTokensDetailsV3>>, TError = ErrorType<unknown>>(params?: GetTokensDetailsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensDetailsV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensDetailsV3>>> = ({ signal }) => getTokensDetailsV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensDetailsV3QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensDetailsV3>>>
export type GetTokensDetailsV3QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get one or more tokens with full details
 */
export const useGetTokensDetailsV3 = <TData = Awaited<ReturnType<typeof getTokensDetailsV3>>, TError = ErrorType<unknown>>(
 params?: GetTokensDetailsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensDetailsV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a list of tokens with full metadata. This is useful for showing a single token page, or scenarios that require more metadata. If you don't need this metadata, you should use the <a href='#/tokens/getTokensV1'>tokens</a> API, which is much faster.
 * @deprecated
 * @summary Tokens (detailed response)
 */
export const getTokensDetailsV4 = (
    params?: GetTokensDetailsV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensDetailsV4Response>(
      {url: `/tokens/details/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensDetailsV4QueryKey = (params?: GetTokensDetailsV4Params,) => {
    return [`/tokens/details/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensDetailsV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensDetailsV4>>, GetTokensDetailsV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensDetailsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV4>>, QueryKey, GetTokensDetailsV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensDetailsV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensDetailsV4>>, QueryKey, GetTokensDetailsV4Params['continuation']> = ({ signal, pageParam }) => getTokensDetailsV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV4>>, QueryKey, GetTokensDetailsV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensDetailsV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensDetailsV4>>>
export type GetTokensDetailsV4InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Tokens (detailed response)
 */
export const useGetTokensDetailsV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensDetailsV4>>, GetTokensDetailsV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensDetailsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData, Awaited<ReturnType<typeof getTokensDetailsV4>>, QueryKey, GetTokensDetailsV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensDetailsV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensDetailsV4QueryOptions = <TData = Awaited<ReturnType<typeof getTokensDetailsV4>>, TError = ErrorType<unknown>>(params?: GetTokensDetailsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensDetailsV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensDetailsV4>>> = ({ signal }) => getTokensDetailsV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensDetailsV4QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensDetailsV4>>>
export type GetTokensDetailsV4QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Tokens (detailed response)
 */
export const useGetTokensDetailsV4 = <TData = Awaited<ReturnType<typeof getTokensDetailsV4>>, TError = ErrorType<unknown>>(
 params?: GetTokensDetailsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensDetailsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensDetailsV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API will return the best price of every token in a collection that is currently on sale. Note: Prices are returned in the native currency of the network.
 * @summary Token Prices
 */
export const getTokensFloorV1 = (
    params?: GetTokensFloorV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensFloorV1Response>(
      {url: `/tokens/floor/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensFloorV1QueryKey = (params?: GetTokensFloorV1Params,) => {
    return [`/tokens/floor/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensFloorV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensFloorV1>>, GetTokensFloorV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensFloorV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFloorV1>>, QueryKey, GetTokensFloorV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensFloorV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensFloorV1>>, QueryKey, GetTokensFloorV1Params['continuation']> = ({ signal, pageParam }) => getTokensFloorV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFloorV1>>, QueryKey, GetTokensFloorV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensFloorV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensFloorV1>>>
export type GetTokensFloorV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token Prices
 */
export const useGetTokensFloorV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensFloorV1>>, GetTokensFloorV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensFloorV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFloorV1>>, QueryKey, GetTokensFloorV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensFloorV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensFloorV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensFloorV1>>, TError = ErrorType<unknown>>(params?: GetTokensFloorV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensFloorV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensFloorV1>>> = ({ signal }) => getTokensFloorV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensFloorV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensFloorV1>>>
export type GetTokensFloorV1QueryError = ErrorType<unknown>

/**
 * @summary Token Prices
 */
export const useGetTokensFloorV1 = <TData = Awaited<ReturnType<typeof getTokensFloorV1>>, TError = ErrorType<unknown>>(
 params?: GetTokensFloorV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensFloorV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensFloorV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API is optimized for quickly fetching a list of tokens ids in by collection, contract, token set id. 
 * @summary Token IDs
 */
export const getTokensIdsV1 = (
    params?: GetTokensIdsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensIdsV1Response>(
      {url: `/tokens/ids/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensIdsV1QueryKey = (params?: GetTokensIdsV1Params,) => {
    return [`/tokens/ids/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensIdsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensIdsV1>>, GetTokensIdsV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensIdsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData, Awaited<ReturnType<typeof getTokensIdsV1>>, QueryKey, GetTokensIdsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensIdsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensIdsV1>>, QueryKey, GetTokensIdsV1Params['continuation']> = ({ signal, pageParam }) => getTokensIdsV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData, Awaited<ReturnType<typeof getTokensIdsV1>>, QueryKey, GetTokensIdsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensIdsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensIdsV1>>>
export type GetTokensIdsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token IDs
 */
export const useGetTokensIdsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensIdsV1>>, GetTokensIdsV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensIdsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData, Awaited<ReturnType<typeof getTokensIdsV1>>, QueryKey, GetTokensIdsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensIdsV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensIdsV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensIdsV1>>, TError = ErrorType<unknown>>(params?: GetTokensIdsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensIdsV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensIdsV1>>> = ({ signal }) => getTokensIdsV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensIdsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensIdsV1>>>
export type GetTokensIdsV1QueryError = ErrorType<unknown>

/**
 * @summary Token IDs
 */
export const useGetTokensIdsV1 = <TData = Awaited<ReturnType<typeof getTokensIdsV1>>, TError = ErrorType<unknown>>(
 params?: GetTokensIdsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensIdsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensIdsV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Note: this API is optimized for bulk access, and offers minimal filters/metadata. If you need more flexibility, try the `NFT API > Transfers` endpoint
 * @deprecated
 * @summary Bulk historical transfers
 */
export const getTransfersBulkV1 = (
    params?: GetTransfersBulkV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTransfersBulkV1Response>(
      {url: `/transfers/bulk/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTransfersBulkV1QueryKey = (params?: GetTransfersBulkV1Params,) => {
    return [`/transfers/bulk/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTransfersBulkV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersBulkV1>>, GetTransfersBulkV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTransfersBulkV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV1>>, TError, TData, Awaited<ReturnType<typeof getTransfersBulkV1>>, QueryKey, GetTransfersBulkV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersBulkV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersBulkV1>>, QueryKey, GetTransfersBulkV1Params['continuation']> = ({ signal, pageParam }) => getTransfersBulkV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV1>>, TError, TData, Awaited<ReturnType<typeof getTransfersBulkV1>>, QueryKey, GetTransfersBulkV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTransfersBulkV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersBulkV1>>>
export type GetTransfersBulkV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical transfers
 */
export const useGetTransfersBulkV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersBulkV1>>, GetTransfersBulkV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTransfersBulkV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV1>>, TError, TData, Awaited<ReturnType<typeof getTransfersBulkV1>>, QueryKey, GetTransfersBulkV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersBulkV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTransfersBulkV1QueryOptions = <TData = Awaited<ReturnType<typeof getTransfersBulkV1>>, TError = ErrorType<unknown>>(params?: GetTransfersBulkV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersBulkV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersBulkV1>>> = ({ signal }) => getTransfersBulkV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransfersBulkV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersBulkV1>>>
export type GetTransfersBulkV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bulk historical transfers
 */
export const useGetTransfersBulkV1 = <TData = Awaited<ReturnType<typeof getTransfersBulkV1>>, TError = ErrorType<unknown>>(
 params?: GetTransfersBulkV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersBulkV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Note: this API is optimized for bulk access, and offers minimal filters/metadata. If you need more flexibility, try the `NFT API > Transfers` endpoint
 * @summary Bulk historical transfers
 */
export const getTransfersBulkV2 = (
    params?: GetTransfersBulkV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTransfersBulkV1Response>(
      {url: `/transfers/bulk/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTransfersBulkV2QueryKey = (params?: GetTransfersBulkV2Params,) => {
    return [`/transfers/bulk/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTransfersBulkV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersBulkV2>>, GetTransfersBulkV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTransfersBulkV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData, Awaited<ReturnType<typeof getTransfersBulkV2>>, QueryKey, GetTransfersBulkV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersBulkV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersBulkV2>>, QueryKey, GetTransfersBulkV2Params['continuation']> = ({ signal, pageParam }) => getTransfersBulkV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData, Awaited<ReturnType<typeof getTransfersBulkV2>>, QueryKey, GetTransfersBulkV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetTransfersBulkV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersBulkV2>>>
export type GetTransfersBulkV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Bulk historical transfers
 */
export const useGetTransfersBulkV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTransfersBulkV2>>, GetTransfersBulkV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTransfersBulkV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData, Awaited<ReturnType<typeof getTransfersBulkV2>>, QueryKey, GetTransfersBulkV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersBulkV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTransfersBulkV2QueryOptions = <TData = Awaited<ReturnType<typeof getTransfersBulkV2>>, TError = ErrorType<unknown>>(params?: GetTransfersBulkV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransfersBulkV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransfersBulkV2>>> = ({ signal }) => getTransfersBulkV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransfersBulkV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTransfersBulkV2>>>
export type GetTransfersBulkV2QueryError = ErrorType<unknown>

/**
 * @summary Bulk historical transfers
 */
export const useGetTransfersBulkV2 = <TData = Awaited<ReturnType<typeof getTransfersBulkV2>>, TError = ErrorType<unknown>>(
 params?: GetTransfersBulkV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransfersBulkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransfersBulkV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a user
 * @summary Users activity
 */
export const getUsersActivityV2 = (
    params: GetUsersActivityV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserActivityV2Response>(
      {url: `/users/activity/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersActivityV2QueryKey = (params: GetUsersActivityV2Params,) => {
    return [`/users/activity/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersActivityV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV2>>, GetUsersActivityV2Params['continuation']>, TError = ErrorType<unknown>>(params: GetUsersActivityV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV2>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV2>>, QueryKey, GetUsersActivityV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV2>>, QueryKey, GetUsersActivityV2Params['continuation']> = ({ signal, pageParam }) => getUsersActivityV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV2>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV2>>, QueryKey, GetUsersActivityV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersActivityV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV2>>>
export type GetUsersActivityV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV2>>, GetUsersActivityV2Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV2>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV2>>, QueryKey, GetUsersActivityV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersActivityV2QueryOptions = <TData = Awaited<ReturnType<typeof getUsersActivityV2>>, TError = ErrorType<unknown>>(params: GetUsersActivityV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV2>>> = ({ signal }) => getUsersActivityV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersActivityV2QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV2>>>
export type GetUsersActivityV2QueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV2 = <TData = Awaited<ReturnType<typeof getUsersActivityV2>>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a user
 * @summary Users activity
 */
export const getUsersActivityV3 = (
    params: GetUsersActivityV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserActivityV3Response>(
      {url: `/users/activity/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersActivityV3QueryKey = (params: GetUsersActivityV3Params,) => {
    return [`/users/activity/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersActivityV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV3>>, GetUsersActivityV3Params['continuation']>, TError = ErrorType<unknown>>(params: GetUsersActivityV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV3>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV3>>, QueryKey, GetUsersActivityV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV3>>, QueryKey, GetUsersActivityV3Params['continuation']> = ({ signal, pageParam }) => getUsersActivityV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV3>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV3>>, QueryKey, GetUsersActivityV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersActivityV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV3>>>
export type GetUsersActivityV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV3>>, GetUsersActivityV3Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV3>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV3>>, QueryKey, GetUsersActivityV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersActivityV3QueryOptions = <TData = Awaited<ReturnType<typeof getUsersActivityV3>>, TError = ErrorType<unknown>>(params: GetUsersActivityV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV3>>> = ({ signal }) => getUsersActivityV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersActivityV3QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV3>>>
export type GetUsersActivityV3QueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV3 = <TData = Awaited<ReturnType<typeof getUsersActivityV3>>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a user
 * @summary Users activity
 */
export const getUsersActivityV4 = (
    params: GetUsersActivityV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserActivityV4Response>(
      {url: `/users/activity/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersActivityV4QueryKey = (params: GetUsersActivityV4Params,) => {
    return [`/users/activity/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersActivityV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV4>>, GetUsersActivityV4Params['continuation']>, TError = ErrorType<unknown>>(params: GetUsersActivityV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV4>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV4>>, QueryKey, GetUsersActivityV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV4>>, QueryKey, GetUsersActivityV4Params['continuation']> = ({ signal, pageParam }) => getUsersActivityV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV4>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV4>>, QueryKey, GetUsersActivityV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersActivityV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV4>>>
export type GetUsersActivityV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV4>>, GetUsersActivityV4Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV4>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV4>>, QueryKey, GetUsersActivityV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersActivityV4QueryOptions = <TData = Awaited<ReturnType<typeof getUsersActivityV4>>, TError = ErrorType<unknown>>(params: GetUsersActivityV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV4>>> = ({ signal }) => getUsersActivityV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersActivityV4QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV4>>>
export type GetUsersActivityV4QueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV4 = <TData = Awaited<ReturnType<typeof getUsersActivityV4>>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a user
 * @summary Users activity
 */
export const getUsersActivityV5 = (
    params: GetUsersActivityV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserActivityV5Response>(
      {url: `/users/activity/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersActivityV5QueryKey = (params: GetUsersActivityV5Params,) => {
    return [`/users/activity/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersActivityV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV5>>, GetUsersActivityV5Params['continuation']>, TError = ErrorType<unknown>>(params: GetUsersActivityV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV5>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV5>>, QueryKey, GetUsersActivityV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV5>>, QueryKey, GetUsersActivityV5Params['continuation']> = ({ signal, pageParam }) => getUsersActivityV5({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV5>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV5>>, QueryKey, GetUsersActivityV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersActivityV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV5>>>
export type GetUsersActivityV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV5>>, GetUsersActivityV5Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV5>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV5>>, QueryKey, GetUsersActivityV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV5InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersActivityV5QueryOptions = <TData = Awaited<ReturnType<typeof getUsersActivityV5>>, TError = ErrorType<unknown>>(params: GetUsersActivityV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV5>>> = ({ signal }) => getUsersActivityV5(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersActivityV5QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV5>>>
export type GetUsersActivityV5QueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV5 = <TData = Awaited<ReturnType<typeof getUsersActivityV5>>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV5QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a user including sales, asks, transfers, mints, bids, cancelled bids, and cancelled asks types.
 * @summary Users activity
 */
export const getUsersActivityV6 = (
    params: GetUsersActivityV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserActivityV6Response>(
      {url: `/users/activity/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersActivityV6QueryKey = (params: GetUsersActivityV6Params,) => {
    return [`/users/activity/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersActivityV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV6>>, GetUsersActivityV6Params['continuation']>, TError = ErrorType<unknown>>(params: GetUsersActivityV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV6>>, QueryKey, GetUsersActivityV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV6>>, QueryKey, GetUsersActivityV6Params['continuation']> = ({ signal, pageParam }) => getUsersActivityV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV6>>, QueryKey, GetUsersActivityV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersActivityV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV6>>>
export type GetUsersActivityV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersActivityV6>>, GetUsersActivityV6Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData, Awaited<ReturnType<typeof getUsersActivityV6>>, QueryKey, GetUsersActivityV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersActivityV6QueryOptions = <TData = Awaited<ReturnType<typeof getUsersActivityV6>>, TError = ErrorType<unknown>>(params: GetUsersActivityV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersActivityV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersActivityV6>>> = ({ signal }) => getUsersActivityV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersActivityV6QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersActivityV6>>>
export type GetUsersActivityV6QueryError = ErrorType<unknown>

/**
 * @summary Users activity
 */
export const useGetUsersActivityV6 = <TData = Awaited<ReturnType<typeof getUsersActivityV6>>, TError = ErrorType<unknown>>(
 params: GetUsersActivityV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersActivityV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersActivityV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * The ReservoirKit `ListModal` client utilizes this API to identify the marketplace(s) it can list on.
 * @summary Supported marketplaces by collection
 */
export const getCollectionsCollectionSupportedmarketplacesV1 = (
    collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<Model242>(
      {url: `/collections/${collection}/supported-marketplaces/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionSupportedmarketplacesV1QueryKey = (collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params,) => {
    return [`/collections/${collection}/supported-marketplaces/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionSupportedmarketplacesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, QueryKey, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionSupportedmarketplacesV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, QueryKey, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionSupportedmarketplacesV1(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, QueryKey, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionSupportedmarketplacesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>>
export type GetCollectionsCollectionSupportedmarketplacesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Supported marketplaces by collection
 */
export const useGetCollectionsCollectionSupportedmarketplacesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, QueryKey, GetCollectionsCollectionSupportedmarketplacesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionSupportedmarketplacesV1InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionSupportedmarketplacesV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionSupportedmarketplacesV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>> = ({ signal }) => getCollectionsCollectionSupportedmarketplacesV1(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionSupportedmarketplacesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>>
export type GetCollectionsCollectionSupportedmarketplacesV1QueryError = ErrorType<unknown>

/**
 * @summary Supported marketplaces by collection
 */
export const useGetCollectionsCollectionSupportedmarketplacesV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionSupportedmarketplacesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionSupportedmarketplacesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionSupportedmarketplacesV1QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * When users are placing collection or trait bids, this API can be used to show them where the bid is in the context of other bids, and how many tokens it will be the top bid for.
 * @deprecated
 * @summary Bid distribution
 */
export const getCollectionsCollectionTopbidsV1 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionTopBidsV1Response>(
      {url: `/collections/${collection}/top-bids/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionTopbidsV1QueryKey = (collection: string,) => {
    return [`/collections/${collection}/top-bids/v1`] as const;
    }

    
export const getGetCollectionsCollectionTopbidsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionTopbidsV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>> = ({ signal }) => getCollectionsCollectionTopbidsV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionTopbidsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>>
export type GetCollectionsCollectionTopbidsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bid distribution
 */
export const useGetCollectionsCollectionTopbidsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionTopbidsV1InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionTopbidsV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionTopbidsV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>> = ({ signal }) => getCollectionsCollectionTopbidsV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionTopbidsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>>
export type GetCollectionsCollectionTopbidsV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Bid distribution
 */
export const useGetCollectionsCollectionTopbidsV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionTopbidsV1QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary Get detailed aggregate about attributes in a collection, e.g. trait floors
 */
export const getCollectionsCollectionAttributesV1 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionAttributesV1Response>(
      {url: `/collections/${collection}/attributes/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesV1QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesV1Params,) => {
    return [`/collections/${collection}/attributes/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, GetCollectionsCollectionAttributesV1Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, QueryKey, GetCollectionsCollectionAttributesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, QueryKey, GetCollectionsCollectionAttributesV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesV1(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, QueryKey, GetCollectionsCollectionAttributesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>>
export type GetCollectionsCollectionAttributesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get detailed aggregate about attributes in a collection, e.g. trait floors
 */
export const useGetCollectionsCollectionAttributesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, GetCollectionsCollectionAttributesV1Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, QueryKey, GetCollectionsCollectionAttributesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesV1InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>> = ({ signal }) => getCollectionsCollectionAttributesV1(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>>
export type GetCollectionsCollectionAttributesV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get detailed aggregate about attributes in a collection, e.g. trait floors
 */
export const useGetCollectionsCollectionAttributesV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesV1QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a collection
 * @summary Collection activity
 */
export const getCollectionsCollectionActivityV3 = (
    collection: string,
    params?: GetCollectionsCollectionActivityV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionActivityV3Response>(
      {url: `/collections/${collection}/activity/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionActivityV3QueryKey = (collection: string,
    params?: GetCollectionsCollectionActivityV3Params,) => {
    return [`/collections/${collection}/activity/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionActivityV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, GetCollectionsCollectionActivityV3Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionActivityV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, QueryKey, GetCollectionsCollectionActivityV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionActivityV3QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, QueryKey, GetCollectionsCollectionActivityV3Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionActivityV3(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, QueryKey, GetCollectionsCollectionActivityV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionActivityV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>>
export type GetCollectionsCollectionActivityV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsCollectionActivityV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, GetCollectionsCollectionActivityV3Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionActivityV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, QueryKey, GetCollectionsCollectionActivityV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionActivityV3InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionActivityV3QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionActivityV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionActivityV3QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>> = ({ signal }) => getCollectionsCollectionActivityV3(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionActivityV3QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>>
export type GetCollectionsCollectionActivityV3QueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsCollectionActivityV3 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionActivityV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionActivityV3QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a collection
 * @summary Collection activity
 */
export const getCollectionsCollectionActivityV1 = (
    collection: string,
    params?: GetCollectionsCollectionActivityV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserActivityV2Response>(
      {url: `/collections/${collection}/activity/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionActivityV1QueryKey = (collection: string,
    params?: GetCollectionsCollectionActivityV1Params,) => {
    return [`/collections/${collection}/activity/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionActivityV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, GetCollectionsCollectionActivityV1Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionActivityV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, QueryKey, GetCollectionsCollectionActivityV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionActivityV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, QueryKey, GetCollectionsCollectionActivityV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionActivityV1(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, QueryKey, GetCollectionsCollectionActivityV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionActivityV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>>
export type GetCollectionsCollectionActivityV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsCollectionActivityV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, GetCollectionsCollectionActivityV1Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionActivityV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, QueryKey, GetCollectionsCollectionActivityV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionActivityV1InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionActivityV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionActivityV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionActivityV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>> = ({ signal }) => getCollectionsCollectionActivityV1(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionActivityV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>>
export type GetCollectionsCollectionActivityV1QueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsCollectionActivityV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionActivityV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionActivityV1QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a collection
 * @summary Collection activity
 */
export const getCollectionsCollectionActivityV2 = (
    collection: string,
    params?: GetCollectionsCollectionActivityV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionActivityV2Response>(
      {url: `/collections/${collection}/activity/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionActivityV2QueryKey = (collection: string,
    params?: GetCollectionsCollectionActivityV2Params,) => {
    return [`/collections/${collection}/activity/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionActivityV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, GetCollectionsCollectionActivityV2Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionActivityV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, QueryKey, GetCollectionsCollectionActivityV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionActivityV2QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, QueryKey, GetCollectionsCollectionActivityV2Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionActivityV2(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, QueryKey, GetCollectionsCollectionActivityV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionActivityV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>>
export type GetCollectionsCollectionActivityV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsCollectionActivityV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, GetCollectionsCollectionActivityV2Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionActivityV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, QueryKey, GetCollectionsCollectionActivityV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionActivityV2InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionActivityV2QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionActivityV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionActivityV2QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>> = ({ signal }) => getCollectionsCollectionActivityV2(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionActivityV2QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>>
export type GetCollectionsCollectionActivityV2QueryError = ErrorType<unknown>

/**
 * @summary Collection activity
 */
export const useGetCollectionsCollectionActivityV2 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionActivityV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionActivityV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionActivityV2QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to show what the distribution of owners in a collection looks like.
 * @summary Owners Collection Distribution
 */
export const getCollectionsCollectionOwnersdistributionV1 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionOwnersDistributionV1Response>(
      {url: `/collections/${collection}/owners-distribution/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionOwnersdistributionV1QueryKey = (collection: string,) => {
    return [`/collections/${collection}/owners-distribution/v1`] as const;
    }

    
export const getGetCollectionsCollectionOwnersdistributionV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionOwnersdistributionV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>> = ({ signal }) => getCollectionsCollectionOwnersdistributionV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionOwnersdistributionV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>>
export type GetCollectionsCollectionOwnersdistributionV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners Collection Distribution
 */
export const useGetCollectionsCollectionOwnersdistributionV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionOwnersdistributionV1InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionOwnersdistributionV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionOwnersdistributionV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>> = ({ signal }) => getCollectionsCollectionOwnersdistributionV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionOwnersdistributionV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>>
export type GetCollectionsCollectionOwnersdistributionV1QueryError = ErrorType<unknown>

/**
 * @summary Owners Collection Distribution
 */
export const useGetCollectionsCollectionOwnersdistributionV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionOwnersdistributionV1QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API returns recommended marketplace configurations given a collection id
 * @summary Marketplace configurations by collection
 */
export const getCollectionsCollectionMarketplaceconfigurationsV1 = (
    collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<Model255>(
      {url: `/collections/${collection}/marketplace-configurations/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionMarketplaceconfigurationsV1QueryKey = (collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params,) => {
    return [`/collections/${collection}/marketplace-configurations/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionMarketplaceconfigurationsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, QueryKey, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionMarketplaceconfigurationsV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, QueryKey, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionMarketplaceconfigurationsV1(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, QueryKey, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionMarketplaceconfigurationsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>>
export type GetCollectionsCollectionMarketplaceconfigurationsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Marketplace configurations by collection
 */
export const useGetCollectionsCollectionMarketplaceconfigurationsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, QueryKey, GetCollectionsCollectionMarketplaceconfigurationsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionMarketplaceconfigurationsV1InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionMarketplaceconfigurationsV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionMarketplaceconfigurationsV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>> = ({ signal }) => getCollectionsCollectionMarketplaceconfigurationsV1(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionMarketplaceconfigurationsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>>
export type GetCollectionsCollectionMarketplaceconfigurationsV1QueryError = ErrorType<unknown>

/**
 * @summary Marketplace configurations by collection
 */
export const useGetCollectionsCollectionMarketplaceconfigurationsV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionMarketplaceconfigurationsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionMarketplaceconfigurationsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionMarketplaceconfigurationsV1QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to show what the distribution of owners in a collections set id looks like.
 * @summary Owners Collection Set Distribution
 */
export const getCollectionssetsCollectionssetidOwnersdistributionV1 = (
    collectionsSetId: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsSetOwnersDistributionV1Response>(
      {url: `/collections-sets/${collectionsSetId}/owners-distribution/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryKey = (collectionsSetId: string,) => {
    return [`/collections-sets/${collectionsSetId}/owners-distribution/v1`] as const;
    }

    
export const getGetCollectionssetsCollectionssetidOwnersdistributionV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>>, TError = ErrorType<unknown>>(collectionsSetId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryKey(collectionsSetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>> = ({ signal }) => getCollectionssetsCollectionssetidOwnersdistributionV1(collectionsSetId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionsSetId),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionssetsCollectionssetidOwnersdistributionV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>>
export type GetCollectionssetsCollectionssetidOwnersdistributionV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Owners Collection Set Distribution
 */
export const useGetCollectionssetsCollectionssetidOwnersdistributionV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>>, TError = ErrorType<unknown>>(
 collectionsSetId: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionssetsCollectionssetidOwnersdistributionV1InfiniteQueryOptions(collectionsSetId,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError = ErrorType<unknown>>(collectionsSetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryKey(collectionsSetId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>> = ({ signal }) => getCollectionssetsCollectionssetidOwnersdistributionV1(collectionsSetId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionsSetId),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionssetsCollectionssetidOwnersdistributionV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>>
export type GetCollectionssetsCollectionssetidOwnersdistributionV1QueryError = ErrorType<unknown>

/**
 * @summary Owners Collection Set Distribution
 */
export const useGetCollectionssetsCollectionssetidOwnersdistributionV1 = <TData = Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError = ErrorType<unknown>>(
 collectionsSetId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionssetsCollectionssetidOwnersdistributionV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionssetsCollectionssetidOwnersdistributionV1QueryOptions(collectionsSetId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the floor price of a collection changes (i.e. the 'floor ask'), an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new listing at a lower price

- `expiry` > the previous best listing expired

- `sale` > the previous best listing was filled

- `cancel` > the previous best listing was cancelled

- `balance-change` > the best listing was invalidated due to no longer owning the NFT

- `approval-change` > the best listing was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.

- Events are only generated if the best price changes. So if a new order or sale happens without changing the best price, no event is generated. This is more common with 1155 tokens, which have multiple owners and more depth. For this reason, if you need sales data, use the Sales API.
 * @summary Collection floor changes
 */
export const getEventsCollectionsFlooraskV1 = (
    params?: GetEventsCollectionsFlooraskV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsFloorAskV1Response>(
      {url: `/events/collections/floor-ask/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsCollectionsFlooraskV1QueryKey = (params?: GetEventsCollectionsFlooraskV1Params,) => {
    return [`/events/collections/floor-ask/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsCollectionsFlooraskV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, GetEventsCollectionsFlooraskV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsFlooraskV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, QueryKey, GetEventsCollectionsFlooraskV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsFlooraskV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, QueryKey, GetEventsCollectionsFlooraskV1Params['continuation']> = ({ signal, pageParam }) => getEventsCollectionsFlooraskV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, QueryKey, GetEventsCollectionsFlooraskV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsCollectionsFlooraskV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>>
export type GetEventsCollectionsFlooraskV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection floor changes
 */
export const useGetEventsCollectionsFlooraskV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, GetEventsCollectionsFlooraskV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsFlooraskV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, QueryKey, GetEventsCollectionsFlooraskV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsFlooraskV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsCollectionsFlooraskV1QueryOptions = <TData = Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsFlooraskV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsFlooraskV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>> = ({ signal }) => getEventsCollectionsFlooraskV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsCollectionsFlooraskV1QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>>
export type GetEventsCollectionsFlooraskV1QueryError = ErrorType<unknown>

/**
 * @summary Collection floor changes
 */
export const useGetEventsCollectionsFlooraskV1 = <TData = Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsFlooraskV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsFlooraskV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the floor price of a collection changes (i.e. the 'floor ask'), an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new listing at a lower price

- `expiry` > the previous best listing expired

- `sale` > the previous best listing was filled

- `cancel` > the previous best listing was cancelled

- `balance-change` > the best listing was invalidated due to no longer owning the NFT

- `approval-change` > the best listing was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Selling a partial quantity of available 1155 tokens in a listing will generate a `sale` and will have a new quantity.

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.

- Events are only generated if the best price changes. So if a new order or sale happens without changing the best price, no event is generated. This is more common with 1155 tokens, which have multiple owners and more depth. For this reason, if you need sales data, use the Sales API.
 * @summary Collection floor changes
 */
export const getEventsCollectionsFlooraskV2 = (
    params?: GetEventsCollectionsFlooraskV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsFloorAskV2Response>(
      {url: `/events/collections/floor-ask/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsCollectionsFlooraskV2QueryKey = (params?: GetEventsCollectionsFlooraskV2Params,) => {
    return [`/events/collections/floor-ask/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsCollectionsFlooraskV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, GetEventsCollectionsFlooraskV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsFlooraskV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, QueryKey, GetEventsCollectionsFlooraskV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsFlooraskV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, QueryKey, GetEventsCollectionsFlooraskV2Params['continuation']> = ({ signal, pageParam }) => getEventsCollectionsFlooraskV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, QueryKey, GetEventsCollectionsFlooraskV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsCollectionsFlooraskV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>>
export type GetEventsCollectionsFlooraskV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection floor changes
 */
export const useGetEventsCollectionsFlooraskV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, GetEventsCollectionsFlooraskV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsFlooraskV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, QueryKey, GetEventsCollectionsFlooraskV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsFlooraskV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsCollectionsFlooraskV2QueryOptions = <TData = Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsFlooraskV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsFlooraskV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>> = ({ signal }) => getEventsCollectionsFlooraskV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsCollectionsFlooraskV2QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>>
export type GetEventsCollectionsFlooraskV2QueryError = ErrorType<unknown>

/**
 * @summary Collection floor changes
 */
export const useGetEventsCollectionsFlooraskV2 = <TData = Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsFlooraskV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsFlooraskV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsFlooraskV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the top offer of a collection changes (i.e. the 'top bid'), an event is generated. This API is designed to be polled at high frequency.
 * @summary Collection top bid changes
 */
export const getEventsCollectionsTopbidV1 = (
    params?: GetEventsCollectionsTopbidV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsTopbidV1Response>(
      {url: `/events/collections/top-bid/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsCollectionsTopbidV1QueryKey = (params?: GetEventsCollectionsTopbidV1Params,) => {
    return [`/events/collections/top-bid/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsCollectionsTopbidV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, GetEventsCollectionsTopbidV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsTopbidV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, QueryKey, GetEventsCollectionsTopbidV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsTopbidV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, QueryKey, GetEventsCollectionsTopbidV1Params['continuation']> = ({ signal, pageParam }) => getEventsCollectionsTopbidV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, QueryKey, GetEventsCollectionsTopbidV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsCollectionsTopbidV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>>
export type GetEventsCollectionsTopbidV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection top bid changes
 */
export const useGetEventsCollectionsTopbidV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, GetEventsCollectionsTopbidV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsTopbidV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, QueryKey, GetEventsCollectionsTopbidV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsTopbidV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsCollectionsTopbidV1QueryOptions = <TData = Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsTopbidV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsTopbidV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>> = ({ signal }) => getEventsCollectionsTopbidV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsCollectionsTopbidV1QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>>
export type GetEventsCollectionsTopbidV1QueryError = ErrorType<unknown>

/**
 * @summary Collection top bid changes
 */
export const useGetEventsCollectionsTopbidV1 = <TData = Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsTopbidV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsTopbidV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the top offer of a collection changes (i.e. the 'top bid'), an event is generated. This API is designed to be polled at high frequency.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new bid at a higher price

- `expiry` > the previous top bid expired

- `sale` > the previous top bid was accepted

- `cancel` > the previous top bid was cancelled

- `balance-change` > the top bid was invalidated due NFT no longer available

- `approval-change` > the top bid was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Selling a partial quantity of available 1155 tokens in a listing will generate a `sale` and will have a new quantity.

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.

- Events are only generated if the top bid changes. So if a new order or sale happens without changing the top bid, no event is generated. This is more common with 1155 tokens, which have multiple owners and more depth. For this reason, if you need sales data, use the Sales API.
 * @summary Collection top bid changes
 */
export const getEventsCollectionsTopbidV2 = (
    params?: GetEventsCollectionsTopbidV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionsTopbidV2Response>(
      {url: `/events/collections/top-bid/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsCollectionsTopbidV2QueryKey = (params?: GetEventsCollectionsTopbidV2Params,) => {
    return [`/events/collections/top-bid/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsCollectionsTopbidV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, GetEventsCollectionsTopbidV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsTopbidV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, QueryKey, GetEventsCollectionsTopbidV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsTopbidV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, QueryKey, GetEventsCollectionsTopbidV2Params['continuation']> = ({ signal, pageParam }) => getEventsCollectionsTopbidV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, QueryKey, GetEventsCollectionsTopbidV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsCollectionsTopbidV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>>
export type GetEventsCollectionsTopbidV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection top bid changes
 */
export const useGetEventsCollectionsTopbidV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, GetEventsCollectionsTopbidV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsTopbidV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData, Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, QueryKey, GetEventsCollectionsTopbidV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsTopbidV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsCollectionsTopbidV2QueryOptions = <TData = Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError = ErrorType<unknown>>(params?: GetEventsCollectionsTopbidV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsCollectionsTopbidV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>> = ({ signal }) => getEventsCollectionsTopbidV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsCollectionsTopbidV2QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>>
export type GetEventsCollectionsTopbidV2QueryError = ErrorType<unknown>

/**
 * @summary Collection top bid changes
 */
export const useGetEventsCollectionsTopbidV2 = <TData = Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError = ErrorType<unknown>>(
 params?: GetEventsCollectionsTopbidV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsCollectionsTopbidV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsCollectionsTopbidV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the best price of a token changes (i.e. the 'floor ask'), an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new listing at a lower price

- `expiry` > the previous best listing expired

- `sale` > the previous best listing was filled

- `cancel` > the previous best listing was cancelled

- `balance-change` > the best listing was invalidated due to no longer owning the NFT

- `approval-change` > the best listing was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.

- Events are only generated if the best price changes. So if a new order or sale happens without changing the best price, no event is generated. This is more common with 1155 tokens, which have multiple owners and more depth. For this reason, if you need sales data, use the Sales API.
 * @summary Token price changes
 */
export const getEventsTokensFlooraskV2 = (
    params?: GetEventsTokensFlooraskV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensFloorAskV2Response>(
      {url: `/events/tokens/floor-ask/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsTokensFlooraskV2QueryKey = (params?: GetEventsTokensFlooraskV2Params,) => {
    return [`/events/tokens/floor-ask/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsTokensFlooraskV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, GetEventsTokensFlooraskV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsTokensFlooraskV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, QueryKey, GetEventsTokensFlooraskV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsTokensFlooraskV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, QueryKey, GetEventsTokensFlooraskV2Params['continuation']> = ({ signal, pageParam }) => getEventsTokensFlooraskV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, QueryKey, GetEventsTokensFlooraskV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsTokensFlooraskV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>>
export type GetEventsTokensFlooraskV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token price changes
 */
export const useGetEventsTokensFlooraskV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, GetEventsTokensFlooraskV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsTokensFlooraskV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, QueryKey, GetEventsTokensFlooraskV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsTokensFlooraskV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsTokensFlooraskV2QueryOptions = <TData = Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, TError = ErrorType<unknown>>(params?: GetEventsTokensFlooraskV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsTokensFlooraskV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>> = ({ signal }) => getEventsTokensFlooraskV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsTokensFlooraskV2QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>>
export type GetEventsTokensFlooraskV2QueryError = ErrorType<unknown>

/**
 * @summary Token price changes
 */
export const useGetEventsTokensFlooraskV2 = <TData = Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, TError = ErrorType<unknown>>(
 params?: GetEventsTokensFlooraskV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsTokensFlooraskV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the best price of a token changes (i.e. the 'floor ask'), an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new listing at a lower price

- `expiry` > the previous best listing expired

- `sale` > the previous best listing was filled

- `cancel` > the previous best listing was cancelled

- `balance-change` > the best listing was invalidated due to no longer owning the NFT

- `approval-change` > the best listing was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.

- Events are only generated if the best price changes. So if a new order or sale happens without changing the best price, no event is generated. This is more common with 1155 tokens, which have multiple owners and more depth. For this reason, if you need sales data, use the Sales API.
 * @summary Token price changes
 */
export const getEventsTokensFlooraskV3 = (
    params?: GetEventsTokensFlooraskV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensFloorAskV3Response>(
      {url: `/events/tokens/floor-ask/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsTokensFlooraskV3QueryKey = (params?: GetEventsTokensFlooraskV3Params,) => {
    return [`/events/tokens/floor-ask/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsTokensFlooraskV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, GetEventsTokensFlooraskV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsTokensFlooraskV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, QueryKey, GetEventsTokensFlooraskV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsTokensFlooraskV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, QueryKey, GetEventsTokensFlooraskV3Params['continuation']> = ({ signal, pageParam }) => getEventsTokensFlooraskV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, QueryKey, GetEventsTokensFlooraskV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsTokensFlooraskV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>>
export type GetEventsTokensFlooraskV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token price changes
 */
export const useGetEventsTokensFlooraskV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, GetEventsTokensFlooraskV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsTokensFlooraskV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, QueryKey, GetEventsTokensFlooraskV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsTokensFlooraskV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsTokensFlooraskV3QueryOptions = <TData = Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, TError = ErrorType<unknown>>(params?: GetEventsTokensFlooraskV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsTokensFlooraskV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>> = ({ signal }) => getEventsTokensFlooraskV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsTokensFlooraskV3QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>>
export type GetEventsTokensFlooraskV3QueryError = ErrorType<unknown>

/**
 * @summary Token price changes
 */
export const useGetEventsTokensFlooraskV3 = <TData = Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, TError = ErrorType<unknown>>(
 params?: GetEventsTokensFlooraskV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsTokensFlooraskV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Every time the best price of a token changes (i.e. the 'floor ask'), an event is generated. This API is designed to be polled at high frequency, in order to keep an external system in sync with accurate prices for any token.

There are multiple event types, which describe what caused the change in price:

- `new-order` > new listing at a lower price

- `expiry` > the previous best listing expired

- `sale` > the previous best listing was filled

- `cancel` > the previous best listing was cancelled

- `balance-change` > the best listing was invalidated due to no longer owning the NFT

- `approval-change` > the best listing was invalidated due to revoked approval

- `revalidation` > manual revalidation of orders (e.g. after a bug fixed)

- `reprice` > price update for dynamic orders (e.g. dutch auctions)

- `bootstrap` > initial loading of data, so that all tokens have a price associated

Some considerations to keep in mind

- Selling a partial quantity of available 1155 tokens in a listing will generate a `sale` and will have a new quantity.

- Due to the complex nature of monitoring off-chain liquidity across multiple marketplaces, including dealing with block re-orgs, events should be considered 'relative' to the perspective of the indexer, ie _when they were discovered_, rather than _when they happened_. A more deterministic historical record of price changes is in development, but in the meantime, this method is sufficent for keeping an external system in sync with the best available prices.

- Events are only generated if the best price changes. So if a new order or sale happens without changing the best price, no event is generated. This is more common with 1155 tokens, which have multiple owners and more depth. For this reason, if you need sales data, use the Sales API.
 * @summary Token price changes
 */
export const getEventsTokensFlooraskV4 = (
    params?: GetEventsTokensFlooraskV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokensFloorAskV4Response>(
      {url: `/events/tokens/floor-ask/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetEventsTokensFlooraskV4QueryKey = (params?: GetEventsTokensFlooraskV4Params,) => {
    return [`/events/tokens/floor-ask/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEventsTokensFlooraskV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, GetEventsTokensFlooraskV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetEventsTokensFlooraskV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, QueryKey, GetEventsTokensFlooraskV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsTokensFlooraskV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, QueryKey, GetEventsTokensFlooraskV4Params['continuation']> = ({ signal, pageParam }) => getEventsTokensFlooraskV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, QueryKey, GetEventsTokensFlooraskV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetEventsTokensFlooraskV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>>
export type GetEventsTokensFlooraskV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token price changes
 */
export const useGetEventsTokensFlooraskV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, GetEventsTokensFlooraskV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetEventsTokensFlooraskV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData, Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, QueryKey, GetEventsTokensFlooraskV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsTokensFlooraskV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetEventsTokensFlooraskV4QueryOptions = <TData = Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError = ErrorType<unknown>>(params?: GetEventsTokensFlooraskV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventsTokensFlooraskV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>> = ({ signal }) => getEventsTokensFlooraskV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEventsTokensFlooraskV4QueryResult = NonNullable<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>>
export type GetEventsTokensFlooraskV4QueryError = ErrorType<unknown>

/**
 * @summary Token price changes
 */
export const useGetEventsTokensFlooraskV4 = <TData = Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError = ErrorType<unknown>>(
 params?: GetEventsTokensFlooraskV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventsTokensFlooraskV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventsTokensFlooraskV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of any collection's bid-ask midpoint (spot or twap). This is approximation of the colletion price. The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @summary Collection bid-ask midpoint
 */
export const getOracleCollectionsBidaskmidpointV1 = (
    params?: GetOracleCollectionsBidaskmidpointV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionBidAskMidpointOracleV1Response>(
      {url: `/oracle/collections/bid-ask-midpoint/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleCollectionsBidaskmidpointV1QueryKey = (params?: GetOracleCollectionsBidaskmidpointV1Params,) => {
    return [`/oracle/collections/bid-ask-midpoint/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleCollectionsBidaskmidpointV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, GetOracleCollectionsBidaskmidpointV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsBidaskmidpointV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, QueryKey, GetOracleCollectionsBidaskmidpointV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsBidaskmidpointV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, QueryKey, GetOracleCollectionsBidaskmidpointV1Params['continuation']> = ({ signal, pageParam }) => getOracleCollectionsBidaskmidpointV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, QueryKey, GetOracleCollectionsBidaskmidpointV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleCollectionsBidaskmidpointV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>>
export type GetOracleCollectionsBidaskmidpointV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection bid-ask midpoint
 */
export const useGetOracleCollectionsBidaskmidpointV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, GetOracleCollectionsBidaskmidpointV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsBidaskmidpointV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, QueryKey, GetOracleCollectionsBidaskmidpointV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsBidaskmidpointV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleCollectionsBidaskmidpointV1QueryOptions = <TData = Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsBidaskmidpointV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsBidaskmidpointV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>> = ({ signal }) => getOracleCollectionsBidaskmidpointV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleCollectionsBidaskmidpointV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>>
export type GetOracleCollectionsBidaskmidpointV1QueryError = ErrorType<unknown>

/**
 * @summary Collection bid-ask midpoint
 */
export const useGetOracleCollectionsBidaskmidpointV1 = <TData = Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsBidaskmidpointV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsBidaskmidpointV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsBidaskmidpointV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of any collection's floor price (spot or twap). The oracle's address is 0x32dA57E736E05f75aa4FaE2E9Be60FD904492726.
 * @deprecated
 * @summary Collection floor
 */
export const getOracleCollectionsFlooraskV4 = (
    params?: GetOracleCollectionsFlooraskV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionFloorAskOracleV4Response>(
      {url: `/oracle/collections/floor-ask/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleCollectionsFlooraskV4QueryKey = (params?: GetOracleCollectionsFlooraskV4Params,) => {
    return [`/oracle/collections/floor-ask/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleCollectionsFlooraskV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, GetOracleCollectionsFlooraskV4Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsFlooraskV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, QueryKey, GetOracleCollectionsFlooraskV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsFlooraskV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, QueryKey, GetOracleCollectionsFlooraskV4Params['continuation']> = ({ signal, pageParam }) => getOracleCollectionsFlooraskV4({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, QueryKey, GetOracleCollectionsFlooraskV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleCollectionsFlooraskV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>>
export type GetOracleCollectionsFlooraskV4InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collection floor
 */
export const useGetOracleCollectionsFlooraskV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, GetOracleCollectionsFlooraskV4Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsFlooraskV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, QueryKey, GetOracleCollectionsFlooraskV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsFlooraskV4InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleCollectionsFlooraskV4QueryOptions = <TData = Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsFlooraskV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsFlooraskV4QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>> = ({ signal }) => getOracleCollectionsFlooraskV4(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleCollectionsFlooraskV4QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>>
export type GetOracleCollectionsFlooraskV4QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collection floor
 */
export const useGetOracleCollectionsFlooraskV4 = <TData = Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsFlooraskV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsFlooraskV4QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of any collection's floor price (spot or twap). The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @deprecated
 * @summary Collection floor
 */
export const getOracleCollectionsFlooraskV5 = (
    params?: GetOracleCollectionsFlooraskV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionFloorAskOracleV4Response>(
      {url: `/oracle/collections/floor-ask/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleCollectionsFlooraskV5QueryKey = (params?: GetOracleCollectionsFlooraskV5Params,) => {
    return [`/oracle/collections/floor-ask/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleCollectionsFlooraskV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, GetOracleCollectionsFlooraskV5Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsFlooraskV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, QueryKey, GetOracleCollectionsFlooraskV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsFlooraskV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, QueryKey, GetOracleCollectionsFlooraskV5Params['continuation']> = ({ signal, pageParam }) => getOracleCollectionsFlooraskV5({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, QueryKey, GetOracleCollectionsFlooraskV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleCollectionsFlooraskV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>>
export type GetOracleCollectionsFlooraskV5InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collection floor
 */
export const useGetOracleCollectionsFlooraskV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, GetOracleCollectionsFlooraskV5Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsFlooraskV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, QueryKey, GetOracleCollectionsFlooraskV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsFlooraskV5InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleCollectionsFlooraskV5QueryOptions = <TData = Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsFlooraskV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsFlooraskV5QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>> = ({ signal }) => getOracleCollectionsFlooraskV5(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleCollectionsFlooraskV5QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>>
export type GetOracleCollectionsFlooraskV5QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collection floor
 */
export const useGetOracleCollectionsFlooraskV5 = <TData = Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsFlooraskV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsFlooraskV5QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of any collection's floor price (spot or twap). The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @summary Collection floor
 */
export const getOracleCollectionsFlooraskV6 = (
    params?: GetOracleCollectionsFlooraskV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionFloorAskOracleV6Response>(
      {url: `/oracle/collections/floor-ask/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleCollectionsFlooraskV6QueryKey = (params?: GetOracleCollectionsFlooraskV6Params,) => {
    return [`/oracle/collections/floor-ask/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleCollectionsFlooraskV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, GetOracleCollectionsFlooraskV6Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsFlooraskV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, QueryKey, GetOracleCollectionsFlooraskV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsFlooraskV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, QueryKey, GetOracleCollectionsFlooraskV6Params['continuation']> = ({ signal, pageParam }) => getOracleCollectionsFlooraskV6({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, QueryKey, GetOracleCollectionsFlooraskV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleCollectionsFlooraskV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>>
export type GetOracleCollectionsFlooraskV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection floor
 */
export const useGetOracleCollectionsFlooraskV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, GetOracleCollectionsFlooraskV6Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsFlooraskV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, QueryKey, GetOracleCollectionsFlooraskV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsFlooraskV6InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleCollectionsFlooraskV6QueryOptions = <TData = Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsFlooraskV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsFlooraskV6QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>> = ({ signal }) => getOracleCollectionsFlooraskV6(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleCollectionsFlooraskV6QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>>
export type GetOracleCollectionsFlooraskV6QueryError = ErrorType<unknown>

/**
 * @summary Collection floor
 */
export const useGetOracleCollectionsFlooraskV6 = <TData = Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsFlooraskV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsFlooraskV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsFlooraskV6QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of any collection's top bid price (spot or twap). The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @deprecated
 * @summary Collection top bid oracle
 */
export const getOracleCollectionsTopbidV2 = (
    params?: GetOracleCollectionsTopbidV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionTopBidOracleV2Response>(
      {url: `/oracle/collections/top-bid/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleCollectionsTopbidV2QueryKey = (params?: GetOracleCollectionsTopbidV2Params,) => {
    return [`/oracle/collections/top-bid/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleCollectionsTopbidV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, GetOracleCollectionsTopbidV2Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsTopbidV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, QueryKey, GetOracleCollectionsTopbidV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsTopbidV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, QueryKey, GetOracleCollectionsTopbidV2Params['continuation']> = ({ signal, pageParam }) => getOracleCollectionsTopbidV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, QueryKey, GetOracleCollectionsTopbidV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleCollectionsTopbidV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>>
export type GetOracleCollectionsTopbidV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collection top bid oracle
 */
export const useGetOracleCollectionsTopbidV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, GetOracleCollectionsTopbidV2Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsTopbidV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, QueryKey, GetOracleCollectionsTopbidV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsTopbidV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleCollectionsTopbidV2QueryOptions = <TData = Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsTopbidV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsTopbidV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>> = ({ signal }) => getOracleCollectionsTopbidV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleCollectionsTopbidV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>>
export type GetOracleCollectionsTopbidV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Collection top bid oracle
 */
export const useGetOracleCollectionsTopbidV2 = <TData = Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsTopbidV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsTopbidV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of any collection's top bid price (spot or twap). The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @summary Collection top bid oracle
 */
export const getOracleCollectionsTopbidV3 = (
    params?: GetOracleCollectionsTopbidV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionBidAskMidpointOracleV1Response>(
      {url: `/oracle/collections/top-bid/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleCollectionsTopbidV3QueryKey = (params?: GetOracleCollectionsTopbidV3Params,) => {
    return [`/oracle/collections/top-bid/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleCollectionsTopbidV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, GetOracleCollectionsTopbidV3Params['continuation']>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsTopbidV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, QueryKey, GetOracleCollectionsTopbidV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsTopbidV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, QueryKey, GetOracleCollectionsTopbidV3Params['continuation']> = ({ signal, pageParam }) => getOracleCollectionsTopbidV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, QueryKey, GetOracleCollectionsTopbidV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleCollectionsTopbidV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>>
export type GetOracleCollectionsTopbidV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Collection top bid oracle
 */
export const useGetOracleCollectionsTopbidV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, GetOracleCollectionsTopbidV3Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsTopbidV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData, Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, QueryKey, GetOracleCollectionsTopbidV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsTopbidV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleCollectionsTopbidV3QueryOptions = <TData = Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError = ErrorType<unknown>>(params?: GetOracleCollectionsTopbidV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleCollectionsTopbidV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>> = ({ signal }) => getOracleCollectionsTopbidV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleCollectionsTopbidV3QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>>
export type GetOracleCollectionsTopbidV3QueryError = ErrorType<unknown>

/**
 * @summary Collection top bid oracle
 */
export const useGetOracleCollectionsTopbidV3 = <TData = Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError = ErrorType<unknown>>(
 params?: GetOracleCollectionsTopbidV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleCollectionsTopbidV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleCollectionsTopbidV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of a token's details (flagged status and last transfer time). The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @deprecated
 * @summary Token status oracle
 */
export const getOracleTokensStatusV2 = (
    params: GetOracleTokensStatusV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokenStatusOracleV2Response>(
      {url: `/oracle/tokens/status/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleTokensStatusV2QueryKey = (params: GetOracleTokensStatusV2Params,) => {
    return [`/oracle/tokens/status/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleTokensStatusV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleTokensStatusV2>>, GetOracleTokensStatusV2Params['continuation']>, TError = ErrorType<unknown>>(params: GetOracleTokensStatusV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV2>>, TError, TData, Awaited<ReturnType<typeof getOracleTokensStatusV2>>, QueryKey, GetOracleTokensStatusV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleTokensStatusV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleTokensStatusV2>>, QueryKey, GetOracleTokensStatusV2Params['continuation']> = ({ signal, pageParam }) => getOracleTokensStatusV2({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV2>>, TError, TData, Awaited<ReturnType<typeof getOracleTokensStatusV2>>, QueryKey, GetOracleTokensStatusV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleTokensStatusV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleTokensStatusV2>>>
export type GetOracleTokensStatusV2InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Token status oracle
 */
export const useGetOracleTokensStatusV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleTokensStatusV2>>, GetOracleTokensStatusV2Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetOracleTokensStatusV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV2>>, TError, TData, Awaited<ReturnType<typeof getOracleTokensStatusV2>>, QueryKey, GetOracleTokensStatusV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleTokensStatusV2InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleTokensStatusV2QueryOptions = <TData = Awaited<ReturnType<typeof getOracleTokensStatusV2>>, TError = ErrorType<unknown>>(params: GetOracleTokensStatusV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleTokensStatusV2QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleTokensStatusV2>>> = ({ signal }) => getOracleTokensStatusV2(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleTokensStatusV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleTokensStatusV2>>>
export type GetOracleTokensStatusV2QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Token status oracle
 */
export const useGetOracleTokensStatusV2 = <TData = Awaited<ReturnType<typeof getOracleTokensStatusV2>>, TError = ErrorType<unknown>>(
 params: GetOracleTokensStatusV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleTokensStatusV2QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a signed message of a token's details (flagged status and last transfer time). The oracle's address is 0xAeB1D03929bF87F69888f381e73FBf75753d75AF. The address is the same for all chains.
 * @summary Token status oracle
 */
export const getOracleTokensStatusV3 = (
    params: GetOracleTokensStatusV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokenStatusOracleV3Response>(
      {url: `/oracle/tokens/status/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOracleTokensStatusV3QueryKey = (params: GetOracleTokensStatusV3Params,) => {
    return [`/oracle/tokens/status/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOracleTokensStatusV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, GetOracleTokensStatusV3Params['continuation']>, TError = ErrorType<unknown>>(params: GetOracleTokensStatusV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData, Awaited<ReturnType<typeof getOracleTokensStatusV3>>, QueryKey, GetOracleTokensStatusV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleTokensStatusV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, QueryKey, GetOracleTokensStatusV3Params['continuation']> = ({ signal, pageParam }) => getOracleTokensStatusV3({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData, Awaited<ReturnType<typeof getOracleTokensStatusV3>>, QueryKey, GetOracleTokensStatusV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetOracleTokensStatusV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOracleTokensStatusV3>>>
export type GetOracleTokensStatusV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token status oracle
 */
export const useGetOracleTokensStatusV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, GetOracleTokensStatusV3Params['continuation']>, TError = ErrorType<unknown>>(
 params: GetOracleTokensStatusV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData, Awaited<ReturnType<typeof getOracleTokensStatusV3>>, QueryKey, GetOracleTokensStatusV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleTokensStatusV3InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOracleTokensStatusV3QueryOptions = <TData = Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError = ErrorType<unknown>>(params: GetOracleTokensStatusV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOracleTokensStatusV3QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOracleTokensStatusV3>>> = ({ signal }) => getOracleTokensStatusV3(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOracleTokensStatusV3QueryResult = NonNullable<Awaited<ReturnType<typeof getOracleTokensStatusV3>>>
export type GetOracleTokensStatusV3QueryError = ErrorType<unknown>

/**
 * @summary Token status oracle
 */
export const useGetOracleTokensStatusV3 = <TData = Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError = ErrorType<unknown>>(
 params: GetOracleTokensStatusV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOracleTokensStatusV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOracleTokensStatusV3QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API return the recent flagged/un-flagged tokens across all collections sorted by change time
 * @summary Flagged Tokens
 */
export const getTokensFlagChangesV1 = (
    params?: GetTokensFlagChangesV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetFlaggedTokensV1Response>(
      {url: `/tokens/flag/changes/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensFlagChangesV1QueryKey = (params?: GetTokensFlagChangesV1Params,) => {
    return [`/tokens/flag/changes/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensFlagChangesV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, GetTokensFlagChangesV1Params['continuation']>, TError = ErrorType<unknown>>(params?: GetTokensFlagChangesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFlagChangesV1>>, QueryKey, GetTokensFlagChangesV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensFlagChangesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, QueryKey, GetTokensFlagChangesV1Params['continuation']> = ({ signal, pageParam }) => getTokensFlagChangesV1({...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFlagChangesV1>>, QueryKey, GetTokensFlagChangesV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensFlagChangesV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensFlagChangesV1>>>
export type GetTokensFlagChangesV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Flagged Tokens
 */
export const useGetTokensFlagChangesV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, GetTokensFlagChangesV1Params['continuation']>, TError = ErrorType<unknown>>(
 params?: GetTokensFlagChangesV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData, Awaited<ReturnType<typeof getTokensFlagChangesV1>>, QueryKey, GetTokensFlagChangesV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensFlagChangesV1InfiniteQueryOptions(params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensFlagChangesV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError = ErrorType<unknown>>(params?: GetTokensFlagChangesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensFlagChangesV1QueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensFlagChangesV1>>> = ({ signal }) => getTokensFlagChangesV1(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn,   staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensFlagChangesV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensFlagChangesV1>>>
export type GetTokensFlagChangesV1QueryError = ErrorType<unknown>

/**
 * @summary Flagged Tokens
 */
export const useGetTokensFlagChangesV1 = <TData = Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError = ErrorType<unknown>>(
 params?: GetTokensFlagChangesV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensFlagChangesV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensFlagChangesV1QueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a token activity including sales, asks, transfers, mints, bids, cancelled bids, and cancelled asks types.
 * @summary Token activity
 */
export const getTokensTokenActivityV5 = (
    token: string,
    params?: GetTokensTokenActivityV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokenActivityV5Response>(
      {url: `/tokens/${token}/activity/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensTokenActivityV5QueryKey = (token: string,
    params?: GetTokensTokenActivityV5Params,) => {
    return [`/tokens/${token}/activity/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensTokenActivityV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, GetTokensTokenActivityV5Params['continuation']>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV5>>, QueryKey, GetTokensTokenActivityV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV5QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, QueryKey, GetTokensTokenActivityV5Params['continuation']> = ({ signal, pageParam }) => getTokensTokenActivityV5(token,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV5>>, QueryKey, GetTokensTokenActivityV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV5>>>
export type GetTokensTokenActivityV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, GetTokensTokenActivityV5Params['continuation']>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV5>>, QueryKey, GetTokensTokenActivityV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV5InfiniteQueryOptions(token,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensTokenActivityV5QueryOptions = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV5QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV5>>> = ({ signal }) => getTokensTokenActivityV5(token,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV5QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV5>>>
export type GetTokensTokenActivityV5QueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV5 = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV5QueryOptions(token,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a token
 * @summary Token activity
 */
export const getTokensTokenActivityV3 = (
    token: string,
    params?: GetTokensTokenActivityV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionActivityV4Response>(
      {url: `/tokens/${token}/activity/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensTokenActivityV3QueryKey = (token: string,
    params?: GetTokensTokenActivityV3Params,) => {
    return [`/tokens/${token}/activity/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensTokenActivityV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV3>>, GetTokensTokenActivityV3Params['continuation']>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV3>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV3>>, QueryKey, GetTokensTokenActivityV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV3QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV3>>, QueryKey, GetTokensTokenActivityV3Params['continuation']> = ({ signal, pageParam }) => getTokensTokenActivityV3(token,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV3>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV3>>, QueryKey, GetTokensTokenActivityV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV3>>>
export type GetTokensTokenActivityV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV3>>, GetTokensTokenActivityV3Params['continuation']>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV3>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV3>>, QueryKey, GetTokensTokenActivityV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV3InfiniteQueryOptions(token,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensTokenActivityV3QueryOptions = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV3>>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV3QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV3>>> = ({ signal }) => getTokensTokenActivityV3(token,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV3QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV3>>>
export type GetTokensTokenActivityV3QueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV3 = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV3>>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV3QueryOptions(token,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a token
 * @summary Token activity
 */
export const getTokensTokenActivityV1 = (
    token: string,
    params?: GetTokensTokenActivityV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserActivityV2Response>(
      {url: `/tokens/${token}/activity/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensTokenActivityV1QueryKey = (token: string,
    params?: GetTokensTokenActivityV1Params,) => {
    return [`/tokens/${token}/activity/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensTokenActivityV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV1>>, GetTokensTokenActivityV1Params['continuation']>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV1>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV1>>, QueryKey, GetTokensTokenActivityV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV1QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV1>>, QueryKey, GetTokensTokenActivityV1Params['continuation']> = ({ signal, pageParam }) => getTokensTokenActivityV1(token,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV1>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV1>>, QueryKey, GetTokensTokenActivityV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV1>>>
export type GetTokensTokenActivityV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV1>>, GetTokensTokenActivityV1Params['continuation']>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV1>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV1>>, QueryKey, GetTokensTokenActivityV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV1InfiniteQueryOptions(token,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensTokenActivityV1QueryOptions = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV1>>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV1QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV1>>> = ({ signal }) => getTokensTokenActivityV1(token,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV1>>>
export type GetTokensTokenActivityV1QueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV1 = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV1>>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV1QueryOptions(token,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a token
 * @summary Token activity
 */
export const getTokensTokenActivityV2 = (
    token: string,
    params?: GetTokensTokenActivityV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionActivityV2Response>(
      {url: `/tokens/${token}/activity/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensTokenActivityV2QueryKey = (token: string,
    params?: GetTokensTokenActivityV2Params,) => {
    return [`/tokens/${token}/activity/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensTokenActivityV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV2>>, GetTokensTokenActivityV2Params['continuation']>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV2>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV2>>, QueryKey, GetTokensTokenActivityV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV2QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV2>>, QueryKey, GetTokensTokenActivityV2Params['continuation']> = ({ signal, pageParam }) => getTokensTokenActivityV2(token,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV2>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV2>>, QueryKey, GetTokensTokenActivityV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV2>>>
export type GetTokensTokenActivityV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV2>>, GetTokensTokenActivityV2Params['continuation']>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV2>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV2>>, QueryKey, GetTokensTokenActivityV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV2InfiniteQueryOptions(token,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensTokenActivityV2QueryOptions = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV2>>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV2QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV2>>> = ({ signal }) => getTokensTokenActivityV2(token,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV2QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV2>>>
export type GetTokensTokenActivityV2QueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV2 = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV2>>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV2QueryOptions(token,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a token
 * @summary Token activity
 */
export const getTokensTokenActivityV4 = (
    token: string,
    params?: GetTokensTokenActivityV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTokenActivityV4Response>(
      {url: `/tokens/${token}/activity/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTokensTokenActivityV4QueryKey = (token: string,
    params?: GetTokensTokenActivityV4Params,) => {
    return [`/tokens/${token}/activity/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTokensTokenActivityV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV4>>, GetTokensTokenActivityV4Params['continuation']>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV4>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV4>>, QueryKey, GetTokensTokenActivityV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV4QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV4>>, QueryKey, GetTokensTokenActivityV4Params['continuation']> = ({ signal, pageParam }) => getTokensTokenActivityV4(token,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV4>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV4>>, QueryKey, GetTokensTokenActivityV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV4>>>
export type GetTokensTokenActivityV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTokensTokenActivityV4>>, GetTokensTokenActivityV4Params['continuation']>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV4>>, TError, TData, Awaited<ReturnType<typeof getTokensTokenActivityV4>>, QueryKey, GetTokensTokenActivityV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV4InfiniteQueryOptions(token,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTokensTokenActivityV4QueryOptions = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV4>>, TError = ErrorType<unknown>>(token: string,
    params?: GetTokensTokenActivityV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTokensTokenActivityV4QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTokensTokenActivityV4>>> = ({ signal }) => getTokensTokenActivityV4(token,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTokensTokenActivityV4QueryResult = NonNullable<Awaited<ReturnType<typeof getTokensTokenActivityV4>>>
export type GetTokensTokenActivityV4QueryError = ErrorType<unknown>

/**
 * @summary Token activity
 */
export const useGetTokensTokenActivityV4 = <TData = Awaited<ReturnType<typeof getTokensTokenActivityV4>>, TError = ErrorType<unknown>>(
 token: string,
    params?: GetTokensTokenActivityV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTokensTokenActivityV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTokensTokenActivityV4QueryOptions(token,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get a boolean response on whether a particular transaction was synced or not.
 * @summary Transaction status
 */
export const getTransactionsTxhashSyncedV1 = (
    txHash: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetTransactionSyncedV1Response>(
      {url: `/transactions/${txHash}/synced/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTransactionsTxhashSyncedV1QueryKey = (txHash: string,) => {
    return [`/transactions/${txHash}/synced/v1`] as const;
    }

    
export const getGetTransactionsTxhashSyncedV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>>, TError = ErrorType<unknown>>(txHash: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransactionsTxhashSyncedV1QueryKey(txHash);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>> = ({ signal }) => getTransactionsTxhashSyncedV1(txHash, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(txHash),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransactionsTxhashSyncedV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>>
export type GetTransactionsTxhashSyncedV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Transaction status
 */
export const useGetTransactionsTxhashSyncedV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>>, TError = ErrorType<unknown>>(
 txHash: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransactionsTxhashSyncedV1InfiniteQueryOptions(txHash,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetTransactionsTxhashSyncedV1QueryOptions = <TData = Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError = ErrorType<unknown>>(txHash: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTransactionsTxhashSyncedV1QueryKey(txHash);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>> = ({ signal }) => getTransactionsTxhashSyncedV1(txHash, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(txHash),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTransactionsTxhashSyncedV1QueryResult = NonNullable<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>>
export type GetTransactionsTxhashSyncedV1QueryError = ErrorType<unknown>

/**
 * @summary Transaction status
 */
export const useGetTransactionsTxhashSyncedV1 = <TData = Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError = ErrorType<unknown>>(
 txHash: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTransactionsTxhashSyncedV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTransactionsTxhashSyncedV1QueryOptions(txHash,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get tokens held by a user, along with ownership information such as associated orders and date acquired.
 * @summary User Tokens
 */
export const getUsersUserTokensV6 = (
    user: string,
    params?: GetUsersUserTokensV6Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTokensV6Response>(
      {url: `/users/${user}/tokens/v6`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserTokensV6QueryKey = (user: string,
    params?: GetUsersUserTokensV6Params,) => {
    return [`/users/${user}/tokens/v6`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserTokensV6InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV6>>, GetUsersUserTokensV6Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV6>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV6>>, QueryKey, GetUsersUserTokensV6Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV6QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV6>>, QueryKey, GetUsersUserTokensV6Params['continuation']> = ({ signal, pageParam }) => getUsersUserTokensV6(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV6>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV6>>, QueryKey, GetUsersUserTokensV6Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV6InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV6>>>
export type GetUsersUserTokensV6InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV6Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV6>>, GetUsersUserTokensV6Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV6Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV6>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV6>>, QueryKey, GetUsersUserTokensV6Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV6InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserTokensV6QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserTokensV6>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV6>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV6QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV6>>> = ({ signal }) => getUsersUserTokensV6(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV6>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV6QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV6>>>
export type GetUsersUserTokensV6QueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV6 = <TData = Awaited<ReturnType<typeof getUsersUserTokensV6>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV6Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV6>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV6QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get tokens held by a user, along with ownership information such as associated orders and date acquired.
 * @summary User Tokens
 */
export const getUsersUserTokensV4 = (
    user: string,
    params?: GetUsersUserTokensV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTokensV4Response>(
      {url: `/users/${user}/tokens/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserTokensV4QueryKey = (user: string,
    params?: GetUsersUserTokensV4Params,) => {
    return [`/users/${user}/tokens/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserTokensV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV4>>, GetUsersUserTokensV4Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV4>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV4>>, QueryKey, GetUsersUserTokensV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV4QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV4>>, QueryKey, GetUsersUserTokensV4Params['continuation']> = ({ signal, pageParam }) => getUsersUserTokensV4(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV4>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV4>>, QueryKey, GetUsersUserTokensV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV4>>>
export type GetUsersUserTokensV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV4>>, GetUsersUserTokensV4Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV4>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV4>>, QueryKey, GetUsersUserTokensV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV4InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserTokensV4QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserTokensV4>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV4QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV4>>> = ({ signal }) => getUsersUserTokensV4(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV4QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV4>>>
export type GetUsersUserTokensV4QueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV4 = <TData = Awaited<ReturnType<typeof getUsersUserTokensV4>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV4QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get tokens held by a user, along with ownership information such as associated orders and date acquired.
 * @summary User tokens
 */
export const getUsersUserTokensV2 = (
    user: string,
    params?: GetUsersUserTokensV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTokensV2Response>(
      {url: `/users/${user}/tokens/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserTokensV2QueryKey = (user: string,
    params?: GetUsersUserTokensV2Params,) => {
    return [`/users/${user}/tokens/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserTokensV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV2>>, GetUsersUserTokensV2Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV2>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV2>>, QueryKey, GetUsersUserTokensV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV2QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV2>>, QueryKey, GetUsersUserTokensV2Params['continuation']> = ({ signal, pageParam }) => getUsersUserTokensV2(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV2>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV2>>, QueryKey, GetUsersUserTokensV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV2>>>
export type GetUsersUserTokensV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User tokens
 */
export const useGetUsersUserTokensV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV2>>, GetUsersUserTokensV2Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV2>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV2>>, QueryKey, GetUsersUserTokensV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV2InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserTokensV2QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserTokensV2>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV2QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV2>>> = ({ signal }) => getUsersUserTokensV2(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV2QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV2>>>
export type GetUsersUserTokensV2QueryError = ErrorType<unknown>

/**
 * @summary User tokens
 */
export const useGetUsersUserTokensV2 = <TData = Awaited<ReturnType<typeof getUsersUserTokensV2>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV2QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get aggregate user liquidity, grouped by collection. Useful for showing a summary of liquidity being provided (orders made).
 * @deprecated
 * @summary Get a summary of a users bids and asks
 */
export const getUsersUserPositionsV1 = (
    user: string,
    params: GetUsersUserPositionsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserPositionsV1Response>(
      {url: `/users/${user}/positions/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserPositionsV1QueryKey = (user: string,
    params: GetUsersUserPositionsV1Params,) => {
    return [`/users/${user}/positions/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserPositionsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, GetUsersUserPositionsV1Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params: GetUsersUserPositionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserPositionsV1>>, QueryKey, GetUsersUserPositionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserPositionsV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, QueryKey, GetUsersUserPositionsV1Params['continuation']> = ({ signal, pageParam }) => getUsersUserPositionsV1(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserPositionsV1>>, QueryKey, GetUsersUserPositionsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserPositionsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserPositionsV1>>>
export type GetUsersUserPositionsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a summary of a users bids and asks
 */
export const useGetUsersUserPositionsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, GetUsersUserPositionsV1Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params: GetUsersUserPositionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserPositionsV1>>, QueryKey, GetUsersUserPositionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserPositionsV1InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserPositionsV1QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError = ErrorType<unknown>>(user: string,
    params: GetUsersUserPositionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserPositionsV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserPositionsV1>>> = ({ signal }) => getUsersUserPositionsV1(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserPositionsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserPositionsV1>>>
export type GetUsersUserPositionsV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get a summary of a users bids and asks
 */
export const useGetUsersUserPositionsV1 = <TData = Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError = ErrorType<unknown>>(
 user: string,
    params: GetUsersUserPositionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserPositionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserPositionsV1QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get aggregate stats for a user, grouped by collection. Useful for showing total portfolio information.
 * @summary User collections
 */
export const getUsersUserCollectionsV2 = (
    user: string,
    params?: GetUsersUserCollectionsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserCollectionsV2Response>(
      {url: `/users/${user}/collections/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserCollectionsV2QueryKey = (user: string,
    params?: GetUsersUserCollectionsV2Params,) => {
    return [`/users/${user}/collections/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserCollectionsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, GetUsersUserCollectionsV2Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserCollectionsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, QueryKey, GetUsersUserCollectionsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserCollectionsV2QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, QueryKey, GetUsersUserCollectionsV2Params['continuation']> = ({ signal, pageParam }) => getUsersUserCollectionsV2(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, QueryKey, GetUsersUserCollectionsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserCollectionsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>>
export type GetUsersUserCollectionsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User collections
 */
export const useGetUsersUserCollectionsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, GetUsersUserCollectionsV2Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserCollectionsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, QueryKey, GetUsersUserCollectionsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserCollectionsV2InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserCollectionsV2QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserCollectionsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserCollectionsV2QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>> = ({ signal }) => getUsersUserCollectionsV2(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserCollectionsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>>
export type GetUsersUserCollectionsV2QueryError = ErrorType<unknown>

/**
 * @summary User collections
 */
export const useGetUsersUserCollectionsV2 = <TData = Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserCollectionsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserCollectionsV2QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API can be used to build a feed for a user
 * @summary User activity
 */
export const getUsersUserActivityV1 = (
    user: string,
    params?: GetUsersUserActivityV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserActivityV1Response>(
      {url: `/users/${user}/activity/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserActivityV1QueryKey = (user: string,
    params?: GetUsersUserActivityV1Params,) => {
    return [`/users/${user}/activity/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserActivityV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserActivityV1>>, GetUsersUserActivityV1Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserActivityV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserActivityV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserActivityV1>>, QueryKey, GetUsersUserActivityV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserActivityV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserActivityV1>>, QueryKey, GetUsersUserActivityV1Params['continuation']> = ({ signal, pageParam }) => getUsersUserActivityV1(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserActivityV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserActivityV1>>, QueryKey, GetUsersUserActivityV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserActivityV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserActivityV1>>>
export type GetUsersUserActivityV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User activity
 */
export const useGetUsersUserActivityV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserActivityV1>>, GetUsersUserActivityV1Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserActivityV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserActivityV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserActivityV1>>, QueryKey, GetUsersUserActivityV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserActivityV1InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserActivityV1QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserActivityV1>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserActivityV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserActivityV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserActivityV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserActivityV1>>> = ({ signal }) => getUsersUserActivityV1(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserActivityV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserActivityV1QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserActivityV1>>>
export type GetUsersUserActivityV1QueryError = ErrorType<unknown>

/**
 * @summary User activity
 */
export const useGetUsersUserActivityV1 = <TData = Awaited<ReturnType<typeof getUsersUserActivityV1>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserActivityV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserActivityV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserActivityV1QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get aggregate stats for a user, grouped by collection. Useful for showing total portfolio information.
 * @deprecated
 * @summary Get aggregate stats for a user, grouped by collection
 */
export const getUsersUserCollectionsV1 = (
    user: string,
    params?: GetUsersUserCollectionsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserCollectionsV1Response>(
      {url: `/users/${user}/collections/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserCollectionsV1QueryKey = (user: string,
    params?: GetUsersUserCollectionsV1Params,) => {
    return [`/users/${user}/collections/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserCollectionsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, GetUsersUserCollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserCollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, QueryKey, GetUsersUserCollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserCollectionsV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, QueryKey, GetUsersUserCollectionsV1Params['continuation']> = ({ signal, pageParam }) => getUsersUserCollectionsV1(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, QueryKey, GetUsersUserCollectionsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserCollectionsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>>
export type GetUsersUserCollectionsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get aggregate stats for a user, grouped by collection
 */
export const useGetUsersUserCollectionsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, GetUsersUserCollectionsV1Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserCollectionsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, QueryKey, GetUsersUserCollectionsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserCollectionsV1InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserCollectionsV1QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserCollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserCollectionsV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>> = ({ signal }) => getUsersUserCollectionsV1(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserCollectionsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>>
export type GetUsersUserCollectionsV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get aggregate stats for a user, grouped by collection
 */
export const useGetUsersUserCollectionsV1 = <TData = Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserCollectionsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserCollectionsV1QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get aggregate stats for a user, grouped by collection. Useful for showing total portfolio information.
 * @summary User collections
 */
export const getUsersUserCollectionsV3 = (
    user: string,
    params?: GetUsersUserCollectionsV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserCollectionsV3Response>(
      {url: `/users/${user}/collections/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserCollectionsV3QueryKey = (user: string,
    params?: GetUsersUserCollectionsV3Params,) => {
    return [`/users/${user}/collections/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserCollectionsV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, GetUsersUserCollectionsV3Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserCollectionsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, QueryKey, GetUsersUserCollectionsV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserCollectionsV3QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, QueryKey, GetUsersUserCollectionsV3Params['continuation']> = ({ signal, pageParam }) => getUsersUserCollectionsV3(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, QueryKey, GetUsersUserCollectionsV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserCollectionsV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>>
export type GetUsersUserCollectionsV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User collections
 */
export const useGetUsersUserCollectionsV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, GetUsersUserCollectionsV3Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserCollectionsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData, Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, QueryKey, GetUsersUserCollectionsV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserCollectionsV3InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserCollectionsV3QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserCollectionsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserCollectionsV3QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>> = ({ signal }) => getUsersUserCollectionsV3(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserCollectionsV3QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>>
export type GetUsersUserCollectionsV3QueryError = ErrorType<unknown>

/**
 * @summary User collections
 */
export const useGetUsersUserCollectionsV3 = <TData = Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserCollectionsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserCollectionsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserCollectionsV3QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get tokens held by a user, along with ownership information such as associated orders and date acquired.
 * @deprecated
 * @summary User tokens
 */
export const getUsersUserTokensV1 = (
    user: string,
    params?: GetUsersUserTokensV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTokensV1Response>(
      {url: `/users/${user}/tokens/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserTokensV1QueryKey = (user: string,
    params?: GetUsersUserTokensV1Params,) => {
    return [`/users/${user}/tokens/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserTokensV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV1>>, GetUsersUserTokensV1Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV1>>, QueryKey, GetUsersUserTokensV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV1>>, QueryKey, GetUsersUserTokensV1Params['continuation']> = ({ signal, pageParam }) => getUsersUserTokensV1(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV1>>, QueryKey, GetUsersUserTokensV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV1>>>
export type GetUsersUserTokensV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary User tokens
 */
export const useGetUsersUserTokensV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV1>>, GetUsersUserTokensV1Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV1>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV1>>, QueryKey, GetUsersUserTokensV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV1InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserTokensV1QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserTokensV1>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV1>>> = ({ signal }) => getUsersUserTokensV1(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV1QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV1>>>
export type GetUsersUserTokensV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary User tokens
 */
export const useGetUsersUserTokensV1 = <TData = Awaited<ReturnType<typeof getUsersUserTokensV1>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV1QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get tokens held by a user, along with ownership information such as associated orders and date acquired.
 * @summary User Tokens
 */
export const getUsersUserTokensV3 = (
    user: string,
    params?: GetUsersUserTokensV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTokensV3Response>(
      {url: `/users/${user}/tokens/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserTokensV3QueryKey = (user: string,
    params?: GetUsersUserTokensV3Params,) => {
    return [`/users/${user}/tokens/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserTokensV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV3>>, GetUsersUserTokensV3Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV3>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV3>>, QueryKey, GetUsersUserTokensV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV3QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV3>>, QueryKey, GetUsersUserTokensV3Params['continuation']> = ({ signal, pageParam }) => getUsersUserTokensV3(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV3>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV3>>, QueryKey, GetUsersUserTokensV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV3>>>
export type GetUsersUserTokensV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV3>>, GetUsersUserTokensV3Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV3>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV3>>, QueryKey, GetUsersUserTokensV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV3InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserTokensV3QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserTokensV3>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV3QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV3>>> = ({ signal }) => getUsersUserTokensV3(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV3QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV3>>>
export type GetUsersUserTokensV3QueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV3 = <TData = Awaited<ReturnType<typeof getUsersUserTokensV3>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV3QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get tokens held by a user, along with ownership information such as associated orders and date acquired.
 * @summary User Tokens
 */
export const getUsersUserTokensV5 = (
    user: string,
    params?: GetUsersUserTokensV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTokensV5Response>(
      {url: `/users/${user}/tokens/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserTokensV5QueryKey = (user: string,
    params?: GetUsersUserTokensV5Params,) => {
    return [`/users/${user}/tokens/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserTokensV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV5>>, GetUsersUserTokensV5Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV5>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV5>>, QueryKey, GetUsersUserTokensV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV5QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV5>>, QueryKey, GetUsersUserTokensV5Params['continuation']> = ({ signal, pageParam }) => getUsersUserTokensV5(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV5>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV5>>, QueryKey, GetUsersUserTokensV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV5>>>
export type GetUsersUserTokensV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV5>>, GetUsersUserTokensV5Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV5>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV5>>, QueryKey, GetUsersUserTokensV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV5InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserTokensV5QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserTokensV5>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV5QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV5>>> = ({ signal }) => getUsersUserTokensV5(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV5QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV5>>>
export type GetUsersUserTokensV5QueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV5 = <TData = Awaited<ReturnType<typeof getUsersUserTokensV5>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV5QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get tokens held by a user, along with ownership information such as associated orders and date acquired.
 * @summary User Tokens
 */
export const getUsersUserTokensV7 = (
    user: string,
    params?: GetUsersUserTokensV7Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTokensV7Response>(
      {url: `/users/${user}/tokens/v7`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetUsersUserTokensV7QueryKey = (user: string,
    params?: GetUsersUserTokensV7Params,) => {
    return [`/users/${user}/tokens/v7`, ...(params ? [params]: [])] as const;
    }

    
export const getGetUsersUserTokensV7InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV7>>, GetUsersUserTokensV7Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV7>>, QueryKey, GetUsersUserTokensV7Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV7QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV7>>, QueryKey, GetUsersUserTokensV7Params['continuation']> = ({ signal, pageParam }) => getUsersUserTokensV7(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV7>>, QueryKey, GetUsersUserTokensV7Params['continuation']> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV7InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV7>>>
export type GetUsersUserTokensV7InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV7Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getUsersUserTokensV7>>, GetUsersUserTokensV7Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV7Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData, Awaited<ReturnType<typeof getUsersUserTokensV7>>, QueryKey, GetUsersUserTokensV7Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV7InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetUsersUserTokensV7QueryOptions = <TData = Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError = ErrorType<unknown>>(user: string,
    params?: GetUsersUserTokensV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUsersUserTokensV7QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersUserTokensV7>>> = ({ signal }) => getUsersUserTokensV7(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData> & { queryKey: QueryKey }
}

export type GetUsersUserTokensV7QueryResult = NonNullable<Awaited<ReturnType<typeof getUsersUserTokensV7>>>
export type GetUsersUserTokensV7QueryError = ErrorType<unknown>

/**
 * @summary User Tokens
 */
export const useGetUsersUserTokensV7 = <TData = Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetUsersUserTokensV7Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getUsersUserTokensV7>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUsersUserTokensV7QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Use this API to see stats on a specific attribute within a collection. This endpoint will return `tokenCount`, `onSaleCount`, `sampleImages`, and `floorAsk` by default. 
 * @summary Explore attributes
 */
export const getCollectionsCollectionAttributesExploreV4 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesExploreV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesExploreV4Response>(
      {url: `/collections/${collection}/attributes/explore/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesExploreV4QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesExploreV4Params,) => {
    return [`/collections/${collection}/attributes/explore/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesExploreV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, GetCollectionsCollectionAttributesExploreV4Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, QueryKey, GetCollectionsCollectionAttributesExploreV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV4QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, QueryKey, GetCollectionsCollectionAttributesExploreV4Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesExploreV4(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, QueryKey, GetCollectionsCollectionAttributesExploreV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>>
export type GetCollectionsCollectionAttributesExploreV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, GetCollectionsCollectionAttributesExploreV4Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, QueryKey, GetCollectionsCollectionAttributesExploreV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV4InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesExploreV4QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV4QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>> = ({ signal }) => getCollectionsCollectionAttributesExploreV4(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV4QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>>
export type GetCollectionsCollectionAttributesExploreV4QueryError = ErrorType<unknown>

/**
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV4 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV4QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get detailed aggregate about attributes in a collection, attribute floors
 * @summary Explore attributes
 */
export const getCollectionsCollectionAttributesExploreV2 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesExploreV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesExploreV2Response>(
      {url: `/collections/${collection}/attributes/explore/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesExploreV2QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesExploreV2Params,) => {
    return [`/collections/${collection}/attributes/explore/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesExploreV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, GetCollectionsCollectionAttributesExploreV2Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, QueryKey, GetCollectionsCollectionAttributesExploreV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV2QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, QueryKey, GetCollectionsCollectionAttributesExploreV2Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesExploreV2(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, QueryKey, GetCollectionsCollectionAttributesExploreV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>>
export type GetCollectionsCollectionAttributesExploreV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, GetCollectionsCollectionAttributesExploreV2Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, QueryKey, GetCollectionsCollectionAttributesExploreV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV2InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesExploreV2QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV2QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>> = ({ signal }) => getCollectionsCollectionAttributesExploreV2(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV2QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>>
export type GetCollectionsCollectionAttributesExploreV2QueryError = ErrorType<unknown>

/**
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV2 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV2QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Use this API to see all possible attributes within a collection.

- `floorAskPrice` for all attributes might not be returned on collections with more than 10k tokens. 

- Attributes are case sensitive. 

- Attributes will return a maximum of 500 values.
 * @summary All attributes
 */
export const getCollectionsCollectionAttributesAllV4 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesAllV4Response>(
      {url: `/collections/${collection}/attributes/all/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesAllV4QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params,) => {
    return [`/collections/${collection}/attributes/all/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesAllV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, GetCollectionsCollectionAttributesAllV4Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, QueryKey, GetCollectionsCollectionAttributesAllV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV4QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, QueryKey, GetCollectionsCollectionAttributesAllV4Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesAllV4(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, QueryKey, GetCollectionsCollectionAttributesAllV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>>
export type GetCollectionsCollectionAttributesAllV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary All attributes
 */
export const useGetCollectionsCollectionAttributesAllV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, GetCollectionsCollectionAttributesAllV4Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, QueryKey, GetCollectionsCollectionAttributesAllV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV4InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesAllV4QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV4QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>> = ({ signal }) => getCollectionsCollectionAttributesAllV4(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV4QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>>
export type GetCollectionsCollectionAttributesAllV4QueryError = ErrorType<unknown>

/**
 * @summary All attributes
 */
export const useGetCollectionsCollectionAttributesAllV4 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesAllV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV4QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary All attributes
 */
export const getCollectionsCollectionAttributesAllV2 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesAllV2Response>(
      {url: `/collections/${collection}/attributes/all/v2`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesAllV2QueryKey = (collection: string,) => {
    return [`/collections/${collection}/attributes/all/v2`] as const;
    }

    
export const getGetCollectionsCollectionAttributesAllV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV2QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>> = ({ signal }) => getCollectionsCollectionAttributesAllV2(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>>
export type GetCollectionsCollectionAttributesAllV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary All attributes
 */
export const useGetCollectionsCollectionAttributesAllV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV2InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesAllV2QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV2QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>> = ({ signal }) => getCollectionsCollectionAttributesAllV2(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV2QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>>
export type GetCollectionsCollectionAttributesAllV2QueryError = ErrorType<unknown>

/**
 * @summary All attributes
 */
export const useGetCollectionsCollectionAttributesAllV2 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV2QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary Get all attributes in a collection
 */
export const getCollectionsCollectionAttributesAllV1 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesV1Response>(
      {url: `/collections/${collection}/attributes/all/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesAllV1QueryKey = (collection: string,) => {
    return [`/collections/${collection}/attributes/all/v1`] as const;
    }

    
export const getGetCollectionsCollectionAttributesAllV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>> = ({ signal }) => getCollectionsCollectionAttributesAllV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>>
export type GetCollectionsCollectionAttributesAllV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get all attributes in a collection
 */
export const useGetCollectionsCollectionAttributesAllV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV1InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesAllV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>> = ({ signal }) => getCollectionsCollectionAttributesAllV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>>
export type GetCollectionsCollectionAttributesAllV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get all attributes in a collection
 */
export const useGetCollectionsCollectionAttributesAllV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV1QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary All attributes
 */
export const getCollectionsCollectionAttributesAllV3 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesAllV3Response>(
      {url: `/collections/${collection}/attributes/all/v3`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesAllV3QueryKey = (collection: string,) => {
    return [`/collections/${collection}/attributes/all/v3`] as const;
    }

    
export const getGetCollectionsCollectionAttributesAllV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV3QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>> = ({ signal }) => getCollectionsCollectionAttributesAllV3(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>>
export type GetCollectionsCollectionAttributesAllV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary All attributes
 */
export const useGetCollectionsCollectionAttributesAllV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV3InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesAllV3QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesAllV3QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>> = ({ signal }) => getCollectionsCollectionAttributesAllV3(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesAllV3QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>>
export type GetCollectionsCollectionAttributesAllV3QueryError = ErrorType<unknown>

/**
 * @summary All attributes
 */
export const useGetCollectionsCollectionAttributesAllV3 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesAllV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesAllV3QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary All attributes + token ids
 */
export const getCollectionsCollectionAttributesStaticV1 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesStaticV1Response>(
      {url: `/collections/${collection}/attributes/static/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesStaticV1QueryKey = (collection: string,) => {
    return [`/collections/${collection}/attributes/static/v1`] as const;
    }

    
export const getGetCollectionsCollectionAttributesStaticV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesStaticV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>> = ({ signal }) => getCollectionsCollectionAttributesStaticV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesStaticV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>>
export type GetCollectionsCollectionAttributesStaticV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary All attributes + token ids
 */
export const useGetCollectionsCollectionAttributesStaticV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesStaticV1InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesStaticV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError = ErrorType<unknown>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesStaticV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>> = ({ signal }) => getCollectionsCollectionAttributesStaticV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesStaticV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>>
export type GetCollectionsCollectionAttributesStaticV1QueryError = ErrorType<unknown>

/**
 * @summary All attributes + token ids
 */
export const useGetCollectionsCollectionAttributesStaticV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError = ErrorType<unknown>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesStaticV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesStaticV1QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @deprecated
 * @summary Get detailed aggregate about attributes in a collection, e.g. trait floors
 */
export const getCollectionsCollectionAttributesExploreV1 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesExploreV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetCollectionAttributesV1Response>(
      {url: `/collections/${collection}/attributes/explore/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesExploreV1QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesExploreV1Params,) => {
    return [`/collections/${collection}/attributes/explore/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesExploreV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, GetCollectionsCollectionAttributesExploreV1Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, QueryKey, GetCollectionsCollectionAttributesExploreV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, QueryKey, GetCollectionsCollectionAttributesExploreV1Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesExploreV1(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, QueryKey, GetCollectionsCollectionAttributesExploreV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>>
export type GetCollectionsCollectionAttributesExploreV1InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get detailed aggregate about attributes in a collection, e.g. trait floors
 */
export const useGetCollectionsCollectionAttributesExploreV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, GetCollectionsCollectionAttributesExploreV1Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, QueryKey, GetCollectionsCollectionAttributesExploreV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV1InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesExploreV1QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV1QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>> = ({ signal }) => getCollectionsCollectionAttributesExploreV1(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV1QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>>
export type GetCollectionsCollectionAttributesExploreV1QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Get detailed aggregate about attributes in a collection, e.g. trait floors
 */
export const useGetCollectionsCollectionAttributesExploreV1 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV1QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Get detailed aggregate about attributes in a collection, attribute floors
 * @deprecated
 * @summary Explore attributes
 */
export const getCollectionsCollectionAttributesExploreV3 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesExploreV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesExploreV3Response>(
      {url: `/collections/${collection}/attributes/explore/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesExploreV3QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesExploreV3Params,) => {
    return [`/collections/${collection}/attributes/explore/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesExploreV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, GetCollectionsCollectionAttributesExploreV3Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, QueryKey, GetCollectionsCollectionAttributesExploreV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV3QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, QueryKey, GetCollectionsCollectionAttributesExploreV3Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesExploreV3(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, QueryKey, GetCollectionsCollectionAttributesExploreV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>>
export type GetCollectionsCollectionAttributesExploreV3InfiniteQueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, GetCollectionsCollectionAttributesExploreV3Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, QueryKey, GetCollectionsCollectionAttributesExploreV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV3InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesExploreV3QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV3QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>> = ({ signal }) => getCollectionsCollectionAttributesExploreV3(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV3QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>>
export type GetCollectionsCollectionAttributesExploreV3QueryError = ErrorType<unknown>

/**
 * @deprecated
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV3 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV3QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Use this API to see stats on a specific attribute within a collection. This endpoint will return `tokenCount`, `onSaleCount`, `sampleImages`, and `floorAskPrices` by default.

- `floorAskPrices` will not be returned on attributes with more than 10k tokens.
 * @summary Explore attributes
 */
export const getCollectionsCollectionAttributesExploreV5 = (
    collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetAttributesExploreV5Response>(
      {url: `/collections/${collection}/attributes/explore/v5`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCollectionsCollectionAttributesExploreV5QueryKey = (collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params,) => {
    return [`/collections/${collection}/attributes/explore/v5`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCollectionsCollectionAttributesExploreV5InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, GetCollectionsCollectionAttributesExploreV5Params['continuation']>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, QueryKey, GetCollectionsCollectionAttributesExploreV5Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV5QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, QueryKey, GetCollectionsCollectionAttributesExploreV5Params['continuation']> = ({ signal, pageParam }) => getCollectionsCollectionAttributesExploreV5(collection,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, QueryKey, GetCollectionsCollectionAttributesExploreV5Params['continuation']> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV5InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>>
export type GetCollectionsCollectionAttributesExploreV5InfiniteQueryError = ErrorType<unknown>

/**
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV5Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, GetCollectionsCollectionAttributesExploreV5Params['continuation']>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData, Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, QueryKey, GetCollectionsCollectionAttributesExploreV5Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV5InfiniteQueryOptions(collection,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetCollectionsCollectionAttributesExploreV5QueryOptions = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError = ErrorType<unknown>>(collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCollectionsCollectionAttributesExploreV5QueryKey(collection,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>> = ({ signal }) => getCollectionsCollectionAttributesExploreV5(collection,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCollectionsCollectionAttributesExploreV5QueryResult = NonNullable<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>>
export type GetCollectionsCollectionAttributesExploreV5QueryError = ErrorType<unknown>

/**
 * @summary Explore attributes
 */
export const useGetCollectionsCollectionAttributesExploreV5 = <TData = Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError = ErrorType<unknown>>(
 collection: string,
    params?: GetCollectionsCollectionAttributesExploreV5Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCollectionsCollectionAttributesExploreV5>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCollectionsCollectionAttributesExploreV5QueryOptions(collection,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Return the top bids for the given user tokens. Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary User Top Bids
 */
export const getOrdersUsersUserTopbidsV4 = (
    user: string,
    params?: GetOrdersUsersUserTopbidsV4Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTopBidsV4Response>(
      {url: `/orders/users/${user}/top-bids/v4`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersUsersUserTopbidsV4QueryKey = (user: string,
    params?: GetOrdersUsersUserTopbidsV4Params,) => {
    return [`/orders/users/${user}/top-bids/v4`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersUsersUserTopbidsV4InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, GetOrdersUsersUserTopbidsV4Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, QueryKey, GetOrdersUsersUserTopbidsV4Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV4QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, QueryKey, GetOrdersUsersUserTopbidsV4Params['continuation']> = ({ signal, pageParam }) => getOrdersUsersUserTopbidsV4(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, QueryKey, GetOrdersUsersUserTopbidsV4Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV4InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>>
export type GetOrdersUsersUserTopbidsV4InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV4Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, GetOrdersUsersUserTopbidsV4Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV4Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, QueryKey, GetOrdersUsersUserTopbidsV4Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV4InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersUsersUserTopbidsV4QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV4QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>> = ({ signal }) => getOrdersUsersUserTopbidsV4(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV4QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>>
export type GetOrdersUsersUserTopbidsV4QueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV4 = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV4Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV4>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV4QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Return the top bids for the given user tokens
 * @summary User Top Bids
 */
export const getOrdersUsersUserTopbidsV2 = (
    user: string,
    params?: GetOrdersUsersUserTopbidsV2Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTopBidsV2Response>(
      {url: `/orders/users/${user}/top-bids/v2`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersUsersUserTopbidsV2QueryKey = (user: string,
    params?: GetOrdersUsersUserTopbidsV2Params,) => {
    return [`/orders/users/${user}/top-bids/v2`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersUsersUserTopbidsV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, GetOrdersUsersUserTopbidsV2Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, QueryKey, GetOrdersUsersUserTopbidsV2Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV2QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, QueryKey, GetOrdersUsersUserTopbidsV2Params['continuation']> = ({ signal, pageParam }) => getOrdersUsersUserTopbidsV2(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, QueryKey, GetOrdersUsersUserTopbidsV2Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>>
export type GetOrdersUsersUserTopbidsV2InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, GetOrdersUsersUserTopbidsV2Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV2Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, QueryKey, GetOrdersUsersUserTopbidsV2Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV2InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersUsersUserTopbidsV2QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV2QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>> = ({ signal }) => getOrdersUsersUserTopbidsV2(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV2QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>>
export type GetOrdersUsersUserTopbidsV2QueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV2 = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV2Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV2QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Return the top bids for the given user tokens
 * @summary User Top Bids
 */
export const getOrdersUsersUserTopbidsV1 = (
    user: string,
    params?: GetOrdersUsersUserTopbidsV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTopBidsV1Response>(
      {url: `/orders/users/${user}/top-bids/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersUsersUserTopbidsV1QueryKey = (user: string,
    params?: GetOrdersUsersUserTopbidsV1Params,) => {
    return [`/orders/users/${user}/top-bids/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersUsersUserTopbidsV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, GetOrdersUsersUserTopbidsV1Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, QueryKey, GetOrdersUsersUserTopbidsV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, QueryKey, GetOrdersUsersUserTopbidsV1Params['continuation']> = ({ signal, pageParam }) => getOrdersUsersUserTopbidsV1(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, QueryKey, GetOrdersUsersUserTopbidsV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>>
export type GetOrdersUsersUserTopbidsV1InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, GetOrdersUsersUserTopbidsV1Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, QueryKey, GetOrdersUsersUserTopbidsV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV1InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersUsersUserTopbidsV1QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV1QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>> = ({ signal }) => getOrdersUsersUserTopbidsV1(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV1QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>>
export type GetOrdersUsersUserTopbidsV1QueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV1 = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV1QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * Return the top bids for the given user tokens
 * @summary User Top Bids
 */
export const getOrdersUsersUserTopbidsV3 = (
    user: string,
    params?: GetOrdersUsersUserTopbidsV3Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<GetUserTopBidsV3Response>(
      {url: `/orders/users/${user}/top-bids/v3`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetOrdersUsersUserTopbidsV3QueryKey = (user: string,
    params?: GetOrdersUsersUserTopbidsV3Params,) => {
    return [`/orders/users/${user}/top-bids/v3`, ...(params ? [params]: [])] as const;
    }

    
export const getGetOrdersUsersUserTopbidsV3InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, GetOrdersUsersUserTopbidsV3Params['continuation']>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, QueryKey, GetOrdersUsersUserTopbidsV3Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV3QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, QueryKey, GetOrdersUsersUserTopbidsV3Params['continuation']> = ({ signal, pageParam }) => getOrdersUsersUserTopbidsV3(user,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, QueryKey, GetOrdersUsersUserTopbidsV3Params['continuation']> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV3InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>>
export type GetOrdersUsersUserTopbidsV3InfiniteQueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV3Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, GetOrdersUsersUserTopbidsV3Params['continuation']>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV3Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, TError, TData, Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, QueryKey, GetOrdersUsersUserTopbidsV3Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV3InfiniteQueryOptions(user,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetOrdersUsersUserTopbidsV3QueryOptions = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, TError = ErrorType<unknown>>(user: string,
    params?: GetOrdersUsersUserTopbidsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrdersUsersUserTopbidsV3QueryKey(user,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>> = ({ signal }) => getOrdersUsersUserTopbidsV3(user,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(user),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, TError, TData> & { queryKey: QueryKey }
}

export type GetOrdersUsersUserTopbidsV3QueryResult = NonNullable<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>>
export type GetOrdersUsersUserTopbidsV3QueryError = ErrorType<unknown>

/**
 * @summary User Top Bids
 */
export const useGetOrdersUsersUserTopbidsV3 = <TData = Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, TError = ErrorType<unknown>>(
 user: string,
    params?: GetOrdersUsersUserTopbidsV3Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrdersUsersUserTopbidsV3>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrdersUsersUserTopbidsV3QueryOptions(user,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect to the given collection image
 */
export const getRedirectCollectionsCollectionImageV1 = (
    collection: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/collections/${collection}/image/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRedirectCollectionsCollectionImageV1QueryKey = (collection: string,) => {
    return [`/redirect/collections/${collection}/image/v1`] as const;
    }

    
export const getGetRedirectCollectionsCollectionImageV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>>, TError = ErrorType<string>>(collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectCollectionsCollectionImageV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>> = ({ signal }) => getRedirectCollectionsCollectionImageV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectCollectionsCollectionImageV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>>
export type GetRedirectCollectionsCollectionImageV1InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect to the given collection image
 */
export const useGetRedirectCollectionsCollectionImageV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>>, TError = ErrorType<string>>(
 collection: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectCollectionsCollectionImageV1InfiniteQueryOptions(collection,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectCollectionsCollectionImageV1QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError = ErrorType<string>>(collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectCollectionsCollectionImageV1QueryKey(collection);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>> = ({ signal }) => getRedirectCollectionsCollectionImageV1(collection, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collection),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectCollectionsCollectionImageV1QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>>
export type GetRedirectCollectionsCollectionImageV1QueryError = ErrorType<string>

/**
 * @summary Redirect to the given collection image
 */
export const useGetRedirectCollectionsCollectionImageV1 = <TData = Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError = ErrorType<string>>(
 collection: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectCollectionsCollectionImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectCollectionsCollectionImageV1QueryOptions(collection,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect response to the given currency address icon
 */
export const getRedirectCurrencyAddressIconV1 = (
    address: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/currency/${address}/icon/v1`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRedirectCurrencyAddressIconV1QueryKey = (address: string,) => {
    return [`/redirect/currency/${address}/icon/v1`] as const;
    }

    
export const getGetRedirectCurrencyAddressIconV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>>, TError = ErrorType<string>>(address: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectCurrencyAddressIconV1QueryKey(address);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>> = ({ signal }) => getRedirectCurrencyAddressIconV1(address, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(address),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectCurrencyAddressIconV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>>
export type GetRedirectCurrencyAddressIconV1InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect response to the given currency address icon
 */
export const useGetRedirectCurrencyAddressIconV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>>, TError = ErrorType<string>>(
 address: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectCurrencyAddressIconV1InfiniteQueryOptions(address,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectCurrencyAddressIconV1QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError = ErrorType<string>>(address: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectCurrencyAddressIconV1QueryKey(address);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>> = ({ signal }) => getRedirectCurrencyAddressIconV1(address, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(address),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectCurrencyAddressIconV1QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>>
export type GetRedirectCurrencyAddressIconV1QueryError = ErrorType<string>

/**
 * @summary Redirect response to the given currency address icon
 */
export const useGetRedirectCurrencyAddressIconV1 = <TData = Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError = ErrorType<string>>(
 address: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectCurrencyAddressIconV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectCurrencyAddressIconV1QueryOptions(address,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect response to the given source logo
 */
export const getRedirectSourcesSourceLogoV2 = (
    source: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/sources/${source}/logo/v2`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRedirectSourcesSourceLogoV2QueryKey = (source: string,) => {
    return [`/redirect/sources/${source}/logo/v2`] as const;
    }

    
export const getGetRedirectSourcesSourceLogoV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>>, TError = ErrorType<string>>(source: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectSourcesSourceLogoV2QueryKey(source);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>> = ({ signal }) => getRedirectSourcesSourceLogoV2(source, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(source),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectSourcesSourceLogoV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>>
export type GetRedirectSourcesSourceLogoV2InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect response to the given source logo
 */
export const useGetRedirectSourcesSourceLogoV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>>, TError = ErrorType<string>>(
 source: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectSourcesSourceLogoV2InfiniteQueryOptions(source,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectSourcesSourceLogoV2QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError = ErrorType<string>>(source: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectSourcesSourceLogoV2QueryKey(source);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>> = ({ signal }) => getRedirectSourcesSourceLogoV2(source, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(source),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectSourcesSourceLogoV2QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>>
export type GetRedirectSourcesSourceLogoV2QueryError = ErrorType<string>

/**
 * @summary Redirect response to the given source logo
 */
export const useGetRedirectSourcesSourceLogoV2 = <TData = Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError = ErrorType<string>>(
 source: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceLogoV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectSourcesSourceLogoV2QueryOptions(source,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect response to the given token image
 */
export const getRedirectTokensTokenImageV1 = (
    token: string,
    params?: GetRedirectTokensTokenImageV1Params,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/tokens/${token}/image/v1`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRedirectTokensTokenImageV1QueryKey = (token: string,
    params?: GetRedirectTokensTokenImageV1Params,) => {
    return [`/redirect/tokens/${token}/image/v1`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRedirectTokensTokenImageV1InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, GetRedirectTokensTokenImageV1Params['continuation']>, TError = ErrorType<string>>(token: string,
    params?: GetRedirectTokensTokenImageV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, QueryKey, GetRedirectTokensTokenImageV1Params['continuation']>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectTokensTokenImageV1QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, QueryKey, GetRedirectTokensTokenImageV1Params['continuation']> = ({ signal, pageParam }) => getRedirectTokensTokenImageV1(token,{...params, continuation: pageParam || params?.['continuation']}, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, QueryKey, GetRedirectTokensTokenImageV1Params['continuation']> & { queryKey: QueryKey }
}

export type GetRedirectTokensTokenImageV1InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>>
export type GetRedirectTokensTokenImageV1InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect response to the given token image
 */
export const useGetRedirectTokensTokenImageV1Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, GetRedirectTokensTokenImageV1Params['continuation']>, TError = ErrorType<string>>(
 token: string,
    params?: GetRedirectTokensTokenImageV1Params, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData, Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, QueryKey, GetRedirectTokensTokenImageV1Params['continuation']>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectTokensTokenImageV1InfiniteQueryOptions(token,params,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectTokensTokenImageV1QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError = ErrorType<string>>(token: string,
    params?: GetRedirectTokensTokenImageV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectTokensTokenImageV1QueryKey(token,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>> = ({ signal }) => getRedirectTokensTokenImageV1(token,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectTokensTokenImageV1QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>>
export type GetRedirectTokensTokenImageV1QueryError = ErrorType<string>

/**
 * @summary Redirect response to the given token image
 */
export const useGetRedirectTokensTokenImageV1 = <TData = Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError = ErrorType<string>>(
 token: string,
    params?: GetRedirectTokensTokenImageV1Params, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectTokensTokenImageV1>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectTokensTokenImageV1QueryOptions(token,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * @summary Redirect response to the given source token page
 */
export const getRedirectSourcesSourceTokensTokenLinkV2 = (
    source: string,
    token: string,
 options?: SecondParameter<typeof client>,signal?: AbortSignal
) => {
      
      
      return client<string>(
      {url: `/redirect/sources/${source}/tokens/${token}/link/v2`, method: 'GET', signal
    },
      options);
    }
  

export const getGetRedirectSourcesSourceTokensTokenLinkV2QueryKey = (source: string,
    token: string,) => {
    return [`/redirect/sources/${source}/tokens/${token}/link/v2`] as const;
    }

    
export const getGetRedirectSourcesSourceTokensTokenLinkV2InfiniteQueryOptions = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>>, TError = ErrorType<string>>(source: string,
    token: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectSourcesSourceTokensTokenLinkV2QueryKey(source,token);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>> = ({ signal }) => getRedirectSourcesSourceTokensTokenLinkV2(source,token, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(source && token),  staleTime: 10000,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectSourcesSourceTokensTokenLinkV2InfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>>
export type GetRedirectSourcesSourceTokensTokenLinkV2InfiniteQueryError = ErrorType<string>

/**
 * @summary Redirect response to the given source token page
 */
export const useGetRedirectSourcesSourceTokensTokenLinkV2Infinite = <TData = InfiniteData<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>>, TError = ErrorType<string>>(
 source: string,
    token: string, options?: { query?:Partial<UseInfiniteQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectSourcesSourceTokensTokenLinkV2InfiniteQueryOptions(source,token,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetRedirectSourcesSourceTokensTokenLinkV2QueryOptions = <TData = Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError = ErrorType<string>>(source: string,
    token: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRedirectSourcesSourceTokensTokenLinkV2QueryKey(source,token);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>> = ({ signal }) => getRedirectSourcesSourceTokensTokenLinkV2(source,token, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(source && token),  staleTime: 10000,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRedirectSourcesSourceTokensTokenLinkV2QueryResult = NonNullable<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>>
export type GetRedirectSourcesSourceTokensTokenLinkV2QueryError = ErrorType<string>

/**
 * @summary Redirect response to the given source token page
 */
export const useGetRedirectSourcesSourceTokensTokenLinkV2 = <TData = Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError = ErrorType<string>>(
 source: string,
    token: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRedirectSourcesSourceTokensTokenLinkV2>>, TError, TData>>, request?: SecondParameter<typeof client>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRedirectSourcesSourceTokensTokenLinkV2QueryOptions(source,token,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}




/**
 * This API requires an administrator API for execution. Explore and try the `/collections-sets/v1` or `/contracts-sets/v1` endpoints. Please contact technical support with more questions.
 * @summary Set a community for a specific collection
 */
export const putCollectionsCollectionCommunityV1 = (
    collection: string,
    model385: BodyType<Model385>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/collections/${collection}/community/v1`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: model385
    },
      options);
    }
  


export const getPutCollectionsCollectionCommunityV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>, TError,{collection: string;data: BodyType<Model385>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>, TError,{collection: string;data: BodyType<Model385>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>, {collection: string;data: BodyType<Model385>}> = (props) => {
          const {collection,data} = props ?? {};

          return  putCollectionsCollectionCommunityV1(collection,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PutCollectionsCollectionCommunityV1MutationResult = NonNullable<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>>
    export type PutCollectionsCollectionCommunityV1MutationBody = BodyType<Model385>
    export type PutCollectionsCollectionCommunityV1MutationError = ErrorType<unknown>

    /**
 * @summary Set a community for a specific collection
 */
export const usePutCollectionsCollectionCommunityV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putCollectionsCollectionCommunityV1>>, TError,{collection: string;data: BodyType<Model385>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPutCollectionsCollectionCommunityV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * The API key can be used in every route, by setting it as a request header **x-api-key**.

<a href='https://docs.reservoir.tools/reference/getting-started'>Learn more</a> about API Keys and Rate Limiting
 * @summary Generate API Key
 */
export const postApikeys = (
    postApikeysBody: BodyType<PostApikeysBody>,
 options?: SecondParameter<typeof client>,) => {
      
      const formUrlEncoded = new URLSearchParams();
formUrlEncoded.append('appName', postApikeysBody.appName)
formUrlEncoded.append('email', postApikeysBody.email)
formUrlEncoded.append('website', postApikeysBody.website)

      return client<GetNewApiKeyResponse>(
      {url: `/api-keys`, method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded
    },
      options);
    }
  


export const getPostApikeysMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApikeys>>, TError,{data: BodyType<PostApikeysBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postApikeys>>, TError,{data: BodyType<PostApikeysBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApikeys>>, {data: BodyType<PostApikeysBody>}> = (props) => {
          const {data} = props ?? {};

          return  postApikeys(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostApikeysMutationResult = NonNullable<Awaited<ReturnType<typeof postApikeys>>>
    export type PostApikeysMutationBody = BodyType<PostApikeysBody>
    export type PostApikeysMutationError = ErrorType<unknown>

    /**
 * @summary Generate API Key
 */
export const usePostApikeys = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApikeys>>, TError,{data: BodyType<PostApikeysBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostApikeysMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger calculation of the give collection tokens rarity
 */
export const postAdminCalcrarity = (
    model386: BodyType<Model386>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/calc-rarity`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model386
    },
      options);
    }
  


export const getPostAdminCalcrarityMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCalcrarity>>, TError,{data: BodyType<Model386>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminCalcrarity>>, TError,{data: BodyType<Model386>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminCalcrarity>>, {data: BodyType<Model386>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminCalcrarity(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminCalcrarityMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminCalcrarity>>>
    export type PostAdminCalcrarityMutationBody = BodyType<Model386>
    export type PostAdminCalcrarityMutationError = ErrorType<string>

    /**
 * @summary Trigger calculation of the give collection tokens rarity
 */
export const usePostAdminCalcrarity = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCalcrarity>>, TError,{data: BodyType<Model386>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminCalcrarityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create rate limit
 */
export const postAdminCreateratelimitrule = (
    model389: BodyType<Model389>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/create-rate-limit-rule`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model389
    },
      options);
    }
  


export const getPostAdminCreateratelimitruleMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCreateratelimitrule>>, TError,{data: BodyType<Model389>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminCreateratelimitrule>>, TError,{data: BodyType<Model389>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminCreateratelimitrule>>, {data: BodyType<Model389>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminCreateratelimitrule(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminCreateratelimitruleMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminCreateratelimitrule>>>
    export type PostAdminCreateratelimitruleMutationBody = BodyType<Model389>
    export type PostAdminCreateratelimitruleMutationError = ErrorType<string>

    /**
 * @summary Create rate limit
 */
export const usePostAdminCreateratelimitrule = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminCreateratelimitrule>>, TError,{data: BodyType<Model389>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminCreateratelimitruleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete the rate limit with the given ID
 */
export const postAdminDeleteratelimitrule = (
    model390: BodyType<Model390>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/delete-rate-limit-rule`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model390
    },
      options);
    }
  


export const getPostAdminDeleteratelimitruleMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminDeleteratelimitrule>>, TError,{data: BodyType<Model390>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminDeleteratelimitrule>>, TError,{data: BodyType<Model390>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminDeleteratelimitrule>>, {data: BodyType<Model390>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminDeleteratelimitrule(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminDeleteratelimitruleMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminDeleteratelimitrule>>>
    export type PostAdminDeleteratelimitruleMutationBody = BodyType<Model390>
    export type PostAdminDeleteratelimitruleMutationError = ErrorType<string>

    /**
 * @summary Delete the rate limit with the given ID
 */
export const usePostAdminDeleteratelimitrule = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminDeleteratelimitrule>>, TError,{data: BodyType<Model390>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminDeleteratelimitruleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any orphaned block.
 */
export const postAdminFixblocks = (
    model391Body: BodyType<Model391Body>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-blocks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model391Body
    },
      options);
    }
  


export const getPostAdminFixblocksMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixblocks>>, TError,{data: BodyType<Model391Body>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixblocks>>, TError,{data: BodyType<Model391Body>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixblocks>>, {data: BodyType<Model391Body>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminFixblocks(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixblocksMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixblocks>>>
    export type PostAdminFixblocksMutationBody = BodyType<Model391Body>
    export type PostAdminFixblocksMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any orphaned block.
 */
export const usePostAdminFixblocks = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixblocks>>, TError,{data: BodyType<Model391Body>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixblocksMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any cache inconsistencies for array of contracts.
 */
export const postAdminFixcache = (
    model392: BodyType<Model392>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-cache`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model392
    },
      options);
    }
  


export const getPostAdminFixcacheMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixcache>>, TError,{data: BodyType<Model392>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixcache>>, TError,{data: BodyType<Model392>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixcache>>, {data: BodyType<Model392>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminFixcache(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixcacheMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixcache>>>
    export type PostAdminFixcacheMutationBody = BodyType<Model392>
    export type PostAdminFixcacheMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any cache inconsistencies for array of contracts.
 */
export const usePostAdminFixcache = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixcache>>, TError,{data: BodyType<Model392>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixcacheMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any order inconsistencies.
 */
export const postAdminFixorders = (
    model393: BodyType<Model393>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-orders`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model393
    },
      options);
    }
  


export const getPostAdminFixordersMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixorders>>, TError,{data: BodyType<Model393>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixorders>>, TError,{data: BodyType<Model393>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixorders>>, {data: BodyType<Model393>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminFixorders(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixordersMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixorders>>>
    export type PostAdminFixordersMutationBody = BodyType<Model393>
    export type PostAdminFixordersMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any order inconsistencies.
 */
export const usePostAdminFixorders = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixorders>>, TError,{data: BodyType<Model393>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixordersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing broken Immutable order hashes
 */
export const postAdminFixordersimtbl = (
    
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-orders-imtbl`, method: 'POST'
    },
      options);
    }
  


export const getPostAdminFixordersimtblMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixordersimtbl>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixordersimtbl>>, TError,void, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixordersimtbl>>, void> = () => {
          

          return  postAdminFixordersimtbl(requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixordersimtblMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixordersimtbl>>>
    
    export type PostAdminFixordersimtblMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing broken Immutable order hashes
 */
export const usePostAdminFixordersimtbl = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixordersimtbl>>, TError,void, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixordersimtblMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any cache inconsistencies for specific token.
 */
export const postAdminFixtokencache = (
    model394: BodyType<Model394>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/fix-token-cache`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model394
    },
      options);
    }
  


export const getPostAdminFixtokencacheMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixtokencache>>, TError,{data: BodyType<Model394>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminFixtokencache>>, TError,{data: BodyType<Model394>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminFixtokencache>>, {data: BodyType<Model394>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminFixtokencache(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminFixtokencacheMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminFixtokencache>>>
    export type PostAdminFixtokencacheMutationBody = BodyType<Model394>
    export type PostAdminFixtokencacheMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any cache inconsistencies for specific token.
 */
export const usePostAdminFixtokencache = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminFixtokencache>>, TError,{data: BodyType<Model394>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminFixtokencacheMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger metadata indexing for a token's collection
 */
export const postAdminIndexmetadata = (
    model395: BodyType<Model395>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/index-metadata`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model395
    },
      options);
    }
  


export const getPostAdminIndexmetadataMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminIndexmetadata>>, TError,{data: BodyType<Model395>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminIndexmetadata>>, TError,{data: BodyType<Model395>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminIndexmetadata>>, {data: BodyType<Model395>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminIndexmetadata(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminIndexmetadataMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminIndexmetadata>>>
    export type PostAdminIndexmetadataMutationBody = BodyType<Model395>
    export type PostAdminIndexmetadataMutationError = ErrorType<string>

    /**
 * @summary Trigger metadata indexing for a token's collection
 */
export const usePostAdminIndexmetadata = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminIndexmetadata>>, TError,{data: BodyType<Model395>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminIndexmetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Pause rabbit queue
 */
export const postAdminPauserabbitqueue = (
    model396: BodyType<Model396>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/pause-rabbit-queue`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model396
    },
      options);
    }
  


export const getPostAdminPauserabbitqueueMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>, TError,{data: BodyType<Model396>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>, TError,{data: BodyType<Model396>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>, {data: BodyType<Model396>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminPauserabbitqueue(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminPauserabbitqueueMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>>
    export type PostAdminPauserabbitqueueMutationBody = BodyType<Model396>
    export type PostAdminPauserabbitqueueMutationError = ErrorType<string>

    /**
 * @summary Pause rabbit queue
 */
export const usePostAdminPauserabbitqueue = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminPauserabbitqueue>>, TError,{data: BodyType<Model396>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminPauserabbitqueueMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Refresh a collection's orders and metadata. Tokens marked as spam will be ignored.
 */
export const postAdminRefreshcollection = (
    model397: BodyType<Model397>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/refresh-collection`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model397
    },
      options);
    }
  


export const getPostAdminRefreshcollectionMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshcollection>>, TError,{data: BodyType<Model397>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshcollection>>, TError,{data: BodyType<Model397>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRefreshcollection>>, {data: BodyType<Model397>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRefreshcollection(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRefreshcollectionMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRefreshcollection>>>
    export type PostAdminRefreshcollectionMutationBody = BodyType<Model397>
    export type PostAdminRefreshcollectionMutationError = ErrorType<string>

    /**
 * @summary Refresh a collection's orders and metadata. Tokens marked as spam will be ignored.
 */
export const usePostAdminRefreshcollection = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshcollection>>, TError,{data: BodyType<Model397>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRefreshcollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Refresh a token's orders and metadata
 */
export const postAdminRefreshtoken = (
    model398: BodyType<Model398>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/refresh-token`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model398
    },
      options);
    }
  


export const getPostAdminRefreshtokenMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshtoken>>, TError,{data: BodyType<Model398>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshtoken>>, TError,{data: BodyType<Model398>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRefreshtoken>>, {data: BodyType<Model398>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRefreshtoken(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRefreshtokenMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRefreshtoken>>>
    export type PostAdminRefreshtokenMutationBody = BodyType<Model398>
    export type PostAdminRefreshtokenMutationError = ErrorType<string>

    /**
 * @summary Refresh a token's orders and metadata
 */
export const usePostAdminRefreshtoken = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRefreshtoken>>, TError,{data: BodyType<Model398>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRefreshtokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Resume rabbit queue
 */
export const postAdminResumerabbitqueue = (
    model399: BodyType<Model399>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resume-rabbit-queue`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model399
    },
      options);
    }
  


export const getPostAdminResumerabbitqueueMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>, TError,{data: BodyType<Model399>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>, TError,{data: BodyType<Model399>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>, {data: BodyType<Model399>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResumerabbitqueue(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResumerabbitqueueMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>>
    export type PostAdminResumerabbitqueueMutationBody = BodyType<Model399>
    export type PostAdminResumerabbitqueueMutationError = ErrorType<string>

    /**
 * @summary Resume rabbit queue
 */
export const usePostAdminResumerabbitqueue = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResumerabbitqueue>>, TError,{data: BodyType<Model399>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResumerabbitqueueMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger fixing any floor events inconsistencies for any particular collection.
 */
export const postAdminResyncfloorevents = (
    model400: BodyType<Model400>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-floor-events`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model400
    },
      options);
    }
  


export const getPostAdminResyncflooreventsMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncfloorevents>>, TError,{data: BodyType<Model400>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncfloorevents>>, TError,{data: BodyType<Model400>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncfloorevents>>, {data: BodyType<Model400>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncfloorevents(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncflooreventsMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncfloorevents>>>
    export type PostAdminResyncflooreventsMutationBody = BodyType<Model400>
    export type PostAdminResyncflooreventsMutationError = ErrorType<string>

    /**
 * @summary Trigger fixing any floor events inconsistencies for any particular collection.
 */
export const usePostAdminResyncfloorevents = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncfloorevents>>, TError,{data: BodyType<Model400>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncflooreventsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger the recalculation of nft balances for tokens transferred in any particular block range
 */
export const postAdminResyncnftbalances = (
    model391Body: BodyType<Model391Body>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-nft-balances`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model391Body
    },
      options);
    }
  


export const getPostAdminResyncnftbalancesMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncnftbalances>>, TError,{data: BodyType<Model391Body>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncnftbalances>>, TError,{data: BodyType<Model391Body>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncnftbalances>>, {data: BodyType<Model391Body>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncnftbalances(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncnftbalancesMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncnftbalances>>>
    export type PostAdminResyncnftbalancesMutationBody = BodyType<Model391Body>
    export type PostAdminResyncnftbalancesMutationError = ErrorType<string>

    /**
 * @summary Trigger the recalculation of nft balances for tokens transferred in any particular block range
 */
export const usePostAdminResyncnftbalances = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncnftbalances>>, TError,{data: BodyType<Model391Body>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncnftbalancesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger the recalculation of sale royalties for any particular block range.
 */
export const postAdminResyncsaleroyalties = (
    model402: BodyType<Model402>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-sale-royalties`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model402
    },
      options);
    }
  


export const getPostAdminResyncsaleroyaltiesMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>, TError,{data: BodyType<Model402>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>, TError,{data: BodyType<Model402>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>, {data: BodyType<Model402>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncsaleroyalties(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncsaleroyaltiesMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>>
    export type PostAdminResyncsaleroyaltiesMutationBody = BodyType<Model402>
    export type PostAdminResyncsaleroyaltiesMutationError = ErrorType<string>

    /**
 * @summary Trigger the recalculation of sale royalties for any particular block range.
 */
export const usePostAdminResyncsaleroyalties = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsaleroyalties>>, TError,{data: BodyType<Model402>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncsaleroyaltiesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger re-syncing of specific source domain
 */
export const postAdminResyncsource = (
    model403: BodyType<Model403>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-source`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model403
    },
      options);
    }
  


export const getPostAdminResyncsourceMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsource>>, TError,{data: BodyType<Model403>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsource>>, TError,{data: BodyType<Model403>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncsource>>, {data: BodyType<Model403>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncsource(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncsourceMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncsource>>>
    export type PostAdminResyncsourceMutationBody = BodyType<Model403>
    export type PostAdminResyncsourceMutationError = ErrorType<string>

    /**
 * @summary Trigger re-syncing of specific source domain
 */
export const usePostAdminResyncsource = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncsource>>, TError,{data: BodyType<Model403>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncsourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger the recalculation of user in certain collection
 */
export const postAdminResyncuserbalance = (
    model404: BodyType<Model404>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/resync-user-balance`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model404
    },
      options);
    }
  


export const getPostAdminResyncuserbalanceMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncuserbalance>>, TError,{data: BodyType<Model404>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncuserbalance>>, TError,{data: BodyType<Model404>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminResyncuserbalance>>, {data: BodyType<Model404>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminResyncuserbalance(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminResyncuserbalanceMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminResyncuserbalance>>>
    export type PostAdminResyncuserbalanceMutationBody = BodyType<Model404>
    export type PostAdminResyncuserbalanceMutationError = ErrorType<string>

    /**
 * @summary Trigger the recalculation of user in certain collection
 */
export const usePostAdminResyncuserbalance = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminResyncuserbalance>>, TError,{data: BodyType<Model404>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminResyncuserbalanceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Retry all the messages within the given dead letter rabbit queue
 */
export const postAdminRetryrabbitqueue = (
    model405: BodyType<Model405>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/retry-rabbit-queue`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model405
    },
      options);
    }
  


export const getPostAdminRetryrabbitqueueMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>, TError,{data: BodyType<Model405>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>, TError,{data: BodyType<Model405>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>, {data: BodyType<Model405>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRetryrabbitqueue(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRetryrabbitqueueMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>>
    export type PostAdminRetryrabbitqueueMutationBody = BodyType<Model405>
    export type PostAdminRetryrabbitqueueMutationError = ErrorType<string>

    /**
 * @summary Retry all the messages within the given dead letter rabbit queue
 */
export const usePostAdminRetryrabbitqueue = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRetryrabbitqueue>>, TError,{data: BodyType<Model405>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRetryrabbitqueueMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Revalidate an existing order
 */
export const postAdminRevalidateorder = (
    model406: BodyType<Model406>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/revalidate-order`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model406
    },
      options);
    }
  


export const getPostAdminRevalidateorderMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRevalidateorder>>, TError,{data: BodyType<Model406>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRevalidateorder>>, TError,{data: BodyType<Model406>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRevalidateorder>>, {data: BodyType<Model406>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRevalidateorder(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRevalidateorderMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRevalidateorder>>>
    export type PostAdminRevalidateorderMutationBody = BodyType<Model406>
    export type PostAdminRevalidateorderMutationError = ErrorType<string>

    /**
 * @summary Revalidate an existing order
 */
export const usePostAdminRevalidateorder = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRevalidateorder>>, TError,{data: BodyType<Model406>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRevalidateorderMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add a new router contract
 */
export const postAdminRouters = (
    model408: BodyType<Model408>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/routers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model408
    },
      options);
    }
  


export const getPostAdminRoutersMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRouters>>, TError,{data: BodyType<Model408>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminRouters>>, TError,{data: BodyType<Model408>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminRouters>>, {data: BodyType<Model408>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminRouters(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminRoutersMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminRouters>>>
    export type PostAdminRoutersMutationBody = BodyType<Model408>
    export type PostAdminRoutersMutationError = ErrorType<string>

    /**
 * @summary Add a new router contract
 */
export const usePostAdminRouters = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminRouters>>, TError,{data: BodyType<Model408>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminRoutersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Set a community for a specific collection
 */
export const postAdminSetcommunity = (
    model409: BodyType<Model409>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/set-community`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model409
    },
      options);
    }
  


export const getPostAdminSetcommunityMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSetcommunity>>, TError,{data: BodyType<Model409>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminSetcommunity>>, TError,{data: BodyType<Model409>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminSetcommunity>>, {data: BodyType<Model409>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminSetcommunity(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminSetcommunityMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminSetcommunity>>>
    export type PostAdminSetcommunityMutationBody = BodyType<Model409>
    export type PostAdminSetcommunityMutationError = ErrorType<string>

    /**
 * @summary Set a community for a specific collection
 */
export const usePostAdminSetcommunity = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSetcommunity>>, TError,{data: BodyType<Model409>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminSetcommunityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger a re-sync of daily volume calculations, volumes should only be calculated when fill_events have been fully synced
 */
export const postAdminSyncdailyvolumes = (
    model410: BodyType<Model410>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/sync-daily-volumes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model410
    },
      options);
    }
  


export const getPostAdminSyncdailyvolumesMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>, TError,{data: BodyType<Model410>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>, TError,{data: BodyType<Model410>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>, {data: BodyType<Model410>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminSyncdailyvolumes(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminSyncdailyvolumesMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>>
    export type PostAdminSyncdailyvolumesMutationBody = BodyType<Model410>
    export type PostAdminSyncdailyvolumesMutationError = ErrorType<string>

    /**
 * @summary Trigger a re-sync of daily volume calculations, volumes should only be calculated when fill_events have been fully synced
 */
export const usePostAdminSyncdailyvolumes = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncdailyvolumes>>, TError,{data: BodyType<Model410>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminSyncdailyvolumesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger syncing of events.
 */
export const postAdminSyncevents = (
    model412: BodyType<Model412>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/sync-events`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model412
    },
      options);
    }
  


export const getPostAdminSynceventsMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncevents>>, TError,{data: BodyType<Model412>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncevents>>, TError,{data: BodyType<Model412>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminSyncevents>>, {data: BodyType<Model412>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminSyncevents(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminSynceventsMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminSyncevents>>>
    export type PostAdminSynceventsMutationBody = BodyType<Model412>
    export type PostAdminSynceventsMutationError = ErrorType<string>

    /**
 * @summary Trigger syncing of events.
 */
export const usePostAdminSyncevents = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminSyncevents>>, TError,{data: BodyType<Model412>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminSynceventsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger bullmq job
 */
export const postAdminTriggerjob = (
    model413: BodyType<Model413>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/trigger-job`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model413
    },
      options);
    }
  


export const getPostAdminTriggerjobMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerjob>>, TError,{data: BodyType<Model413>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerjob>>, TError,{data: BodyType<Model413>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminTriggerjob>>, {data: BodyType<Model413>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminTriggerjob(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminTriggerjobMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminTriggerjob>>>
    export type PostAdminTriggerjobMutationBody = BodyType<Model413>
    export type PostAdminTriggerjobMutationError = ErrorType<string>

    /**
 * @summary Trigger bullmq job
 */
export const usePostAdminTriggerjob = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerjob>>, TError,{data: BodyType<Model413>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminTriggerjobMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger rabbit job
 */
export const postAdminTriggerrabbitjob = (
    model414: BodyType<Model414>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/trigger-rabbit-job`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model414
    },
      options);
    }
  


export const getPostAdminTriggerrabbitjobMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>, TError,{data: BodyType<Model414>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>, TError,{data: BodyType<Model414>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>, {data: BodyType<Model414>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminTriggerrabbitjob(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminTriggerrabbitjobMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>>
    export type PostAdminTriggerrabbitjobMutationBody = BodyType<Model414>
    export type PostAdminTriggerrabbitjobMutationError = ErrorType<string>

    /**
 * @summary Trigger rabbit job
 */
export const usePostAdminTriggerrabbitjob = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminTriggerrabbitjob>>, TError,{data: BodyType<Model414>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminTriggerrabbitjobMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update the given api key
 */
export const postAdminUpdateapikey = (
    model415: BodyType<Model415>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/update-api-key`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model415
    },
      options);
    }
  


export const getPostAdminUpdateapikeyMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateapikey>>, TError,{data: BodyType<Model415>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateapikey>>, TError,{data: BodyType<Model415>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminUpdateapikey>>, {data: BodyType<Model415>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminUpdateapikey(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminUpdateapikeyMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminUpdateapikey>>>
    export type PostAdminUpdateapikeyMutationBody = BodyType<Model415>
    export type PostAdminUpdateapikeyMutationError = ErrorType<string>

    /**
 * @summary Update the given api key
 */
export const usePostAdminUpdateapikey = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateapikey>>, TError,{data: BodyType<Model415>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminUpdateapikeyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Increment the metadata version for a collection to bust the cache
 */
export const postAdminUpdateimageversion = (
    model416: BodyType<Model416>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/update-image-version`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model416
    },
      options);
    }
  


export const getPostAdminUpdateimageversionMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateimageversion>>, TError,{data: BodyType<Model416>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateimageversion>>, TError,{data: BodyType<Model416>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminUpdateimageversion>>, {data: BodyType<Model416>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminUpdateimageversion(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminUpdateimageversionMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminUpdateimageversion>>>
    export type PostAdminUpdateimageversionMutationBody = BodyType<Model416>
    export type PostAdminUpdateimageversionMutationError = ErrorType<string>

    /**
 * @summary Increment the metadata version for a collection to bust the cache
 */
export const usePostAdminUpdateimageversion = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateimageversion>>, TError,{data: BodyType<Model416>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminUpdateimageversionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update the rate limit for the given ID
 */
export const postAdminUpdateratelimitrule = (
    model417: BodyType<Model417>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/update-rate-limit-rule`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model417
    },
      options);
    }
  


export const getPostAdminUpdateratelimitruleMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateratelimitrule>>, TError,{data: BodyType<Model417>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateratelimitrule>>, TError,{data: BodyType<Model417>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminUpdateratelimitrule>>, {data: BodyType<Model417>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminUpdateratelimitrule(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminUpdateratelimitruleMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminUpdateratelimitrule>>>
    export type PostAdminUpdateratelimitruleMutationBody = BodyType<Model417>
    export type PostAdminUpdateratelimitruleMutationError = ErrorType<string>

    /**
 * @summary Update the rate limit for the given ID
 */
export const usePostAdminUpdateratelimitrule = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdateratelimitrule>>, TError,{data: BodyType<Model417>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminUpdateratelimitruleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Trigger re-syncing of specific source domain
 */
export const postAdminUpdatesource = (
    model418: BodyType<Model418>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/admin/update-source`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model418
    },
      options);
    }
  


export const getPostAdminUpdatesourceMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdatesource>>, TError,{data: BodyType<Model418>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdatesource>>, TError,{data: BodyType<Model418>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminUpdatesource>>, {data: BodyType<Model418>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminUpdatesource(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminUpdatesourceMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminUpdatesource>>>
    export type PostAdminUpdatesourceMutationBody = BodyType<Model418>
    export type PostAdminUpdatesourceMutationError = ErrorType<string>

    /**
 * @summary Trigger re-syncing of specific source domain
 */
export const usePostAdminUpdatesource = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminUpdatesource>>, TError,{data: BodyType<Model418>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminUpdatesourceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Array of collections to gather in a set. Adding or removing a collection will change the response. You may use this set when `collectionSetId` is an available param. An example is below.

`"collections": "0xba30E5F9Bb24caa003E9f2f0497Ad287FDF95623", "0xBC4CA0EdA7647A8aB7C2061c2E118A18a936f13D"`

`"collectionsSetId": "8daa732ebe5db23f267e58d52f1c9b1879279bcdf4f78b8fb563390e6946ea65"`
 * @summary Create collection set
 */
export const postCollectionssetsV1 = (
    model420: BodyType<Model420>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostCreateCollectionsSetV1Response>(
      {url: `/collections-sets/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model420
    },
      options);
    }
  


export const getPostCollectionssetsV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionssetsV1>>, TError,{data: BodyType<Model420>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionssetsV1>>, TError,{data: BodyType<Model420>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionssetsV1>>, {data: BodyType<Model420>}> = (props) => {
          const {data} = props ?? {};

          return  postCollectionssetsV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionssetsV1MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionssetsV1>>>
    export type PostCollectionssetsV1MutationBody = BodyType<Model420>
    export type PostCollectionssetsV1MutationError = ErrorType<unknown>

    /**
 * @summary Create collection set
 */
export const usePostCollectionssetsV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionssetsV1>>, TError,{data: BodyType<Model420>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionssetsV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Array of contracts to gather in a set. Adding or removing a contract will change the response. You may use this set when contractSetId is an available param.
 * @summary Create contracts set
 */
export const postContractssetsV1 = (
    model421: BodyType<Model421>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostCreateContractsSetV1Response>(
      {url: `/contracts-sets/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model421
    },
      options);
    }
  


export const getPostContractssetsV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postContractssetsV1>>, TError,{data: BodyType<Model421>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postContractssetsV1>>, TError,{data: BodyType<Model421>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postContractssetsV1>>, {data: BodyType<Model421>}> = (props) => {
          const {data} = props ?? {};

          return  postContractssetsV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostContractssetsV1MutationResult = NonNullable<Awaited<ReturnType<typeof postContractssetsV1>>>
    export type PostContractssetsV1MutationBody = BodyType<Model421>
    export type PostContractssetsV1MutationError = ErrorType<unknown>

    /**
 * @summary Create contracts set
 */
export const usePostContractssetsV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postContractssetsV1>>, TError,{data: BodyType<Model421>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostContractssetsV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Submit single order
 */
export const postOrderV2 = (
    model423: BodyType<Model423>,
    params?: PostOrderV2Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostOrderV2Response>(
      {url: `/order/v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model423,
        params
    },
      options);
    }
  


export const getPostOrderV2MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV2>>, TError,{data: BodyType<Model423>;params?: PostOrderV2Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrderV2>>, TError,{data: BodyType<Model423>;params?: PostOrderV2Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrderV2>>, {data: BodyType<Model423>;params?: PostOrderV2Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postOrderV2(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderV2MutationResult = NonNullable<Awaited<ReturnType<typeof postOrderV2>>>
    export type PostOrderV2MutationBody = BodyType<Model423>
    export type PostOrderV2MutationError = ErrorType<unknown>

    /**
 * @summary Submit single order
 */
export const usePostOrderV2 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV2>>, TError,{data: BodyType<Model423>;params?: PostOrderV2Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrderV2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Submit signed order
 */
export const postOrderV3 = (
    model425: BodyType<Model425>,
    params?: PostOrderV3Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostOrderV2Response>(
      {url: `/order/v3`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model425,
        params
    },
      options);
    }
  


export const getPostOrderV3MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV3>>, TError,{data: BodyType<Model425>;params?: PostOrderV3Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrderV3>>, TError,{data: BodyType<Model425>;params?: PostOrderV3Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrderV3>>, {data: BodyType<Model425>;params?: PostOrderV3Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postOrderV3(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderV3MutationResult = NonNullable<Awaited<ReturnType<typeof postOrderV3>>>
    export type PostOrderV3MutationBody = BodyType<Model425>
    export type PostOrderV3MutationError = ErrorType<unknown>

    /**
 * @summary Submit signed order
 */
export const usePostOrderV3 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV3>>, TError,{data: BodyType<Model425>;params?: PostOrderV3Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrderV3MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Submit signed orders
 */
export const postOrderV4 = (
    model430: BodyType<Model430>,
    params?: PostOrderV4Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostOrderV4Response>(
      {url: `/order/v4`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model430,
        params
    },
      options);
    }
  


export const getPostOrderV4MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV4>>, TError,{data: BodyType<Model430>;params?: PostOrderV4Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrderV4>>, TError,{data: BodyType<Model430>;params?: PostOrderV4Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrderV4>>, {data: BodyType<Model430>;params?: PostOrderV4Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postOrderV4(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrderV4MutationResult = NonNullable<Awaited<ReturnType<typeof postOrderV4>>>
    export type PostOrderV4MutationBody = BodyType<Model430>
    export type PostOrderV4MutationError = ErrorType<unknown>

    /**
 * @summary Submit signed orders
 */
export const usePostOrderV4 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrderV4>>, TError,{data: BodyType<Model430>;params?: PostOrderV4Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrderV4MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Submit multiple Seaport offers (compatible with OpenSea's API response)
 */
export const postSeaportOffers = (
    model436: BodyType<Model436>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/seaport/offers`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model436
    },
      options);
    }
  


export const getPostSeaportOffersMutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSeaportOffers>>, TError,{data: BodyType<Model436>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postSeaportOffers>>, TError,{data: BodyType<Model436>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSeaportOffers>>, {data: BodyType<Model436>}> = (props) => {
          const {data} = props ?? {};

          return  postSeaportOffers(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostSeaportOffersMutationResult = NonNullable<Awaited<ReturnType<typeof postSeaportOffers>>>
    export type PostSeaportOffersMutationBody = BodyType<Model436>
    export type PostSeaportOffersMutationError = ErrorType<string>

    /**
 * @deprecated
 * @summary Submit multiple Seaport offers (compatible with OpenSea's API response)
 */
export const usePostSeaportOffers = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postSeaportOffers>>, TError,{data: BodyType<Model436>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostSeaportOffersMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Create Token Set
 */
export const postTokensetsV1 = (
    model437: BodyType<Model437>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<Model258>(
      {url: `/token-sets/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model437
    },
      options);
    }
  


export const getPostTokensetsV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensetsV1>>, TError,{data: BodyType<Model437>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensetsV1>>, TError,{data: BodyType<Model437>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensetsV1>>, {data: BodyType<Model437>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensetsV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensetsV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensetsV1>>>
    export type PostTokensetsV1MutationBody = BodyType<Model437>
    export type PostTokensetsV1MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Create Token Set
 */
export const usePostTokensetsV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensetsV1>>, TError,{data: BodyType<Model437>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensetsV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to create a `tokenSetId` to call specific tokens from a collection. Adding or removing a tokenId will change the response. See an example below.

 Input of `0xd774557b647330c91bf44cfeab205095f7e6c367:1` and `0xd774557b647330c91bf44cfeab205095f7e6c367:2`

 Output of `list:0xd774557b647330c91bf44cfeab205095f7e6c367:0xb6fd98eeb7e08fc521f11511289afe4d8e873fd7a3fb76ab757fa47c23f596e9`

 Notes:

- Include `list:` when using this `tokenSetId` for it to work successfully.

- You cannot adjust tokens within a `tokenSetId`. Please create a new set.

- Use the `/tokens/ids` endpoint to get a list of tokens within a set.
 * @summary Create token set
 */
export const postTokensetsV2 = (
    model439: BodyType<Model439>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<Model258>(
      {url: `/token-sets/v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model439
    },
      options);
    }
  


export const getPostTokensetsV2MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensetsV2>>, TError,{data: BodyType<Model439>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensetsV2>>, TError,{data: BodyType<Model439>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensetsV2>>, {data: BodyType<Model439>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensetsV2(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensetsV2MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensetsV2>>>
    export type PostTokensetsV2MutationBody = BodyType<Model439>
    export type PostTokensetsV2MutationError = ErrorType<unknown>

    /**
 * @summary Create token set
 */
export const usePostTokensetsV2 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensetsV2>>, TError,{data: BodyType<Model439>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensetsV2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Get API usage metrics for the given API key
 * @summary Get API usage metrics for the given API key
 */
export const postAdminApikeysMetrics = (
    postAdminApikeysMetricsBody: BodyType<PostAdminApikeysMetricsBody>,
 options?: SecondParameter<typeof client>,) => {
      
      const formUrlEncoded = new URLSearchParams();
postAdminApikeysMetricsBody.keys.forEach(value => formUrlEncoded.append('keys', value));
if(postAdminApikeysMetricsBody.granularity !== undefined) {
 formUrlEncoded.append('granularity', postAdminApikeysMetricsBody.granularity)
 }
if(postAdminApikeysMetricsBody.groupBy !== undefined) {
 formUrlEncoded.append('groupBy', postAdminApikeysMetricsBody.groupBy.toString())
 }
if(postAdminApikeysMetricsBody.startTime !== undefined) {
 formUrlEncoded.append('startTime', postAdminApikeysMetricsBody.startTime)
 }
if(postAdminApikeysMetricsBody.endTime !== undefined) {
 formUrlEncoded.append('endTime', postAdminApikeysMetricsBody.endTime)
 }

      return client<PostApiKeyMetricsResponse>(
      {url: `/admin/api-keys/metrics`, method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded
    },
      options);
    }
  


export const getPostAdminApikeysMetricsMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminApikeysMetrics>>, TError,{data: BodyType<PostAdminApikeysMetricsBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postAdminApikeysMetrics>>, TError,{data: BodyType<PostAdminApikeysMetricsBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postAdminApikeysMetrics>>, {data: BodyType<PostAdminApikeysMetricsBody>}> = (props) => {
          const {data} = props ?? {};

          return  postAdminApikeysMetrics(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostAdminApikeysMetricsMutationResult = NonNullable<Awaited<ReturnType<typeof postAdminApikeysMetrics>>>
    export type PostAdminApikeysMetricsMutationBody = BodyType<PostAdminApikeysMetricsBody>
    export type PostAdminApikeysMetricsMutationError = ErrorType<unknown>

    /**
 * @summary Get API usage metrics for the given API key
 */
export const usePostAdminApikeysMetrics = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postAdminApikeysMetrics>>, TError,{data: BodyType<PostAdminApikeysMetricsBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostAdminApikeysMetricsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This API requires an allowed API key for execution. Please contact technical support with more questions.
 * @summary Disable or reenable metadata for a collection
 */
export const postCollectionsDisablemetadataV1 = (
    model442: BodyType<Model442>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/collections/disable-metadata/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model442
    },
      options);
    }
  


export const getPostCollectionsDisablemetadataV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>, TError,{data: BodyType<Model442>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>, TError,{data: BodyType<Model442>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>, {data: BodyType<Model442>}> = (props) => {
          const {data} = props ?? {};

          return  postCollectionsDisablemetadataV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionsDisablemetadataV1MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>>
    export type PostCollectionsDisablemetadataV1MutationBody = BodyType<Model442>
    export type PostCollectionsDisablemetadataV1MutationError = ErrorType<unknown>

    /**
 * @summary Disable or reenable metadata for a collection
 */
export const usePostCollectionsDisablemetadataV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsDisablemetadataV1>>, TError,{data: BodyType<Model442>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionsDisablemetadataV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Refresh Collection
 */
export const postCollectionsRefreshV1 = (
    model443: BodyType<Model443>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/collections/refresh/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model443
    },
      options);
    }
  


export const getPostCollectionsRefreshV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsRefreshV1>>, TError,{data: BodyType<Model443>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionsRefreshV1>>, TError,{data: BodyType<Model443>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionsRefreshV1>>, {data: BodyType<Model443>}> = (props) => {
          const {data} = props ?? {};

          return  postCollectionsRefreshV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionsRefreshV1MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionsRefreshV1>>>
    export type PostCollectionsRefreshV1MutationBody = BodyType<Model443>
    export type PostCollectionsRefreshV1MutationError = ErrorType<unknown>

    /**
 * @summary Refresh Collection
 */
export const usePostCollectionsRefreshV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsRefreshV1>>, TError,{data: BodyType<Model443>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionsRefreshV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to refresh a collection metadata. Only use this endpoint when you notice multiple tokens with incorrect metadata. Otherwise, refresh single token metadata. Collections with over 30,000 tokens require admin key override, so please contact technical support for assistance.

 Collection metadata is automatically updated at 23:30 UTC daily for:

- Top 500 Collection by 24hr Volume

- Collections Minted 1 Day Ago

- Collections Minted 7 Days Ago

 Caution: This API should be used in moderation, like only when missing data is discovered. Calling it in bulk or programmatically will result in your API key getting rate limited.
 * @deprecated
 * @summary Refresh Collection
 */
export const postCollectionsRefreshV2 = (
    model444: BodyType<Model444>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/collections/refresh/v2`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model444
    },
      options);
    }
  


export const getPostCollectionsRefreshV2MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsRefreshV2>>, TError,{data: BodyType<Model444>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionsRefreshV2>>, TError,{data: BodyType<Model444>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionsRefreshV2>>, {data: BodyType<Model444>}> = (props) => {
          const {data} = props ?? {};

          return  postCollectionsRefreshV2(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionsRefreshV2MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionsRefreshV2>>>
    export type PostCollectionsRefreshV2MutationBody = BodyType<Model444>
    export type PostCollectionsRefreshV2MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Refresh Collection
 */
export const usePostCollectionsRefreshV2 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsRefreshV2>>, TError,{data: BodyType<Model444>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionsRefreshV2MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This API can be used by allowed API keys to update the spam status of a collection.
 * @summary Update collections spam status
 */
export const postCollectionsSpamstatusV1 = (
    model446: BodyType<Model446>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/collections/spam-status/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model446
    },
      options);
    }
  


export const getPostCollectionsSpamstatusV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>, TError,{data: BodyType<Model446>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>, TError,{data: BodyType<Model446>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>, {data: BodyType<Model446>}> = (props) => {
          const {data} = props ?? {};

          return  postCollectionsSpamstatusV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionsSpamstatusV1MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>>
    export type PostCollectionsSpamstatusV1MutationBody = BodyType<Model446>
    export type PostCollectionsSpamstatusV1MutationError = ErrorType<unknown>

    /**
 * @summary Update collections spam status
 */
export const usePostCollectionsSpamstatusV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsSpamstatusV1>>, TError,{data: BodyType<Model446>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionsSpamstatusV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Attach a signature to an existing auth challenge
 */
export const postExecuteAuthsignatureV1 = (
    model447: BodyType<Model447>,
    params: PostExecuteAuthsignatureV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostAuthSignatureV1Response>(
      {url: `/execute/auth-signature/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model447,
        params
    },
      options);
    }
  


export const getPostExecuteAuthsignatureV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>, TError,{data: BodyType<Model447>;params: PostExecuteAuthsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>, TError,{data: BodyType<Model447>;params: PostExecuteAuthsignatureV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>, {data: BodyType<Model447>;params: PostExecuteAuthsignatureV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecuteAuthsignatureV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteAuthsignatureV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>>
    export type PostExecuteAuthsignatureV1MutationBody = BodyType<Model447>
    export type PostExecuteAuthsignatureV1MutationError = ErrorType<unknown>

    /**
 * @summary Attach a signature to an existing auth challenge
 */
export const usePostExecuteAuthsignatureV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteAuthsignatureV1>>, TError,{data: BodyType<Model447>;params: PostExecuteAuthsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteAuthsignatureV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Generate a bid and submit it to multiple marketplaces
 * @deprecated
 * @summary Create bid (offer)
 */
export const postExecuteBidV4 = (
    model449: BodyType<Model449>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteBidV4Response>(
      {url: `/execute/bid/v4`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model449
    },
      options);
    }
  


export const getPostExecuteBidV4MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV4>>, TError,{data: BodyType<Model449>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV4>>, TError,{data: BodyType<Model449>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBidV4>>, {data: BodyType<Model449>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBidV4(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBidV4MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBidV4>>>
    export type PostExecuteBidV4MutationBody = BodyType<Model449>
    export type PostExecuteBidV4MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Create bid (offer)
 */
export const usePostExecuteBidV4 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV4>>, TError,{data: BodyType<Model449>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBidV4MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Generate bids and submit them to multiple marketplaces.

 Notes:

- Please use the `/cross-posting-orders/v1` to check the status on cross posted bids.

- We recommend using Reservoir SDK as it abstracts the process of iterating through steps, and returning callbacks that can be used to update your UI.
 * @summary Create bids (offers)
 */
export const postExecuteBidV5 = (
    model455: BodyType<Model455>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteBidV5Response>(
      {url: `/execute/bid/v5`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model455
    },
      options);
    }
  


export const getPostExecuteBidV5MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV5>>, TError,{data: BodyType<Model455>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV5>>, TError,{data: BodyType<Model455>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBidV5>>, {data: BodyType<Model455>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBidV5(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBidV5MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBidV5>>>
    export type PostExecuteBidV5MutationBody = BodyType<Model455>
    export type PostExecuteBidV5MutationError = ErrorType<unknown>

    /**
 * @summary Create bids (offers)
 */
export const usePostExecuteBidV5 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBidV5>>, TError,{data: BodyType<Model455>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBidV5MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Buy tokens
 */
export const postExecuteBuyV5 = (
    model463: BodyType<Model463>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteBuyV5Response>(
      {url: `/execute/buy/v5`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model463
    },
      options);
    }
  


export const getPostExecuteBuyV5MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV5>>, TError,{data: BodyType<Model463>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV5>>, TError,{data: BodyType<Model463>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBuyV5>>, {data: BodyType<Model463>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBuyV5(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBuyV5MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBuyV5>>>
    export type PostExecuteBuyV5MutationBody = BodyType<Model463>
    export type PostExecuteBuyV5MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Buy tokens
 */
export const usePostExecuteBuyV5 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV5>>, TError,{data: BodyType<Model463>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBuyV5MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Buy tokens
 */
export const postExecuteBuyV6 = (
    model472: BodyType<Model472>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteBuyV6Response>(
      {url: `/execute/buy/v6`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model472
    },
      options);
    }
  


export const getPostExecuteBuyV6MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV6>>, TError,{data: BodyType<Model472>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV6>>, TError,{data: BodyType<Model472>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBuyV6>>, {data: BodyType<Model472>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBuyV6(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBuyV6MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBuyV6>>>
    export type PostExecuteBuyV6MutationBody = BodyType<Model472>
    export type PostExecuteBuyV6MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Buy tokens
 */
export const usePostExecuteBuyV6 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV6>>, TError,{data: BodyType<Model472>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBuyV6MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to fill listings. We recommend using the SDK over this API as the SDK will iterate through the steps and return callbacks. Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Buy tokens (fill listings)
 */
export const postExecuteBuyV7 = (
    model480: BodyType<Model480>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteBuyV7Response>(
      {url: `/execute/buy/v7`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model480
    },
      options);
    }
  


export const getPostExecuteBuyV7MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV7>>, TError,{data: BodyType<Model480>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV7>>, TError,{data: BodyType<Model480>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteBuyV7>>, {data: BodyType<Model480>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteBuyV7(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteBuyV7MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteBuyV7>>>
    export type PostExecuteBuyV7MutationBody = BodyType<Model480>
    export type PostExecuteBuyV7MutationError = ErrorType<unknown>

    /**
 * @summary Buy tokens (fill listings)
 */
export const usePostExecuteBuyV7 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteBuyV7>>, TError,{data: BodyType<Model480>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteBuyV7MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Cancel existing orders on any marketplace
 * @summary Cancel orders
 */
export const postExecuteCancelV3 = (
    model493: BodyType<Model493>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteCancelV3Response>(
      {url: `/execute/cancel/v3`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model493
    },
      options);
    }
  


export const getPostExecuteCancelV3MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelV3>>, TError,{data: BodyType<Model493>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelV3>>, TError,{data: BodyType<Model493>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteCancelV3>>, {data: BodyType<Model493>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteCancelV3(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteCancelV3MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteCancelV3>>>
    export type PostExecuteCancelV3MutationBody = BodyType<Model493>
    export type PostExecuteCancelV3MutationError = ErrorType<unknown>

    /**
 * @summary Cancel orders
 */
export const usePostExecuteCancelV3 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelV3>>, TError,{data: BodyType<Model493>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteCancelV3MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This endpoint cancels Imtbl orders in their API, using the signature of the cancel request.
 * @summary Imtbl cancel orders
 */
export const postExecuteCancelimtblV1 = (
    model500: BodyType<Model500>,
    params?: PostExecuteCancelimtblV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostCancelImtblV1Response>(
      {url: `/execute/cancel-imtbl/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model500,
        params
    },
      options);
    }
  


export const getPostExecuteCancelimtblV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>, TError,{data: BodyType<Model500>;params?: PostExecuteCancelimtblV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>, TError,{data: BodyType<Model500>;params?: PostExecuteCancelimtblV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>, {data: BodyType<Model500>;params?: PostExecuteCancelimtblV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecuteCancelimtblV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteCancelimtblV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>>
    export type PostExecuteCancelimtblV1MutationBody = BodyType<Model500>
    export type PostExecuteCancelimtblV1MutationError = ErrorType<unknown>

    /**
 * @summary Imtbl cancel orders
 */
export const usePostExecuteCancelimtblV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelimtblV1>>, TError,{data: BodyType<Model500>;params?: PostExecuteCancelimtblV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteCancelimtblV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * If your order was created using the Seaport Oracle to allow off chain & gasless cancellations, you can just use the Kit's cancel modals, SDK's `cancelOrder`, or `/execute/cancel/`. Those tools will automatically access this endpoint for an oracle cancellation without you directly calling this endpoint.
 * @summary Off-chain cancel orders
 */
export const postExecuteCancelsignatureV1 = (
    model502: BodyType<Model502>,
    params?: PostExecuteCancelsignatureV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/execute/cancel-signature/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model502,
        params
    },
      options);
    }
  


export const getPostExecuteCancelsignatureV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>, TError,{data: BodyType<Model502>;params?: PostExecuteCancelsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>, TError,{data: BodyType<Model502>;params?: PostExecuteCancelsignatureV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>, {data: BodyType<Model502>;params?: PostExecuteCancelsignatureV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecuteCancelsignatureV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteCancelsignatureV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>>
    export type PostExecuteCancelsignatureV1MutationBody = BodyType<Model502>
    export type PostExecuteCancelsignatureV1MutationError = ErrorType<unknown>

    /**
 * @summary Off-chain cancel orders
 */
export const usePostExecuteCancelsignatureV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteCancelsignatureV1>>, TError,{data: BodyType<Model502>;params?: PostExecuteCancelsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteCancelsignatureV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Generate a listing and submit it to multiple marketplaces
 * @deprecated
 * @summary Create ask (listing)
 */
export const postExecuteListV4 = (
    model505: BodyType<Model505>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteListV4Response>(
      {url: `/execute/list/v4`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model505
    },
      options);
    }
  


export const getPostExecuteListV4MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV4>>, TError,{data: BodyType<Model505>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV4>>, TError,{data: BodyType<Model505>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteListV4>>, {data: BodyType<Model505>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteListV4(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteListV4MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteListV4>>>
    export type PostExecuteListV4MutationBody = BodyType<Model505>
    export type PostExecuteListV4MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Create ask (listing)
 */
export const usePostExecuteListV4 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV4>>, TError,{data: BodyType<Model505>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteListV4MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Generate listings and submit them to multiple marketplaces.

 Notes:

- Please use the `/cross-posting-orders/v1` to check the status on cross posted bids.

- We recommend using Reservoir SDK as it abstracts the process of iterating through steps, and returning callbacks that can be used to update your UI.
 * @summary Create asks (listings)
 */
export const postExecuteListV5 = (
    model509: BodyType<Model509>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteListV5Response>(
      {url: `/execute/list/v5`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model509
    },
      options);
    }
  


export const getPostExecuteListV5MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV5>>, TError,{data: BodyType<Model509>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV5>>, TError,{data: BodyType<Model509>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteListV5>>, {data: BodyType<Model509>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteListV5(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteListV5MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteListV5>>>
    export type PostExecuteListV5MutationBody = BodyType<Model509>
    export type PostExecuteListV5MutationError = ErrorType<unknown>

    /**
 * @summary Create asks (listings)
 */
export const usePostExecuteListV5 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteListV5>>, TError,{data: BodyType<Model509>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteListV5MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to mint tokens. We recommend using the SDK over this API as the SDK will iterate through the steps and return callbacks.
 * @summary Mint tokens
 */
export const postExecuteMintV1 = (
    model520: BodyType<Model520>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteMintV1Response>(
      {url: `/execute/mint/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model520
    },
      options);
    }
  


export const getPostExecuteMintV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteMintV1>>, TError,{data: BodyType<Model520>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteMintV1>>, TError,{data: BodyType<Model520>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteMintV1>>, {data: BodyType<Model520>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteMintV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteMintV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteMintV1>>>
    export type PostExecuteMintV1MutationBody = BodyType<Model520>
    export type PostExecuteMintV1MutationError = ErrorType<unknown>

    /**
 * @summary Mint tokens
 */
export const usePostExecuteMintV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteMintV1>>, TError,{data: BodyType<Model520>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteMintV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Attach a signature to an existing permit
 */
export const postExecutePermitsignatureV1 = (
    model523: BodyType<Model523>,
    params: PostExecutePermitsignatureV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/execute/permit-signature/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model523,
        params
    },
      options);
    }
  


export const getPostExecutePermitsignatureV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>, TError,{data: BodyType<Model523>;params: PostExecutePermitsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>, TError,{data: BodyType<Model523>;params: PostExecutePermitsignatureV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>, {data: BodyType<Model523>;params: PostExecutePermitsignatureV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecutePermitsignatureV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecutePermitsignatureV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>>
    export type PostExecutePermitsignatureV1MutationBody = BodyType<Model523>
    export type PostExecutePermitsignatureV1MutationError = ErrorType<unknown>

    /**
 * @summary Attach a signature to an existing permit
 */
export const usePostExecutePermitsignatureV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecutePermitsignatureV1>>, TError,{data: BodyType<Model523>;params: PostExecutePermitsignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecutePermitsignatureV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Attach a signature to an existing pre-signature
 */
export const postExecutePresignatureV1 = (
    model524: BodyType<Model524>,
    params: PostExecutePresignatureV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/execute/pre-signature/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model524,
        params
    },
      options);
    }
  


export const getPostExecutePresignatureV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecutePresignatureV1>>, TError,{data: BodyType<Model524>;params: PostExecutePresignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecutePresignatureV1>>, TError,{data: BodyType<Model524>;params: PostExecutePresignatureV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecutePresignatureV1>>, {data: BodyType<Model524>;params: PostExecutePresignatureV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecutePresignatureV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecutePresignatureV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecutePresignatureV1>>>
    export type PostExecutePresignatureV1MutationBody = BodyType<Model524>
    export type PostExecutePresignatureV1MutationError = ErrorType<unknown>

    /**
 * @summary Attach a signature to an existing pre-signature
 */
export const usePostExecutePresignatureV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecutePresignatureV1>>, TError,{data: BodyType<Model524>;params: PostExecutePresignatureV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecutePresignatureV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Send the success status of an execution
 */
export const postExecuteResultsV1 = (
    model525: BodyType<Model525>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteResultsV1Response>(
      {url: `/execute/results/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model525
    },
      options);
    }
  


export const getPostExecuteResultsV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteResultsV1>>, TError,{data: BodyType<Model525>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteResultsV1>>, TError,{data: BodyType<Model525>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteResultsV1>>, {data: BodyType<Model525>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteResultsV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteResultsV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteResultsV1>>>
    export type PostExecuteResultsV1MutationBody = BodyType<Model525>
    export type PostExecuteResultsV1MutationError = ErrorType<unknown>

    /**
 * @summary Send the success status of an execution
 */
export const usePostExecuteResultsV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteResultsV1>>, TError,{data: BodyType<Model525>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteResultsV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Sell tokens (accept bids)
 */
export const postExecuteSellV6 = (
    model528: BodyType<Model528>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteBuyV6Response>(
      {url: `/execute/sell/v6`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model528
    },
      options);
    }
  


export const getPostExecuteSellV6MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV6>>, TError,{data: BodyType<Model528>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV6>>, TError,{data: BodyType<Model528>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteSellV6>>, {data: BodyType<Model528>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteSellV6(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteSellV6MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteSellV6>>>
    export type PostExecuteSellV6MutationBody = BodyType<Model528>
    export type PostExecuteSellV6MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Sell tokens (accept bids)
 */
export const usePostExecuteSellV6 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV6>>, TError,{data: BodyType<Model528>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteSellV6MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Use this API to accept bids. We recommend using the SDK over this API as the SDK will iterate through the steps and return callbacks. Please mark `excludeEOA` as `true` to exclude Blur orders.
 * @summary Sell tokens (accept bids)
 */
export const postExecuteSellV7 = (
    model533: BodyType<Model533>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<GetExecuteSellV7Response>(
      {url: `/execute/sell/v7`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model533
    },
      options);
    }
  


export const getPostExecuteSellV7MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV7>>, TError,{data: BodyType<Model533>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV7>>, TError,{data: BodyType<Model533>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteSellV7>>, {data: BodyType<Model533>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteSellV7(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteSellV7MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteSellV7>>>
    export type PostExecuteSellV7MutationBody = BodyType<Model533>
    export type PostExecuteSellV7MutationError = ErrorType<unknown>

    /**
 * @summary Sell tokens (accept bids)
 */
export const usePostExecuteSellV7 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSellV7>>, TError,{data: BodyType<Model533>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteSellV7MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Indirectly fill an order via a solver
 */
export const postExecuteSolveV1 = (
    model540: BodyType<Model540>,
    params?: PostExecuteSolveV1Params,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteSolveV1Response>(
      {url: `/execute/solve/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model540,
        params
    },
      options);
    }
  


export const getPostExecuteSolveV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveV1>>, TError,{data: BodyType<Model540>;params?: PostExecuteSolveV1Params}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveV1>>, TError,{data: BodyType<Model540>;params?: PostExecuteSolveV1Params}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteSolveV1>>, {data: BodyType<Model540>;params?: PostExecuteSolveV1Params}> = (props) => {
          const {data,params} = props ?? {};

          return  postExecuteSolveV1(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteSolveV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteSolveV1>>>
    export type PostExecuteSolveV1MutationBody = BodyType<Model540>
    export type PostExecuteSolveV1MutationError = ErrorType<unknown>

    /**
 * @summary Indirectly fill an order via a solver
 */
export const usePostExecuteSolveV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveV1>>, TError,{data: BodyType<Model540>;params?: PostExecuteSolveV1Params}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteSolveV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get the status of an execution
 */
export const postExecuteStatusV1 = (
    model541: BodyType<Model541>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteStatusV1Response>(
      {url: `/execute/status/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model541
    },
      options);
    }
  


export const getPostExecuteStatusV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteStatusV1>>, TError,{data: BodyType<Model541>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteStatusV1>>, TError,{data: BodyType<Model541>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteStatusV1>>, {data: BodyType<Model541>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteStatusV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteStatusV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteStatusV1>>>
    export type PostExecuteStatusV1MutationBody = BodyType<Model541>
    export type PostExecuteStatusV1MutationError = ErrorType<unknown>

    /**
 * @summary Get the status of an execution
 */
export const usePostExecuteStatusV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteStatusV1>>, TError,{data: BodyType<Model541>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteStatusV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Batch transfer NFTs
 */
export const postExecuteTransferV1 = (
    model544: BodyType<Model544>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteTransferV1Response>(
      {url: `/execute/transfer/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model544
    },
      options);
    }
  


export const getPostExecuteTransferV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteTransferV1>>, TError,{data: BodyType<Model544>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteTransferV1>>, TError,{data: BodyType<Model544>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteTransferV1>>, {data: BodyType<Model544>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteTransferV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteTransferV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteTransferV1>>>
    export type PostExecuteTransferV1MutationBody = BodyType<Model544>
    export type PostExecuteTransferV1MutationError = ErrorType<unknown>

    /**
 * @summary Batch transfer NFTs
 */
export const usePostExecuteTransferV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteTransferV1>>, TError,{data: BodyType<Model544>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteTransferV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cancel Imtbl Orders
 */
export const postOrdersCancelimtblV1 = (
    model551: BodyType<Model551>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<string>(
      {url: `/orders/cancel-imtbl/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model551
    },
      options);
    }
  


export const getPostOrdersCancelimtblV1MutationOptions = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>, TError,{data: BodyType<Model551>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>, TError,{data: BodyType<Model551>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>, {data: BodyType<Model551>}> = (props) => {
          const {data} = props ?? {};

          return  postOrdersCancelimtblV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostOrdersCancelimtblV1MutationResult = NonNullable<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>>
    export type PostOrdersCancelimtblV1MutationBody = BodyType<Model551>
    export type PostOrdersCancelimtblV1MutationError = ErrorType<string>

    /**
 * @summary Cancel Imtbl Orders
 */
export const usePostOrdersCancelimtblV1 = <TError = ErrorType<string>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postOrdersCancelimtblV1>>, TError,{data: BodyType<Model551>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostOrdersCancelimtblV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This API requires an allowed API key for execution. Please contact technical support with more questions.
 * @summary Disable or reenable metadata for a token
 */
export const postTokensDisablemetadataV1 = (
    model553: BodyType<Model553>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/tokens/disable-metadata/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model553
    },
      options);
    }
  


export const getPostTokensDisablemetadataV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>, TError,{data: BodyType<Model553>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>, TError,{data: BodyType<Model553>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>, {data: BodyType<Model553>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensDisablemetadataV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensDisablemetadataV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>>
    export type PostTokensDisablemetadataV1MutationBody = BodyType<Model553>
    export type PostTokensDisablemetadataV1MutationError = ErrorType<unknown>

    /**
 * @summary Disable or reenable metadata for a token
 */
export const usePostTokensDisablemetadataV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensDisablemetadataV1>>, TError,{data: BodyType<Model553>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensDisablemetadataV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update token flag status
 */
export const postTokensFlagV1 = (
    model554: BodyType<Model554>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/tokens/flag/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model554
    },
      options);
    }
  


export const getPostTokensFlagV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensFlagV1>>, TError,{data: BodyType<Model554>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensFlagV1>>, TError,{data: BodyType<Model554>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensFlagV1>>, {data: BodyType<Model554>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensFlagV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensFlagV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensFlagV1>>>
    export type PostTokensFlagV1MutationBody = BodyType<Model554>
    export type PostTokensFlagV1MutationError = ErrorType<unknown>

    /**
 * @summary Update token flag status
 */
export const usePostTokensFlagV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensFlagV1>>, TError,{data: BodyType<Model554>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensFlagV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Token metadata is never automatically refreshed, but may be manually refreshed with this API.

Caution: This API should be used in moderation, like only when missing data is discovered. Calling it in bulk or programmatically will result in your API key getting rate limited.
 * @summary Refresh Token
 */
export const postTokensRefreshV1 = (
    model555: BodyType<Model555>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/tokens/refresh/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model555
    },
      options);
    }
  


export const getPostTokensRefreshV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensRefreshV1>>, TError,{data: BodyType<Model555>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensRefreshV1>>, TError,{data: BodyType<Model555>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensRefreshV1>>, {data: BodyType<Model555>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensRefreshV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensRefreshV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensRefreshV1>>>
    export type PostTokensRefreshV1MutationBody = BodyType<Model555>
    export type PostTokensRefreshV1MutationError = ErrorType<unknown>

    /**
 * @summary Refresh Token
 */
export const usePostTokensRefreshV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensRefreshV1>>, TError,{data: BodyType<Model555>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensRefreshV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Simulate the floor ask of any token
 */
export const postTokensSimulatefloorV1 = (
    model556: BodyType<Model556>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/tokens/simulate-floor/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model556
    },
      options);
    }
  


export const getPostTokensSimulatefloorV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>, TError,{data: BodyType<Model556>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>, TError,{data: BodyType<Model556>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>, {data: BodyType<Model556>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensSimulatefloorV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensSimulatefloorV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>>
    export type PostTokensSimulatefloorV1MutationBody = BodyType<Model556>
    export type PostTokensSimulatefloorV1MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Simulate the floor ask of any token
 */
export const usePostTokensSimulatefloorV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatefloorV1>>, TError,{data: BodyType<Model556>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensSimulatefloorV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @deprecated
 * @summary Simulate the top bid of any token
 */
export const postTokensSimulatetopbidV1 = (
    model557: BodyType<Model557>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/tokens/simulate-top-bid/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model557
    },
      options);
    }
  


export const getPostTokensSimulatetopbidV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>, TError,{data: BodyType<Model557>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>, TError,{data: BodyType<Model557>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>, {data: BodyType<Model557>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensSimulatetopbidV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensSimulatetopbidV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>>
    export type PostTokensSimulatetopbidV1MutationBody = BodyType<Model557>
    export type PostTokensSimulatetopbidV1MutationError = ErrorType<unknown>

    /**
 * @deprecated
 * @summary Simulate the top bid of any token
 */
export const usePostTokensSimulatetopbidV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSimulatetopbidV1>>, TError,{data: BodyType<Model557>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensSimulatetopbidV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * This API can be used by allowed API keys to update the spam status of a token.
 * @summary Update the tokens spam status
 */
export const postTokensSpamstatusV1 = (
    model559: BodyType<Model559>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/tokens/spam-status/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model559
    },
      options);
    }
  


export const getPostTokensSpamstatusV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSpamstatusV1>>, TError,{data: BodyType<Model559>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postTokensSpamstatusV1>>, TError,{data: BodyType<Model559>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postTokensSpamstatusV1>>, {data: BodyType<Model559>}> = (props) => {
          const {data} = props ?? {};

          return  postTokensSpamstatusV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostTokensSpamstatusV1MutationResult = NonNullable<Awaited<ReturnType<typeof postTokensSpamstatusV1>>>
    export type PostTokensSpamstatusV1MutationBody = BodyType<Model559>
    export type PostTokensSpamstatusV1MutationError = ErrorType<unknown>

    /**
 * @summary Update the tokens spam status
 */
export const usePostTokensSpamstatusV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postTokensSpamstatusV1>>, TError,{data: BodyType<Model559>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostTokensSpamstatusV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Re-activates listings when a token is transferred from one account to another, and then back
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_activity_transfer` event
 */
export const postWebhooksImtblzkevmactivitytransfereventV1 = (
    metadataBody: BodyType<MetadataBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/webhooks/imtbl-zkevm-activity-transfer-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: metadataBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmactivitytransfereventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>, TError,{data: BodyType<MetadataBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>, {data: BodyType<MetadataBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmactivitytransfereventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmactivitytransfereventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>>
    export type PostWebhooksImtblzkevmactivitytransfereventV1MutationBody = BodyType<MetadataBody>
    export type PostWebhooksImtblzkevmactivitytransfereventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_activity_transfer` event
 */
export const usePostWebhooksImtblzkevmactivitytransfereventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmactivitytransfereventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmactivitytransfereventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Consumer for the Immutable Webhook `imtbl_zkevm_collection_updated` event. This event is triggered when a collection is updated in Immutable, and updates the collection spam and verification status.
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_collection_updated` event
 */
export const postWebhooksImtblzkevmcollectionupdatedeventV1 = (
    metadataBody: BodyType<MetadataBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/webhooks/imtbl-zkevm-collection-updated-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: metadataBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmcollectionupdatedeventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>, {data: BodyType<MetadataBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmcollectionupdatedeventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmcollectionupdatedeventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>>
    export type PostWebhooksImtblzkevmcollectionupdatedeventV1MutationBody = BodyType<MetadataBody>
    export type PostWebhooksImtblzkevmcollectionupdatedeventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_collection_updated` event
 */
export const usePostWebhooksImtblzkevmcollectionupdatedeventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmcollectionupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmcollectionupdatedeventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Triggers token reindexing when a metadata update event is received from Immutable
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_metadata_updated` event
 */
export const postWebhooksImtblzkevmmetadataupdatedeventV1 = (
    metadataBody: BodyType<MetadataBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/webhooks/imtbl-zkevm-metadata-updated-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: metadataBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmmetadataupdatedeventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>, {data: BodyType<MetadataBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmmetadataupdatedeventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmmetadataupdatedeventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>>
    export type PostWebhooksImtblzkevmmetadataupdatedeventV1MutationBody = BodyType<MetadataBody>
    export type PostWebhooksImtblzkevmmetadataupdatedeventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_metadata_updated` event
 */
export const usePostWebhooksImtblzkevmmetadataupdatedeventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmmetadataupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmmetadataupdatedeventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Token metadata updates from Immutable can automatically trigger re-indexing in reservoir via Webhooks
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_nft_updated` event
 */
export const postWebhooksImtblzkevmnftupdatedeventV1 = (
    metadataBody: BodyType<MetadataBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/webhooks/imtbl-zkevm-nft-updated-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: metadataBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmnftupdatedeventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>, {data: BodyType<MetadataBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmnftupdatedeventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmnftupdatedeventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>>
    export type PostWebhooksImtblzkevmnftupdatedeventV1MutationBody = BodyType<MetadataBody>
    export type PostWebhooksImtblzkevmnftupdatedeventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_nft_updated` event
 */
export const usePostWebhooksImtblzkevmnftupdatedeventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmnftupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmnftupdatedeventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Re-activates listings when a cancelled order is activated again, usually after a transfer
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_order_updated` event
 */
export const postWebhooksImtblzkevmorderupdatedeventV1 = (
    metadataBody: BodyType<MetadataBody>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/webhooks/imtbl-zkevm-order-updated-event/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: metadataBody
    },
      options);
    }
  


export const getPostWebhooksImtblzkevmorderupdatedeventV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>, {data: BodyType<MetadataBody>}> = (props) => {
          const {data} = props ?? {};

          return  postWebhooksImtblzkevmorderupdatedeventV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostWebhooksImtblzkevmorderupdatedeventV1MutationResult = NonNullable<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>>
    export type PostWebhooksImtblzkevmorderupdatedeventV1MutationBody = BodyType<MetadataBody>
    export type PostWebhooksImtblzkevmorderupdatedeventV1MutationError = ErrorType<unknown>

    /**
 * @summary Immutable Webhook endpoint for the `imtbl_zkevm_order_updated` event
 */
export const usePostWebhooksImtblzkevmorderupdatedeventV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postWebhooksImtblzkevmorderupdatedeventV1>>, TError,{data: BodyType<MetadataBody>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostWebhooksImtblzkevmorderupdatedeventV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Override collections metadata and royalties
 * @summary Override collections
 */
export const postCollectionsCollectionOverrideV1 = (
    collection: string,
    model562: BodyType<Model562>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/collections/${collection}/override/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model562
    },
      options);
    }
  


export const getPostCollectionsCollectionOverrideV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>, TError,{collection: string;data: BodyType<Model562>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>, TError,{collection: string;data: BodyType<Model562>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>, {collection: string;data: BodyType<Model562>}> = (props) => {
          const {collection,data} = props ?? {};

          return  postCollectionsCollectionOverrideV1(collection,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostCollectionsCollectionOverrideV1MutationResult = NonNullable<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>>
    export type PostCollectionsCollectionOverrideV1MutationBody = BodyType<Model562>
    export type PostCollectionsCollectionOverrideV1MutationError = ErrorType<unknown>

    /**
 * @summary Override collections
 */
export const usePostCollectionsCollectionOverrideV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postCollectionsCollectionOverrideV1>>, TError,{collection: string;data: BodyType<Model562>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostCollectionsCollectionOverrideV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get the capacity for indirect filling via a solver
 */
export const postExecuteSolveCapacityV1 = (
    model563: BodyType<Model563>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PostExecuteSolveCapacityV1Response>(
      {url: `/execute/solve/capacity/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model563
    },
      options);
    }
  


export const getPostExecuteSolveCapacityV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>, TError,{data: BodyType<Model563>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>, TError,{data: BodyType<Model563>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>, {data: BodyType<Model563>}> = (props) => {
          const {data} = props ?? {};

          return  postExecuteSolveCapacityV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostExecuteSolveCapacityV1MutationResult = NonNullable<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>>
    export type PostExecuteSolveCapacityV1MutationBody = BodyType<Model563>
    export type PostExecuteSolveCapacityV1MutationError = ErrorType<unknown>

    /**
 * @summary Get the capacity for indirect filling via a solver
 */
export const usePostExecuteSolveCapacityV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postExecuteSolveCapacityV1>>, TError,{data: BodyType<Model563>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostExecuteSolveCapacityV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Simulate any given order
 */
export const postManagementOrdersSimulateV1 = (
    model564: BodyType<Model564>,
 options?: SecondParameter<typeof client>,) => {
      
      
      return client<PutSetCollectionCommunityV1Response>(
      {url: `/management/orders/simulate/v1`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: model564
    },
      options);
    }
  


export const getPostManagementOrdersSimulateV1MutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>, TError,{data: BodyType<Model564>}, TContext>, request?: SecondParameter<typeof client>}
): UseMutationOptions<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>, TError,{data: BodyType<Model564>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>, {data: BodyType<Model564>}> = (props) => {
          const {data} = props ?? {};

          return  postManagementOrdersSimulateV1(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PostManagementOrdersSimulateV1MutationResult = NonNullable<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>>
    export type PostManagementOrdersSimulateV1MutationBody = BodyType<Model564>
    export type PostManagementOrdersSimulateV1MutationError = ErrorType<unknown>

    /**
 * @summary Simulate any given order
 */
export const usePostManagementOrdersSimulateV1 = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postManagementOrdersSimulateV1>>, TError,{data: BodyType<Model564>}, TContext>, request?: SecondParameter<typeof client>}
) => {

      const mutationOptions = getPostManagementOrdersSimulateV1MutationOptions(options);

      return useMutation(mutationOptions);
    }
    

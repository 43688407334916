import { useOrchestratedWidget } from '@/components/features/IMX/IMXCheckoutActions/useOrchestratedWidget';
import { env } from '@/env.mjs';
import { routes } from '@/utils/routes';
import { type Currency } from '@sphere/reservoir-kit-ui';
import { Dispatch, SetStateAction } from 'react';
import { defineChain, defineTransactionRequest, toHex, zeroAddress, type Chain } from 'viem';
import {
  beam,
  beamTestnet,
  immutableZkEvm as nativeImmutableZkEvm,
  immutableZkEvmTestnet as nativeImmutableZkEvmTestnet,
} from 'viem/chains';
import { ReservoirAction } from './reservoir';
import usdcContracts from './usdcContracts';
import wrappedContracts from './wrappedContracts';

export type ReservoirChain = Chain & {
  lightIconUrl: string;
  darkIconUrl: string;
  reservoirBaseUrl: string;
  supportedConnectors: string[];
  onAddFunds(modalState: [boolean, Dispatch<SetStateAction<boolean>>]): void;
  proxyApi?: string;
  routePrefix: string;
  reservoirApiKey?: string;
  coingeckoId?: string;
  collectionSetId?: string;
  community?: string;
  wssUrl?: string;
  defaultCurrencies?: Currency[];
  customCurrencies?: Currency[];
  oracleBidsEnabled?: boolean;
  checkPollingInterval?: number;
  disabledActions?: ReservoirAction[];
  bidCurrencies?: Currency[];
  multiCurrencyCartEnabled?: boolean;
  gasBalanceCheckEnabled?: boolean;
};

/**
 * If wrapped tokens have the same coingecko id as their native token, they need to be added here.
 */
export const tokensWithDuplicateCoingeckoIds: Record<string, string[]> = {
  IMX: ['WIMX'],
  SOPH: ['WSOPH'],
};

const nativeCurrencyBase = {
  contract: zeroAddress,
  symbol: 'ETH',
  decimals: 18,
  coinGeckoId: 'ethereum',
};

const usdcCurrencyBase = {
  contract: '',
  symbol: 'USDC',
  decimals: 6,
  coinGeckoId: 'usd-coin',
};

/**
 * Defines all the supported connectors across all chains.
 * Also used to determine the order in which they are displayed.
 */
export const SUPPORTED_CONNECTOR_IDS = [
  'com.onbeam',
  'com.immutable.passport',
  'io.metamask',
  'io.rabby',
  'coinbaseWalletSDK',
  'walletConnect',
];

const supportedBeamConnectors = SUPPORTED_CONNECTOR_IDS.filter(
  c => ['com.immutable.passport'].indexOf(c) < 0,
);

const supportedImmutableZkEvmConnectors = SUPPORTED_CONNECTOR_IDS.filter(
  c => ['com.immutable.passport', 'io.metamask'].indexOf(c) !== -1,
);

const supportedSophonConnectors = supportedBeamConnectors;

type ZkEVMRpcTransactionOverrides = {
  gas?: bigint;
  value?: bigint;
};

export const sophonTestnet = defineChain({
  id: 531050104,
  name: 'Sophon Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Sophon',
    symbol: 'SOPH',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.testnet.sophon.xyz'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Sophon Testnet Explorer',
      url: 'https://explorer.testnet.sophon.xyz/',
    },
  },
  contracts: {
    multicall3: {
      address: '0x83c04d112adedA2C6D9037bb6ecb42E7f0b108Af',
      blockCreated: 15642,
    },
  },
  testnet: true,
});

export const immutableZkEvm = defineChain({
  ...nativeImmutableZkEvm,
  formatters: {
    transactionRequest: defineTransactionRequest({
      format(args: ZkEVMRpcTransactionOverrides) {
        // We only want to override values for real transactions, which require the user to
        // approve the transaction and pay gas. In those cases, `value` is not undefined but
        // holds the amount of the transaction. We use this to recognize real transactions.
        if (typeof args.value === 'undefined') return {};

        // Values below are provided by IMX and not to be changed.
        return {
          type: '0x2', // eip1559
          maxPriorityFeePerGas: '0x2540be400', // 10_000_000_000 (10 gwei)
          maxFeePerGas: '0x37e11d600', // 15_000_000_000 (15 gwei)
          chainId: '0x343b', // 13371
          gas: args.gas ? toHex(args.gas) : undefined,
        };
      },
    }),
  },
});

export const immutableZkEvmTestnet = defineChain({
  ...nativeImmutableZkEvmTestnet,
  name: 'Immutable Testnet',
  formatters: {
    transactionRequest: defineTransactionRequest({
      format(args: ZkEVMRpcTransactionOverrides) {
        // We only want to override values for real transactions, which require the user to
        // approve the transaction and pay gas. In those cases, `value` is not undefined but
        // holds the amount of the transaction. We use this to recognize real transactions.
        if (typeof args.value === 'undefined') return {};

        // Values below are provided by IMX and not to be changed.
        return {
          type: '0x2', // eip1559
          maxPriorityFeePerGas: '0x2540be400', // 10_000_000_000 (10 gwei)
          maxFeePerGas: '0x37e11d600', // 15_000_000_000 (15 gwei)
          chainId: '0x34a1', // 13473
          gas: args.gas ? toHex(args.gas) : undefined,
        };
      },
    }),
  },
});

const chains: ReservoirChain[] = [
  {
    ...beam,
    lightIconUrl: '/icons/beam-icon-dark.svg',
    darkIconUrl: '/icons/beam-icon-light.svg',
    reservoirBaseUrl:
      process.env.NEXT_PUBLIC_RESERVOIR_BEAM_MAINNET_URL || 'https://4337.api.sphere.market',
    reservoirApiKey: process.env.RESERVOIR_BEAM_API_KEY,
    proxyApi: '/api/reservoir/beam',
    routePrefix: 'beam',
    coingeckoId: 'beam-2',
    // collectionSetId: process.env.NEXT_PUBLIC_beam_COLLECTION_SET_ID,
    // community: process.env.NEXT_PUBLIC_beam_COMMUNITY,
    // wssUrl: 'wss://<to-be-defined>',
    defaultCurrencies: [
      {
        ...nativeCurrencyBase,
        symbol: 'BEAM',
        coinGeckoId: 'beam-2',
      },
      {
        ...usdcCurrencyBase,
        contract: usdcContracts[beam.id],
      },
      {
        contract: wrappedContracts[beam.id],
        symbol: 'WBEAM',
        decimals: 18,
        coinGeckoId: 'wrapped-merit-circle',
      },
    ],
    oracleBidsEnabled: true,
    checkPollingInterval: 1_000,
    supportedConnectors: supportedBeamConnectors,
    onAddFunds: () => window.open(routes.external.swap, '_blank'),
    gasBalanceCheckEnabled: true,
  },
  {
    ...beamTestnet,
    lightIconUrl: '/icons/beam-icon-dark.svg',
    darkIconUrl: '/icons/beam-icon-light.svg',
    reservoirBaseUrl:
      process.env.NEXT_PUBLIC_RESERVOIR_BEAM_TESTNET_URL || 'https://13337.api.sphere.market',
    reservoirApiKey: process.env.RESERVOIR_BEAM_API_KEY,
    proxyApi: '/api/reservoir/beam-testnet',
    routePrefix: 'beam-testnet',
    coingeckoId: 'beam-2',
    // collectionSetId: process.env.NEXT_PUBLIC_beam_COLLECTION_SET_ID,
    // community: process.env.NEXT_PUBLIC_beam_COMMUNITY,
    // wssUrl: 'wss://<to-be-defined>',
    defaultCurrencies: [
      {
        ...nativeCurrencyBase,
        symbol: 'BEAM',
        coinGeckoId: 'beam-2',
      },
      {
        ...usdcCurrencyBase,
        contract: usdcContracts[beamTestnet.id],
      },
      {
        contract: wrappedContracts[beamTestnet.id],
        symbol: 'WBEAM',
        decimals: 18,
        coinGeckoId: 'wrapped-merit-circle',
      },
      {
        contract: '0x78ec2fcc0950c573a65e03302289a4617200a561',
        symbol: 'RAT',
        decimals: 6,
        coinGeckoId: 'beamcat', // TODO: add real coingecko id (no price yet)
      },
    ],
    oracleBidsEnabled: true,
    checkPollingInterval: 1_000,
    supportedConnectors: supportedBeamConnectors,
    onAddFunds: () => window.open(routes.external.swap, '_blank'),
    gasBalanceCheckEnabled: true,
  },
  {
    ...immutableZkEvm,
    lightIconUrl: '/icons/immutable-icon-dark.svg',
    darkIconUrl: '/icons/immutable-icon-light.svg',
    reservoirBaseUrl:
      process.env.NEXT_PUBLIC_RESERVOIR_IMMUTABLE_MAINNET_URL || 'https://13371.api.sphere.market',
    reservoirApiKey: process.env.RESERVOIR_IMMUTABLE_API_KEY,
    proxyApi: '/api/reservoir/immutable',
    routePrefix: 'immutable',
    coingeckoId: 'immutable-x',
    // collectionSetId: process.env.NEXT_PUBLIC_immutable_COLLECTION_SET_ID,
    // community: process.env.NEXT_PUBLIC_immutable_COMMUNITY,
    // wssUrl: 'wss://<to-be-defined>',
    defaultCurrencies: [
      {
        ...nativeCurrencyBase,
        symbol: 'IMX',
        coinGeckoId: 'immutable-x',
      },
      {
        ...usdcCurrencyBase,
        contract: usdcContracts[immutableZkEvm.id],
      },
      {
        contract: wrappedContracts[immutableZkEvm.id],
        symbol: 'WIMX',
        decimals: 18,
        coinGeckoId: 'immutable-x',
      },
      {
        contract: '0x52a6c53869ce09a731cd772f245b97a4401d3348',
        symbol: 'ETH',
        decimals: 18,
        coinGeckoId: 'ethereum',
      },
    ],
    oracleBidsEnabled: true,
    checkPollingInterval: 1_000,
    disabledActions: [
      ReservoirAction.AcceptBid,
      ReservoirAction.Bid,
      ReservoirAction.CancelBid,
      ReservoirAction.Collect,
      ReservoirAction.EditBid,
      ReservoirAction.EditListing,
    ],
    supportedConnectors: supportedImmutableZkEvmConnectors,
    onAddFunds: modalState => {
      modalState[1](false);
      useOrchestratedWidget.setState({ orchestratedWidgetType: 'wallet' });
    },
    gasBalanceCheckEnabled: true,
  },
  {
    ...immutableZkEvmTestnet,
    lightIconUrl: '/icons/immutable-icon-dark.svg',
    darkIconUrl: '/icons/immutable-icon-light.svg',
    reservoirBaseUrl:
      process.env.NEXT_PUBLIC_RESERVOIR_IMMUTABLE_TESTNET_URL || 'https://13473.api.sphere.market',
    reservoirApiKey: process.env.RESERVOIR_IMMUTABLE_API_KEY,
    proxyApi: '/api/reservoir/immutable-testnet',
    routePrefix: 'immutable-testnet',
    coingeckoId: 'immutable-x',
    // collectionSetId: process.env.NEXT_PUBLIC_immutable_COLLECTION_SET_ID,
    // community: process.env.NEXT_PUBLIC_immutable_COMMUNITY,
    // wssUrl: 'wss://<to-be-defined>',
    defaultCurrencies: [
      {
        ...nativeCurrencyBase,
        symbol: 'IMX',
        coinGeckoId: 'immutable-x',
      },
      {
        ...usdcCurrencyBase,
        contract: usdcContracts[immutableZkEvmTestnet.id],
      },
      {
        contract: wrappedContracts[immutableZkEvmTestnet.id],
        symbol: 'WIMX',
        decimals: 18,
        coinGeckoId: 'immutable-x',
      },
      {
        contract: '0xe9e96d1aad82562b7588f03f49ad34186f996478',
        symbol: 'ETH',
        decimals: 18,
        coinGeckoId: 'ethereum',
      },
    ],
    oracleBidsEnabled: true,
    checkPollingInterval: 1_000,
    disabledActions: [
      ReservoirAction.AcceptBid,
      ReservoirAction.Bid,
      ReservoirAction.CancelBid,
      ReservoirAction.Collect,
      ReservoirAction.EditBid,
      ReservoirAction.EditListing,
    ],
    supportedConnectors: supportedImmutableZkEvmConnectors,
    onAddFunds: modalState => {
      modalState[1](false);
      useOrchestratedWidget.setState({ orchestratedWidgetType: 'wallet' });
    },
    gasBalanceCheckEnabled: true,
  },
  {
    ...sophonTestnet,
    lightIconUrl: '/icons/sophon-icon-dark.svg',
    darkIconUrl: '/icons/sophon-icon-light.svg',
    reservoirBaseUrl:
      process.env.NEXT_PUBLIC_RESERVOIR_SOPHON_TESTNET_URL || 'https://531050104.api.sphere.market',
    reservoirApiKey: process.env.RESERVOIR_SOPHON_API_KEY,
    proxyApi: '/api/reservoir/sophon-testnet',
    routePrefix: 'sophon-testnet',
    coingeckoId: 'sophon',
    defaultCurrencies: [
      {
        ...usdcCurrencyBase,
        contract: usdcContracts[sophonTestnet.id],
      },
    ],
    oracleBidsEnabled: true,
    checkPollingInterval: 1_000,
    supportedConnectors: supportedSophonConnectors,
    onAddFunds: () => window.open('https://app.sophon.xyz', '_blank'),
  },
];

const chainIds = (env.NEXT_PUBLIC_MARKETPLACE_SUPPORTED_CHAINS || `${beam.id}`)
  .split(',')
  .map(Number);
if (!chainIds.length) throw new Error('At least one supported chain must be specified');

const supportedChains = chains.filter(chain => chainIds.includes(chain.id));

const DefaultChain = supportedChains[0];

export default supportedChains;

export { DefaultChain, chains };

import { env } from '@/env.mjs';
import { BeamConfiguration, ChainId } from '@onbeam/sdk';

const environment = env.NEXT_PUBLIC_BEAM_ENVIRONMENT;
const publishableKey = `${env.NEXT_PUBLIC_BEAM_PUBLISHABLE_KEY}`;

const config = new BeamConfiguration({
  chains: [
    {
      id:
        env.NEXT_PUBLIC_BEAM_ENVIRONMENT === 'mainnet'
          ? ChainId.BEAM_MAINNET
          : ChainId.BEAM_TESTNET,
      publishableKey,
      isPreview: environment === 'preview',
    },
  ],
  debug: environment === 'preview',
});

export { config };
